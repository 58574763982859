// Customizable Area Start
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { styled } from "@material-ui/styles";
import { Button, Box, Grid, Dialog, Checkbox, InputLabel, Divider, Chip, withStyles, Tooltip, TextField, CircularProgress } from "@material-ui/core";
import ProjectText from "./ProjectText";
import { ContainedButton } from "./GlobalButton";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { 
    add, 
    add_transparent,
    downIMg,
    image_success,
    remove,
    upIMg,
    close } from "../../blocks/listdemandpickflow/src/assets";
import moment from "moment";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import LandingPageTextField, { OptionForSelect, ProjectTextField, AutocompleteStyle, } from "./ProjectTextField";
import { Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import Spinner from "./ProjectLoader";

interface AddListingProps extends RouteComponentProps {
    SellerPendingScreen?: boolean,
    isBuyerScreen?: boolean,
    data?: any,
    navigation?: any;
    principalOrgList?: any;
    EditId?: string;
    APicallForTable?: () => void
}

interface AddListingState {
    addListingModal: boolean;
    switch: boolean;
    isSlotFilterOpen: boolean;
    activeSlotFilter: any;
    prinicpalOrgList: any;
    show: boolean;
    addListing: boolean;
    etbDate: string;
    loginData: any;
    expiry_date: any;
    FormikFormReenableReinitialize: boolean;
    FormIsEdit: boolean;
    isViewLess: boolean;
    listingAdded: boolean;
    addedListingID: string;
    loading: boolean;
    token: string;
    DeleteContainer: Array<{
        DeleteContainerType?: number,
        DeleteContainerSize?: Array<number>
    }>,
    EditFormId: string,
    EditListingId:string,
    vesselVoyageData: Array<{
        id: number | string;
        vessel_code: string;
        in_voyage_code: string;
        out_voyage_code: string;
    }>;
    voyageData: Array<{
        id: number | string;
        vessel_code: string;
        in_voyage_code: string;
        out_voyage_code: string;
    }>;
    etaDate: string;
    PolDropDownData: Array<{
        PortId: string,
        Type: string,
        PortName: string,
        PortCountry: string,
        PortRegion: string,
        PortDescription: string
    }>,
    containerData: Array<{
        container_type: string;
        seller_box_sizes: Array<string>;
        codes: Array<string>;
    }>;
    selectedContainer: any;
    FormEditData: {
        PrincipalOrganizationName: string,
        Vessel: string,
        Voyage: string,
        Pod: string,
        PodTerminal: string,
        Pol: string,
        PolTerminal: string,
        Email: string,
        EtaPod: string,
        EtbPol: string,
        FullName: string,
        OrganizationCode: string,
        ContactNumber: string,
        DistributionEmail: string,
        Expiry: string,
        TotalTeu: string,
        Validity: string,
        out_voyage: string,
        in_voyage: string
    },
    ContainerEditDetails: Array<
        {
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            ContainerId: string,
            CONTAINERSIZEARRAY: Array<{
                ContainerSizeId: string,
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string,
            }>
        }
    >,
    AllPriceTermsData: Array<{
        id: string,
        BoxSize: string
        Container: string
        PortOfDestination: string
        PortOfLocation: string
        Rate: string
        TermsOne: string,
        TermsTwo: string,
    }>
    sellerListings: any;
    filter: {
        listingID: Array<{ label: string; isChecked: boolean }>;
        pol: Array<{ label: string; isChecked: boolean }>;
        pod: Array<{ label: string; isChecked: boolean }>;
        vessel: Array<{ label: string; isChecked: boolean }>;
        startDate: string;
        endDate: string
    };
    polData: any;
    sellerListingSnackbarOpen: boolean,
    priceAndTermData: any;
    PrincipalOrganizationSelectedName: string
    EmailDistributionListData: Array<string>
    PolTerminalDropDown: Array<string>,
    PodTerminalDropDown: Array<string>,
    VesselDropDownData: Array<{
        inVoyN: string,
        outVoyN: string,
        vslM: string,
        name: string
    }>,
    PortNetApiAllData: Array<{
        inVoyN: string,
        outVoyN: string,
        vslM: string,
        name: string
    }>,
    VoyageDropDownData: Array<{
        inVoyN: string,
        outVoyN: string,
        vslM: string,
        name: string
    }>,
    SelectedVesselVoyage: {
        inVoyN?: string,
        outVoyN?: string,
        vslM?: string,
        name?: string
    },
    IsVesselSelected: boolean,
    InVoyogeAndOutVoyoge: string,
    SelectedPol: string,
    SelectedPolTerminal: string,
    SelectedPod: string,
    SelectedPodTerminal: string,
    VesselDropDownOpen: boolean,
    VoyageDropDownOpen: boolean,
    VesselDropDownString: string,
    IsEtaEtbDateDisable: boolean,
    PortNetAPiCall: boolean,
    isMatchesFound: boolean,
    voyageValue: any,
    POLTerminalValue: any,
    PODTerminalValue: any,
    vesselInputValue: any,
    vesselDropdownState: any,
}

interface FormValues {
    PRINCIPALORGANISATION: string,
    VESSEL: string,
    VOYAGE: string,
    POD: string,
    PODTERMINAL: string,
    POL: string,
    POLTERMINAL: string,
    ETBATPOL: string,
    ETAATPOD: string,
    QUANTITYOFTEUSAVAILABLEFORSALE: string,
    VALIDITYOFLISTING: string,
    EXPIRYDATE: string,
    ContainerPricingDetails: FormErros[],
    FULLNAME: string,
    EMAILADDRESS: string,
    CONTACTNUMBER: string,
    EMAILDISTRIBUTIONLIST: string | string[],
}

interface FormErros {
    CONTAINERTYPE: string,
    CONTAINERCODES: string,
    CONTAINERSIZEARRAY: Array<{
        CONTAINERSIZE: string,
        AVERAGETONSBOX: string,
        RATE: string,
        TERMSONE: string,
        TERMSTWO: string
    }>
}


const useStyles = {
    btnDiv: {
        display: "flex", alignItems: "center", justifyContent: "space-between",
    },
    addListing: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: ".875rem",
        background: "#1653DD",
        border: "none",
        borderRadius: "2rem",
        padding: ".5625rem 1.125rem",
        color: "#FFFFFF",
        marginRight: "1.875rem",
        fontWeight: 600,
        height: "3rem",
        width: "16rem",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
    },
    btnImgDiv: { marginRight: "8px" },
};

export const SectionLIstingWrapper = styled("section")({
    "@media (max-width: 960px)": {
        width: "100%"
    },
    "& .TempButton": {
        "@media (max-width: 960px)": {
            marginTop: '14px',
        },
        "@media (max-width: 430px)": {
            marginTop: '14px',
            width: '100% !important',
            marginRight: '0px !important'
        },
    },
    "& .btnDivBox": {
        "@media (max-width: 960px)": {
            justifyContent: 'center !important'
        },
    }

})
const CustomDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        maxWidth:"820px",
      borderRadius: '15px',
      overflowY: 'auto', // Enable vertical scrolling
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#888',
      },
    },
  });

const StyleModel = styled(Box)({
    "& .CodesMultiSelect": {
        height: "56px"
    },
    "& .AutoComplateTextField": {
        color: "#3CA8D6",
    },
    "& .DeleteChip": {
        margin: "0 2px",
        backgroundColor: "#e3e6ed",
        color: "black",
        borde: "none",
        fontWeight: "600 !important",
        fontFamily: "Asap"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked":{
        color:'#000'
    },
    
    "& .MuiAutocomplete-inputRoot":{    
        fontFamily:'asap'
      },
    "& .MultiSelect": {
        "& .MuiOutlinedInput-input": {
            padding: "5px 12px",
            height: "33px"
        }
    },

    "& .custom-checkbox .MuiSvgIcon-root" :{
        color: '#4caf50' 
      },
      
      "& .custom-checkbox .Mui-checked .MuiSvgIcon-root" : {
        color: '#4caf50' 
      }
      
       ,
    "& .MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded":{
        minWidth:'100px'
    },
    "& .ViewlessContainer": {
        "& .MuiInputBase-input .Mui-disabled": {
            color: "black"
        }
    },
    "& .SellerMultipleSelect": {
        "& .SellerReactSelects__control": {
            borderRadius: "10px",
        }
    },
    "& .ErrorTextContainer": {
        display: "flex",
        marginTop: "5px",
        alignItems: "center",
        "& .TextHidden": {
            visibility: "hidden"
        },
        "& .ErrorIconContainer": {
            marginRight: "6px",
            "& .MuiSvgIcon-root": {
                color: "red"
            }
        },
        "& .TextError": {
            color: "red",
            fontSize: "16px",
            marginBottom: "0px",
            transition: "width 1s ease-out ",
            fontFamily: "Asap, sans-serif",
        },
    },
    "& .PhoneInputPackgeClass": {
        height: "40px",
        borderRadius: "6px",
        fontSize: "15px",
        fontFamily: "Asap, sans-serif",
        width: "100%",
        fontWeight: 500,
        backgroundColor: "white",
        "@media (max-width: 1200px)": {
            fontSize: "12px",
            height: "30px"
        }
    },
    "& .centerGrid": {
        justifyContent: "center"
    },
    "& .AddContainerFirstButton": {
        display: "flex",
        justifyContent: "flex-end",
        whiteSpace: "nowrap",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey",
            "& .BlackPlusIcon": {
                filter: "brightness(1)"
            },
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .SlashClassContainer": {
        position: "relative",
        "& .SlashClass": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            transform: "translate(40%, 100%)"
        },
    },
    "& .CenterAddContainer": {
        display: "flex",
        justifyContent: "center !important",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .CurrencyBox": {
        marginRight: "5px",
        borderRight:"1px solid darkgrey",
         padding:'8px 5px 6px 0px'
    },
    "& .ViewMoreContainer": {
        position: "absolute",
        right: "20px",
        "@media (max-width: 600px)": {
            marginTop: "20px"
        }
    },
    "& .BlackPlusIcon": {
        filter: "brightness(0)"
    },
    "& .AddContainerSecondButton": {
        display: "flex",
        justifyContent: "flex-start",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        }
    },
    "& .AddContainerSecondButtonCenter": {
        display: "flex",
        justifyContent: "flex-start",
        "& .Mui-disabled": {
            backgroundColor: "lightgrey",
            color: "grey"
        },
        "@media (max-width: 960px)": {
            display: "flex",
            justifyContent: "center"
        }
    },
    "& .DivedSection": {
        "& .MuiDivider-root": {
            backgroundColor: "white",
            height: "5px"
        }
    },
    "& .dial-code": {
        fontFamily: "Asap, sans-serif",
        fontWeight: "500",
        color: "black !important",
    },
    "& .no-entries-message": {
        fontFamily: "Asap, sans-serif",
        fontWeight: "500",
        color: "black !important",
    },
    "& .country-name": {
        fontFamily: "Asap, sans-serif",
    },
    "& .ErrorPhoneClass": {
        "& .PhoneInputPackgeClass": {
            backgroundColor: "#FBE9E9"
            
        },
        "& .selected-flag": {
            backgroundColor: "#FBE9E9"
        },
        "& .flag-dropdown": {
            backgroundColor: "#FBE9E9"
        },
    },
    "& .flag-dropdown": {
        borderRight: "none",
        borderRadius: "6px 0px 0px 6px"
    },
    "& .selected-flag": {
        backgroundColor: "white",
        borderRadius: "10px"
    },
    "& .PhoneInput": {
        position: "relative",
        "& .PhoneError": {
            fontSize: "18px",
            position: "absolute",
            color: "red",
            margin: "6px 14px",
        }
    },
});

const AddListingFormWrapper = styled(Box)({
    padding: "20px 10px",
    "& .infoIcon": {
        marginLeft: "6px",
        fontSize: "1.3rem"
    },
    "& .close": {
        textAlign: "end" as "end",
        cursor: "pointer",
    },
    "& .title": {
        fontSize: "28px",
        color: "#1E1E1E",
        fontFamily: "Asap, sans-serif",
        margin: "0 0 15px 0",
        textAlign: "center" as "center",
    },
    "& .vesselHeading": {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "asap, sans-serif",
        textAlign: "center" as "center",
        margin: "0 0 25px 0",
    },
    "& .inputHeightMedia": {
        height: "initial !important"
    },
    "& .dFlexJCenter": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .btnTheme": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px",
        background: "#1653DD",
        border: "none",
        borderRadius: "32px",
        padding: "9px 18px",
        color: "#FFFFFF",
        fontWeight: 600,
        height: "45px",
        width: "256px",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer",
    }
})

const ListAddedModalWrapper = styled(Box)({
    "& .MuiDialog-paperWidthMd":{
        maxWidth:"800px"
    },
    padding: "10px 20px",
    "& .textEnd": {
        display: "flex",
        justifyContent: "end",
    },
    "& .textCenter": {
        textAlign: "center" as "center",
    },
    "& .pTB50": {
        padding: "50px 0",
    },
    "& .listingHeading": {
        color: "#1E1E1E",
        fontSize: "28px",
        fontFamily: "Asap, sans-serif",
        fontWeight: 700,
    },
    "& .listingIdPara": {
        color: "#363636",
        fontSize: "16px",
        fontFamily: "Asap, sans-serif",
        margin: "0 0 5px 0",
    },
})

const PrincipalOrgnasationWrapper = styled(Box)({
    margin: "0 20px 20px 20px",
})

const RenderVasselBlockWrapper = styled(Box)({
    background: "#E3E6ED",
    padding: "25px 10px",
    borderRadius: "18px",
    marginBottom: "15px",
    "& .vesselHeading": {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "asap, sans-serif",
        textAlign: "center" as "center",
        margin: "0 0 25px 0",
    },
    "& .validityBox": {
        display: "flex",
        alignItems: "center",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .etbDiv": {
        marginLeft: "10px",
    },
    "& .beforeETB": {
        color: "#797979",
        fontSize: "14px",
        fontFamily: "Asap, sans-serif",
        whiteSpace: "nowrap",
        marginRight: "5px"
    },
})

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)",
        backgroundColor: theme.palette.common.white,
        color: "black",
        fontSize: 14,
        display: " inline-flex",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        fontFamily: "Asap, sans-serif",
        padding: "8px 16px",
    },
    arrow: {
        color: "white"
    }
}))(Tooltip);

const ListingPersonnelDetailsContainerWrapper = styled(Box)({
    background: "#E3E6ED",
    padding: "25px 10px",
    borderRadius: "18px",
    marginBottom: "15px",
    "& .vesselHeading": {
        color: "#1E1E1E",
        fontSize: "18px",
        fontFamily: "asap, sans-serif",
        textAlign: "center" as "center",
        margin: "0 0 25px 0",
    },
})


class AddListing extends Component<AddListingProps, AddListingState> {
    subScribedMessages: string[];
    setValuesRef: (field: string, value: string, shouldValidate?: boolean | undefined) => void = () => { };
    timeoutId: NodeJS.Timeout | null = null;
    debounceDelay = 1000
    constructor(props: AddListingProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.PostDetailDataMessage),
        ];
        this.state = {
            addListingModal: false,
            switch: true,
            isSlotFilterOpen: false,
            activeSlotFilter: [],
            prinicpalOrgList: [],
            show: false,
            addListing: false,
            etbDate: "",
            loginData: {},
            expiry_date: "",
            FormikFormReenableReinitialize: false,
            FormIsEdit: false,
            isViewLess: false,
            listingAdded: false,
            addedListingID: "",
            loading: false,
            token: "",
            DeleteContainer: [],
            EditFormId: "",
            EditListingId:'',
            vesselVoyageData: [],
            voyageData: [],
            etaDate: "",
            PolDropDownData: [],
            containerData: [],
            selectedContainer: [],
            FormEditData: {
                PrincipalOrganizationName: "",
                Vessel: "",
                Voyage: "",
                Pod: "",
                PodTerminal: "",
                Pol: "",
                PolTerminal: "",
                Email: "",
                EtaPod: "",
                EtbPol: "",
                FullName: "",
                OrganizationCode: "",
                ContactNumber: "",
                DistributionEmail: "",
                Expiry: "",
                TotalTeu: "",
                Validity: "",
                out_voyage: "",
                in_voyage: ""
            },
            ContainerEditDetails: [
                {
                    CONTAINERTYPE: "",
                    CONTAINERCODES: "",
                    ContainerId: "",
                    CONTAINERSIZEARRAY: [{
                        ContainerSizeId: "",
                        CONTAINERSIZE: "",
                        AVERAGETONSBOX: "",
                        RATE: "",
                        TERMSONE: "",
                        TERMSTWO: "",
                    }]
                }
            ],
            AllPriceTermsData: [],
            sellerListings: [],
            filter: {
                listingID: [],
                pol: [],
                pod: [],
                vessel: [],
                startDate: '',
                endDate: ""
            },
            polData: [],
            sellerListingSnackbarOpen: false,
            priceAndTermData: {},
            PrincipalOrganizationSelectedName: "",
            EmailDistributionListData: [],
            PolTerminalDropDown: [],
            PodTerminalDropDown: [],
            VesselDropDownData: [],
            PortNetApiAllData: [],
            VoyageDropDownData: [],
            IsVesselSelected: false,
            InVoyogeAndOutVoyoge: "",
            SelectedVesselVoyage: {
                inVoyN: "",
                outVoyN: "",
                vslM: "",
                name: ""
            },
            SelectedPol: "",
            SelectedPod: "",
            VesselDropDownOpen: false,
            VoyageDropDownOpen: false,
            VesselDropDownString: "",
            SelectedPolTerminal: "",
            SelectedPodTerminal: "",
            IsEtaEtbDateDisable: false,
            PortNetAPiCall: false,
            isMatchesFound: false,
            voyageValue: null,
            POLTerminalValue: null,
            PODTerminalValue: null,
            vesselInputValue: null,
            vesselDropdownState: null,
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.getVesselVoyagesApiCallID) {
            const DataAPIresposne = responseJson.data?.map(
                (item: {
                    attributes: {
                        vessel_code: string;
                        in_voyage_code: string;
                        out_voyage_code: string;
                    };
                }) => {
                    return {
                        vessel_code: item.attributes.vessel_code,
                        in_voyage_code: item.attributes.in_voyage_code,
                        out_voyage_code: item.attributes.out_voyage_code,
                    };
                }
            );
            const ReposneData = Array.isArray(responseJson.data) ? responseJson.data.map((item:
                { id: number, attributes: { vessel_code: string; in_voyage_code: string; out_voyage_code: string; } }
            ) => {
                return {
                    name: item.attributes.vessel_code,
                    inVoyN: item.attributes.in_voyage_code,
                    outVoyN: item.attributes.out_voyage_code,
                    vslM: item.attributes.vessel_code,
                }
            }) : []

            const Data = ReposneData.filter((value: { name: string }, index: number, self: Array<{}>) => index === self.findIndex((obj: { name?: string }) => obj.name === value.name));

            this.setState({
                VesselDropDownData: Data,
                PortNetApiAllData: ReposneData,
                loading: false,
            })
            this.setState({ vesselVoyageData: DataAPIresposne, loading: false, });
            setStorageData("voyageData", JSON.stringify(DataAPIresposne))
        }
        if (apiRequestCallId === this.getContainerListApiCallID) {
            const Data = responseJson?.data?.map(
                (item: {
                    attributes: {
                        container_type: string;
                        seller_box_sizes: Array<string>;
                        codes: Array<string>;
                        created_at:string;
                    };
                }) => {
                    return {
                        container_type: item?.attributes?.container_type,
                        seller_box_sizes: item?.attributes?.seller_box_sizes,
                        codes: item?.attributes?.codes,
                        created_at:item?.attributes?.created_at
                    };
                }
            );
               
            if(responseJson?.data.length>0){
            var sortedArray = Data?.sort((a: any, b: any) =>
            Date.parse(new Date(a.created_at).toISOString()) - Date.parse(new Date(b.created_at).toISOString())
            );
            }
            this.setState({ containerData: sortedArray });
            setStorageData("containerData", JSON.stringify(Data))
        }
        if (apiRequestCallId === this.getSellerListingDataApiCallID) {
            this.setState({ sellerListings: responseJson, loading: false });
            let temp = {
                listingID: responseJson.map(
                    (val: { seller_listing: { data: { attributes: { listing_uid: string } } } }) => val.seller_listing.data.attributes.listing_uid
                ),
                pol: responseJson.map((val: { seller_listing: { data: { attributes: { pol: string } } } }) => val.seller_listing.data.attributes.pol),
                pod: responseJson.map((val: { seller_listing: { data: { attributes: { pod: string } } } }) => val.seller_listing.data.attributes.pod),
                vessel: responseJson.map(
                    (val: { seller_listing: { data: { attributes: { vessel: string, voyage: string } } } }) => `${val.seller_listing.data.attributes.vessel}/${val.seller_listing.data.attributes.voyage}`
                ),
                startDate: responseJson.map((val: { seller_listing: { data: { attributes: { etb_pol: any } } } }) => val.seller_listing.data.attributes.etb_pol),
                endDate: responseJson.map((val: { seller_listing: { data: { attributes: { eta_pod: any } } } }) => val.seller_listing.data.attributes.eta_pod),
            };
            const a1 = temp.listingID.filter((item: string, index: number) => {
                return temp.listingID.indexOf(item) == index;
            })
            const a2 = temp.pol.filter((item: string, index: number) => {
                return temp.pol.indexOf(item) == index;
            })
            const a3 = temp.pod.filter((item: string, index: number) => {
                return temp.pod.indexOf(item) == index;
            })
            const a4 = temp.vessel.filter((item: string, index: number) => {
                return temp.vessel.indexOf(item) == index;
            })
            let arr = {
                listingID: a1.map((str: string) => ({
                    label: str,
                    isChecked: false,
                })),
                pol: a2.map((str: string) => ({ label: str, isChecked: false })),
                pod: a3.map((str: string) => ({ label: str, isChecked: false })),
                vessel: a4.map((str: string) => ({
                    label: str,
                    isChecked: false,
                })),
                startDate: "",
                endDate: "",
            };
            this.setState({
                filter: arr,
            });
        }
        if (apiRequestCallId === this.getPrincipalOrganisationDataApiCallID) {
            this.setState({ prinicpalOrgList: responseJson?.data });
            setStorageData("PrincipalOrganisation", JSON.stringify(responseJson?.data))
        }
        if (apiRequestCallId === this.getPriceAndTearmsDataApiCallID) {
            let Priceandtermsdata = responseJson?.data.map((item: {
                id: string, attributes: {
                    box_size: string,
                    container_type: string, id: number
                    port_of_destination: string,
                    port_of_location: string
                    price_and_currency: string,
                    terms: string
                }
            }) => {
                return {
                    id: item.id,
                    BoxSize: item.attributes.box_size,
                    Container: item.attributes.container_type,
                    PortOfDestination: item.attributes.port_of_destination,
                    PortOfLocation: item.attributes.port_of_location,
                    Rate: item.attributes.price_and_currency,
                    TermsOne: item.attributes.terms.split(/[\/,]/)[0]?.trim(),
                    TermsTwo: item.attributes.terms.split(/[\/,]/)[1]?.trim(),
                }
            })
            this.setState({ priceAndTermData: responseJson, AllPriceTermsData: Priceandtermsdata });
        }
        if (apiRequestCallId === this.createSellerListingApiCallID) {
            if (responseJson.message || responseJson[0].message) {
                this.setState({ addedListingID: responseJson?.seller_listing?.listing_uid ?? responseJson[0]?.seller_listing?.listing_uid, addListing: false, listingAdded: true, loading: false });
                this.getSellerListingData()
                if (responseJson[0]?.message === "Matches found and offer created ") { this.setState({ isMatchesFound: true, addedListingID: responseJson[0]?.matched_listings?.listing_uid}) } else { this.setState({ isMatchesFound: false }) }
            }
        }
        this.GetPolDataAPiCallResponse(apiRequestCallId, responseJson)
        this.GetVesselVoyagePostApiDataresponseSet(apiRequestCallId, responseJson)
        this.GetEtaEtbAPiResponse(apiRequestCallId, responseJson);
    }

    componentDidUpdate() {
        if (this.props.EditId !== "" && !this.state.show && this.props.location.pathname.toLowerCase() === "/sellerlisting") {
            if (this.props.EditId) {
                this.EditFuncationOnclick(this.props.EditId)
                this.setState({
                    show: true
                })
            }
        }
    }

    async componentDidMount() {
        let loginData = await getStorageData("loginData");
        let LocationToken = await getStorageData("authToken");
        let obj = JSON.parse(loginData)
        this.setState({ loginData: obj })
        this.OnloadApicall(LocationToken);
    }

    OnloadApicall = (LocationToken: string) => {
        this.getPrincipalOrganisation();
        if (this.props.location.pathname === "/SellerListing") {
            this.getSellerListingData()
        }
        this.getPolData(LocationToken);
        this.getContainerList(LocationToken)
        this.getDateData(LocationToken);
        this.getPriceAndTerms()
    }


    EditFuncationOnclick = async (id: string) => {
        const EditData = this.state.sellerListings.filter((data: { seller_listing: { data: { id: string } } }) => {
            if (data.seller_listing.data.id === id) {
                return data
            }
        })
        const {
            principal_organization_name, vessel, total_teu, validity, voyage, pod,
            pod_terminal, pol, pol_terminal, email, eta_pod, etb_pol, full_name, organization_code, contact_number, distribution_email, expiry, in_voyage, out_voyage,
        } = EditData[0].seller_listing.data.attributes
        let temp = this.state.vesselVoyageData.filter(
            (val: { vessel_code: string }) => val.vessel_code === vessel
        );
        this.setState({ voyageData: temp });
        const PodData = this.state.PolDropDownData.filter((data) => {
            if (data.PortName.toLowerCase() == pod.toLowerCase()) {
                return data
            }
        })[0]

        const PolData = this.state.PolDropDownData.filter((data) => {
            if (data.PortName.toLowerCase() == pol.toLowerCase()) {
                return data
            }
        })[0]

        const EditFormData = {
            PrincipalOrganizationName: principal_organization_name,
            Vessel: vessel,
            Voyage: voyage,
            Pod: PodData.PortName + ',' + PodData.PortId,
            PodTerminal: pod_terminal,
            Pol: PolData.PortName + ',' + PolData.PortId,
            PolTerminal: pol_terminal,
            Email: email,
            EtaPod: eta_pod,
            EtbPol: etb_pol,
            FullName: full_name,
            OrganizationCode: organization_code,
            ContactNumber: contact_number,
            DistributionEmail: distribution_email,
            Expiry: expiry, TotalTeu: total_teu,
            Validity: validity,
            out_voyage: out_voyage,
            in_voyage: in_voyage
        }
        const loginData = await getStorageData("loginData");
        const parseData = JSON.parse(loginData)
        const dateFrom = moment(EditFormData.EtbPol)
            .subtract(parseData.minimum_validity_of_listing, "d")
            .parseZone()
        this.setState({
            expiry_date: dateFrom,
            PrincipalOrganizationSelectedName: EditFormData.PrincipalOrganizationName,
            IsVesselSelected: true,
            VesselDropDownString: vessel,
            SelectedPodTerminal: pod_terminal,
            SelectedPod: pod,
            SelectedPol: pol,
            SelectedPolTerminal: pol_terminal,
            InVoyogeAndOutVoyoge: `In ${in_voyage} Out ${out_voyage}`,
        }, () => {
            this.OnchangeOfAutoComplate(vessel)
        });


        const ContainerDataDetails = EditData[0].containers.map((items: {
            container: { container_type: string, codes: Array<string>, id: number }, container_details: Array<{
                container_size: string, desired_rates: number, quantity: number, terms: string, tons_teu: number, id: string
            }>
        }) => {
            this.onSelectConainer({ target: { value: items.container.container_type } });
            return {
                CONTAINERTYPE: items.container.container_type,
                CONTAINERCODES: items.container.codes.join(','),
                ContainerId: `${items.container.id}`,
                CONTAINERSIZEARRAY: items.container_details.map((dat) => {
                    return {
                        CONTAINERSIZE: dat.container_size,
                        AVERAGETONSBOX: dat.quantity,
                        RATE: dat.desired_rates,
                        TERMSONE: dat.terms.split('/')[0],
                        TERMSTWO: dat.terms.split('/')[1],
                        ContainerSizeId: `${dat.id}`,
                    }
                })
            }
        })
        this.setState({
            show: true,
            addListing: true,
            FormEditData: EditFormData,
            FormIsEdit: true,
            EmailDistributionListData: EditFormData.DistributionEmail,
            ContainerEditDetails: ContainerDataDetails,
            EditFormId: EditData[0].seller_listing.data.id,
            EditListingId:EditData[0]?.seller_listing.data.attributes.listing_uid,
            etbDate: moment.parseZone(EditFormData.EtbPol).format('YYYY-MM-DD'),
            etaDate: moment.parseZone(EditFormData.EtaPod).format('YYYY-MM-DD'),
            loginData: parseData,
            FormikFormReenableReinitialize: true,
            PodTerminalDropDown: PodData.PortCountry as unknown as string[],
            PolTerminalDropDown: PolData.PortCountry as unknown as string[],
        }, () => {
            this.setState({
                FormikFormReenableReinitialize: false
            })
        });
    }

    // Api calling Ids
    getPrincipalOrganisationDataApiCallID: string = "";
    getVesselVoyagesApiCallID: string = "";
    getSellerListingDataApiCallID: string = "";
    createSellerListingApiCallID: string = "";
    getPolDataApiCallID: string = "";
    getContainerListApiCallID: string = "";
    getDateDataApiCallID: string = ""
    getPriceAndTearmsDataApiCallID: string = "";
    GetVesselVoyagePostApi: string = "";
    GetEtaETbAPiId: string = "";
    // Api calling functions
    getPrincipalOrganisation = async () => {
        let authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPrincipalOrganisationDataApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_profile/principal_organizations"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    ErrorHandelResponse = (data: Array<{ [key: string]: string }>) => {
        if (typeof data === "string") {
            toast.error(data);
        }
        this.ErrorForLoop(data)
    };

    ErrorForLoop = (data: Array<{ [key: string]: string }>) => {
        for (const obj of Object.values(data)) {
            if (typeof data === "object") {
                toast.error(obj);
                if (Array.isArray(obj)) {
                    for (const key in obj) {
                        toast.error(`${Object.entries(obj[key])[0][0].toString().replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())} ${Object.entries(obj[key])[0][1].toString().replace(",", " ")}.`);
                    }
                }
            }
            if (Array.isArray(data)) {
                if (data[0].message) {
                    toast.error("The respective data does not exist.");
                } else {
                    for (const key in obj) {
                        toast.error(obj[key]);
                    }
                }
            }
        }
    }

    GetEtaEtbAPiResponse = (apiRequestCallId: string, data: { errors: Array<{ [key: string]: string }>, results: Array<{ vslM: string, inVoyN: string, outVoyN: string, portOfLoad: string, portofDisc: string, etbDt: string, etuDt: string, etaDt: string }> }) => {
        if (apiRequestCallId === this.GetEtaETbAPiId) {
            if (Array.isArray(data.errors)) {
                this.setState({
                    loading: false
                })
                this.ErrorHandelResponse(data.errors)
            }
            if (Array.isArray(data.results) && data.results.length !== 0) {
                if (data.results[0].etbDt || data.results[0].etaDt) {
                    this.setValuesRef("ETBATPOL", moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD'))
                    this.setValuesRef("ETAATPOD", moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD'))
                    this.setState({
                        loading: false,
                        etbDate: moment.parseZone(data.results[0].etbDt).format('YYYY-MM-DD'),
                        etaDate: moment.parseZone(data.results[0].etaDt).format('YYYY-MM-DD'),
                    }, () => {
                        const dateFrom = moment(this.state.etbDate).subtract(this.state.loginData.minimum_validity_of_listing, "d").parseZone().format("YYYY-MM-DD");
                        this.setState({ expiry_date: dateFrom, loading: false, FormikFormReenableReinitialize: false });
                    })
                }


            }
        }
    }

    GetVesselVoyagePostApiDataresponseSet = (apiRequestCallId: string, responseJson: {
        errors?: Array<{ [key: string]: string }>,
        results: Array<{
            inVoyN: string, outVoyN: string, vslM: string
        }>
    }) => {
        if (this.GetVesselVoyagePostApi === apiRequestCallId) {
            if (responseJson.errors !== null || responseJson.results === null && responseJson.errors === null) {
                this.getVesselVoyages()
                this.setState({
                    loading: false,
                    PortNetAPiCall: false
                })
            } else {
                const ReposneData = Array.isArray(responseJson.results) ? responseJson.results.map((item: {
                    inVoyN: string, outVoyN: string, vslM: string
                }) => {
                    return {
                        name: item.vslM,
                        inVoyN: item.inVoyN,
                        outVoyN: item.outVoyN,
                        vslM: item.vslM,
                    }
                }) : []

                const Data = ReposneData.filter((value, index, self) => index === self.findIndex((obj) => obj.name === value.name));

                this.setState({
                    VesselDropDownData: Data,
                    PortNetApiAllData: ReposneData,
                    loading: !!this.state.FormIsEdit,
                    PortNetAPiCall: false,
                    VesselDropDownOpen: true,
                }, () => {
                    if (this.state.FormIsEdit) {
                        const VoyageData = this.state.PortNetApiAllData.filter((item: { name: string, inVoyN: string, outVoyN: string }) => {
                            if (item.name.includes(this.state.FormEditData.Vessel)) {
                                return item
                            }
                        })
                        const DataSet = this.state.PortNetApiAllData.filter((item: { name: string, inVoyN: string, outVoyN: string }) => {
                            if (item.inVoyN.includes(this.state.FormEditData.in_voyage)) {
                                return item
                            }
                        })
                        this.setState({
                            VoyageDropDownData: VoyageData,
                            SelectedVesselVoyage: DataSet.length !== 0 ? DataSet[0] : {},
                            loading: false,
                        })
                    }
                })
            }
        }
    }

    OnOpenDropDown = () => {
        if (this.state.PrincipalOrganizationSelectedName !== "" || !Array.isArray(this.state.prinicpalOrgList)) {
            this.setState({
                VesselDropDownOpen: !this.state.VesselDropDownOpen,
            })
            if(Array.isArray(this.state.prinicpalOrgList) !== true){
                this.setState({
                    PrincipalOrganizationSelectedName: this.state.prinicpalOrgList.attributes.name
                })
            }
        } else {
            toast.warning("Please select principal organisation")
        }
    }

    OnOpenDropDownVoyage = () => {
        if (this.state.IsVesselSelected) {
            this.setState({
                VoyageDropDownOpen: !this.state.VoyageDropDownOpen,
            })
        } else {
            toast.warning("Please select Vessel first")
        }
    }

    AutocompleteTextfieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let values = event.target.value
        if (3 <= event.target.value.length && this.state.PrincipalOrganizationSelectedName !== "") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.debouncedFunction(values)
            }, this.debounceDelay);
        }
    };



    debouncedFunction = (value: string) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            this.OnchangeOfAutoComplate(value)
        }, 1000)
    };

    OnchangeOfAutoComplate = async (values: string) => {
        const token = await getStorageData("authToken");
        this.setState({
            PortNetAPiCall: true,
            VesselDropDownOpen: false
        })
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const httpBody = {
            userId: this.state.loginData.email,
            orgCode: this.state.PrincipalOrganizationSelectedName,
            vslM: values.toUpperCase(),
            partialMatch: true
        };

        this.GetVesselVoyagePostApi = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_apiintegration52/portnets/get_vessel_voyage"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getVesselVoyages = async () => {
        let authToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVesselVoyagesApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_content_management/vessel_voyages?principal_organization_name=${this.state.PrincipalOrganizationSelectedName}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getSellerListingData = async () => {
        let LocationToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: LocationToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSellerListingDataApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_listdemandpickflow/seller_listings"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getPolData = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPolDataApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_content_management/ports"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    GetPolDataAPiCallResponse = (apiRequestCallId: string, responseJson: { data: Array<{ id: string, type: string, attributes: { enabled: true, port_code: string, port_country: string, port_description: string, port_region: string } }> }) => {
        if (apiRequestCallId === this.getPolDataApiCallID) {
            this.setState({ polData: responseJson.data });
            setStorageData("PolData", JSON.stringify(responseJson?.data))
            const PolData = responseJson?.data.map((data: { id: string, type: string, attributes: { enabled: true, port_code: string, port_country: string, port_description: string, port_region: string } }) => {
                return {
                    PortId: data.id,
                    Type: data.type,
                    PortName: data.attributes.port_code,
                    PortCountry: data.attributes.port_country,
                    PortRegion: data.attributes.port_region,
                    PortDescription: data.attributes.port_country
                }
            }).filter((value: { PortName: string }, index: number, self: Array<{}>) => index === self.findIndex((obj: { PortName?: string }) => obj.PortName === value.PortName));
            this.setState({
                PolDropDownData: PolData
            })
        }
    }

    getContainerList = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getContainerListApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_content_management/container_managements"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    AutocompleteOnChange = (
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
        value: unknown,
        KeyName: string
    ) => {
        const data = value as { name: string, inVoyN: string, outVoyN: string, vslM: string }
        if (KeyName === "VESSEL") {
            this.setState({vesselDropdownState: data.name})
            const VoyageData = this.state.PortNetApiAllData.filter((item: { name: string }) => {
                if (item?.name?.includes(data?.name)) {
                    return item
                }
            })

            this.setState({
                VoyageDropDownData: VoyageData,
                IsVesselSelected: true,
                InVoyogeAndOutVoyoge: "",
                SelectedPod: "",
                SelectedPol: "",
                SelectedPodTerminal: "",
                SelectedPolTerminal: "",
                PolTerminalDropDown: [],
                PodTerminalDropDown: [],
                SelectedVesselVoyage: {
                    inVoyN: "",
                    outVoyN: "",
                    vslM: "",
                    name: ""
                }
            })
            setFieldValue(KeyName, `${data?.name.toUpperCase()}`)
            setFieldValue("VOYAGE", "")
            setFieldValue("POD", "")
            setFieldValue("POL", "")
            setFieldValue("PODTERMINAL", "")
            setFieldValue("POLTERMINAL", "")
        }
        if (KeyName === "VOYAGE") {
            this.setState({
                InVoyogeAndOutVoyoge: `In ${data.inVoyN} Out ${data.outVoyN}`,
                SelectedVesselVoyage: data,
                SelectedPod: "",
                SelectedPol: "",
                SelectedPodTerminal: "",
                SelectedPolTerminal: "",
            })
            setFieldValue(KeyName, data?.inVoyN)
            setFieldValue("POD", "")
            setFieldValue("POL", "")
            setFieldValue("PODTERMINAL", "")
            setFieldValue("POLTERMINAL", "")
        }
    }

    getPriceAndTerms = async () => {
        let token = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPriceAndTearmsDataApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_admin/price_managements`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDateData = (token: string) => {
        const header = {
            "Content-Type": "application/json",
            token: token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDateDataApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_listdemandpickflow/pending_requests/set_date"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Api calling functions

    handleSlotFilter = (principalOrg: { attributes: { code: string, name: string } }, isChecked: boolean) => {
        const hasActiveFilter = this.state.activeSlotFilter.find((slot: { attributes: { code: string, name: string } }) => slot.attributes.code === principalOrg.attributes.code && slot.attributes.name === principalOrg.attributes.name)
        if (isChecked && !hasActiveFilter) {
            this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
            return
        }
        this.setState(prevState => ({
            activeSlotFilter: prevState.activeSlotFilter.filter((item: {
                attributes: {
                    code: string, name: string
                }
            }) => item.attributes.code !== principalOrg.attributes.code && item.attributes.name !== principalOrg.attributes.name)
        }));
    }

    onClickAddListing = async () => {
        const loginData = await getStorageData("loginData");
        Promise.resolve(loginData).then((Data: string) => {
            const parseData = JSON.parse(Data)
            const Emaillist = parseData?.distribution_email?.split(',').map((item: string) => item.trim());
            this.setState({ show: true, addListing: true, loginData: parseData, EmailDistributionListData: Emaillist, FormikFormReenableReinitialize: true }, () => {
                this.setState({
                    FormikFormReenableReinitialize: false
                })
            });
            const dateFrom = moment.parseZone(this.state.etbDate)
                .subtract(parseData.minimum_validity_of_listing, "d")
                .format("YYYY-MM-DD");

            this.setState({ expiry_date: dateFrom });
        })
    }

    FristGrid = () => {
        return (
            <SectionLIstingWrapper>
                <div className="btnDivBox" style={useStyles.btnDiv}>
                    <button
                        className="TempButton"
                        style={useStyles.addListing}
                        onClick={() => this.onClickAddListing()}
                        data-test-id="addListing"
                        id="BtnaddListing"
                    >
                        <img src={add} alt="" style={useStyles.btnImgDiv} />
                        Add Listing
                    </button>
                </div>
            </SectionLIstingWrapper>
        )
    }

    NewListingSchema = () => {
        return Yup.object().shape({
            ...(this.state.prinicpalOrgList.length !== 0 && {
                PRINCIPALORGANISATION: Yup.string().required(
                    "Principal Organisation Required"
                ),
            }),
            VESSEL: Yup.string()
            .required("Vessel Required"),
            VOYAGE: Yup.string().required("Voyage Required"),
            POD: Yup.string().required("POD Required"),
            PODTERMINAL: Yup.string().required("POD Terminal Required"),
            POL: Yup.string().required("POL Required"),
            POLTERMINAL: Yup.string().required("POL Terminal Required"),
            ETBATPOL: Yup.date().required("ETB at POL Required"),
            ETAATPOD: Yup.date().required('End date Required')
                .min(Yup.ref('ETBATPOL'), 'End date must be after start date').required("ETA at POD Required"),
            QUANTITYOFTEUSAVAILABLEFORSALE: Yup.number().required(
                "Quantity of TEUs Available for Sale Required"
            ).min(1, "Invalid Input"),
            VALIDITYOFLISTING: Yup.number().typeError('Must be a number').required(
                "Validity of listing Required"
            )
            .min(1, "Invalid Input")
            .typeError('Must be a number')
            ,


            ContainerPricingDetails: Yup.array().of(
                Yup.object().shape({
                    CONTAINERTYPE: Yup.string().required("Container Status Required"),
                    CONTAINERCODES: Yup.string().required("Container Types Required"),
                    CONTAINERSIZEARRAY: Yup.array().of(
                        Yup.object().shape({
                            CONTAINERSIZE: Yup.string().required("Container Size  Required"),
                            AVERAGETONSBOX: Yup.number().typeError('Must be a number').required("Avg. Tons/Container Required"),
                            RATE: Yup.number().typeError('Must be a number').required("Seller Price Required"),
                            TERMSONE: Yup.string().required("Terms  Required"),
                            TERMSTWO: Yup.string().required("Terms  Required"),
                        }))
                })
            ),
            FULLNAME: Yup.string().required("Full Name Required"),
            EMAILADDRESS: Yup.string().email("Email address looks incorrect!").required("Email address Required"),
            CONTACTNUMBER: Yup.string().min(10, "Contact Number Required").required("Contact Number Required"),
            EMAILDISTRIBUTIONLIST: Yup.array().of(
                Yup.string()
                    .email("Email distribution list required ")
                    .test({
                        name: 'email',
                        message: 'Please enter a valid email address',
                        test: (value) => {
                            return Yup.string().email().isValidSync(value);
                        },
                    })
            )
                .min(1, "At least one Email Distribution List Required")
        });
    };

    listAddedModal = () => {
        return (
        <ListAddedModalWrapper className="listingSubmittedBox" >
            <div className="textEnd" >
                <img src={close} alt="close" data-test-id="CloseModel" onClick={this.HandelCloseModelFuncation} />
            </div>
            {
                this.state.isMatchesFound ? 
                    <div className="textCenter pTB50">
                        <div className="mb20">
                            <img src={image_success} alt="" />
                        </div>
                        <h1 className="listingHeading">Matching Request Available For Your Listing!</h1>
                        <p className="listingIdPara">
                            Your listing (ID: {this.state.addedListingID}) has been submitted successfully.
                        </p>
                        <p className="listingIdPara">
                        Automatic offers have been extended to matching request on the platform.
                        </p>
                        <p className="listingIdPara">
                        View and manage the offers below.
                        </p>
                        <Button onClick={() => this.handleMatchingOffersNavigations()} style={{ color: "#fff", background: "#1653DD", fontSize: "16px", fontWeight: 700, fontFamily: "Asap", borderRadius: "45px", padding: "16px 58px", marginTop: "20px" }}>VIEW OFFER</Button>
                    </div>
                    :
                    <div className="textCenter pTB50">
                    <div className="mb20">
                        <img src={image_success} alt="" />
                    </div>
                    <h1 className="listingHeading">{this.state.FormIsEdit ? 'Listing Updated!' :  'Listing Submitted!'}</h1>
                    <p className="listingIdPara">
                        { this.state.FormIsEdit ? 
                        `Your listing (ID: ${this.state.EditListingId}) has been
                        updated successfully.`
                        : 
                        `Your listing (ID: ${this.state.addedListingID}) has been
                        submitted successfully.`}
                    </p>
                    <p className="listingIdPara">
                    { this.state.FormIsEdit ? 
                        `Offers have been made from this listing (ID: ${this.state.EditListingId}) previously.`
                        : "You'll be notified when there are matching requests for your listing."}
                    </p>
                    {this.state.FormIsEdit ? <>
                    <p className="listingIdPara">
                    Please ensure the validity of your offers.
                </p>
                <div className="dFlexJCenter">
                <ContainedButton
                    type="button"
                    className="btnTheme"
                    onClick={()=>this.props.history.push('SellerPendingOffers')}
                >
                    MANAGE OFFERS
                </ContainedButton>
            </div></>: <span></span>}
                </div>
            }

        </ListAddedModalWrapper>)
    }

    createSellerListing = async (values: {
        PRINCIPALORGANISATION: string,
        VESSEL: string,
        VOYAGE: string,
        POD: string,
        PODTERMINAL: string,
        POL: string,
        POLTERMINAL: string,
        ETBATPOL: string,
        ETAATPOD: string,
        QUANTITYOFTEUSAVAILABLEFORSALE: string,
        VALIDITYOFLISTING: string,
        EXPIRYDATE: string,
        ContainerPricingDetails: Array<
            {
                CONTAINERTYPE: string,
                CONTAINERCODES: string,
                ContainerId?: string,
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string,
                    AVERAGETONSBOX: string,
                    RATE: string,
                    TERMSONE: string,
                    TERMSTWO: string,
                    ContainerSizeId?: string
                }>
            }
        >,
        FULLNAME: string,
        EMAILADDRESS: string,
        CONTACTNUMBER: string,
        EMAILDISTRIBUTIONLIST: string | string[],

    }) => {
        let LocationToken = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: LocationToken,
        };
        this.setState({
            loading: true
        })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        let ContaiernData = values.ContainerPricingDetails.map((item) => {
            return {
                container_type: item.CONTAINERTYPE,
                codes: item.CONTAINERCODES.split(',').map(data => data.trim()),
                ...(this.state.FormIsEdit && {
                    id: item.ContainerId,
                    box_sizes: item.CONTAINERSIZEARRAY.map((ite) => ite.CONTAINERSIZE.trim()),
                }),
                container_details: item.CONTAINERSIZEARRAY.map((SubItem) => {
                    return {
                        ...(this.state.FormIsEdit && {
                            id: SubItem.ContainerSizeId
                        }),
                        container_size: SubItem.CONTAINERSIZE,
                        quantity: Number(SubItem.AVERAGETONSBOX),
                        desired_rates: Number(SubItem.RATE),
                        terms: `${SubItem.TERMSONE} / ${SubItem.TERMSTWO}`
                    }
                }),
            }
        })
        const DeleteContainer = this.state.DeleteContainer.map((Deletedata) => {
            return {
                ...(Deletedata.DeleteContainerSize === undefined ? {
                    DeleteContainerTypeId: Deletedata.DeleteContainerType,
                } : {
                    DeleteContainerTypeId: Deletedata.DeleteContainerType,
                    DeleteContainerSizeIdes: Deletedata.DeleteContainerSize
                })
            }
        }).filter((item) => {
            if (item !== null) {
                return item
            }
        })
        const LocalDataorganization_id = await getStorageData("loginData")
        const OrgId = JSON.parse(LocalDataorganization_id).organization_id

        const body = {
            seller_listing: {
                full_name: values.FULLNAME,
                email: values.EMAILADDRESS,
                pol: values.POL.split(',')[0],
                pol_terminal: values.POLTERMINAL,
                pod: values.POD.split(',')[0],
                pod_terminal: values.PODTERMINAL,
                validity: values.VALIDITYOFLISTING,
                organization_id: `${OrgId}`,
                vessel: values.VESSEL,
                principal_organization_name: values.PRINCIPALORGANISATION,
                voyage: values.VOYAGE,
                etb_pol: values.ETBATPOL,
                eta_pod: values.ETAATPOD,
                total_teu: values.QUANTITYOFTEUSAVAILABLEFORSALE,
                expiry: this.state.expiry_date,
                distribution_email: values.EMAILDISTRIBUTIONLIST,
                contact_number: values.CONTACTNUMBER,
                in_voyage: this.state.SelectedVesselVoyage.inVoyN,
                out_voyage: this.state.SelectedVesselVoyage.outVoyN
            },
            containers: ContaiernData,
            ...(this.state.FormIsEdit && { delete_containers: DeleteContainer })
        };

        const Url = this.state.FormIsEdit ? `bx_block_listdemandpickflow/seller_listings/${this.state.EditFormId}` : "/bx_block_listdemandpickflow/seller_listings"

        this.createSellerListingApiCallID = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            Url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.FormIsEdit ? "PUT" : "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    ContainerPricingDetailsCondtion = (values: FormValues) => {
        let ContainerPricingDetailsIndex = values.ContainerPricingDetails[values.ContainerPricingDetails.length - 1].CONTAINERSIZEARRAY.map((subArrayItem) => {
            if (subArrayItem.AVERAGETONSBOX === "" || subArrayItem.CONTAINERSIZE === "" || subArrayItem.RATE === "" || subArrayItem.TERMSONE === "" || subArrayItem.TERMSTWO === "") {
                return ""
            }
        }).includes("")
        return ContainerPricingDetailsIndex
    }

    AddContainerCondtion = (values: FormValues) => {
        let AddContainerDisable = values.ContainerPricingDetails.map((item) => {
            let data = item.CONTAINERSIZEARRAY.map((subArrayItem) => {
                if (subArrayItem.AVERAGETONSBOX === "" || subArrayItem.CONTAINERSIZE === "" || subArrayItem.RATE === "" || subArrayItem.TERMSONE === "" || subArrayItem.TERMSTWO === "") {
                    return ""
                }
            })
            if (item.CONTAINERTYPE === "" || item.CONTAINERCODES === "" || data.includes("")) {
                return ""
            }
        }).includes("")
        return AddContainerDisable
    }

    ErrorRender = (ErrorMessage: string | undefined, TextShow: boolean) => {
        return (
            ErrorMessage !== undefined && TextShow &&
            <Box className="ErrorTextContainer">
                <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}>
                    {typeof ErrorMessage === "string" ? ErrorMessage : ""}
                </ProjectText>
            </Box>
        );
    };

    PrincipalOrgnasation = (errors: FormikErrors<FormValues>, touched: FormikTouched<{ PRINCIPALORGANISATION: string | boolean }>, values: { PRINCIPALORGANISATION: string },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <PrincipalOrgnasationWrapper className="organisation">
                <InputLabel htmlFor="PRINCIPALORGANISATION">
                    <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                    >
                        PRINCIPAL ORGANISATION
                    </ProjectText>
                </InputLabel>
                <ProjectTextField
                    variant="outlined"
                    fullWidth
                    defaultValue="all"
                    FormError={
                        errors.PRINCIPALORGANISATION !== undefined &&
                        touched.PRINCIPALORGANISATION !== undefined &&
                        values.PRINCIPALORGANISATION === ""
                    }
                    value={values.PRINCIPALORGANISATION}
                    inputProps={{
                        "data-test-id": "PRINCIPALORGANISATION",
                    }}
                    id="PRINCIPALORGANISATION"
                    name="PRINCIPALORGANISATION"
                    onChange={(e) =>
                        this.AddListingFormTextFieldChange(
                            e,
                            setFieldValue,
                            "PRINCIPALORGANISATION"
                        )
                    }
                    disabled = {!Array.isArray(this.state.prinicpalOrgList)}
                >
                    {!Array.isArray(this.state.prinicpalOrgList) ?
                        <OptionForSelect
                            value={this.state.prinicpalOrgList.attributes.name}
                            key={this.state.prinicpalOrgList.id}
                            data-test-id={`option1`}
                            selected = {this.state.prinicpalOrgList.attributes.name}
                        >
                            <ProjectText
                                variant="h6"
                                TextSize="14px"
                                fontweight="600"
                            >
                                {this.state.prinicpalOrgList.attributes.name}
                            </ProjectText>
                        </OptionForSelect>
                        :
                        Array.isArray(this.state.prinicpalOrgList) && this.state.prinicpalOrgList?.map(
                            (val: { id: string, attributes: { name: string } }, index: number) => {
                                return (
                                    <OptionForSelect
                                        value={val.attributes.name}
                                        key={val.id}
                                        data-test-id={`option${index}`}
                                    >
                                        <ProjectText
                                            variant="h6"
                                            TextSize="14px"
                                            fontweight="600"
                                        >
                                            {val.attributes.name}
                                        </ProjectText>
                                    </OptionForSelect>
                                );
                            }
                        )}
                </ProjectTextField>
                {this.ErrorRender(
                    errors.PRINCIPALORGANISATION,
                    errors.PRINCIPALORGANISATION !== undefined &&
                    touched.PRINCIPALORGANISATION !== undefined && values.PRINCIPALORGANISATION === ""
                )}
            </PrincipalOrgnasationWrapper>
        )
    }

    AddListingFormTextFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ value: unknown }>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, keyname: string) => {
        setFieldValue(`${keyname}`, `${e.target.value}`)
        if (keyname === "FULLNAME") {
            let temp = {
                ...this.state.loginData,
                full_name: e.target.value,
            };
            this.setState({ loginData: temp });
        }
        if (keyname === "EMAILADDRESS") {
            let temp = {
                ...this.state.loginData,
                email: e.target.value,
            };
            this.setState({ loginData: temp });
        }
        if (keyname === "PRINCIPALORGANISATION") {
            this.setState({
                PrincipalOrganizationSelectedName: `${e.target.value}`
            })
        }
        if (keyname === "VESSEL") {
            let temp = this.state.vesselVoyageData.filter(
                (val: { vessel_code: string }) => val.vessel_code === e.target.value
            );
            this.setState({ voyageData: temp });
            setFieldValue("VOYAGE", "")
        }
        if (keyname === "POD") {
            let split = `${e.target.value}`.split(',')
            const CountryData = this.state.PolDropDownData.filter((item: { PortId: string }) => {
                return item.PortId === split[1]
            })
            setFieldValue("PODTERMINAL", "")

            this.setState({
                PodTerminalDropDown: CountryData[0].PortCountry as unknown as string[],
                SelectedPod: split[0],
                SelectedPodTerminal: "",
            })
        }
        if (keyname === "POL") {
            let split = `${e.target.value}`.split(',')
            const CountryData = this.state.PolDropDownData.filter((item: { PortId: string }) => {
                return item.PortId === split[1]
            })
            setFieldValue("POLTERMINAL", "")
            this.setState({
                PolTerminalDropDown: CountryData[0].PortCountry as unknown as string[],
                SelectedPol: split[0],
                SelectedPolTerminal: ""
            })
        }
        if (keyname === "POLTERMINAL") {
            this.setState({
                SelectedPolTerminal: `${e.target.value}`
            }, () => {
                this.GetAPiCallOfEtaEtb(keyname)
            })
        }
        if (keyname === "PODTERMINAL") {
            this.setState({
                SelectedPodTerminal: `${e.target.value}`
            }, () => {
                this.GetAPiCallOfEtaEtb(keyname)
            })
        }

    }

    PolAndPodAutoComplateOnChange = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
        value: unknown,
        KeyName: string) => {
            const CanweSelectPolAndPod = this.state.IsVesselSelected && this.state.VesselDropDownString !== ""  && this.state.InVoyogeAndOutVoyoge !== "" 
        if (CanweSelectPolAndPod) {
            const Data = value as { PortId: string, PortName: string, PortRegion: string }
            if (KeyName === "POL" && Data.PortId && Data.PortName) {
                const CountryData = this.state.PolDropDownData.filter((item: { PortId: string }) => {
                    return item.PortId === Data.PortId
                })
                setFieldValue(KeyName, Data.PortName + "," + Data.PortId)
                setFieldValue("POLTERMINAL", "")
                this.setState({
                    PolTerminalDropDown: CountryData[0].PortCountry as unknown as string[],
                    SelectedPol: Data.PortName,
                    SelectedPolTerminal: ""
                })
            }
            if (KeyName === "POD" && Data.PortId && Data.PortName) {
                const CountryData = this.state.PolDropDownData.filter((item: { PortId: string }) => {
                    return item.PortId === Data.PortId
                })
                setFieldValue("PODTERMINAL", "")

                this.setState({
                    PodTerminalDropDown: CountryData[0].PortCountry as unknown as string[],
                    SelectedPod: Data.PortName,
                    SelectedPodTerminal: "",
                })
            }
            if (KeyName === "POLTERMINAL" && value) {
                setFieldValue(KeyName, value as string)
                this.setState({
                    SelectedPolTerminal: `${value}`
                }, () => {
                    this.GetAPiCallOfEtaEtb(KeyName)
                })
            }
            if (KeyName === "PODTERMINAL" && value) {
                setFieldValue(KeyName, value as string)
                this.setState({
                    SelectedPodTerminal: `${value}`
                }, () => {
                    this.GetAPiCallOfEtaEtb(KeyName)
                })
            }
        }
        this.AlertForPolAndPod()
    }
    
    AlertForPolAndPod = () =>{
        if(!this.state.IsVesselSelected || this.state.VesselDropDownString === ""){
            toast.warning(`Please select vessel`)
        }
        else if(this.state.InVoyogeAndOutVoyoge === ""){
            toast.warning(`Please select voyage`)
        }
    }

    GetAPiCallOfEtaEtb = (keyname: string) => {
        const KeyNamesArray = ["POD", "POL", "POLTERMINAL", "PODTERMINAL"]
        if (KeyNamesArray.includes(keyname) && this.state.SelectedPod !== "" && this.state.SelectedPol !== "" && this.state.SelectedPodTerminal !== "" && this.state.SelectedPolTerminal !== "") {
            this.GetEtaETbAPiFuncation()
        }
    }

    GetEtaETbAPiFuncation = async () => {
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": "application/json",
            token: token,
        };
        this.setState({
            loading: true
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const httpBody = {
            userId: this.state.loginData.email,
            orgCode: this.state.PrincipalOrganizationSelectedName,
            vslM: this.state.SelectedVesselVoyage.vslM,
            inVoyN: this.state.SelectedVesselVoyage.inVoyN,
            outVoyN: this.state.SelectedVesselVoyage.outVoyN,
            portOfLoad: this.state.SelectedPol,
            portOfDisc: this.state.SelectedPod
        };

        this.GetEtaETbAPiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "/bx_block_apiintegration52/portnets/get_etb_info"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    VesselRedner = (errors: {
        VOYAGE?: string; POLTERMINAL?: string; ETBATPOL?: string; POD?: string; VALIDITYOFLISTING?: string; PODTERMINAL?: string; ETAATPOD?: string; VESSEL?: string;
        POL?: string; QUANTITYOFTEUSAVAILABLEFORSALE?: string; EXPIRYDATE?: string;
    },
        touched: {
            ETBATPOL?: string | boolean;
            ETAATPOD?: string | boolean; POL?: string | boolean; VOYAGE?: string | boolean; POLTERMINAL?: string | boolean; PODTERMINAL?: string | boolean; QUANTITYOFTEUSAVAILABLEFORSALE?: string | boolean; EXPIRYDATE?: string | boolean; POD?: string | boolean;
            VALIDITYOFLISTING?: string | boolean; VESSEL?: string | boolean;
        },
        values: {
            VESSEL: string;
            POL: string; VOYAGE: string; POLTERMINAL: string; ETAATPOD?: string; POD: string; PODTERMINAL: string; QUANTITYOFTEUSAVAILABLEFORSALE: string; VALIDITYOFLISTING: string; EXPIRYDATE?: string; ETBATPOL: string;
        },
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="VESSEL">
                        <ProjectText
                            Upercase
                            variant="subtitle1"
                            TextSize="14px"
                            fontweight="900"
                            textcolor="black"
                        >
                            VESSEL
                        </ProjectText>
                    </InputLabel>
                    <AutocompleteStyle
                        id="VESSELAutoSelectId"
                        options={this.state.VesselDropDownData}
                        onChange={(event: React.ChangeEvent<{}>, value) => {
                            this.AutocompleteOnChange(setFieldValue, value, "VESSEL")
                        }}
                        onOpen={this.OnOpenDropDown}
                        getOptionSelected={(option: unknown, value) => (option as { name: string }).name === values.VESSEL}
                        size="small"
                        FormError={
                            errors.VESSEL !== undefined &&
                            touched.VESSEL !== undefined && this.state.VesselDropDownString?.trim() == "" ||  (touched.VESSEL !== undefined && this.state.vesselInputValue === "")
                        }
                        getOptionLabel={(option: unknown) => (option as { name: string }).name ?? ""}
                        inputValue={this.state.VesselDropDownString}
                        onInputChange={(event: any, newInputValue: any) => {
                            if (event !== null) {
                                this.setState({vesselInputValue: event.target?.value, vesselDropdownState: event.target?.value})
                                setFieldValue("VESSEL", newInputValue.toUpperCase())
                                this.setState({
                                    VesselDropDownString: newInputValue.toUpperCase()
                                })
                            }
                        }}
                        data-test-id="VESSEL"
                        closeIcon={false}
                        renderInput={(params) => {
                            return (<TextField {...params} variant="outlined" data-test-id="VESSELDrop" onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.AutocompleteTextfieldChange(event)
                            }}
                                value={values.VESSEL}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <Box className="AutoComplateTextField">
                                            {this.state.VesselDropDownOpen && this.state.VesselDropDownData.length === 0 || this.state.PortNetAPiCall ? <CircularProgress color="inherit" size={20} /> : null}
                                            <ProjectText variant='h6' TextSize='16px' >
                                        <span className="selectSarch">{params.InputProps.endAdornment}</span>
                                    </ProjectText>
                                        </Box>
                                    ),
                                }}
                            />)
                        }}
                        onClose={this.OnOpenDropDown}
                        open={ !!this.state?.vesselDropdownState && this.state.VesselDropDownOpen}
                        noOptionsText={this.state.PortNetAPiCall ? "Loading..." : "No Options"}
                        clearOnEscape={false}
                        clearOnBlur={false}
                        renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                            return (
                                <ProjectText
                                    variant="h6"
                                    TextSize="14px"
                                    fontweight="600"
                                    data-test-id={(option as { name: string }).name}
                                >
                                    {(option as { name: string }).name ?? ""}
                                </ProjectText>
                            )
                        }}
                    />
                    {this.ErrorRender(errors.VESSEL || "Vessel Required",(errors.VESSEL !== undefined && touched.VESSEL !== undefined && values.VESSEL == "") || (touched.VESSEL !== undefined && this.state.vesselInputValue === ""))}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="VESSEL">
                        <ProjectText
                            Upercase
                            variant="subtitle1"
                            TextSize="14px"
                            fontweight="900"
                            textcolor="black"
                        >
                            VOYAGE
                        </ProjectText>
                    </InputLabel>
                    <AutocompleteStyle
                        id="VOyogesdata"
                        options={this.state.VoyageDropDownData}
                        onChange={(event: React.ChangeEvent<{}>, value) => {
                            this.AutocompleteOnChange(setFieldValue, value, "VOYAGE")
                            this.setState({voyageValue: value})
                        }}
                        onOpen={this.OnOpenDropDownVoyage}
                        getOptionSelected={(option: unknown, value) => (option as { inVoyN: string }).inVoyN + (option as { outVoyN: string }).outVoyN === this.state.InVoyogeAndOutVoyoge}
                        size="small"
                        FormError={
                            errors.VOYAGE !== undefined &&
                            touched.VOYAGE !== undefined && values.VOYAGE === ""
                        }
                        getOptionLabel={(option: unknown) => `In ${(option as { inVoyN: string }).inVoyN} Out ${(option as { outVoyN: string }).outVoyN}` ?? ""}
                        closeIcon={false}
                        inputValue={this.state.InVoyogeAndOutVoyoge}
                        data-test-id="VOYAGE"
                        renderInput={(params) => {
                            return (<TextField {...params} variant="outlined" data-test-id="VOYAGEDrop" />)
                        }}
                        onClose={this.OnOpenDropDownVoyage}
                        onInputChange={(event, newInputValue) => {
                            if (event !== null) {
                              setFieldValue("VOYAGE", newInputValue)
                              this.setState({
                                InVoyogeAndOutVoyoge: newInputValue
                              })
                            }
                          }}
                        open={this.state.VoyageDropDownOpen}
                        clearOnEscape={false}
                        clearOnBlur={false}
                        renderOption={(option: unknown, state: AutocompleteRenderOptionState) => {
                            return (
                                <Box data-test-id={(option as { name: string }).name ?? ""}>
                                    <ProjectText variant="h6" TextSize=".875rem" fontweight="600">
                                        <ProjectText TextSize=".875rem"
                                            fontweight="600" variant="caption" textcolor="grey">IN </ProjectText>{(option as { inVoyN: string }).inVoyN ?? ""} &nbsp; &nbsp; <ProjectText variant="caption" TextSize=".875rem"
                                                fontweight="600" textcolor="grey"> OUT</ProjectText>{" "}
                                        {(option as { outVoyN: string }).outVoyN ?? ""}
                                    </ProjectText>
                                </Box>
                            )
                        }}
                    />
                    {this.ErrorRender(errors.VOYAGE, errors.VOYAGE !== undefined && touched.VOYAGE !== undefined && values.VOYAGE === "")}
                </Grid>
            </Grid>
        )
    }

    PolRender = (errors: {
        VESSEL?: string;
        POL?: string;
        VOYAGE?: string;
        ETAATPOD?: string;
        POD?: string;
        VALIDITYOFLISTING?: string;
        PODTERMINAL?: string;
        QUANTITYOFTEUSAVAILABLEFORSALE?: string;
        POLTERMINAL?: string;
        ETBATPOL?: string;
        EXPIRYDATE?: string;
    },
        touched: {
            VESSEL?: string | boolean;
            ETBATPOL?: string | boolean;
            POD?: string | boolean;
            VOYAGE?: string | boolean;
            POLTERMINAL?: string | boolean;
            ETAATPOD?: string | boolean;
            VALIDITYOFLISTING?: string | boolean;
            PODTERMINAL?: string | boolean;
            QUANTITYOFTEUSAVAILABLEFORSALE?: string | boolean;
            POL?: string | boolean;
            EXPIRYDATE?: string | boolean;
        },
        values: {
            VESSEL: string;
            POL: string;
            VOYAGE: string;
            ETAATPOD?: string;
            POD: string;
            PODTERMINAL: string;
            QUANTITYOFTEUSAVAILABLEFORSALE: string;
            VALIDITYOFLISTING: string;
            EXPIRYDATE?: string;
            POLTERMINAL: string;
            ETBATPOL: string;
        },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void) => {

            const CanweSelectPolAndPod = this.state.IsVesselSelected && this.state.VesselDropDownString !== ""  && this.state.InVoyogeAndOutVoyoge !== ""
        return (
            <Grid container spacing={2} style={{ marginBottom: ".625rem" }}>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                            <InputLabel htmlFor="POL">
                                <ProjectText
                                    Upercase
                                    variant="subtitle1"
                                    TextSize=".875rem"
                                    fontweight="900"
                                    textcolor="black"
                                >
                                    POL
                                </ProjectText>
                            </InputLabel>
                            <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                getOptionSelected={(option: unknown, value) => (option as { PortName: string }).PortName === values.POL.split(",")[0]}
                                options={this.state.PolDropDownData.filter((item: { PortName: string }) => item.PortName !== values.POD.split(",")[0])}
                                getOptionLabel={(option: unknown) => (option as { PortName: string }).PortName}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "POL")
                                }}
                                FormError={
                                    errors.POL !== undefined && touched.POL !== undefined && values.POL === ""
                                }
                                inputValue={values.POL.split(",")[0]}
                                onInputChange={(event, newInputValue) => {                                    
                                    if (event !== null && CanweSelectPolAndPod) {
                                        setFieldValue("POL", newInputValue)
                                    }
                                }}
                                clearOnBlur={false}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {(option as { PortName: string }).PortName ?? ""}
                                    </ProjectText>
                                )}
                            />
                            {this.ErrorRender(errors.POL, errors.POL !== undefined && touched.POL !== undefined && values.POL === "")}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel htmlFor="POLTERMINAL">
                                <ProjectText
                                    variant="subtitle1"
                                    TextSize=".875rem"
                                    fontweight="900"
                                    textcolor="black"
                                >
                                    POL TERMINAL
                                </ProjectText>
                            </InputLabel>
                            <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                options={this.state.PolTerminalDropDown}
                                getOptionSelected={(option: unknown, value) => option as string === values.POLTERMINAL}
                                getOptionLabel={(option: unknown) => option as string ?? ""}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "POLTERMINAL")
                                    this.setState({POLTerminalValue: value})
                                }}
                                inputValue={values.POLTERMINAL}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null && CanweSelectPolAndPod) {
                                        setFieldValue("POLTERMINAL", newInputValue)
                                    }
                                }}
                                clearOnBlur={false}
                                FormError={errors.POLTERMINAL !== undefined && touched.POLTERMINAL !== undefined && values.POLTERMINAL === ""}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {option as string ?? ""}
                                    </ProjectText>
                                )}
                            />
                            {this.ErrorRender(
                                errors.POLTERMINAL,
                                errors.POLTERMINAL !== undefined &&
                                touched.POLTERMINAL !== undefined && values.POLTERMINAL === ""
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <InputLabel htmlFor="POD">
                                <ProjectText
                                    Upercase
                                    variant="subtitle1"
                                    TextSize=".875rem"
                                    fontweight="900"
                                    textcolor="black"
                                >
                                    POD
                                </ProjectText>
                            </InputLabel>
                            <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                BoderRadius="6px"
                                options={this.state.PolDropDownData.filter((item: { PortName: string }) => item.PortName !== values.POL.split(",")[0])}
                                getOptionLabel={(option: unknown) => (option as { PortName: string }).PortName}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "POD")
                                }}
                                clearOnBlur={false}
                                FormError={
                                    errors.POD !== undefined && touched.POD !== undefined && values.POD === ""
                                }
                                inputValue={values.POD.split(",")[0]}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null && CanweSelectPolAndPod) {
                                        setFieldValue("POD", newInputValue)
                                    }
                                }}
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {(option as { PortName: string }).PortName ?? ""}
                                    </ProjectText>
                                )}
                            />
                            {this.ErrorRender(errors.POD, errors.POD !== undefined && touched.POD !== undefined && values.POD === "")}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel htmlFor="PODTERMINAL">
                                <ProjectText
                                    variant="subtitle1"
                                    TextSize=".875rem"
                                    fontweight="900"
                                    textcolor="black"
                                >
                                    POD TERMINAL
                                </ProjectText>
                            </InputLabel>
                            <AutocompleteStyle
                                id="combo-box-demo"
                                size="small"
                                BoderRadius="6px"
                                options={this.state.PodTerminalDropDown}
                                getOptionLabel={(option: unknown) => option as string ?? ""}
                                onChange={(event: React.ChangeEvent<{}>, value) => {
                                    this.PolAndPodAutoComplateOnChange(setFieldValue, value, "PODTERMINAL")
                                    this.setState({PODTerminalValue: value})
                                }}
                                clearOnBlur={false}
                                inputValue={values.PODTERMINAL}
                                onInputChange={(event, newInputValue) => {
                                    if (event !== null && CanweSelectPolAndPod) {
                                        setFieldValue("PODTERMINAL", newInputValue)
                                    }
                                }}
                                FormError={
                                    errors.PODTERMINAL !== undefined &&
                                    touched.PODTERMINAL !== undefined && values.PODTERMINAL === ""
                                }
                                closeIcon={false}
                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                                    <ProjectText
                                        TextSize="14px"
                                        variant="h6"
                                        fontweight="600"
                                    >
                                        {option as string ?? ""}
                                    </ProjectText>
                                )}
                            />

                            {this.ErrorRender(
                                errors.PODTERMINAL,
                                errors.PODTERMINAL !== undefined &&
                                touched.PODTERMINAL !== undefined && values.PODTERMINAL === ""
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderVasselBlock = (
        errors: {
            VESSEL?: string;
            POL?: string;
            VOYAGE?: string;
            POLTERMINAL?: string;
            ETBATPOL?: string;
            ETAATPOD?: string;
            POD?: string;
            VALIDITYOFLISTING?: string;
            PODTERMINAL?: string;
            QUANTITYOFTEUSAVAILABLEFORSALE?: string;
            EXPIRYDATE?: string;
        },
        touched: {
            VESSEL?: string | boolean;
            POL?: string | boolean;
            VOYAGE?: string | boolean;
            POLTERMINAL?: string | boolean;
            ETBATPOL?: string | boolean;
            ETAATPOD?: string | boolean;
            POD?: string | boolean;
            VALIDITYOFLISTING?: string | boolean;
            PODTERMINAL?: string | boolean;
            QUANTITYOFTEUSAVAILABLEFORSALE?: string | boolean;
            EXPIRYDATE?: string | boolean;
        },
        values: {
            VESSEL: string;
            POL: string;
            VOYAGE: string;
            POLTERMINAL: string;
            ETBATPOL: string;
            ETAATPOD?: string;
            POD: string;
            PODTERMINAL: string;
            QUANTITYOFTEUSAVAILABLEFORSALE: string;
            VALIDITYOFLISTING: string;
            EXPIRYDATE?: string;
        },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void
    ) => {
        return (
            <RenderVasselBlockWrapper className="vesselContainer">
                <h3 className="vesselHeading">Vessel Details</h3>
                {this.VesselRedner(errors, touched, values, setFieldValue)}
                {this.PolRender(errors, touched, values, setFieldValue)}
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="ETBATPOL">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                ETB AT POL
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": "ETBATPOL",
                                min: moment.parseZone(new Date().setDate(new Date().getDate() + +values.VALIDITYOFLISTING + 1)).format("YYYY-MM-DD")
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => {
                                this.setState({ etbDate: e.target.value });
                                const dateFrom = moment(e.target.value)
                                .subtract(+values.VALIDITYOFLISTING, "d")
                                .parseZone()
                                this.setState({ expiry_date: dateFrom });
                                this.AddListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "ETBATPOL"
                                );
                                setFieldValue("ETAATPOD", "")
                            }}
                            size="small"
                            FormError={
                                errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined && values.ETBATPOL === ""
                            }
                            type="Date"
                            value={values.ETBATPOL}
                            id="ETBATPOL"
                            name="ETBATPOL"
                            fullWidth
                        />
                        {this.ErrorRender(errors.ETBATPOL, errors.ETBATPOL !== undefined && touched.ETBATPOL !== undefined && values.ETBATPOL === "")}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="ETAATPOD">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                ETA AT POD
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": "ETAATPOD",
                                min: values.ETBATPOL
                                ? moment.parseZone(values.ETBATPOL)
                                    .add(1, 'days')
                                    .format("YYYY-MM-DD")
                                : moment()
                                    .add(+values.VALIDITYOFLISTING + 1, 'days')
                                    .format("YYYY-MM-DD")
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => {
                                this.setState({ etaDate: e.target.value });
                                this.AddListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "ETAATPOD"
                                );
                            }}
                            size="small"
                            FormError={
                                errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined && values.ETAATPOD === ""
                            }
                            type="Date"
                            value={values.ETAATPOD}
                            id="ETAATPOD"
                            name="ETAATPOD"
                            fullWidth
                        />
                        {this.ErrorRender(errors.ETAATPOD, errors.ETAATPOD !== undefined && touched.ETAATPOD !== undefined && values.ETAATPOD === "")}
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} lg={6}>
                        <InputLabel htmlFor="QUANTITYOFTEUSAVAILABLEFORSALE">
                            <ProjectText
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                QUANTITY OF TEUs AVAILABLE FOR SALE
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": "QUANTITYOFTEUSAVAILABLEFORSALE",
                                onKeyDown: (e) => {
                                    if (e.key === '.' || e.key === 'e' || e.key === '+' || e.key === '-') {
                                      e.preventDefault();
                                    }
                                }
                            }}
                            onKeyPress={(e)=>{this.validateOnlyNumber(e)}}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                            ) => {
                                this.AddListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "QUANTITYOFTEUSAVAILABLEFORSALE"
                                );
                            }}
                            size="small"
                            FormError={
                                errors.QUANTITYOFTEUSAVAILABLEFORSALE !== undefined &&
                                touched.QUANTITYOFTEUSAVAILABLEFORSALE !== undefined && (values.QUANTITYOFTEUSAVAILABLEFORSALE === "" || parseInt(values.QUANTITYOFTEUSAVAILABLEFORSALE) <1)
                            }
                            type="number"
                            value={values.QUANTITYOFTEUSAVAILABLEFORSALE}
                            id="QUANTITYOFTEUSAVAILABLEFORSALE"
                            name="QUANTITYOFTEUSAVAILABLEFORSALE"
                            fullWidth
                        />
                        {this.ErrorRender(
                            errors.QUANTITYOFTEUSAVAILABLEFORSALE,
                            errors.QUANTITYOFTEUSAVAILABLEFORSALE !== undefined &&
                            touched.QUANTITYOFTEUSAVAILABLEFORSALE !== undefined && values.QUANTITYOFTEUSAVAILABLEFORSALE === ""
                        )}
                        {
                                    this.ErrorRender(
                                        "Invalid Input", touched.QUANTITYOFTEUSAVAILABLEFORSALE !== undefined && parseInt(values.QUANTITYOFTEUSAVAILABLEFORSALE) <1                                    
                                        )
                                }
                    </Grid>
                    <Grid item lg={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="EXPIRYDATE">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                    >
                                        VALIDITY OF LISTING
                                    </ProjectText>
                                </InputLabel>
                                <div className="validityBox">
                                    <LandingPageTextField
                                        BoderRadius="6px"
                                        className="inputHeightMedia"
                                        inputProps={{
                                            "data-test-id": "VALIDITYOFLISTING",
                                            max: moment(values.ETBATPOL).diff(moment(), 'days'),
                                        }}
                                        onKeyPress={(e)=>{this.validateOnlyNumber(e)}}
                                        variant="outlined"
                                        onChange={(
                                            e: React.ChangeEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >
                                        ) => {
                                            const dateFrom = moment(values.ETBATPOL)
                                                .subtract(e.target.value, "days")
                                                .parseZone()
                                            this.setState({ expiry_date: dateFrom });
                                            this.AddListingFormTextFieldChange(
                                                e,
                                                setFieldValue,
                                                "VALIDITYOFLISTING"
                                            );
                                        }}
                                        type="number"
                                        size="small"
                                        FormError={
                                            errors.VALIDITYOFLISTING !== undefined &&
                                            touched.VALIDITYOFLISTING !== undefined && (values.VALIDITYOFLISTING === "" || parseInt(values.VALIDITYOFLISTING) <1   ) 
                                        }
                                        value={values.VALIDITYOFLISTING}
                                        id="VALIDITYOFLISTING"
                                        name="VALIDITYOFLISTING"
                                    />
                                    <div className="etbDiv">
                                        <span className="beforeETB">DAYS BEFORE ETB</span>
                                    </div>
                                </div>
                                {this.ErrorRender(
                                    errors.VALIDITYOFLISTING,
                                    errors.VALIDITYOFLISTING !== undefined &&
                                    touched.VALIDITYOFLISTING !== undefined && values.VALIDITYOFLISTING === ""
                                )}
                                {
                                    this.ErrorRender(
                                        "Invalid Input", touched.VALIDITYOFLISTING !== undefined && parseInt(values.VALIDITYOFLISTING) <1                                    
                                        )
                                }
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <InputLabel htmlFor="EXPIRYDATE">
                                    <ProjectText
                                        Upercase
                                        variant="subtitle1"
                                        TextSize="14px"
                                        fontweight="900"
                                        textcolor="black"
                                        type="date"
                                    >
                                        EXPIRY DATE
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    BoderRadius="6px"
                                    inputProps={{
                                        "data-test-id": "EXPIRYDATE",
                                    }}
                                    variant="outlined"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                    ) => {
                                        this.AddListingFormTextFieldChange(
                                            e,
                                            setFieldValue,
                                            "EXPIRYDATE"
                                        );
                                    }}
                                    size="small"
                                    FormError={
                                        errors.EXPIRYDATE !== undefined &&
                                        touched.EXPIRYDATE !== undefined
                                    }
                                    type="date"
                                     disabled
                                    value={moment.parseZone(this.state.expiry_date).format("YYYY-MM-DD")}
                                    id="EXPIRYDATE"
                                    name="EXPIRYDATE"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </RenderVasselBlockWrapper>
        );
    };

    ViewLessOnchange = () => {
        this.setState({
            isViewLess: !this.state.isViewLess,
        });
    };

    ViewLessButton = () => {
        return (
            <Box className="ViewMoreContainer">
                <Button variant="text" data-test-id="ViewLess" startIcon={this.state.isViewLess ? <img src={downIMg} alt="downIMg" /> : <img src={upIMg} alt="upimg" />} onClick={this.ViewLessOnchange}>
                    <ProjectText fontweight="700" TextSize="1rem">
                        {this.state.isViewLess ? "VIEW MORE" : "VIEW LESS"}
                    </ProjectText>
                </Button>
            </Box>
        )
    }

    RemoveContainerType = (value: Array<{ CONTAINERTYPE: string, CONTAINERCODES: string, ContainerId?: number, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string }> }>, index: number, setFieldValue: (field: string, value: Array<{ CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string }> }>, shouldValidate?: boolean | undefined) => void) => {
        if (this.state.FormIsEdit) {
            const Containerdata = [...value];
            const DeleteData = [...this.state.DeleteContainer]
            const FilterData = DeleteData.filter((item) => {
                return item.DeleteContainerType !== Containerdata[index].ContainerId
            })
            let Curreddata = { DeleteContainerType: Containerdata[index].ContainerId, }
            FilterData.push(Curreddata)
            this.setState({ DeleteContainer: FilterData })
        }
        let data = [...value];
        if (1 < data.length) {
            data.splice(index, 1);
        }
        setFieldValue("ContainerPricingDetails", data)
    }

    Sectionheading = (CurrentIndex: number,
        index: number,
        ContainerPricingDetailsLengthIsOne: boolean,
        values: FormValues,
        setFieldValue: (field: string, value: Array<{ CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string }> }>, shouldValidate?: boolean | undefined) => void
    ) => {
        const numberStrings = [
            "First", "Second", "Third", "Fourth", "Fifth",
            "Sixth", "Seventh", "Eighth", "Ninth", "Tenth",
            "Eleventh", "Twelfth", "Thirteenth", "Fourteenth", "Fifteenth",
            "Sixteenth", "Seventeenth", "Eighteenth", "Nineteenth", "Twentieth"
        ];
        return (
            <Box style={{ position: "relative", padding: "10px 0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {
                    values.ContainerPricingDetails.length !== 1 && (
                        <ProjectText data-test-id={`Sectionheader${index}`} variant="h6" textcolor="#7F889D" Upercase fontweight="700" TextSize="14px" align="center">
                            {`${numberStrings[index] ? numberStrings[index] : CurrentIndex} CONTAINER`}
                        </ProjectText>
                    )
                }
                {ContainerPricingDetailsLengthIsOne && (
                    <Button data-test-id={`RemoveType${index}`} variant="text"
                        onClick={() => this.RemoveContainerType(values.ContainerPricingDetails, index, setFieldValue)}
                        style={{
                            position: "absolute",
                            right: "8px"
                        }} startIcon={<img src={remove} alt="delte" />}>
                        <ProjectText variant="h6" fontweight="700" textcolor="#1653DD" TextSize=".875rem">
                            Remove
                        </ProjectText>
                    </Button>
                )}
            </Box>
        )
    }

    RenderViewLessContainer = (item: {
        CONTAINERTYPE: string, CONTAINERCODES: string,
        CONTAINERSIZEARRAY: Array<{
            CONTAINERSIZE: string,
            AVERAGETONSBOX: string,
            RATE: string,
            TERMSONE: string,
            TERMSTWO: string,
        }>
    }, values: {
        ContainerPricingDetails: Array<{
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string,
            }>
        }>
    }, index: number) => {
        return (
            <Box className="ViewlessContainer">
                <Grid container spacing={2} justifyContent="flex-end" style={{ padding: "5px 10px" }}>
                    <Grid item xs={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="CONTAINERTYPE">
                                    <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                        CONTAINER TYPE
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    variant="outlined"
                                    fullWidth
                                    BoderRadius="6px"
                                    size="small"
                                    value={item.CONTAINERTYPE}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel htmlFor="CONTAINERCODES">
                                    <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                        Container Type
                                    </ProjectText>
                                </InputLabel>
                                <LandingPageTextField
                                    variant="outlined"
                                    fullWidth
                                    BoderRadius="6px"
                                    size="small"
                                    value={item.CONTAINERCODES}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {item.CONTAINERSIZEARRAY.map((data, index: number) => {
                        return (
                            <Grid item xs={8} key={`${String.fromCharCode(94 + index)}`}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="CONTAINERCODES">
                                            <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                CONTAINER SIZE 
                                            </ProjectText>
                                        </InputLabel>
                                        <LandingPageTextField
                                            variant="outlined"
                                            fullWidth
                                            BoderRadius="6px"
                                            size="small"
                                            value={data.CONTAINERSIZE}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="CONTAINERCODES">
                                            <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                AVG TONS/BOX
                                            </ProjectText>
                                        </InputLabel>
                                        <LandingPageTextField
                                            variant="outlined"
                                            fullWidth
                                            BoderRadius="6px"
                                            size="small"
                                            value={data.AVERAGETONSBOX}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="CONTAINERCODES">
                                            <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                RATE
                                            </ProjectText>
                                        </InputLabel>
                                        <LandingPageTextField
                                            variant="outlined"
                                            fullWidth
                                            BoderRadius="6px"
                                            value={data.RATE}
                                            disabled
                                            size="small"
                                            InputProps={{
                                                endAdornment: <Box style={{ width: "100px" }}>
                                                    <ProjectText textcolor="#ACACAC" TextSize="1rem" style={{whiteSpace:"nowrap"}}>/{data.CONTAINERSIZE}</ProjectText>
                                                </Box>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel htmlFor="CONTAINERCODES">
                                            <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                                TERMS
                                            </ProjectText>
                                        </InputLabel>
                                        <LandingPageTextField
                                            variant="outlined"
                                            fullWidth
                                            BoderRadius="6px"
                                            size="small"
                                            value={`${data.TERMSONE} / ${data.TERMSTWO} `}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                    }
                </Grid>
                <Grid container spacing={2}>
                    {values.ContainerPricingDetails.length - 1 !== index && (
                        <Grid item xs={12}>
                            <Box className="DividerContainer DivedSection">
                                <Divider />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
        )
    }

    onSelectConainer = (e: React.ChangeEvent<{ value: unknown }> | { target: { value: string } }) => {
        let temp = this.state.containerData.filter(
            (data: any) => data.container_type === e.target.value
        );
      
        this.setState({ selectedContainer: temp[0] });
    };

    AddContainer = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ value: any }>,
        setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, keyname: "CONTAINERTYPE" | "CONTAINERCODES", value: Array<{
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string
            }>
        }>, index: number) => {

        if (keyname === "CONTAINERTYPE") {
            this.onSelectConainer(e);
            setFieldValue(`ContainerPricingDetails.${[index]}.${keyname}`, `${e.target.value}`)
            if (value[index].CONTAINERTYPE !== "") {
                for (let i = 0; i < value[index].CONTAINERSIZEARRAY.length; i++) {
                    setFieldValue(`ContainerPricingDetails[${index}].CONTAINERSIZEARRAY.${i}.CONTAINERSIZE`, "");
                    setFieldValue(`ContainerPricingDetails[${index}].CONTAINERSIZEARRAY.${i}.AVERAGETONSBOX`, "");
                    setFieldValue(`ContainerPricingDetails[${index}].CONTAINERSIZEARRAY.${i}.RATE`, "");
                    setFieldValue(`ContainerPricingDetails[${index}].CONTAINERSIZEARRAY.${i}.TERMSONE`, "");
                    setFieldValue(`ContainerPricingDetails[${index}].CONTAINERSIZEARRAY.${i}.TERMSTWO`, "");
                }
            }
            setFieldValue(`ContainerPricingDetails[${index}].CONTAINERCODES`, '');
        }
        if (keyname === "CONTAINERCODES") {
            setFieldValue(`ContainerPricingDetails.${[index]}.${keyname}`, `${e.target.value}`)
        }
    }

    MainArrayError = (errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>, index: number, key: "CONTAINERTYPE" | "CONTAINERCODES" | "CONTAINERSIZE" | "AVERAGETONSBOX" | "RATE" | "TERMSONE" | "TERMSTWO"
    ) => {                
        if (errors.ContainerPricingDetails && touched.ContainerPricingDetails) {
            let newData = errors?.ContainerPricingDetails[index] as FormErros
            let touchData = errors?.ContainerPricingDetails[index] && touched?.ContainerPricingDetails[index] as unknown as FormErros
            if (newData && touchData) {
                if (key === "CONTAINERTYPE" || key === "CONTAINERCODES") {
                    return newData[key]
                }
            }
        }
    }

    Twofield = (errors: FormikErrors<FormValues>, touched: FormikTouched<{ PRINCIPALORGANISATION: string | boolean }>
        , index: number, values: FormValues,
        item: {
            CONTAINERTYPE: string,
            CONTAINERCODES: string
        },
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean
        ) => void
    ) => {
        const SelectContainerType: Array<string | undefined> = values.ContainerPricingDetails.map((data: { CONTAINERTYPE: string }) => {
            if (data.CONTAINERTYPE) {
                return data.CONTAINERTYPE
            }
        }).filter((data) => data !== undefined);

        let FilterData = this.state.containerData.filter((ContainerItems: { container_type: string }) => {
            const RemoveCurrent = SelectContainerType.filter((SelectedString: string | undefined) => SelectedString !== values.ContainerPricingDetails[index].CONTAINERTYPE)
            if (!RemoveCurrent.includes(ContainerItems.container_type)) {
                return ContainerItems
            }
        })
        return (
            <Grid container spacing={2} style={{ marginBottom: "10px", padding: "0 10px" }}>
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="CONTAINERTYPE">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                            CONTAINER STATUS 
                        </ProjectText>
                    </InputLabel>
                    <ProjectTextField
                    MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                        variant="outlined"
                        fullWidth
                        FormError={!!this.MainArrayError(errors, touched, index, "CONTAINERTYPE") && !values.ContainerPricingDetails[index]?.CONTAINERTYPE}
                        value={item.CONTAINERTYPE}
                        inputProps={{
                            "data-test-id": "CONTAINERTYPE"
                        }}
                        id="CONTAINERTYPE"
                        name="CONTAINERTYPE"
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                            this.AddContainer(e, setFieldValue, "CONTAINERTYPE", values.ContainerPricingDetails, index)
                        }}
                    >
                        
                        {FilterData?.map(
                            (data: { container_type: string }) => {
                                return (
                                    <OptionForSelect
                                         style={{position: 'relative'}}
                                         key={data.container_type}
                                        value={data.container_type}>
                                        <ProjectText
                                            variant="h6"
                                            TextSize="14px"
                                            fontweight="600"
                                        >
                                            {data.container_type}
                                        </ProjectText>
                                    </OptionForSelect>
                                );
                            }
                        )}
                    </ProjectTextField>
                    {this.ErrorRender(this.MainArrayError(errors, touched,  index, "CONTAINERTYPE"), !!this.MainArrayError(errors, touched, index, "CONTAINERTYPE") && !values.ContainerPricingDetails[index]?.CONTAINERTYPE)}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="CONTAINERCODES">
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                            CONTAINER TYPES
                        </ProjectText>
                    </InputLabel>
                    <ProjectTextField
                     MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                        variant="outlined"
                        fullWidth
                        FormError={!!this.MainArrayError(errors, touched, index, "CONTAINERCODES")}
                        value={item.CONTAINERCODES.split(",").filter((codeString) => codeString?.trim() !== "").map((val: string) => val?.trim())}
                        multiple
                        style={{
                            zIndex: 1,
                            paddingTop: "0px",
                            paddingBottom: "0px"
                        }}
                        className="MultiSelect"
                        id="CONTAINERCODES"
                        name="CONTAINERCODES"
                        onMouseUp={() => {
                            this.onSelectConainer({ target: { value: item.CONTAINERTYPE } })
                        }}
                        renderValue={() => {
                            return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', zIndex: 900 }}>
                                    {(item.CONTAINERCODES.split(",").filter((codeString) => codeString?.trim() !== "") || []).map((val: string) => {
                                        return <Box style={{ display: "flex", fontWeight: 600, fontFamily: "Asap" }} key={val?.trim()}>
                                            <Chip variant="outlined" className="DeleteChip" key={val?.trim()} label={val?.trim()} />
                                        </Box>
                                    })}
                                </Box>
                            )
                        }}
                        inputProps={{
                            "data-test-id": "CONTAINERCODES"
                        }}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                            this.AddContainer(e, setFieldValue, "CONTAINERCODES", values.ContainerPricingDetails, index)
                        }}
                    >
                        {this.state.containerData.filter((data) => { if (data.container_type === item.CONTAINERTYPE) { return item } })[0]?.codes?.map(
                            (val: string) => {
                                return (
                                    <OptionForSelect value={val?.trim()} key={val?.trim()} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <ProjectText
                                            variant="h6"
                                            TextSize="14px"
                                            fontweight="600"
                                        >
                                            {val.trim()}
                                        </ProjectText>
                                        {(item.CONTAINERCODES.split(",").filter((codeString) => codeString !== "") || []).map((its: string) => its.trim()).includes(val.trim()) ? <Checkbox    style={{color:'#1653DD'}} checked/> : <Checkbox />}
                                    </OptionForSelect>
                                );
                            }
                        )}
                    </ProjectTextField>
                    {this.ErrorRender(this.MainArrayError(errors, touched, index, "CONTAINERCODES"), !!this.MainArrayError(errors, touched, index, "CONTAINERCODES"))}
                </Grid>
                <Grid item xs={12}>
                    <Box className="DividerContainer">
                        <Divider />
                    </Box>
                </Grid>
            </Grid>
        )
    }

    AddContainerSize = (
        values: Array<{
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string
            }>
        }>,
        setFieldValue: (field: string, value: { CONTAINERSIZE: string; AVERAGETONSBOX: string; RATE: string; TERMSONE: string; TERMSTWO: string }[], shouldValidate?: boolean | undefined) => void
    ) => {
        //Create a new array with the updated values
        let LastIndex = values.length - 1;

        const newContainerSizeArray = [...values[LastIndex].CONTAINERSIZEARRAY, {
            CONTAINERSIZE: "",
            AVERAGETONSBOX: "",
            RATE: "",
            TERMSONE: "",
            TERMSTWO: ""
        }];
        setFieldValue(`ContainerPricingDetails[${LastIndex}].CONTAINERSIZEARRAY`, newContainerSizeArray);
    }

    RemoveContainerSize = (
        value: Array<{
            CONTAINERTYPE: string;
            CONTAINERCODES: string;
            ContainerId?: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string;
                AVERAGETONSBOX: string;
                RATE: string;
                TERMSONE: string;
                TERMSTWO: string;
                ContainerSizeId?: number
            }>;
        }>,
        ContainerPricingDetailsIndex: number,
        index: number,
        setFieldValue: (
            field: string,
            value: Array<{
                CONTAINERTYPE: string;
                CONTAINERCODES: string;
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string;
                    AVERAGETONSBOX: string;
                    RATE: string;
                    TERMSONE: string;
                    TERMSTWO: string;
                }>;
            }>,
            shouldValidate?: boolean | undefined
        ) => void
    ) => {
        // Create a copy of the CONTAINERSIZEARRAY and remove the item at the specified index
        const updatedData = [...value[ContainerPricingDetailsIndex].CONTAINERSIZEARRAY];
        updatedData.splice(index, 1);
        // Create a copy of the value array and update the CONTAINERSIZEARRAY with the modified data
        const updatedValue = [...value];
        updatedValue[ContainerPricingDetailsIndex] = {
            ...updatedValue[ContainerPricingDetailsIndex],
            CONTAINERSIZEARRAY: updatedData,
        };
        if (this.state.FormIsEdit) {
            const DeleteData = [...this.state.DeleteContainer]
            const CurrentDataContainer = value[ContainerPricingDetailsIndex]
            const ContainerTypeId = CurrentDataContainer.ContainerId
            const ContainerSizeId = CurrentDataContainer.CONTAINERSIZEARRAY[index].ContainerSizeId
            const FindIndex = DeleteData.findIndex((i) => {
                return i.DeleteContainerType === ContainerTypeId
            })
            let Curreddata = {}
            if (FindIndex !== -1) {
                const Container = DeleteData[FindIndex].DeleteContainerSize
                ContainerSizeId && Container?.push(ContainerSizeId)
            } else {
                Curreddata = {
                    DeleteContainerType: ContainerTypeId,
                    DeleteContainerSize: [ContainerSizeId]
                }
                DeleteData.push(Curreddata)
            }
            this.setState({
                DeleteContainer: DeleteData
            })
        }
        // Set the updated value using setFieldValue
        setFieldValue('ContainerPricingDetails', updatedValue);
    };

    AvrageLableAndRemoveIcon = (
        values: {
            ContainerPricingDetails: Array<{
                CONTAINERTYPE: string;
                CONTAINERCODES: string;
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string;
                    AVERAGETONSBOX: string;
                    RATE: string;
                    TERMSONE: string;
                    TERMSTWO: string;
                }>;
            }>
        },
        index: number,
        setFieldValue: (
            field: string,
            value: Array<{
                CONTAINERTYPE: string;
                CONTAINERCODES: string;
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string;
                    AVERAGETONSBOX: string;
                    RATE: string;
                    TERMSONE: string;
                    TERMSTWO: string;
                }>;
            }>,
            shouldValidate?: boolean | undefined
        ) => void,
        SubArrayIndex: number,
        ContainerSizeIsNotZero: boolean
    ) => {
        return (
            <Grid item xs={12}>
                <Box style={{ position: "relative", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <InputLabel htmlFor={`AVERAGETONSBOX${SubArrayIndex}`}>
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                        AVG. TONS/CONTAINER
                        </ProjectText>
                    </InputLabel>
                    {ContainerSizeIsNotZero && (
                        <Button variant="text" style={{ height: "10px", display: "flex", justifyContent: "center", alignItems: "center" }} data-test-id={`RemoveContainer${SubArrayIndex}`} onClick={() => this.RemoveContainerSize(values.ContainerPricingDetails, index, SubArrayIndex, setFieldValue)} startIcon={<img src={remove} alt="delte" />}>
                            <ProjectText variant="h6" textcolor="#1653DD" fontweight="700" TextSize=".875rem">
                                Remove
                            </ProjectText>
                        </Button>
                    )}
                </Box>
            </Grid>)
    }

    validateOnlyNumber=(e:any)=>{
        return (e.which < 48 || e.which > 57) && e.preventDefault()
    }


    AddContainerSubArrayData = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ value: unknown }>, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void, keyname: "CONTAINERTYPE" | "CONTAINERCODES" | "CONTAINERSIZE" | "AVERAGETONSBOX" | "RATE" | "TERMSONE" | "TERMSTWO",
        value: {
            PRINCIPALORGANISATION: string, VESSEL: string, VOYAGE: string, POD: string, PODTERMINAL: string, POL: string, POLTERMINAL: string, ETBATPOL: string, ETAATPOD: string, QUANTITYOFTEUSAVAILABLEFORSALE: string, VALIDITYOFLISTING: string, EXPIRYDATE: string, ContainerPricingDetails: Array<{
                CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string, }>
            }>, FULLNAME: string, EMAILADDRESS: string, CONTACTNUMBER: string, EMAILDISTRIBUTIONLIST: string | string[],
        }, index: number, SubArrayIndex: number) => {

        if (keyname === "CONTAINERSIZE") {
            let containerType = value.ContainerPricingDetails[index].CONTAINERTYPE
            let BoxSize = value.ContainerPricingDetails[index].CONTAINERSIZEARRAY[SubArrayIndex].CONTAINERSIZE
            let pod = value.POD
            let pol = value.POL
            if (pol === "") {
                toast.warning(`Please select POL`)
            }
            else if (pod === "") {
                toast.warning(`Please select POD`)
            } else {
                setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.${keyname}`, `${e.target.value}`)
                let FilterData = this.state.AllPriceTermsData?.filter((ItemData: { Container: string, PortOfDestination: string, PortOfLocation: string, BoxSize: string }) => {
                    if (ItemData?.Container?.includes(containerType) && ItemData?.PortOfDestination?.includes(pod) && ItemData?.PortOfLocation?.includes(pol) && ItemData?.BoxSize?.includes(BoxSize)) {
                        return ItemData
                    }
                })
                if (FilterData?.length !== 0) {
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.RATE`, FilterData[0]?.Rate)
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.TERMSONE`, FilterData[0]?.TermsOne)
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.TERMSTWO`, FilterData[0]?.TermsTwo)
                } else {
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.RATE`, "")
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.TERMSONE`, "")
                    setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.TERMSTWO`, "")
                }
            }
        } else {
            setFieldValue(`ContainerPricingDetails.${index}.CONTAINERSIZEARRAY.${SubArrayIndex}.${keyname}`, `${e.target.value}`)
        }
    }

    SubCondtion = (newData: FormErros, touchData: FormErros, SubArrayIndex: number, key: "CONTAINERSIZE" | "AVERAGETONSBOX" | "RATE" | "TERMSONE" | "TERMSTWO") => {
        if (newData?.CONTAINERSIZEARRAY[SubArrayIndex] && touchData?.CONTAINERSIZEARRAY[SubArrayIndex]) {
            let SubArrayError = newData.CONTAINERSIZEARRAY[SubArrayIndex]
            if (SubArrayError) {
                if (key === "CONTAINERSIZE" || key === "AVERAGETONSBOX" || key === "RATE" || key === "TERMSONE" || key === "TERMSTWO") {
                    return SubArrayError[key]
                }
            }
        }
    }

    FormErrorContainerPricingDetailsFirstObject = (errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>, index: number, SubArrayIndex: number, key: "CONTAINERSIZE" | "AVERAGETONSBOX" | "RATE" | "TERMSONE" | "TERMSTWO"
    ) => {
        if (errors.ContainerPricingDetails) {
            let newData = errors?.ContainerPricingDetails[index] && errors?.ContainerPricingDetails[index] as unknown as FormErros
            let touchData = touched.ContainerPricingDetails && touched?.ContainerPricingDetails[index] as unknown as FormErros
            if (newData && touchData) {
                if (newData?.CONTAINERSIZEARRAY && touchData?.CONTAINERSIZEARRAY) {
                    return this.SubCondtion(newData, touchData, SubArrayIndex, key)
                }
            }
        }
    }

    AvrageTextField = (SubArrayIndex: number,
        values: {
            PRINCIPALORGANISATION: string, VESSEL: string, VOYAGE: string, POD: string, PODTERMINAL: string, POL: string, POLTERMINAL: string, ETBATPOL: string, ETAATPOD: string, QUANTITYOFTEUSAVAILABLEFORSALE: string, VALIDITYOFLISTING: string, EXPIRYDATE: string, ContainerPricingDetails: Array<{
                CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string, }>
            }>, FULLNAME: string, EMAILADDRESS: string, CONTACTNUMBER: string, EMAILDISTRIBUTIONLIST: string | string[],
        }, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
        index: number, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>, SubArrayItem: { AVERAGETONSBOX: string }) => {
        return (
            <LandingPageTextField BoderRadius="6px" inputProps={{
                "data-test-id": `AVERAGETONSBOX${SubArrayIndex}`,
                min: 1
            }}
                variant="outlined"
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    this.AddContainerSubArrayData(e, setFieldValue, "AVERAGETONSBOX", values, index, SubArrayIndex)
                }}
                onKeyPress={(e)=>{this.validateOnlyNumber(e)}}
                size="small"
                FormError={!!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "AVERAGETONSBOX")}
                type='number'
                value={SubArrayItem.AVERAGETONSBOX}
                id={`AVERAGETONSBOX${SubArrayIndex}`}
                name={`AVERAGETONSBOX${SubArrayIndex}`}
                fullWidth
            />
        )
    }

    ContainerRate = (SubArrayItem: { CONTAINERSIZE: string, RATE: string }, SubArrayIndex: number, setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void,
        errors: FormikErrors<FormValues>,
        touched: FormikTouched<FormValues>,
        values: FormValues,
        index: number) => {
        return (
            <Grid item lg={6}>
                <InputLabel htmlFor={`RATE${SubArrayIndex}`}>
                    <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                    Seller Price
                    </ProjectText>
                </InputLabel>
                <LandingPageTextField BoderRadius="6px" inputProps={{ "data-test-id": `RATE${SubArrayIndex}`, min: 1 }}
                    variant="outlined"
                    className="inputHeightMedia"
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.AddContainerSubArrayData(e, setFieldValue, "RATE", values, index, SubArrayIndex)
                    }}
                    onKeyPress={(e)=>{this.validateOnlyNumber(e)}}

                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px"
                    }}
                    FormError={!!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "RATE")}
                    type='number'
                    size="small"
                    value={SubArrayItem.RATE}
                    id={`RATE${SubArrayIndex}`}
                    name={`RATE${SubArrayIndex}`}
                    InputProps={{
                        endAdornment: <Box style={{ width: "80px" }}>
                            <ProjectText textcolor="#ACACAC" TextSize="1rem">
                                {SubArrayItem.CONTAINERSIZE !== "" && `/${SubArrayItem.CONTAINERSIZE}`}
                            </ProjectText>
                        </Box>,
                        startAdornment: <Box className="CurrencyBox">
                            <ProjectText fontweight="700" TextSize="1rem">
                                USD
                            </ProjectText>
                        </Box>
                    }}
                    fullWidth
                />
                {this.ErrorRender(this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "RATE"), !!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "RATE"))}
            </Grid>
        )
    }


    TermsFields = (SubArrayItem: { CONTAINERSIZE: string, TERMSONE: string, TERMSTWO: string, RATE: string }, SubArrayIndex: number, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>, values: FormValues, index: number) => {
        return (
            <Grid item lg={6}>
                <Box style={{ display: "flex" }}>
                    <InputLabel htmlFor={`TERMS${SubArrayIndex}`}>
                        <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                            TERMS{' '}
                        </ProjectText>
                    </InputLabel>
                    {/* <LightTooltip
                        title="Full legal name of your organisation as registered with the local authority."
                        placement="right"
                        className="tooltip"
                    >
                        <InfoOutlinedIcon className="infoIcon" />
                    </LightTooltip> */}
                </Box>
                <Grid container spacing={2} justifyContent="center" className="SlashClassContainer">
                    <Grid item lg={6}>
                        <LandingPageTextField BoderRadius="6px" inputProps={{
                            "data-test-id": `TERMS${SubArrayIndex}`,
                        }}
                            variant="outlined"
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.AddContainerSubArrayData(e, setFieldValue, "TERMSONE", values, index, SubArrayIndex)
                            }}
                            size="small"
                            FormError={!!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSONE")}
                            type='text'
                            value={SubArrayItem.TERMSONE}
                            id={`TERMS${SubArrayIndex}`}
                            name={`TERMS${SubArrayIndex}`}
                            fullWidth
                        />
                        <Box className="ErrorTextContainer">
                            <ProjectText className={`TextError`}>
                                {this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSONE") ? this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSONE") : ""}
                            </ProjectText>
                        </Box>
                    </Grid>
                    <Box className="SlashClass">
                        <Box>
                            <span>
                                /
                            </span>
                        </Box>
                    </Box>
                    <Grid item lg={6}>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": `TERMSTwo${SubArrayIndex}`,
                            }}
                            variant="outlined"
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                this.AddContainerSubArrayData(e, setFieldValue, "TERMSTWO", values, index, SubArrayIndex)
                            }}
                            size="small"
                            FormError={!!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSTWO")}
                            type='text'
                            value={SubArrayItem.TERMSTWO}
                            id="EXPIRYDATE"
                            name="EXPIRYDATE"
                            fullWidth
                        />
                        <Box className="ErrorTextContainer">
                            <ProjectText className={`TextError`}>
                                {this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSTWO") ? this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "TERMSTWO") : ""}
                            </ProjectText>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    ContainerSizeDivder = (item: { CONTAINERSIZEARRAY: Array<{}> }, SubArrayIndex: number) => {
        return item.CONTAINERSIZEARRAY.length - 1 !== SubArrayIndex && (
            <Grid item xs={12}>
                <Box className="DividerContainer">
                    <Divider />
                </Box>
            </Grid>
        )
    }

    ContainerSize = (SubArrayItem: { CONTAINERSIZE: string }, SubArrayIndex: number, setFieldValue: (
        field: string,
        value: string,
        shouldValidate?: boolean
    ) => void,
        errors: FormikErrors<FormValues>,
        touched: FormikTouched<FormValues>,
        values: FormValues,
        index: number
    ) => {
        let lastdata = values?.ContainerPricingDetails[index]?.CONTAINERSIZEARRAY[0]?.CONTAINERSIZE !== "" && values?.ContainerPricingDetails[index]?.CONTAINERSIZEARRAY[0]?.CONTAINERSIZE
        let filterdata = this.state.selectedContainer?.seller_box_sizes?.filter((item: { size: string }) => {
            if (item.size !== lastdata) {
                return item
            }
        })

        const Filter = this.state.containerData.filter((data) => { if (data.container_type === values?.ContainerPricingDetails[index].CONTAINERTYPE) { return data } })[0]

        let dropdowndata = SubArrayIndex === 0 ? this.state.selectedContainer?.seller_box_sizes : filterdata
        return (
            <Grid item xs={12} lg={6}>
                <InputLabel htmlFor={`CONTAINERSIZE${SubArrayIndex}`}>
                    <ProjectText Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                        CONTAINER SIZE 
                    </ProjectText>
                </InputLabel>
                <ProjectTextField
                 MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                        "data-test-id": `CONTAINERSIZE${SubArrayIndex}`,
                    }}
                    FormError={!!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "CONTAINERSIZE") && !SubArrayItem.CONTAINERSIZE}
                    value={SubArrayItem.CONTAINERSIZE}
                    id={`CONTAINERSIZE${SubArrayIndex}`}
                    name={`CONTAINERSIZE${SubArrayIndex}`}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        this.AddContainerSubArrayData(e, setFieldValue, "CONTAINERSIZE", values, index, SubArrayIndex)
                    }}
                >
                    {dropdowndata?.map(
                        (val: { size: string }) => {
                            return (
                                <OptionForSelect value={val.size} key={val.size}>
                                    <ProjectText
                                        variant="h6"
                                        TextSize="14px"
                                        fontweight="600"
                                    >
                                        {val.size}
                                    </ProjectText>
                                </OptionForSelect>
                            );
                        }
                    )}
                </ProjectTextField>
                {this.ErrorRender(this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "CONTAINERSIZE"), !!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "CONTAINERSIZE") && !SubArrayItem.CONTAINERSIZE)}
            </Grid>
        )
    }

    WhiteDivder = (values: {
        PRINCIPALORGANISATION: string;
        VESSEL: string;
        VOYAGE: string;
        POD: string;
        PODTERMINAL: string;
        POL: string;
        POLTERMINAL: string;
        ETBATPOL: string;
        ETAATPOD: string;
        QUANTITYOFTEUSAVAILABLEFORSALE: string;
        EMAILDISTRIBUTIONLIST: string | string[];
        ContainerPricingDetails: Array<{}>
    }, index: number) => {
        return values.ContainerPricingDetails.length - 1 !== index && (
            <Box className="DividerContainer DivedSection">
                <Divider />
            </Box>
        )
    }

    FalseIsviewless = (errors: FormikErrors<FormValues>, touched: FormikTouched<FormValues>, index: number, values: FormValues, item: any, setFieldValue: any) => {
        let ContainerPricingDetailsIndex = this.ContainerPricingDetailsCondtion(values)
        return (
            <Box>
                {this.Twofield(errors, touched, index, values, item, setFieldValue)}
                {
                    item.CONTAINERSIZEARRAY.map((SubArrayItem: {
                        CONTAINERSIZE: string,
                        AVERAGETONSBOX: string,
                        RATE: string,
                        TERMSONE: string,
                        TERMSTWO: string
                    }, SubArrayIndex: number) => {
                        const ContainerSizeIsNotZero = item.CONTAINERSIZEARRAY.length !== 1
                        return (
                            <Grid container spacing={2} style={{ marginBottom: "10px", paddingLeft: "10px", paddingRight: "10px" }} key={`index${String.fromCharCode(94 + index)}`}>
                                {this.ContainerSize(SubArrayItem, SubArrayIndex, setFieldValue, errors, touched, values, index)}
                                <Grid item lg={6}>
                                    {this.AvrageLableAndRemoveIcon(values, index, setFieldValue, SubArrayIndex, ContainerSizeIsNotZero)}
                                    {this.AvrageTextField(SubArrayIndex, values, setFieldValue, index, errors, touched, SubArrayItem)}
                                    {this.ErrorRender(this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "AVERAGETONSBOX"), !!this.FormErrorContainerPricingDetailsFirstObject(errors, touched, index, SubArrayIndex, "AVERAGETONSBOX"))}
                                </Grid>
                                {this.ContainerRate(SubArrayItem, SubArrayIndex, setFieldValue, errors, touched, values, index)}
                                {this.TermsFields(SubArrayItem, SubArrayIndex, setFieldValue, errors, touched, values, index)}
                                {this.ContainerSizeDivder(item, SubArrayIndex)}
                            </Grid>
                        )
                    })
                }
                {
                    index !== values.ContainerPricingDetails.length - 1 &&
                    <Grid container xs={12} justifyContent="center">
                        {this.AddContainerSizeOnly(ContainerPricingDetailsIndex, values, setFieldValue, index)}
                    </Grid>
                }
                {this.WhiteDivder(values, index)}
            </Box>
        )
    }



    AddContainerSizeOnly = (ContainerPricingDetailsIndex: boolean,
        values: {
            ContainerPricingDetails: Array<{
                CONTAINERTYPE: string;
                CONTAINERCODES: string;
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string;
                    AVERAGETONSBOX: string;
                    RATE: string;
                    TERMSONE: string;
                    TERMSTWO: string;
                }>;
            }>
        },
        setFieldValue: (field: string, value: { CONTAINERSIZE: string; AVERAGETONSBOX: string; RATE: string; TERMSONE: string; TERMSTWO: string }[], shouldValidate?: boolean | undefined) => void,
        AddIndex: number
    ) => {
        let Lenthoffcontainer = this.state.selectedContainer?.seller_box_sizes?.length !== undefined ? this.state.selectedContainer?.seller_box_sizes.length : 0
        let Notdata = values?.ContainerPricingDetails[AddIndex]?.CONTAINERSIZEARRAY?.length === Lenthoffcontainer
        return !this.state.isViewLess && !Notdata && (
            <Grid item>
                <Box className="AddContainerFirstButton" style={{ justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <ContainedButton onClick={() => this.AddContainerSizeFuncation(values.ContainerPricingDetails, setFieldValue, AddIndex)} MarginFalse
                        BorderColor={Notdata ? "" : "black"}
                        TextColor="black" bgcolor="transparent"
                        HoverColor="transparent"
                        data-test-id="ADDCONTAINERSIZE"
                        disabled={Notdata} size="small"
                        startIcon={<img src={add_transparent} className="BlackPlusIcon" alt="" style={{ marginRight: "8px" }} />}
                    >
                        ADD CONTAINER SIZE
                    </ContainedButton>
                </Box>
            </Grid>
        )
    }

    AddContainerSizeFuncation = (
        values: Array<{
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string
            }>
        }>,
        setFieldValue: (field: string, value: { CONTAINERSIZE: string; AVERAGETONSBOX: string; RATE: string; TERMSONE: string; TERMSTWO: string }[], shouldValidate?: boolean | undefined) => void,
        AddIndex: number
    ) => {
        //Create a new array with the updated values

        const newContainerSizeArray = [...values[AddIndex].CONTAINERSIZEARRAY, {
            CONTAINERSIZE: "",
            AVERAGETONSBOX: "",
            RATE: "",
            TERMSONE: "",
            TERMSTWO: ""
        }];
        setFieldValue(`ContainerPricingDetails[${AddIndex}].CONTAINERSIZEARRAY`, newContainerSizeArray);
    }

    MapData = (values: any, setFieldValue: any, errors: any, touched: any) => {
        return values.ContainerPricingDetails.map((item: {
            CONTAINERTYPE: string,
            CONTAINERCODES: string,
            CONTAINERSIZEARRAY: Array<{
                CONTAINERSIZE: string,
                AVERAGETONSBOX: string,
                RATE: string,
                TERMSONE: string,
                TERMSTWO: string
            }>
        }, index: number) => {
            let CurrentIndex = index === 0 ? 1 : index + 1
            let ContainerPricingDetailsLengthIsOne = values.ContainerPricingDetails.length !== 1
            return (
                <Box key={`${item.CONTAINERCODES} ${String.fromCharCode(94 + index)}`}>
                    {this.Sectionheading(CurrentIndex, index, ContainerPricingDetailsLengthIsOne, values, setFieldValue)}
                    <Box>
                        {
                            this.state.isViewLess ?
                                this.RenderViewLessContainer(item, values, index) :
                                this.FalseIsviewless(errors, touched, index, values, item, setFieldValue)
                        }
                    </Box>
                </Box>
            )
        })
    }

    AddContainerSizeButton = (ContainerPricingDetailsIndex: boolean,
        values: {
            ContainerPricingDetails: Array<{
                CONTAINERTYPE: string;
                CONTAINERCODES: string;
                CONTAINERSIZEARRAY: Array<{
                    CONTAINERSIZE: string;
                    AVERAGETONSBOX: string;
                    RATE: string;
                    TERMSONE: string;
                    TERMSTWO: string;
                }>;
            }>
        },
        setFieldValue: (field: string, value: { CONTAINERSIZE: string; AVERAGETONSBOX: string; RATE: string; TERMSONE: string; TERMSTWO: string }[], shouldValidate?: boolean | undefined) => void
    ) => {
        let Lenthoffcontainer = this.state.selectedContainer?.seller_box_sizes?.length !== undefined ? this.state.selectedContainer?.seller_box_sizes.length : 0
        let Notdata = values?.ContainerPricingDetails[values?.ContainerPricingDetails?.length - 1]?.CONTAINERSIZEARRAY?.length === Lenthoffcontainer
        return !this.state.isViewLess && !Notdata && (
            <Grid item >
                <Box className="AddContainerFirstButton">
                    <ContainedButton onClick={() => this.AddContainerSize(values.ContainerPricingDetails, setFieldValue)} MarginFalse
                        BorderColor={Notdata || ContainerPricingDetailsIndex ? "" : "black"}
                        TextColor="black" bgcolor="transparent"
                        HoverColor="transparent"
                        data-test-id="ADDCONTAINERSIZE"
                        disabled={Notdata || ContainerPricingDetailsIndex} size="small"
                        startIcon={<img src={add_transparent} className="BlackPlusIcon" alt="" style={{ marginRight: "8px" }} />}
                    >
                        ADD CONTAINER SIZE
                    </ContainedButton>
                </Box>
            </Grid>
        )
    }

    AddContainerType = (
        values: Array<{ CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string }> }>,
        setFieldValue: (field: string, value: Array<{ CONTAINERTYPE: string, CONTAINERCODES: string, CONTAINERSIZEARRAY: Array<{ CONTAINERSIZE: string, AVERAGETONSBOX: string, RATE: string, TERMSONE: string, TERMSTWO: string }> }>, shouldValidate?: boolean | undefined) => void
    ) => {
        // Create a new array with the updated values
        let data = [...values]
        data.push({
            CONTAINERTYPE: "",
            CONTAINERCODES: "",
            CONTAINERSIZEARRAY: [{
                CONTAINERSIZE: "",
                AVERAGETONSBOX: "",
                RATE: "",
                TERMSONE: "",
                TERMSTWO: ""
            }]
        })
        if (this.state.isViewLess) {
            this.setState({
                isViewLess: false,
            })
        }
        setFieldValue("ContainerPricingDetails", data);
    }

    TypeButton = (ContainerPricingDetailsIndex: boolean, AddContainerDisable: boolean, values: any, setFieldValue: any) => {
        let ClassString = ""
        if (!this.state.isViewLess) {
            if (ContainerPricingDetailsIndex) {
                ClassString = "AddContainerSecondButton"
            } else {
                ClassString = "AddContainerSecondButtonCenter"
            }
        } else {
            ClassString = "CenterAddContainer"
        }
        let Lenthoffcontainer = this.state.selectedContainer?.seller_box_sizes?.length !== undefined ? this.state.selectedContainer?.seller_box_sizes.length : 0
        let Notdata = values.ContainerPricingDetails[values.ContainerPricingDetails.length - 1].CONTAINERSIZEARRAY.length === Lenthoffcontainer
        const SelectContainerType: Array<string | undefined> = values.ContainerPricingDetails.map((data: { CONTAINERTYPE: string }) => {
            if (data.CONTAINERTYPE) {
                return data.CONTAINERTYPE
            }
        }).filter((data: any) => data !== undefined);
        const shouldButtonVisible = this.state.containerData?.length >= SelectContainerType?.length + 1;

        return (
            <>
            {
                shouldButtonVisible && 
                <Grid item>
                    <Box className={`${ClassString} ${Notdata ? "CenterAddContainer" : "CenterAddContainer"}`}>
                        <ContainedButton data-test-id="ADDCONTAINERTYPE" disabled={AddContainerDisable} onClick={() => this.AddContainerType(values.ContainerPricingDetails, setFieldValue)} MarginFalse size="small" startIcon={<img src={add_transparent} alt="" style={{ marginRight: "8px" }} />}>
                            ADD CONTAINER STATUS
                        </ContainedButton>
                    </Box>
                </Grid>
            }
            </>
        )
    }

    PhoneInputNumber = (
        dialCode: string,
        number: string,
        setFieldValue: (
            field: string,
            value: string,
            shouldValidate?: boolean | undefined
        ) => void
    ) => {
        let numbers = number;
        setFieldValue("CONTACTNUMBER", `${numbers}`);
        let temp = {
            ...this.state.loginData,
            full_phone_number: number,
        };
        this.setState({ loginData: temp });
    };

    ListingPersonnelDetailsContainer = (
        errors: FormikErrors<{ FULLNAME: string, EMAILADDRESS: string, EMAILDISTRIBUTIONLIST: string, CONTACTNUMBER: string | number }>,
        touched: FormikTouched<FormValues>,
        setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
        values: { FULLNAME: string, EMAILADDRESS: string, EMAILDISTRIBUTIONLIST: string | string[], CONTACTNUMBER: string | number }
    ) => {
        const distributionEmailArray = Array.isArray(this.state.EmailDistributionListData) ? this.state.EmailDistributionListData : [];
        const distributionEmailArrayValue = Array.isArray(values.EMAILDISTRIBUTIONLIST) ? values.EMAILDISTRIBUTIONLIST : [];
        const responseDistributionEmailArray = Array.isArray(this.state.FormEditData?.DistributionEmail) ? this.state.FormEditData?.DistributionEmail : [];
        return (
            <ListingPersonnelDetailsContainerWrapper className="vesselContainer">
                <h3 className="vesselHeading">
                    Listing Personnel's Details
                </h3>
                <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: "15px" }}
                >
                    <Grid item lg={4}>
                        <InputLabel htmlFor="FULLNAME">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                FULL NAME
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": "FULLNAME",
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                this.AddListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "FULLNAME"
                                );
                            }}
                            size="small"
                            FormError={
                                errors.FULLNAME !== undefined &&
                                touched.FULLNAME !== undefined && values.FULLNAME === ""
                            }
                            type="text"
                            value={this.state.FormIsEdit ? this.state.FormEditData?.FullName : this.state.loginData?.full_name}
                            id="FULLNAME"
                            name="FULLNAME"
                            fullWidth
                        />
                        {this.ErrorRender(errors.FULLNAME, errors.FULLNAME !== undefined && touched.FULLNAME !== undefined && values.FULLNAME === "")}
                    </Grid>
                    <Grid item lg={4}>
                        <InputLabel htmlFor="EMAILADDRESS">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                EMAIL ADDRESS
                            </ProjectText>
                        </InputLabel>
                        <LandingPageTextField
                            BoderRadius="6px"
                            inputProps={{
                                "data-test-id": "EMAILADDRESS",
                            }}
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >
                            ) => {
                                this.AddListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "EMAILADDRESS"
                                );
                            }}
                            size="small"
                            FormError={
                                errors.EMAILADDRESS !== undefined &&
                                touched.EMAILADDRESS !== undefined && values.EMAILADDRESS === ""
                            }
                            type="text"
                            value={this.state.FormIsEdit ? this.state.FormEditData?.Email : this.state.loginData?.email}
                            id="EMAILADDRESS"
                            name="EMAILADDRESS"
                            fullWidth
                        />
                        {this.ErrorRender(errors.EMAILADDRESS, errors.EMAILADDRESS !== undefined && touched.EMAILADDRESS !== undefined && values.EMAILADDRESS === "")}
                    </Grid>
                    <Grid item lg={4}>
                        <InputLabel htmlFor="EMAILADDRESS">
                            <ProjectText
                                Upercase
                                variant="subtitle1"
                                TextSize="14px"
                                fontweight="900"
                                textcolor="black"
                            >
                                Contact Number
                            </ProjectText>
                        </InputLabel>
                        <Box className={`${errors.CONTACTNUMBER !== undefined && touched.CONTACTNUMBER ? "ErrorPhoneClass" : false}`}>
                            <PhoneInput
                                country={"sg"}
                                placeholder={""}
                                value={`${this.state.FormIsEdit ? this.state.FormEditData?.ContactNumber : values.CONTACTNUMBER}`}
                                onChange={(
                                    number: string,
                                    data: { dialCode: string }
                                ) => {
                                    this.PhoneInputNumber(
                                        data.dialCode,
                                        number,
                                        setFieldValue
                                    );
                                }}
                                searchPlaceholder="Search for countries"
                                inputProps={{
                                    required: true,
                                    id: "AdminNo",
                                    name: "AdminNo",
                                    "data-test-id": "CONTACTNUMBER",
                                }}
                                countryCodeEditable={false}
                                enableSearch={true}
                                jumpCursorToEnd={true}
                                inputClass={`PhoneInputPackgeClass`}
                            />
                        </Box>
                        {this.ErrorRender(
                            errors.CONTACTNUMBER,
                            errors.CONTACTNUMBER !== undefined &&
                            touched.CONTACTNUMBER !== undefined
                        )}
                    </Grid>
                </Grid>
                <InputLabel htmlFor="EMAILDISTRIBUTIONLIST">
                    <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                    >
                        EMAIL DISTRIBUTION LIST
                    </ProjectText>
                </InputLabel>
                <AutocompleteStyle
                    id="timeIntervalsDropdownFilterTestID"
                    options={this.state.FormIsEdit ? responseDistributionEmailArray : distributionEmailArray}
                    getOptionLabel={(option: unknown) => option as string ?? ""}
                    onChange={(event: React.ChangeEvent<{}>, value: unknown) => {
                        this.EmailAcooundMultipleAddChange(value as string, setFieldValue)
                    }}
                    size="small"
                    freeSolo
                    filterOptions={this.AutoComplateFilerdata}
                    value={this.state.FormIsEdit ? responseDistributionEmailArray : distributionEmailArrayValue}
                    multiple
                    FormError={
                        errors.EMAILDISTRIBUTIONLIST !== undefined &&
                        touched.EMAILDISTRIBUTIONLIST !== undefined
                    }
                    closeIcon={false}
                    data-test-id="EMAILDISTRIBUTIONLIST"
                    renderInput={(params) => {
                        return (<TextField {...params} variant="outlined" data-test-id="EMAILADDRESSDrop" />)
                    }}
                    renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (
                        <ProjectText
                            TextSize="14px"
                            variant="h6"
                            fontweight="600"
                        >
                            {option as string}
                        </ProjectText>
                    )}
                />
                {this.ErrorRender(Array.isArray(errors.EMAILDISTRIBUTIONLIST) ? errors.EMAILDISTRIBUTIONLIST[errors.EMAILDISTRIBUTIONLIST.length - 1] : errors.EMAILDISTRIBUTIONLIST, errors.EMAILDISTRIBUTIONLIST !== undefined && touched.EMAILDISTRIBUTIONLIST !== undefined)}
            </ListingPersonnelDetailsContainerWrapper>
        )
    }

    handleClose = () => {
        this.setState({ show: false });
        this.HandelCloseModelFuncation()
    };

    handleMatchingOffersNavigations = () => {
        this.setState({show: false})
        this.props.history.push("SellerPendingOffers");
        window.location.reload();
    }

    HandelCloseModelFuncation = async () => {
        this.setState({
            show: false,
            addListing: false,
            isMatchesFound: false,
            FormEditData: { PrincipalOrganizationName: "", Vessel: "", Voyage: "", Pod: "", PodTerminal: "", Pol: "", PolTerminal: "", Email: "", EtaPod: "", EtbPol: "", FullName: "", OrganizationCode: "", ContactNumber: "", DistributionEmail: "", Expiry: "", TotalTeu: "", Validity: "", in_voyage: "", out_voyage: "" },
            FormIsEdit: false,
            listingAdded: false,
            ContainerEditDetails: [],
            etbDate: "",
            etaDate: "",
            EditFormId: "",
            EditListingId:"",
            PrincipalOrganizationSelectedName: "",
            EmailDistributionListData: [],
            IsVesselSelected: false,
            PodTerminalDropDown: [],
            PolTerminalDropDown: [],
            VoyageDropDownData: [],
            InVoyogeAndOutVoyoge: "",
            VesselDropDownData: [],
            SelectedPod: "",
            VesselDropDownString: "",
            SelectedPol: "",
            loginData: { full_name: "", email: "", full_phone_number: "" }
        });
        if (this.props.APicallForTable) {
            this.props.APicallForTable()
            let LocationToken = await getStorageData("authToken");
            this.OnloadApicall(LocationToken)
        }
        window.location.reload()
    }

    onListingFormSubmit = (values: any) => {
        const voyageDropdownArray = this.state.VoyageDropDownData?.map((item) => {
            return `In ${item.inVoyN} Out ${item.outVoyN}`
        })
        if(voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge) && this.state.PolTerminalDropDown?.includes(values.POLTERMINAL) && this.state.PodTerminalDropDown?.includes(values.PODTERMINAL)){
            this.createSellerListing(values);
        }
        if(!voyageDropdownArray?.includes(this.state.InVoyogeAndOutVoyoge)){
            toast.warning("Please select Voyage value")
        }
        if(!this.state.PolTerminalDropDown?.includes(values.POLTERMINAL)){
            toast.warning("Please select POL Terminal value")
        }
        if(!this.state.PodTerminalDropDown?.includes(values.PODTERMINAL)){
            toast.warning("Please select POD Terminal value")
        }
    }

    AddListinForm = (FormInitialValuesValues: FormValues) => {
        return (
            <CustomDialog open={this.state.show} maxWidth="md" fullWidth  PaperProps={{
                style: { borderRadius: "15px"}   }}>
                {/* Add New Listing Modal Start */}
                {this.state.addListing && (
                    <StyleModel>
                        <Formik
                            validationSchema={() => this.NewListingSchema()}
                            validateOnChange={true}
                            enableReinitialize={this.state.FormikFormReenableReinitialize}
                            initialValues={FormInitialValuesValues}
                            onSubmit={(values) => {
                                this.onListingFormSubmit(values)
                            }}
                            validateOnBlur={true}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit, values, }) => {
                                this.setValuesRef = setFieldValue;
                                let ContainerPricingDetailsIndex = this.ContainerPricingDetailsCondtion(values)
                                let AddContainerDisable = this.AddContainerCondtion(values)

                                return (
                                    <form
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                        className="FormOrganisationDetails"
                                    >
                                        <AddListingFormWrapper className="addListingModal">
                                            <Box
                                                className="close"
                                                onClick={this.handleClose}
                                                data-test-id="close-btn"
                                            >
                                                <img src={close} alt="" />
                                            </Box>
                                            <h1 className="title">{this.state.FormIsEdit ? "Edit " : "Add New "}Listing</h1>
                                            {this.PrincipalOrgnasation(errors, touched, values, setFieldValue)}
                                            {this.renderVasselBlock(errors, touched, values, setFieldValue)}

                                            <section style={{
                                                background: "#E3E6ED",
                                                padding: "25px 0px",
                                                borderRadius: "18px",
                                                marginBottom: "15px",
                                            }}>
                                                <div style={{ display: "flex", justifyContent: "center", paddingRight: "10px" }}>
                                                    <h3 className="vesselHeading">
                                                        Container & Pricing Details
                                                    </h3>
                                                    {values.ContainerPricingDetails.length !== 1 && this.ViewLessButton()}
                                                    {this.state.isViewLess && this.ViewLessButton()}
                                                </div>
                                                {this.MapData(values, setFieldValue, errors, touched)}
                                                <Grid container className="centerGrid" spacing={2}>
                                                    {this.AddContainerSizeButton(ContainerPricingDetailsIndex, values, setFieldValue)}
                                                    {this.TypeButton(ContainerPricingDetailsIndex, AddContainerDisable, values, setFieldValue)}
                                                </Grid>
                                            </section>
                                            {this.ListingPersonnelDetailsContainer(errors, touched, setFieldValue, values)}
                                            <div className="dFlexJCenter">
                                                <ContainedButton
                                                    type="submit"
                                                    data-test-id="SubmitForm"
                                                    className="btnTheme"
                                                >
                                                    Submit
                                                </ContainedButton>
                                            </div>
                                        </AddListingFormWrapper>
                                    </form>
                                );
                            }}
                        </Formik>
                    </StyleModel>
                )}
                {/* Add New Listing Modal End */}

                {/* Listing Submitted Modal Start */}
                {this.state.listingAdded ? this.listAddedModal() : false}
                {/* Listing Submitted Modal End */}
            </CustomDialog>
        );
    };

    EmailAcooundMultipleAddChange = (newValue: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void) => {
        let New = newValue as unknown as string[]
        const NewArray = New?.map((item: string) => { return item.replace(/Add/g, "").trim() })
        if (typeof newValue === "string") {
            setFieldValue("EMAILDISTRIBUTIONLIST", [NewArray] as never)
        } else if (this.state.EmailDistributionListData?.includes(newValue) === false) {
            // Create a new value from the user input
            setFieldValue("EMAILDISTRIBUTIONLIST", NewArray as never)
        } else {
            setFieldValue("EMAILDISTRIBUTIONLIST", NewArray as never)
        }
    }

    AutoComplateFilerdata = (options: unknown[], params: FilterOptionsState<unknown>) => {
        const AutoComplatefilter = [] as Array<string>
        if (params.inputValue !== "") {
            AutoComplatefilter.push(`Add ${params.inputValue}`);
        }
        return AutoComplatefilter;
    }

    Loader = () => {
        if (this.state.loading) {
            return <Spinner spinnerModal={this.state.loading} />
        }
    }

    render() {
        const FormInitialValuesValues = {
            PRINCIPALORGANISATION: (!Array.isArray(this.state.prinicpalOrgList) && this.state.prinicpalOrgList.attributes.name) || this.state.FormEditData.PrincipalOrganizationName || "",
            VESSEL: this.state.FormEditData.Vessel || "",
            VOYAGE: this.state.FormEditData.Voyage || "",
            POD: this.state.FormEditData.Pod || "",
            PODTERMINAL: this.state.FormEditData.PodTerminal || "",
            POL: this.state.FormEditData.Pol || "",
            POLTERMINAL: this.state.FormEditData.PolTerminal || "",
            ETBATPOL: this.state.etbDate || "",
            ETAATPOD: this.state.etaDate || "",
            QUANTITYOFTEUSAVAILABLEFORSALE: this.state.FormEditData.TotalTeu || "",
            VALIDITYOFLISTING: this.state.loginData?.minimum_validity_of_listing || "",
            EXPIRYDATE: "",
            ContainerPricingDetails: this.state.FormIsEdit === false ? [
                {
                    CONTAINERTYPE: "",
                    CONTAINERCODES: "",
                    CONTAINERSIZEARRAY: [{
                        CONTAINERSIZE: "",
                        AVERAGETONSBOX: "",
                        RATE: "",
                        TERMSONE: "",
                        TERMSTWO: "",
                    }]
                }
            ] : this.state.ContainerEditDetails,
            FULLNAME: this.state.loginData?.full_name || "",
            EMAILADDRESS: this.state.loginData?.email || "",
            CONTACTNUMBER: this.state.loginData?.full_phone_number || "",
            EMAILDISTRIBUTIONLIST: this.state.EmailDistributionListData || "",
        }
        return (
            <>
                {this.Loader()}
                {this.FristGrid()}
                {this.AddListinForm(FormInitialValuesValues)}
            </>
        );
    }
}

export default withRouter(AddListing);
// Customizable Area End