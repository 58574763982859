// Customizable Area Start
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import clsx from "clsx";
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationSignUpController from "./OrganizationSignUpController.web";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {
  successImg,
  image_editinfo,
  upload,
  remove,
  pdf,
  image_eyeoff,
  image_eyeon,
} from "./assets";
const addIMg = require("../assets/add_.png");
const downIMg = require("../assets/image_down.png");
const upIMg = require("../assets/image_up.png");
import {
  Box,
  InputLabel,
  Tooltip,
  Radio,
  FormControlLabel,
  Divider,
  StepConnector,
  Checkbox
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { StepIconProps } from "@material-ui/core/StepIcon";
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import { ContainedButton } from "../../../components/src/GlobalButton";
import Spinner from "../../../components/src/ProjectLoader";
import ErrorIcon from '@material-ui/icons/Error';
class OrganizationSignUp extends OrganizationSignUpController {


  RenderStpes = () => {
    if (this.state.activeStep == 0) {
      return this.FormFirst()
    }
    if (this.state.activeStep == 1) {
      if (this.state.stepLabels.includes("Principal Organisation Details")) {
        return this.PrincipalOrganisationDetails()
      } else {
        return this.FormAdminDetails()
      }
    }
    if (this.state.activeStep == 2) {
      if (this.state.stepLabels.includes("Principal Organisation Details")) {
        return this.FormAdminDetails()
      } else {
        return this.FinishForm()
      }
    }
    if (this.state.activeStep == 3) {
      if (!this.state.stepLabels.includes("Principal Organisation Details")) {
        return this.FormAdminDetails()
      } else {
        return this.FinishForm()
      }
    }
  }

  // Form First Start

  OrganisationDetailsFristForm = () => {
    const Regx = /^(?=.*\d)(?=.*[A-Z])[0-9A-Z]{9,10}$/
    return Yup.object().shape({
      OrganizationName: Yup.string().required("Organisation name is required"),
      OrganizationUEN: Yup.string().min(9, "Minimum 9 characters").max(10, "Maximum 10 characters").matches(Regx, "Organisation UEN is not valid").required("Organisation UEN is required"),
      BusinessRegistrationDoc: Yup.mixed()
        .required("Business registration document is required")
        .nullable(),
    })
  }

  ErrorRender = (ErrorMessage: string | undefined, TextShow: boolean) => {
    return (
      <Box className="ErrorTextContainer">
        <Box className={`ErrorIconContainer ${TextShow ? "" : "TextHidden"}`}>
          <ErrorIcon />
        </Box>
        <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}>
          {typeof ErrorMessage === "string" ? ErrorMessage : ""}
        </ProjectText>
      </Box>
    )
  }

  FormFirst = () => {
    return (
      <Grid item xs={12}>
        <Formik
          validationSchema={() => this.OrganisationDetailsFristForm()}
          initialValues={{
            OrganizationName: this.state.OrganizationName || "",
            OrganizationUEN: this.state.OrganizationUEN || "",
            BusinessRegistrationDoc: this.state.FileName || "",
          }}
          enableReinitialize={this.state.FormenableReinitialize}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            this.FirstFormSubmit()
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off" className="FormOrganisationDetails">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <ProjectText align="center" variant="h6" TextSize="1.25rem" fontweight="700">
                      Organisation Details
                    </ProjectText>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="TooltipContainer">
                      <InputLabel htmlFor="OrganizationName">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                          ORGANISATION'S FULL NAME
                        </ProjectText>
                      </InputLabel>
                      <LightTooltip
                        title="Full legal name of your organisation as registered with the local authority."
                        placement="right"
                        className="tooltip"
                      >
                        <InfoOutlinedIcon className="infoIcon" />
                      </LightTooltip>
                    </Box>
                    <LandingPageTextField BoderRadius="10px" className="tempheight" inputProps={{
                      "data-test-id": "ORGANISATIONSFULLNAME",
                    }}
                      variant="outlined"
                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.TextfieldChnage(e, setFieldValue)
                      }}
                      FormError={errors.OrganizationName !== undefined && touched.OrganizationName !== undefined}
                      type='text'
                      value={this.state.OrganizationName}
                      id="OrganizationName"
                      name="OrganizationName"
                      fullWidth />
                    {this.ErrorRender(errors.OrganizationName, errors.OrganizationName !== undefined && touched.OrganizationName !== undefined)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="TooltipContainer">
                      <InputLabel htmlFor="OrganizationUEN">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                          ORGANIsATION'S UEN
                        </ProjectText>
                      </InputLabel>
                      <LightTooltip
                        title="The unique registration number assigned to your organisation by the local authority."
                        placement="right"
                        className="tooltip"
                      >
                        <InfoOutlinedIcon className="infoIcon" />
                      </LightTooltip>
                    </Box>
                    <LandingPageTextField className="tempheight" BoderRadius="10px" inputProps={{
                      "data-test-id": "ORGANISATIONSUENTEXTFIELD",
                      maxLength: 10
                    }}
                      FormError={errors.OrganizationUEN !== undefined && touched.OrganizationUEN}
                      value={this.state.OrganizationUEN}
                      variant="outlined"
                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.TextfieldChnage(e, setFieldValue)
                      }}
                      type='text'
                      id="OrganizationUEN"
                      name="OrganizationUEN"
                      fullWidth
                    />
                    {this.ErrorRender(errors.OrganizationUEN, errors.OrganizationUEN !== undefined && touched.OrganizationUEN !== undefined)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="TooltipContainer">
                      <InputLabel htmlFor="email">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black" noWrap>
                          BUSINESS REGISTRATION DOCUMENT
                        </ProjectText>
                      </InputLabel>
                      <LightTooltip
                        title="A legal document confirming your business's existence and official status."
                        placement="right"
                        className="tooltip"
                      >
                        <InfoOutlinedIcon className="infoIcon" />
                      </LightTooltip>
                    </Box>
                    <Box>
                      {this.state.BusinessRegistrationDoc?.length === 0 ?
                        <label htmlFor="pdf"
                          data-test-id="FirsTFormDocumentDrag"
                          onDragEnter={(event) => this.handleDrag(event)} onDrop={(event) =>
                            this.handleDropFile(event, setFieldValue)} onDragLeave={(event) => this.handleDrag(event)}
                          onDragOver={(event) => this.handleDrag(event)}>
                          <Button
                            className={`UploadFileBtn ${this.state.DragActive ? "BgLight" : ""} ${typeof errors.BusinessRegistrationDoc === "string" && this.state.BusinessRegistrationDoc.length === 0 && touched.BusinessRegistrationDoc ? "Errorclass" : ""}`}
                            variant="text"
                            fullWidth
                            id="PdfFile"
                            component="span"
                            size="large"
                            startIcon={<img src={upload} data-test-id="DeleteBusinessPdf" alt="upload" className="SmallIcon" />}
                          >
                            <ProjectText variant="subtitle1" TextSize=".875rem" textcolor="#797979" noWrap>
                              Browse or drag & drop file here (PDF, DOCX)
                            </ProjectText>
                          </Button>
                        </label> :
                        <Button
                          className={`UploadFileBtn`}
                          variant="text"
                          fullWidth
                          id="PdfFile"
                          component="span"
                          size="large">
                          <Box className="UploadFileNameRender">
                            <Box className="textandPdfIcon">
                              <img src={pdf} alt="upload" className="SmallIcon" />
                              <ProjectText variant="subtitle1" TextSize=".875rem" fontweight="500" noWrap>
                                {this.state.FileName}
                              </ProjectText>
                            </Box>
                            <Box>
                              <Box
                                data-test-id="DeleteBusinessPdf"
                                onClick={() => {
                                  this.DeletePdf(setFieldValue);
                                }}
                              >
                                <img src={remove} data-test-id="DeletePdfBtn" alt="upload" className="SmallIcon" />
                              </Box>
                            </Box>
                          </Box>
                        </Button>
                      }
                      <input
                        name="BusinessRegistrationDoc"
                        className="Custmizedinputs"
                        id="pdf"
                        disabled={this.state.BusinessRegistrationDoc.length !== 0}
                        accept=".pdf, .doc, .docx"
                        type="file"
                        data-test-id="FirsTFormDocument"
                        style={{ display: "none" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          this.UploadFile(e, setFieldValue);
                        }}
                      />
                    </Box>
                    {this.ErrorRender(errors.BusinessRegistrationDoc, errors.BusinessRegistrationDoc !== undefined && touched.BusinessRegistrationDoc !== undefined)}
                  </Grid>
                  <Grid item xs={12} md={6} className="PhoneInput">
                    <Box className="TooltipContainer">
                      <InputLabel htmlFor="ContactNumber">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black" noWrap>
                          ACTING ON BEHALF OF A PRINCIPAL ORGANISATION?
                        </ProjectText>
                      </InputLabel>
                    </Box>
                    <Box className="PrincipalCheckBoxContainer">
                      <FormControlLabel
                        value="yes"
                        control={
                          <Radio
                            data-test-id="YesCheckBox"
                            className="RadioButtonCheck"
                            onClick={() => this.handleYes()}
                          />
                        }
                        label="YES"
                        checked={this.state.stepLabels.includes(
                          "Principal Organisation Details"
                        )}
                      />
                      <FormControlLabel
                        style={{
                          marginLeft: "20px",
                        }}
                        value="no"
                        control={
                          <Radio
                            data-test-id="NoCheckBox"
                            className="RadioButtonCheck"
                            onClick={() => this.RemoveStep()}
                          />
                        }
                        label="NO"
                        checked={
                          !this.state.stepLabels.includes(
                            "Principal Organisation Details"
                          )
                        }
                      />
                    </Box>
                    <Box className="ErrorTextContainer">
                      <Box className={`ErrorIconContainer TextHidden`}>
                        <ErrorIcon />
                      </Box>
                      <ProjectText className={`TextError TextHidden`}>
                        {""}
                      </ProjectText>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Button type="submit" data-test-id="next">
                        <ProjectText fontweight="700">
                          Next{" "}
                        </ProjectText>
                        <ArrowForwardIosRoundedIcon className="ArrowIcon" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>
      </Grid>
    )
  }
  // Form First end

  // Form Second Start

  PrincipalOrganisationDetails = () => {
    let DisabledCondition = this.state.FormWithFiles.filter((item: {
      NameOfSection: string
      BusinessName: string,
      AuthorisationFileName: string,
    }) => item.NameOfSection === "" || item.BusinessName === "" || item.AuthorisationFileName === "").length !== 0
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box className="PrincipalheadingContainer">
              <ProjectText align="center" variant="h6" TextSize="1.25rem" fontweight="700">
                Principal Organisation Details
              </ProjectText>
              <Box className="ViewMoreContainer">
                <Button variant="text" data-test-id="ViewLess" onClick={this.ViewLessOnchange} startIcon={this.state.isViewLess ? <img src={downIMg} alt="downIMg" /> : <img src={upIMg} alt="upimg" />}>
                  <ProjectText fontweight="700" TextSize="1rem">
                    {this.state.isViewLess ? "VIEW MORE" : "VIEW LESS"}
                  </ProjectText>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Box className={`${!this.state.isViewLess ? "PrincipalSectionsContainer" : "PrincipalSectionsContainerWidth100"} `}>
            {this.state.FormWithFiles.map((item: {
              NameOfSection: string
              BusinessName: string,
              BusinessFile: Array<File>,
              AuthorisationFileName: string,
              authorisationFile: Array<File>,
              NameTouch: boolean,
              BusinessTouch: boolean,
              authorisationTouch: boolean,
              BusinessDrag: boolean,
              AuthorisationDrag: boolean
            }, index: number) => {
              let CurrentIndex = index === 0 ? 1 : index + 1
              let Arraylength = this.state.FormWithFiles.length !== 1
              return (
                <Grid container spacing={2} alignItems="center" key={item.NameOfSection} className="PrincipalSection">
                  <Grid item xs={12}>
                    <Box className="TooltipContainer SpaceBetweenClass" data-test-id={`Box${index}`}>
                      <InputLabel htmlFor="OrganizationName">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                          NAME OF PRINCIPAL ORGANISATION - {CurrentIndex}
                        </ProjectText>
                      </InputLabel>
                      {Arraylength &&
                        <Button variant="text" data-test-id={`RemoveOnchange${index}`} onClick={() => this.RemoveOneSection(index)}>
                          <ProjectText variant="h1" textcolor="#1653DD" fontweight="700" TextSize=".875rem">
                            Remove
                          </ProjectText>
                        </Button>
                      }
                    </Box>
                    <LandingPageTextField BoderRadius="10px" inputProps={{
                      "data-test-id": `PrincipalOrganizationName${index}`,
                    }}
                      variant="outlined"
                      FormError={item.NameOfSection === "" && item.NameTouch}
                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.PrincipalOrganizationNameChange(e, index)
                      }}
                      autoFocus
                      className="PrincipalOrganizationNameTextField tempheight"
                      onBlur={() => this.SaveHeading(index)}
                      value={this.state.FormWithFilesHeadings[index]}
                      type='text'
                      id="PrincipalOrganizationName0"
                      name="OrganizationName"
                      fullWidth />
                    {this.ErrorRender(`Principal organisation name is required ${CurrentIndex}`, item.NameOfSection === "" && item.NameTouch)}
                  </Grid>
                  {this.BusinessRegistrationDocumentContainer(item, index)}
                  {this.AuthorisationDocumentDocumentContainer(item, index)}
                  {Arraylength && this.state.FormWithFiles.length - 1 !== index && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              )
            })}
          </Box>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} className="DisablePadding">
                <Grid container justifyContent="center" className="AddButtonContainer">
                  <ContainedButton
                    disabled={DisabledCondition}
                    data-test-id="addonChangeBtn"
                    onClick={() => this.AddnOnChange()} startIcon={<img src={addIMg} alt="addimg" />}>
                    ADD PRINCIPAL ORGANISATION
                  </ContainedButton>
                </Grid>
              </Grid>
              <Button
                onClick={this.handleBack}
                data-test-id="back"
              >
                <ArrowBackIosRoundedIcon className="ArrowIcon" />
                <ProjectText fontweight="700">
                  Back{" "}
                </ProjectText>
              </Button>
              <Button className="NextButton" data-test-id="next" onClick={this.PrincipalOrganisationNextButton}>
                <ProjectText fontweight="700">
                  Next{" "}
                </ProjectText>
                <ArrowForwardIosRoundedIcon className="ArrowIcon" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  BusinessRegistrationDocumentContainer = (item: { BusinessFile: Array<File>, BusinessName: string, BusinessTouch: boolean, BusinessDrag: boolean }, index: number) => {
    let ErrorClass = item.BusinessName === "" && item.BusinessFile.length === 0 && item.BusinessTouch
    let CurrentIndex = index === 0 ? 1 : index + 1
    return (
      !this.state.isViewLess && (
        <Grid item xs={12} md={6} className="DisableBottomPadding">
          <Box className="TooltipContainer">
            <InputLabel htmlFor="OrganizationUEN">
              <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                BUSINESS REGISTRATION DOCUMENT
              </ProjectText>
            </InputLabel>
            <LightTooltip
              title="A legal document confirming your business's existence and official status."
              placement="right"
              className="tooltip"
            >
              <InfoOutlinedIcon className="infoIcon" />
            </LightTooltip>
          </Box>
          <Box>
            {item.BusinessFile.length === 0 ?
              <label htmlFor={`BusinessRegistrationDoc${index}`}
                data-test-id={`BusinessRegistrationDocDrag${index}`}
                onDragEnter={(event) => this.handleDragPrincipalFuncation(event, index, "BusinessFile")}
                onDrop={(event) => this.handleFilUploadDrag(event, index, "BusinessFile")}
                onDragLeave={(event) => this.handleDragPrincipalFuncation(event, index, "BusinessFile")}
                onDragOver={(event) => this.handleDragPrincipalFuncation(event, index, "BusinessFile")}
              >
                <Button
                  className={`UploadFileBtn ${item.BusinessDrag ? "BgLight" : ""} ${ErrorClass ? "Errorclass" : ""}`}
                  variant="text"
                  fullWidth
                  id="PdfFile"
                  component="span"
                  size="large"
                  startIcon={<img src={upload} alt="upload" className="SmallIcon" />}
                >
                  <ProjectText variant="subtitle1" TextSize=".875rem" textcolor="#797979" noWrap>
                    Browse or drag & drop file here (PDF, DOCX)
                  </ProjectText>
                </Button>
              </label> : <Button
                className={`UploadFileBtn`}
                variant="text"
                fullWidth
                id="PdfFile"
                component="span"
                size="large"
              >
                <Box className="UploadFileNameRender">
                  <Box className="textandPdfIcon">
                    <img src={pdf} alt="upload" className="SmallIcon" />
                    <ProjectText variant="subtitle1" TextSize=".875rem" fontweight="500" noWrap data-test-id={`BusinessDocName${index}`}>
                      {item.BusinessName}
                    </ProjectText>
                  </Box>
                  <Box>
                    <Box
                      data-test-id={"BussinesPdf" + index}
                      onClick={() => { this.handleFilRemove(index, "BusinessFile") }}
                    >
                      <img src={remove} data-test-id="DeletePdfBtn" alt="upload" className="SmallIcon" />
                    </Box>
                  </Box>
                </Box>
              </Button>}
          </Box>
          <input
            name={`BusinessRegistrationDoc${index}`}
            className="Custmizedinputs"
            id={`BusinessRegistrationDoc${index}`}
            multiple
            disabled={item.BusinessFile.length !== 0}
            accept=".pdf, .doc, .docx"
            type="file"
            data-test-id={"BusinessRegistrationDoc" + index}
            style={{ display: "none" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.handleFilUpload(e, index, "BusinessFile");
            }}
          />
          {this.ErrorRender(`Business registration document is required ${CurrentIndex}`, item.BusinessName === "" && item.BusinessFile.length === 0 && item.BusinessTouch)}
        </Grid>
      )
    )

  }

  AuthorisationDocumentDocumentContainer = (item: { authorisationFile: Array<File>, AuthorisationFileName: string, authorisationTouch: boolean, AuthorisationDrag: boolean }, index: number) => {
    let IsErrorOrNot = item.AuthorisationFileName === "" && item.authorisationFile.length === 0 && item.authorisationTouch
    let CurrentIndex = index === 0 ? 1 : index + 1
    return (
      !this.state.isViewLess && (
        <Grid item xs={12} md={6} className="DisableBottomPadding">
          <Box className="TooltipContainer">
            <InputLabel htmlFor="email">
              <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                AUTHORISATION LETTER BY PRINCIPAL ORGANISATION
              </ProjectText>
            </InputLabel>
          </Box>
          <Box>
            {item.authorisationFile.length === 0 ?
              <label htmlFor={`AuthorisationDoc${index}`}
                data-test-id={`AuthorisationDocDrag${index}`}
                onDragEnter={(event) => this.handleDragPrincipalFuncation(event, index, "authorisation")}
                onDrop={(event) => this.handleFilUploadDrag(event, index, "authorisation")}
                onDragLeave={(event) => this.handleDragPrincipalFuncation(event, index, "authorisation")}
                onDragOver={(event) => this.handleDragPrincipalFuncation(event, index, "authorisation")}
              >
                <Button
                  className={`UploadFileBtn ${item.AuthorisationDrag ? "BgLight" : ""}  ${IsErrorOrNot ? "Errorclass" : ""}`}
                  fullWidth
                  size="large"
                  startIcon={<img src={upload} alt="upload" className="SmallIcon" />}
                  id="PdfFile"
                   variant="text"
                   component="span"
                >
                  <ProjectText 
                  textcolor="#797979"
                  variant="subtitle1" 
                  noWrap
                  TextSize=".875rem"                 
                   >
                    Browse or drag & drop file here (PDF, DOCX)
                  </ProjectText>
                </Button>
              </label> : <Button
                className={`UploadFileBtn`}
                component="span"
                variant="text"               
                fullWidth
                size="large"
                 id="PdfFile"
              >
                <Box className="UploadFileNameRender">
                  <Box className="textandPdfIcon">
                    <img src={pdf} alt="upload" className="SmallIcon" />
                    <ProjectText                    
                   data-test-id={`BusinessDocName${index}`}
                    TextSize=".875rem" 
                    variant="subtitle1" 
                    noWrap
                    fontweight="500"                   
                    >
                      {item.AuthorisationFileName}
                    </ProjectText>
                  </Box>
                  <Box>
                    <Box
                      data-test-id={"AuthorisationPdfDeleteBtn" + index}
                      onClick={() => { this.handleFilRemove(index, "authorisation") }}
                    >
                      <img src={remove} data-test-id="DeletePdfBtn" alt="upload" className="SmallIcon" />
                    </Box>
                  </Box>
                </Box>
              </Button>
            }
            <input
              className="Custmizedinputs"
              name={`AuthorisationDoc${index}`}
              id={`AuthorisationDoc${index}`}
              disabled={item.authorisationFile.length !== 0}
              accept=".pdf, .doc, .docx"
              type="file"
              data-test-id={`AuthorisationDoc${index}`}
              style={{ display: "none" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                this.handleFilUpload(e, index, "authorisation");
              }}
            />
            {this.ErrorRender(`Authorisation document is required ${CurrentIndex}`, item.AuthorisationFileName === "" && item.authorisationFile.length === 0 && item.authorisationTouch)}
          </Box>
        </Grid>
      )

    )
  }
  // Form Second End

  AdminDetailsFormSchema = () => {
    const PasswordRegexTwoUpperCase = /^(?:[^A-Z]*[A-Z]){2,}[^A-Z]*$/;
    const PasswordRegexTwoLowerCase = /^(?:[^a-z]*[a-z]){2,}[^a-z]*$/;
    const PasswordRegexTwoSpecialCharacters = /^(?:[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*[!@#$%^&*()_+\-={}[\]:;<>,.?/~]){2,}[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*$/;
    const PasswordRegexTwoNumbers = /^(?:[^\d]*\d){2,}[^\d]*$/;
    return Yup.object().shape({
      AdminName: Yup.string().required("Full name is required"),
      AdminNo: Yup.string().min(10, "Invalid contact number").required("Contact number is required"),
      AdminDesignation: Yup.string().required("Designation is required"),
      AdminDepartment: Yup.string().required("Department is required"),
      AdminEmail: Yup.string()
        .email("Email address looks incorrect!")
        .required("Email address is required"),
      AdminPassword: Yup.string()
        .required('Password is required')
        .matches(PasswordRegexTwoUpperCase, 'Password must contain at least 2 uppercase letters')
        .matches(PasswordRegexTwoLowerCase, 'Password must contain at least 2 lowercase letters')
        .matches(PasswordRegexTwoSpecialCharacters, 'Password must contain exactly two special characters')
        .matches(PasswordRegexTwoNumbers, 'Password must contain exactly two numbers')
    })
  }

  PasswordField = (errors: { AdminPassword?: any }, touched: { AdminPassword?: any  }, values: { AdminPassword: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    return (
      <Grid item xs={12} md={6}>
        <Box className="TooltipContainer">
          <InputLabel htmlFor="AdminPassword">
            <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
              Password
            </ProjectText>
          </InputLabel>
          <LightTooltip
            title="Password must be 8 characters and contains at least 2 letters, numbers, and special characters. (e.g., P@s$W0rd1)"
            placement="right"
            className="tooltip"
          >
            <InfoOutlinedIcon className="infoIcon" />
          </LightTooltip>
        </Box>
        <LandingPageTextField BoderRadius="10px" inputProps={{
          "data-test-id": "Password",
        }}
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            this.TextfieldChnage(e, setFieldValue)
          }}
          FormError={errors.AdminPassword !== undefined && touched.AdminPassword !== undefined}
          type={this.state.showPassword ? 'text' : "password"}
          value={values.AdminPassword}
          id="AdminPassword"
          name="AdminPassword"
          className={this.state.showPassword ? "" : "PasswordTextField tempheight"}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                data-test-id="showPassword"
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                <Box><img className="SmallIcon" src={this.state.showPassword ? image_eyeon: image_eyeoff} alt={this.state.showPassword ? "on":"off"} /></Box>
              </IconButton>
            </InputAdornment>,
          }}
          fullWidth
        />
        {this.ErrorRender(errors.AdminPassword, errors.AdminPassword !== undefined && touched.AdminPassword !== undefined)}
      </Grid>
    )
  }

  PhoneNumberRender = (errors: { AdminNo?: string }, touched: { AdminNo?: string | boolean }, values: { AdminNo: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    return (
      <Grid item xs={12} md={6}>
        <Box className="TooltipContainer">
          <InputLabel htmlFor="AdminNo">
            <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
              CONTACT NUMBER
            </ProjectText>
          </InputLabel>
        </Box>
        <Box className={`${errors.AdminNo !== undefined && touched.AdminNo ? "ErrorPhoneClass" : false}`}>
          <PhoneInput
            country={"sg"}
            placeholder={""}
            value={values.AdminNo}
            onChange={(number: string, data: { dialCode: string }) => {
              this.PhoneInputNumber(data.dialCode, number, setFieldValue)
            }}
            searchPlaceholder="Search for countries"
            inputProps={{
              required: true,
              id: "AdminNo",
              name: "AdminNo",
              "data-test-id": "CONTACTNUMBER"
            }}
            enableSearch={true}
            jumpCursorToEnd={true}
            countryCodeEditable={false}
            inputClass={`PhoneInputPackgeClass`}
          />
        </Box>
        {this.ErrorRender(errors.AdminNo, errors.AdminNo !== undefined && touched.AdminNo !== undefined)}
      </Grid>
    )
  }

  FormAdminDetails = () => {
    return (
      <Grid item xs={12}>
        <Formik
          validationSchema={() => this.AdminDetailsFormSchema()}
          initialValues={{
            AdminNo: this.state.PhoneNumber || "",
            AdminName: this.state.AdminName || "",
            AdminDesignation: this.state.AdminDesignation || "",
            AdminDepartment: this.state.AdminDepartment || "",
            AdminEmail: this.state.AdminEmail || "",
            AdminPassword: this.state.AdminPassword || "",
          }}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={this.state.FormenableReinitialize}
          onSubmit={() => {
            this.AdminFormSubmit()
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit, values }) => {
            return (
              <form onSubmit={handleSubmit} id="FormTwo" className="FormOrganisationDetails">
                <Box>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <ProjectText align="center" variant="h6" TextSize="1.25rem" fontweight="700">
                        Admin Details
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="TooltipContainer">
                        <InputLabel htmlFor="OrganizationName">
                          <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                            FULL NAME
                          </ProjectText>
                        </InputLabel>
                      </Box>
                      <LandingPageTextField className="tempheight" BoderRadius="10px" inputProps={{
                        "data-test-id": "FULLNAME",
                      }}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          this.TextfieldChnage(e, setFieldValue)
                        }}
                        FormError={errors.AdminName !== undefined && touched.AdminName !== undefined}
                        type='text'
                        value={this.state.AdminName}
                        id="AdminName"
                        name="AdminName"
                        fullWidth
                      />
                      {this.ErrorRender(errors.AdminName, errors.AdminName !== undefined && touched.AdminName !== undefined)}
                    </Grid>
                    {this.PhoneNumberRender(errors, touched, values, setFieldValue)}
                    <Grid item xs={12} md={6}>
                      <InputLabel htmlFor="AdminDesignation">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                          DESIGNATION
                        </ProjectText>
                      </InputLabel>
                      <LandingPageTextField className="tempheight" BoderRadius="10px" inputProps={{
                        "data-test-id": "DESIGNATION",
                      }}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          this.TextfieldChnage(e, setFieldValue)
                        }}
                        FormError={errors.AdminDesignation !== undefined && touched.AdminDesignation !== undefined}
                        type='text'
                        value={values.AdminDesignation}
                        id="AdminDesignation"
                        name="AdminDesignation"
                        fullWidth
                      />
                      {this.ErrorRender(errors.AdminDesignation, errors.AdminDesignation !== undefined && touched.AdminDesignation !== undefined)}
                    </Grid>
                    <Grid item xs={12} md={6} className="PhoneInput">
                      <InputLabel htmlFor="AdminDepartment">
                        <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                          DEPARTMENT
                        </ProjectText>
                      </InputLabel>
                      <LandingPageTextField className="tempheight" BoderRadius="10px" inputProps={{
                        "data-test-id": "ADMINDEPARTMENT",
                      }}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          this.TextfieldChnage(e, setFieldValue)
                        }}
                        FormError={errors.AdminDepartment !== undefined && touched.AdminDepartment !== undefined}
                        type='text'
                        value={values.AdminDepartment}
                        id="AdminDepartment"
                        name="AdminDepartment"
                        fullWidth
                      />
                      {this.ErrorRender(errors.AdminDepartment, errors.AdminDepartment !== undefined && touched.AdminDepartment !== undefined)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className="TooltipContainer">
                        <InputLabel htmlFor="AdminEmail">
                          <ProjectText className="OrganizationSignUpInputTitle" Upercase textcolor="black">
                            EMAIL ADDRESS
                          </ProjectText>
                        </InputLabel>
                      </Box>
                      <LandingPageTextField className="tempheight" BoderRadius="10px" inputProps={{
                        "data-test-id": "EMAILADDRESS",
                      }}
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          this.TextfieldChnage(e, setFieldValue)
                        }}
                        FormError={errors.AdminEmail !== undefined && touched.AdminEmail !== undefined}
                        type='text'
                        value={values.AdminEmail}
                        id="AdminEmail"
                        name="AdminEmail"
                        fullWidth
                      />
                      {this.ErrorRender(errors.AdminEmail, errors.AdminEmail !== undefined && touched.AdminEmail !== undefined)}
                    </Grid>
                    {this.PasswordField(errors, touched, values, setFieldValue)}
                    <Grid item xs={12}>
                      <Grid container justifyContent={!this.state.AdminFrom ? "space-between" : "flex-end"}>
                        {!this.state.AdminFrom &&
                          <Button
                            onClick={this.AdminDetailsBackButton}
                            data-test-id="back"
                          >
                            <ArrowBackIosRoundedIcon className="ArrowIcon" />
                            <ProjectText fontweight="700">
                              Back{" "}
                            </ProjectText>
                          </Button>
                        }
                        <Button type="submit" data-test-id="AdminNextButton">
                          <ProjectText fontweight="700">
                            {this.state.AdminFrom ? "Submit" : "Next"} {" "}
                          </ProjectText>
                          <ArrowForwardIosRoundedIcon className="ArrowIcon" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Grid>
    )
  }

  FinishForm = () => {
    const Color = "#1653DD"
    const StarText = '&#10059;'
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Box className="FormOrganisationDetails">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12}>
                <ProjectText align="center" variant="h6" TextSize="1.25rem" fontweight="700">
                  Review & Submit
                </ProjectText>
              </Grid>
            </Grid>
            <Box className={this.state.stepLabels.includes("Principal Organisation Details") ? "MaxHeightConatiner" : "MaxHeightConatinerFalse"}>
              <Box className="GreyContainer">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Box className="TitltContainer">
                      <ProjectText fontweight="700" TextSize="20px">
                        Organisation Details
                      </ProjectText>
                      <IconButton onClick={() => this.editStep(0)} aria-label="delete" className="EditIconClass" size="small">
                        <img src={image_editinfo} alt="editicon" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      Full Name
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.OrganizationName}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      UEN Number
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.OrganizationUEN}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      Business Registration Document
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <Box className="FileNamesAndIconContainer">
                      <img src={pdf} alt="upload" className="Pdficon" />
                      <a href={this.state.BusinessRegistrationDocBlob} target="_blank">
                        <ProjectText variant="subtitle2" fontweight="500" TextSize="14px" textcolor={Color}>
                          {this.state.FileName}
                        </ProjectText>
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {this.state.stepLabels.includes("Principal Organisation Details") && (
                <Box className="GreyContainer">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Box className="TitltContainer">
                        <ProjectText fontweight="700" TextSize="20px">
                          Principal Organisation Details
                        </ProjectText>
                        <IconButton aria-label="delete" onClick={() => this.editStep(1)} className="EditIconClass" size="small">
                          <img src={image_editinfo} alt="editicon" />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  {this.state.FormWithFiles.map((item: {
                    NameOfSection: string,
                    BusinessName: string,
                    AuthorisationFileName: string,
                    BusinessFileBlob: string,
                    authorisationFileBlob: string,
                  }, index: number) => {
                    const ArrayIndex = index === 0 ? 1 : index + 1
                    let Arraylength = this.state.FormWithFiles.length !== 1
                    return (
                      <Grid container alignItems="center" spacing={2} key={`${item.NameOfSection} ${item.BusinessFileBlob}`}>
                        <Grid item xs={5}>
                          <ProjectText Upercase fontweight="700" TextSize="14px">
                            PRINCIPAL ORGANISATION - {ArrayIndex}
                          </ProjectText>
                        </Grid>
                        <Grid item xs={7}>
                          <Box className="FileNamesAndIconContainer">
                            <ProjectText variant="subtitle2" TextSize="14px" fontweight="500">
                              {item.NameOfSection}
                            </ProjectText>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <ProjectText Upercase fontweight="700" TextSize="14px">
                            Business Registration Document
                          </ProjectText>
                        </Grid>
                        <Grid item xs={7}>
                          <Box className="FileNamesAndIconContainer">
                            <img src={pdf} alt="upload" className="Pdficon" />
                            <a href={item.BusinessFileBlob} target="_blank">
                              <ProjectText variant="subtitle2" TextSize="14px" fontweight="500" textcolor={Color}>
                                {item.BusinessName}
                              </ProjectText>
                            </a>
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <ProjectText Upercase fontweight="700" TextSize="14px">
                            AUTHORISATION LETTER
                          </ProjectText>
                        </Grid>
                        <Grid item xs={7}>
                          <Box className="FileNamesAndIconContainer">
                            <img src={pdf} alt="upload" className="Pdficon" />
                            <a href={this.state.BusinessRegistrationDocBlob} target="_blank">
                              <ProjectText variant="subtitle2" TextSize="14px" fontweight="500" textcolor={Color}>
                                {item.AuthorisationFileName}
                              </ProjectText>
                            </a>
                          </Box>
                        </Grid>
                        {Arraylength && this.state.FormWithFiles.length - 1 !== index && (
                          <Grid item xs={12}>
                            <Box className="DividerContainer">
                              <Divider />
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                    )
                  })}
                </Box>
              )}
              <Box className="GreyContainer">
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Box className="TitltContainer">
                      <ProjectText fontweight="700" TextSize="20px">
                        Admin Details
                      </ProjectText>
                      <IconButton aria-label="delete" data-test-id="Step3" onClick={() => this.editStep(2)} className="EditIconClass" size="small">
                        <img src={image_editinfo} alt="editicon" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      Full Name
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.AdminName}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      CONTACT NUMBER
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.PhoneNumber}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      DESIGNATION
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.AdminDesignation}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      DEPARTMENT
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.AdminDepartment}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText className="OrganizationSignUpInputTitle" Upercase>
                      EMAIL ADDRESS
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <ProjectText fontweight="500" TextSize="14px">
                      {this.state.AdminEmail}
                    </ProjectText>
                  </Grid>
                  <Grid item xs={5}>
                    <ProjectText Upercase fontweight="700" TextSize="14px">
                      PASSWORD
                    </ProjectText>
                  </Grid>
                  <Grid item xs={7}>
                    <Box className="PasswordContainer">
                      {this.state.showPassword ?
                        <ProjectText fontweight="500" noWrap TextSize="14px">
                          {this.state.AdminPassword}
                        </ProjectText>
                        :
                        <ProjectText 
                          fontweight="600"
                          noWrap 
                          TextSize="14px" >
                            {'*'.repeat(this.state.AdminPassword.length ?? 0)}
                          </ProjectText>
                      }
                      <Box className="EyeIconContainer" onClick={this.handleClickShowPassword}>
                        {this.state.showPassword ? (<Box><img src={image_eyeon} alt="on" className="SmallIcon" /></Box>) : (<Box><img src={image_eyeoff} alt="off" className="SmallIcon" /></Box>)}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className="TermsAndConditionsGrid">
          <Box className="TermsAndConditions">
            <Box className="CheckBoxContainer">
              <Checkbox data-test-id="CheckBox"
                checked={this.state.tcChecked} onClick={() => this.ChangeCheckBox()} />
            </Box>
            <Box className="TextContainer">
              <ProjectText TextSize="1rem" fontweight="500">
                I have reviewed and consent to the{' '}
              </ProjectText>
              <Button variant="text" data-test-id="TermsandConditionsButton" onClick={this.TearmsAndConditonOpen}>
                <ProjectText textcolor={Color} className="TermsandConditionsButtonText" TextSize="1rem" fontweight="500">
                  {' '} Terms and Conditions
                </ProjectText>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className="SubmitButtonContainer">
            <ContainedButton fullWidth disabled={!this.state.tcChecked} data-test-id="FinishButton" onClick={this.signUpOrganization}>
              Submit
            </ContainedButton>
          </Box>
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <OrganizationPageStyle data-test-id="OrganizationSignUp">
        <AppBackground>
          {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
          {!this.state.showPopup && (
            <Grid container justifyContent="center" alignItems="center" className="OrganizationSignUpContainerPadding">
              <Grid item md={6}>
                <GenericCard>
                  <Grid container>
                    <Grid item xs={12}>
                      <ProjectText className="OrganizationSignUpTitle" align="center">
                        Setup your Organisation's account
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12} data-test-id="SteperRender">
                        <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector />}>
                          {this.state.stepLabels.map((label: string) => (
                            <Step key={label}>
                              <StepLabel StepIconComponent={ColorlibStepIcon}>
                                <Box className="SteperHeading">
                                  <ProjectText className="OrganizationSignUpSecTitle" align="center">
                                    {label}
                                  </ProjectText>
                                </Box>
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    </Grid>
                    {this.state.FormSubmitContainer ? this.FinishForm() : this.RenderStpes()}
                  </Grid>
                </GenericCard>
              </Grid>
            </Grid>
          )}
          {this.state.showPopup && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid xs={5}>
                <GenericCard>
                  <AccountRequest>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12}>
                        <Box className="ImgContainer">
                          <img
                            src={successImg}
                            alt="checkimg"
                            className="successImg"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectText variant="h6" align="center" TextSize="1.75rem" fontweight="700">Account Request Submitted!</ProjectText>
                      </Grid>
                      <Grid item xs={8}>
                        <ProjectText variant="h6" align="center" TextSize="1rem" fontweight="500">
                          You'll be notified by email once your Organisation Account
                          has been approved by our SlotXpert Admin.
                        </ProjectText>
                      </Grid>
                    </Grid>
                  </AccountRequest>
                </GenericCard>
              </Grid>
            </Grid>
          )}
        </AppBackground>
      </OrganizationPageStyle>
    );
  }
}
export {OrganizationSignUp}
export default withRouter(OrganizationSignUp);

const AccountRequest = styled("div")({
  "& .ImgContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
})

const OrganizationPageStyle = styled("div")({
  "& .OrganizationSignUpTitle": {
    fontSize: '28px', fontWeight: 700,  marginBottom: '5px',
    "@media (max-width: 1280px)": {
       fontSize: '26px'
    },
    "@media (max-width: 1080px)": {
      fontSize: '24px'
    },
    "@media (max-width: 600px)": {
      fontSize: '20px'
    },
  },
  "& .OrganizationSignUpSecTitle": { 
    fontSize: '20px', fontWeight: 700, marginBottom: '12px',
    "@media (max-width: 1280px)": {
      fontSize: '18px'
    },
    "@media (max-width: 1080px)": {
      fontSize: '16px'
    },
    "@media (max-width: 600px)": {
      fontSize: '14px'
    },
  },
  "& .OrganizationSignUpInputTitle": {  
    fontSize: '14px', fontWeight: 900, whiteSpace: 'normal',
    "@media (max-width: 1700px)": {
      fontSize: '14px'
    },
    "@media (max-width: 1080px)": {
      fontSize: '14px'
    },
    "@media (max-width: 600px)": {
      fontSize: '12px'
    },
  },
  "& .BgLight": {
    backgroundColor: "#80808045",
    transition: "all ease",
    "& .DeleteBusinessPdf": {
      transform: "scale(1)"
    }
  },
  "& .OrganizationSignUpContainerPadding": {
    overflowX: 'hidden',
    "@media (max-width: 960px)": {
      padding: "120px 15px",
    },
  },
  "& .SterperLable": {
    "& .MuiStepLabel-iconContainer": {
      "& .MuiStepIcon-active": {
        color: "#1653DD",
        "& .MuiStepIcon-root": {
          border: "none"
        },
        "& .MuiStepIcon-text": {
          fill: "white"
        }
      }
    },
    "& .MuiStepLabel-labelContainer": {
      "& .MuiStepLabel-label": {
        marginTop: "5px",
      }
    },
    "& .MuiStepIcon-root": {
      color: "white", borderRadius: "50%", border: "1px solid grey",
      "& .MuiStepIcon-text": {
        fill: "black"
      }
    },
  },
  "& .MaxHeightConatinerFalse": {
    "@media (max-width: 1440px)": {
      overflowX: "hidden", overflowY: "auto",  maxHeight: "280px",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#ffffff"
      },
      "&::-webkit-scrollbar-thumb": {
        borderColor: "#aba6a6",    borderRadius: "10px",  border: "10px solid #d2d0d0"
      },
    }
  },
  "& .MaxHeightConatiner": {
    overflowX: "hidden", overflowY: "auto",  maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff"
    },
    "&::-webkit-scrollbar-thumb": {
      borderColor: "#aba6a6",  borderRadius: "10px",  border: "10px solid #d2d0d0"
    },
  },
  // first step from
  "& .FormOrganisationDetails": {
    "& .PasswordTextField": {
      "& input": {
        fontFamily: 'monospace',  fontSize: "xx-large",   height: "18px"
      }
    },
    marginBottom: "0px",
    "& .PrincipalCheckBoxContainer": {
      borderRadius: '8px',   border: '1px solid #CDCDCD', display: "flex", padding: "0px  24px", height: "52px",
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#1653DD",
      },
      "& .MuiFormControlLabel-label": {
        fontWeight: '700',  fontFamily: "Asap, sans-serif",
      },
    },
    "& .GreyContainer": {
      border: "1px solid #CDCDCD",  backgroundColor: "#F9F9F9", padding: "1rem 1.5rem",  borderRadius: "10px",  marginTop: "10px",
      marginBottom: "10px",
      "& .TitltContainer": {
        display: "flex",  justifyContent: "space-between",  marginBottom: ".625rem",  alignItems: "center",
        "& .EditIconClass": {
          "& img": {
            height: "1rem"
          }
        }
      },
      "& .FileNamesAndIconContainer": {
        display: "flex",  alignItems: "center",
        "& .Pdficon": {
          height: "1.375rem",  marginRight: ".625rem"
        }
      }
    },
    "& .PasswordContainer": {
      display: "flex",  alignItems: "center",
      "& .EyeIconContainer": {
        marginLeft: "4px"
      }
    },
  },
  "& .TermsAndConditionsGrid": {
    padding: "0",
    "& .TermsAndConditions": {
      display: "flex",
      alignItems: "center",
      "& .TextContainer": {
        display: "flex",  alignItems: "center"
      },
      "& .CheckBoxContainer": {
        "& .MuiSvgIcon-root": {
          fontSize: "1.9rem"
        },
        "& .Mui-checked": {
          color: "#1653DD"
        }
      }
    },
    "& .TermsandConditionsButtonText": {
      textTransform: "capitalize",  textDecoration: "underline"
    }
  },
  "& .DisablePadding": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  "& .PrincipalSectionsContainerWidth100": {
    width: "100%",  marginBottom: "1.875rem",  maxHeight: "400px",  overflowX: "hidden",  overflowY: "auto",  scrollbarWidth: "auto",
    scrollbarColor: "#ffffff",
    "& .HiddenClass": {
      visibility: "hidden"
    },
    "& .DisableBottomPadding": {
      paddingBottom: "0px"
    },
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff"
    },
    "&::-webkit-scrollbar-thumb": {
      borderColor: "#aba6a6", borderRadius: "10px",  border: "10px solid #d2d0d0"
    },
  },
  "& .PrincipalOrganizationNameTextField": {
    "& input": {
      fontWeight: "500"
    }
  },
  "& .DividerContainer": {
    marginTop: "1.25rem",  marginBottom: "1.25rem"
  },
  "& .PrincipalSectionsContainer": {
    overflowX: "hidden",  overflowY: "auto",  maxHeight: "270px",  scrollbarWidth: "auto", scrollbarColor: "#ffffff",
    width: "100%",
    "& .HiddenClass": {
      visibility: "hidden"
    },
    "& .DisableBottomPadding": {
      paddingBottom: "0px"
    },
    "&::-webkit-scrollbar": {
      width: "8px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff"
    },
    "&::-webkit-scrollbar-thumb": {
      borderColor: "#aba6a6",  borderRadius: "10px",  border: "10px solid #d2d0d0"
    },
    "& .PrincipalSection": {
      marginTop: "5px",
      marginBottom: "5px",
      "& .MuiDivider-root": {
        height: "8px"
      }
    },
  },
  "& .MuiDivider-root": {
    height: "8px"
  },
  "& .MuiTypography-noWrap": {
    "@media (max-width: 960px)": {
      overflow: 'hidden', whiteSpace: 'initial', textOverflow: 'ellipsis'
    }
  },
  "& .SpaceBetweenClass": {
    justifyContent: "space-between"
  },
  "& .AddButtonContainer": {
    marginTop: "10px",
    "& .Mui-disabled": {
      backgroundColor: "lightgrey",
      color: "grey"
    }
  },
  "& .ErrorClass": {
    backgroundColor: "#FBE9E9"
  },
  "& .AbsoluteClass": {
    position: "absolute",
    marginTop: ".25rem !important"
  },
  "& .ErrorTextContainer": {
    display: "flex",  marginTop: "5px",  alignItems: "center",
    "@media (max-width: 768px)": {
      marginTop: "8px",
    },

    "& .TextHidden": {
      visibility: "hidden"
    },
    "& .ErrorIconContainer": {
      marginRight: "6px",
      "& .MuiSvgIcon-root": {
        color: "red"
      }
    },
    "& .TextError": {
      color: "red",  fontSize: "16px",  marginBottom: "0px",  transition: "width 1s ease-out ", fontFamily: "Asap, sans-serif",
    },
  },
  "& .PrincipalheadingContainer": {
    position: "relative",  display: "flex",  justifyContent: "center",
    "& .ViewMoreContainer": {
      position: "absolute", right: "0px"
    }
  },
  "& .TooltipContainer": {
    display: "flex",  alignItems: "center",  overflowX: "hidden", width: "100%", marginBottom: "5px",
    "& .MuiInputLabel-root": {
      marginRight: "4px"
    }
  },
  "& .colorwhite": {
    visibility: "hidden"
  },
  "& .ArrowIcon": {
    fontSize: "1.1rem",  fontWeight: "800",  padding: "0 0.3rem 0 0.3rem",  color: "black"
  },
  "& .ArrowIconDisable": {
    fontSize: "1.1rem",  fontWeight: "800",  padding: "0 0.3rem 0 0.3rem",  color: "grey"
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",  height: "56px",  borderRadius: "10px", fontSize: "15px", fontFamily: "Asap, sans-serif", fontWeight: 'bold', backgroundColor: "white",
    "@media (max-width: 1200px)": {
      fontSize: "12px",
      // height: "30px"
    },
    "@media (max-width: 460px)": {
      // fontSize: "12px",
      height: "initials"
    }
  },
  "& .dial-code": {
    fontFamily: "Asap, sans-serif",  color: "black !important",  fontWeight: "bold"
  },
  "& .no-entries-message": {
    fontFamily: "Asap, sans-serif",  color: "black !important", fontWeight: "bold"
  },
  "& .country-name": {
    fontFamily: "Asap, sans-serif", fontWeight: "bold",
  },
  "& .ErrorPhoneClass": {
    "& .PhoneInputPackgeClass": {
      backgroundColor: "#FBE9E9"
    },
    "& .flag-dropdown": {
      backgroundColor: "#FBE9E9"
    },
    "& .selected-flag": {
      backgroundColor: "#FBE9E9"
    }
  },
  "& .flag-dropdown": {
    borderRight: "none",  borderRadius: "10px 0px 0px 10px"
  },
  "& .selected-flag": {
    backgroundColor: "white", borderRadius: "10px"
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px",  color: "red",  margin: "6px 14px",  position: "absolute"
    }
  },
  "& .EmailAndCallTextConatiner": {
    display: "flex",  alignItems: "center"
  },
  "& .SmallIcon": {
    height: "1.375rem", cursor: "pointer"
  },
  "& .UploadFileBtn": {
    padding: "15px",  width: "100%",  border: '1px solid #CDCDCD',  borderRadius: '8px',  position: "relative",
    "& .MuiButton-label": {
      display: "flex",   justifyContent: "flex-start",  alignItems: "center",  textTransform: "none"
    },
  },
  "& .UploadFileNameRender": {
    display: "flex",  justifyContent: "space-between",  alignItems: "center", width: "100%",
    "& .SmallIcon": {
      marginRight: "10px"
    },
    "& .textandPdfIcon": {
      display: "flex",   alignItems: "center",   width: "90%"
    }
  },
  "& .SubmitButtonContainer": {
    "& .Mui-disabled": {
      backgroundColor: "lightgrey",  color: "grey"
    }
  },
  "& .tempheight": {
    "@media (max-width: 1280px)": {
      height: "initial"
    },
  }
});

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.common.white,
    color: "black",
    fontSize: 14,
    display: " inline-flex",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    fontFamily: "Asap, sans-serif",
    padding: "8px 16px",
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    '& $line': {
      backgroundColor: '#1653DD',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#1653DD',
    },
  },
  line: {
    height: 3,  border: 0,  backgroundColor: '#eaeaf0',  borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    zIndex: 1,
    color: 'black',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: "2px solid #DADADA",
    fontFamily: "Asap, sans-serif",
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  active: {
    backgroundColor: "#1653DD",  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',  color: "white",  border: "none",  fontFamily: "Asap, sans-serif",
  },
  completed: {
    backgroundColor: "#1653DD",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: "white",
    border: "none",
    fontFamily: "Asap, sans-serif",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {props.icon}
    </div>
  );
}
// Customizable Area End