// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Portal from '@material-ui/core/Portal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Pagination from "../../blocks/pagination/src/Pagination";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Switchaccounts2 from "../../blocks/switchaccounts2/src/Switchaccounts2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Inventoryhealth from "../../blocks/inventoryhealth/src/Inventoryhealth";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Datastorage2 from "../../blocks/datastorage2/src/Datastorage2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Datamigration2 from "../../blocks/datamigration2/src/Datamigration2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import OrganizationSignUp from "../../blocks/email-account-registration/src/OrganizationSignUp.web";
import OrganizationUserSignUp from "../../blocks/email-account-registration/src/OrganizationUserSignUp.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import EmailAccountLogin from "../../blocks/email-account-login/src/EmailAccountLogin.web";
import BuyerUserProfile from "../../blocks/user-profile-basic/src/BuyerUserProfile.web"
// import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web"
import History from "../../blocks/user-profile-basic/src/History.web"

import BuyersOffer from "../../blocks/listdemandpickflow/src/BuyersOffer.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Listdemandpickflow from "../../blocks/listdemandpickflow/src/Listdemandpickflow";
import SellerListing from "../../blocks/listdemandpickflow/src/SellerListing";
import SellerPendingOffers from "../../blocks/listdemandpickflow/src/SellerPendingOffers";
import SellerPendingRequest from "../../blocks/listdemandpickflow/src/SellerPendingRequest";
import SellerBookings from "../../blocks/listdemandpickflow/src/SellerBookings.web";
import BuyersRequest from "../../blocks/listdemandpickflow/src/BuyersRequest";
import BuyerMatches from "../../blocks/listdemandpickflow/src/BuyerMatches";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Faq from "../../blocks/landingpage/src/Faq.web"
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Demorequest from "../../blocks/demorequest/src/Demorequest";
import VesselUtilisation from "../../blocks/listdemandpickflow/src/VesselUtilisation.web";

const routeMap = {
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Pagination: {
    component: Pagination,
    path: "/Pagination"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Switchaccounts2: {
    component: Switchaccounts2,
    path: "/Switchaccounts2"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Inventoryhealth: {
    component: Inventoryhealth,
    path: "/Inventoryhealth"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Datastorage2: {
    component: Datastorage2,
    path: "/Datastorage2"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Datamigration2: {
    component: Datamigration2,
    path: "/Datamigration2"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Listdemandpickflow: {
    component: Listdemandpickflow,
    path: "/Listdemandpickflow"
  },
  SellerListing: {
    component: SellerListing,
    path: "/SellerListing"
  },
  SellerPendingOffers: {
    component: SellerPendingOffers,
    path: "/SellerPendingOffers"
  },
  SellerPendingRequest: {
    component: SellerPendingRequest,
    path: "/SellerPendingRequest"
  },
  SellerBookings: {
    component: SellerBookings,
    path: "/SellerBookings"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: "/Interactivefaqs"
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: "/AddInteractivefaqs"
  },
  Demorequest: {
    component: Demorequest,
    path: "/Demorequest"
  },
  Tasks: {
    component: Tasks,
    path: "/Tasks"
  },
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Pagination: {
    component: Pagination,
    path: "/Pagination"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Switchaccounts2: {
    component: Switchaccounts2,
    path: "/Switchaccounts2"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Inventoryhealth: {
    component: Inventoryhealth,
    path: "/Inventoryhealth"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Datastorage2: {
    component: Datastorage2,
    path: "/Datastorage2"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Datamigration2: {
    component: Datamigration2,
    path: "/Datamigration2"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: "/EmailAccountLogin"
  },
  BuyersOffer:{
    component:BuyersOffer,
   path:"/BuyersOffer"},

  History: {
    component: History,
    path: "/History"
  },

  BuyerUserProfile:{
    component:BuyerUserProfile,
   path:"/UserProfile"},


  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Listdemandpickflow: {
    component: Listdemandpickflow,
    path: "/Listdemandpickflow"
  },
  BuyersRequest: {
    component: BuyersRequest,
    path: "/BuyersRequest"
  },
  Listdemandpickflow: {
    component: Listdemandpickflow,
    path: "/Listdemandpickflow"
  },
  BuyerMatches: {
    component: BuyerMatches,
    path: "/BuyerMatches"
  },
  
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  Faq: {
    component: Faq,
    path: "/Faq"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: "/Interactivefaqs"
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: "/AddInteractivefaqs"
  },
  Demorequest: {
    component: Demorequest,
    path: "/Demorequest"
  },
  OrganizationSignUp: {
    component: OrganizationSignUp,
    path: "/OrganizationSignUp"
  },
  OrganizationUserSignUp: {
    component: OrganizationUserSignUp,
    path: "/OrganizationUserSignUp"
  },
  VesselUtilisation: {
    component: VesselUtilisation,
    path: "/VesselUtilisation"
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey:process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View >
        {/* style={{ height: '100vh', width: '100vw' }}> */}
        {/* <TopNav /> */}
        <Portal>
          <ToastContainer
            autoClose={3000}
            position="top-right"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </Portal>
        {WebRoutesGenerator({ routeMap })}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;
