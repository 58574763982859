// Customizable Area Start
import { 
  BlockComponent 
} from "../../../framework/src/BlockComponent";
import { 
  runEngine 
} from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { 
  Message 
} from "../../../framework/src/Message";
import { 
  getStorageData, 
  setStorageData 
} from "../../../framework/src/Utilities";
import { 
  toast 
} from "react-toastify";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

interface Status {
  pending: number;
  rejected: number;
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  currentTab: number;
  anchorEl: any;
  page: number;
  rowsPerPage: number;
  popup: boolean;
  termsPopup:boolean;
  anchorElterms: any;
  profilePop: boolean;
  columns: any;
  rows: any;
  accordianData: string;
  switch: boolean;
  show: boolean;
  showGuideVedios: boolean;
  RejectModal: boolean;
  SuccessModal: boolean;
  nominationModal: boolean;
  trackingModal: boolean;
  nominationCheckbox: boolean;
  cancelBooking: boolean;
  sucessOpenmodal: boolean;
  submittingState: boolean;
  DraftBLstate: boolean;
  revisionrequest: boolean;
  revisionSucess: boolean;
  paymentconfiguration: boolean;
  submittingSucess: boolean;
  verifySucess: boolean;
  paymentSucess: boolean;
  authorizationModal: boolean;
  BookingrefrenceModal: boolean;
  BookingrefrenceSuccess: boolean;
  finalBL: boolean;
  finalBLsuccess: boolean;
  activeButtonvalue: string;
  portnetUrlData:string,
  activeState: number;
  slotsData: {
    org: string;
    requestId: string;
    dateRange: string;
    pol1: string;
    pol2: string;
    carrier: string;
    pod1: string;
    pod2: string;
    type: string;
    codes: string;
    rate: string[];
    total: string;
    status: Status;
  }[];
  trackingData: any;
  selectedBooking: any;
  BookingrefrenceNumber: string;
  DragActive: boolean;
  draftBLDoc: Array<File>;
  FileName: string;
  draftBLDocBlob: any;
  invoiceDoc: Array<File>;
  invoiceDocFileName: string;
  invoiceBlob: any;
  finalBLDoc: Array<File>;
  finalDocFileName: string;
  finalDocBlob: any;
  sellerTerm :any
  activeSlotFilter:any
  isSlotFilterOpen:boolean;
  prinicpalOrgList:any;
  sellerListingSnackbarOpen: boolean,
  ListingOpen: boolean;
  offerExpanded: boolean;
  offerExpandedPol: boolean,
  offerExpandedPod: boolean;
  offerExpandedtype: boolean;
  filter: {
    bookingID: Array<{ label: string; isChecked: boolean }>;
    pol: Array<{ label: string; isChecked: boolean }>;
    pod: Array<{ label: string; isChecked: boolean }>;
    vessel: Array<{ label: string; isChecked: boolean; id?:number }>;
    startDate: string;
    endDate: string;
    principalOrgFilter:Array<string>
  };
  open: boolean,
  filterData:boolean,
  StartDate: string, 
  EndDate: string,
  RenderStart: string, 
  RenderEndDate: string, 
  sellerListing:any;
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number
  },
  DateRangeStartDate: Date | undefined;
  DateRangeEndDate: Date | undefined;
  loading: boolean;
  approvalModal: boolean;
  approvalConfirmationModal: boolean;
  approvalReasonModal: boolean;
  approvalCancelledModal: boolean;
  reasonText: string | unknown;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SellerBookingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let sellerBookingsLoginData = await getStorageData("UserRole");
      if(!sellerBookingsLoginData){
        window.location.href = "/"
      } else if(sellerBookingsLoginData == "buyer"){
        window.location.href = "/BuyersRequest"
      }
    })()

    const rowData = [
      { org: "HMM", 
      matchId: "30711", 
      dateRange: "2 JUN - 2 SEP", 
      pol1: "SGSIN", 
      pol2: "TCN",
        carrier: "Arrow", 
        pod1: "INMUN", 
        pod2: "BCN", 
        type: "EMPTY", 
        codes: "MT,MH,RM",
        rate: ["20FT 100 $180/20FT CY/CY", "20FT 100 $180/20FT CY/CY"],
        total: "$36,000", 
        status: {pending: 2, rejected: 1}
      },
    ];

    this.state = {
      accordianData: "",
      nominationModal: false,
      nominationCheckbox: false,
      cancelBooking: false,
      trackingModal: false,
      sucessOpenmodal: false,
      submittingState: false,
      submittingSucess: false,
      verifySucess: false,
      paymentSucess: false,
      revisionSucess: false,
      DraftBLstate: false,
      revisionrequest: false,
      rowsPerPage: 10,
      showGuideVedios: false,
      paymentconfiguration: false,
      finalBL: false,
      finalBLsuccess: false,
      authorizationModal: false,
      BookingrefrenceModal: false,
      BookingrefrenceSuccess: false,
      activeButtonvalue: "Authorize co code",
      activeState: 0,
      portnetUrlData:'',
      popup: false,
      switch: true,
      columns: [],
      rows: rowData,
      currentTab: 3,
      trackingData: [],
      anchorEl: null,
      SuccessModal: false,
      page: 0,
      profilePop: false,
      RejectModal: false,
      slotsData: [],
      show: false,
      termsPopup:false,
      anchorElterms: false,
      selectedBooking: {},
      BookingrefrenceNumber: "",
      draftBLDoc: [],
      DragActive: false,
      FileName: "",
      draftBLDocBlob: [],
      invoiceDoc: [],
      invoiceDocFileName: "",
      invoiceBlob: [],
      finalBLDoc: [],
      finalDocFileName: "",
      finalDocBlob: [],
      sellerTerm : "",
      activeSlotFilter:[],
      isSlotFilterOpen:false,
      prinicpalOrgList:[],
      sellerListingSnackbarOpen: false,
      ListingOpen: false,
      offerExpanded: false,
      offerExpandedPol: false,
      offerExpandedPod: false,
      offerExpandedtype: false,
      filter: {
        bookingID: [],
        pol: [],
        pod: [],
        vessel: [],
        startDate: '',
        endDate: "",
        principalOrgFilter:[]
      },
      open: false,
      filterData:false,
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      sellerListing:[],
      DynamicCount: {
        listing: "0",
        requst: "0",
        offers: "0",
        booking: "0"
      },
      DateRangeStartDate: new Date(),
      DateRangeEndDate: new Date(),
      loading: true,
      approvalModal: false,
      approvalConfirmationModal: false,
      approvalReasonModal: false,
      approvalCancelledModal: false,
      reasonText: "",
    };
    
  }
  async componentDidMount() {
    this.getSellerBooking();
    let val = await getStorageData("PrincipalOrganisation");
    let logindata = await getStorageData("loginData");
     let terms =await getStorageData("priceTerm");let portnetUrl =await getStorageData("portnetUrl");
       if(terms!=null){this.setState({sellerTerm:terms})}
       if(portnetUrl!=null){this.setState({portnetUrlData:portnetUrl})}
    const promise1 = Promise.resolve(val);
    promise1.then((value:string) => {
      let result = JSON.parse(value)
      this.setState({prinicpalOrgList:result })
    });
    const newLoginData = JSON.parse(logindata)
    this.setState({sellerTerm: newLoginData?.price_terms})
  }

  getSellerBookingDataApiCallID: string = "";
  coAuthorizeApiCallID: string = "";
  acceptBookingApiCallId: string = "";
  cancelBookingApiCallId: string = "";
  verifyBLAPICallID:string="";
  issueBookingReferenceNumberApiCallID: string = "";
  uploadDraftBlApiCallID: string = "";
  sellerUploadInvoiceApiCallID: string = "";
  sellerFinalBlApiCallID: string = "";
  onSellerListingSwitchRoleAPICallID: string = "";
  applyFilterApiCallID: string = "";
  sellerCancelBookingApiCallID: string = "";

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getSellerBookingDataApiCallID) {
      this.setState({ trackingData: responseJson.data , loading: false, DynamicCount: {
        ...this.state.DynamicCount,
        booking: responseJson?.data.length,
      },});
      let temp = {
        bookingID: responseJson.data.map((val: any) => val.attributes.booking_uid),
        pol: responseJson.data.map((val: any) => val.attributes.pol),
        pod: responseJson.data.map((val: any) => val.attributes.pod),
        vessel: responseJson.data.map((val: {attributes:{ vessel_voyage: string; vessel_voyage_id:number } }) => {return {label:val?.attributes?.vessel_voyage,id:val?.attributes?.vessel_voyage_id}}),
        startDate: responseJson.data.map((val: any) => val.attributes.container_loading_range_start),
        endDate: responseJson.data.map((val: any) => val.attributes.container_loading_range_end),
      };
      let a1 = [...new Set(temp.bookingID)];
      let a2 = [...new Set(temp.pol)];
      let a3 = [...new Set(temp.pod)];
      let a4 = temp.vessel;
      let arr = { 
        bookingID: a1.map((str: any) => ({ label: str,  isChecked: false,
        })),
        pol: a2.map((str: any) => ({ label: str, isChecked: false })),
        pod: a3.map((str: any) => ({ label: str, isChecked: false })),
        vessel: Array.from(
          new Set(
            a4
              .filter((str: any) => str.id !== null)
              .map((str: any) => str.id)
          )
        ).map((id: any) => ({
          label: a4.find((str: any) => str.id === id).label,
          id: id,
          isChecked: false
        })),
        startDate: "",
        endDate: "",
        principalOrgFilter:[]
      };
      this.setState({ filter: arr});
    }

    this.coAuthorizeApiCall(apiRequestCallId, responseJson);
    this.acceptBookingApiCall(apiRequestCallId, responseJson);
    this.cancelBookingApiCall(apiRequestCallId, responseJson);

    if (apiRequestCallId === this.uploadDraftBlApiCallID) {
      this.getSellerBooking()
      this.setState({ verifySucess: true, DraftBLstate: false });
    }
    if (apiRequestCallId === this.sellerUploadInvoiceApiCallID) {
      this.getSellerBooking()
      this.setState({paymentSucess: true, paymentconfiguration: false});
    }
    if (apiRequestCallId === this.sellerFinalBlApiCallID) {
      this.VerifyBL()
      this.getSellerBooking()
      this.setState({ finalBLsuccess: true, finalBL: false});
    }
    if(apiRequestCallId===this.verifyBLAPICallID){
      this.getSellerBooking()
    }
    if (apiRequestCallId === this.applyFilterApiCallID) {
      if(responseJson?.data&&responseJson?.data.length>0){
      this.setState({trackingData:responseJson?.data,open:false,filterData:false, DynamicCount: {
        ...this.state.DynamicCount,
        booking: responseJson?.data.length,
      },})
    }else{
      this.setState({trackingData:[],filterData:true})
    }
    }
    this.OnSellerListingSwitch(apiRequestCallId, responseJson)
  }

  coAuthorizeApiCall = (apiRequestCallId: string, responseJson:any) => {
    if (apiRequestCallId === this.coAuthorizeApiCallID) {
      if (responseJson?.data?.attributes?.co_authorizer) {
        let temp = this.state.trackingData.map((val: any) => {
          if (val.id === this.state.selectedBooking.id) {
            return {
              ...val,
              activeButtonValue: "Issue Booking no",
              activeState: 1,
            };
          }
          if (val.id != this.state.selectedBooking.id) {
            return { ...val };
          }
        });
        this.getSellerBooking();
        this.setState({ authorizationModal: true, nominationModal: false, trackingData: temp, });
      } else {
        toast.error(responseJson.errors[0]);
      }
    }
    this.handleApiResponse(apiRequestCallId,responseJson)
  }

  acceptBookingApiCall = (apiRequestCallId: string, responseJson:any) => {
    if (apiRequestCallId === this.acceptBookingApiCallId) { if (responseJson?.data?.attributes?.booking_confirmation_status !== "Pending Confirmation") { this.getSellerBooking(); this.setState({ approvalConfirmationModal: true, approvalModal: false });
      } else { toast.error(responseJson.errors[0]); } }
    this.handleApiResponse(apiRequestCallId,responseJson) }

  cancelBookingApiCall = (apiRequestCallId: string, responseJson:any) => {
    if (apiRequestCallId === this.cancelBookingApiCallId) { if (responseJson?.data?.attributes?.booking_confirmation_status === "Booking Cancelled") { this.getSellerBooking(); this.setState({ approvalCancelledModal: true, approvalReasonModal: false });
      } else { toast.error(responseJson.errors[0]); } } 
    if (apiRequestCallId === this.sellerCancelBookingApiCallID) { this.setState({ cancelBooking: false, trackingModal : false }); toast.success("Booking Cancelled Succesfully"); this.getSellerBooking();}
    this.handleApiResponse(apiRequestCallId,responseJson) 
  }

  handleApiResponse = (apiRequestCallId:any,responseJson:any)=>{
    if (apiRequestCallId === this.issueBookingReferenceNumberApiCallID) {
      if (responseJson?.data?.attributes?.booking_reference_number) {
        this.getSellerBooking();
        this.setState({ BookingrefrenceSuccess: true, BookingrefrenceModal: false});
      } else {
        toast.error(responseJson.errors[0]);
      }
    }
    if (apiRequestCallId === this.uploadDraftBlApiCallID) {
      if (responseJson?.data?.attributes.draft_bl) {
        this.getSellerBooking();
        this.setState({ verifySucess: true, DraftBLstate: false, });
      }
       else{
        toast.error(responseJson.errors[0].token);
      }
    }
    if (apiRequestCallId === this.sellerUploadInvoiceApiCallID) {
      this.getSellerBooking();
      this.setState({ paymentSucess: true, paymentconfiguration: false, });
    }
    if (apiRequestCallId === this.sellerFinalBlApiCallID) {
      this.getSellerBooking();
      this.setState({ finalBLsuccess: true, finalBL: false, });
    }
  }

  getSellerBooking = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken, "Content-Type": "application/json",
    };

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)  );

    this.getSellerBookingDataApiCallID = requestMessage.messageId;

    requestMessage.addData(  getName(MessageEnum.RestAPIResponceEndPointMessage),  "bx_block_listdemandpickflow/bookings"
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),  JSON.stringify(header)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  coAuthorize = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken, "Content-Type": "application/json", };

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      booking: { id: this.state.selectedBooking.id,  co_authorizer: true,  },
    };
    this.coAuthorizeApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/bookings/co_authorizer"
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),  JSON.stringify(header)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  approveBooking = async () => {
    let approveBookingToken = await getStorageData("authToken");
    const header = {
      token: approveBookingToken, 
      "Content-Type": "application/json", };

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
        booking:{
            id: this.state.selectedBooking.id,
            booking_confirmation_status: "Booking Confirmed"
        }
    };
    this.acceptBookingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/bookings/seller_accept_reject_booking"
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),  JSON.stringify(header)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelBooking = async () => {
    const authToken = await getStorageData("authToken") ;
    const revisionHeader= {
      "Content-Type": "application/json",
      token: authToken,
    };
    const reVisionBody = {
        booking:{
          id: this.state.selectedBooking.id,
          booking_confirmation_status: "Booking Cancelled",
          seller_booking_cancel_reason: this.state.reasonText,
          booking_status: "rejected"
      }
    }
    const requestMessage4 = new Message( getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelBookingApiCallId = requestMessage4.messageId;

    requestMessage4.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/seller_accept_reject_booking"
    );

    requestMessage4.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(revisionHeader)
    );

    requestMessage4.addData( getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reVisionBody)
    );

    requestMessage4.addData(  getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );

    runEngine.sendMessage(requestMessage4.id, requestMessage4);
  };

  issueBookingReferenceNumber = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {  token: LocationToken, "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      booking: {
        id: this.state.selectedBooking.id,
        booking_reference_number: this.state.BookingrefrenceNumber,
      },
    };
    this.issueBookingReferenceNumberApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/issue_booking_reference_number"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadDraftBl = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken,
      contentType: "multipart/form-data",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append(`booking[id]`, this.state.selectedBooking.id);
    formData.append(`booking[draft_bl]`, this.state.draftBLDoc[0]);

    this.uploadDraftBlApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/upload_draft_bl"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sellerUploadInvoice = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken,
      contentType: "multipart/form-data",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append(`booking[id]`, this.state.selectedBooking.id);
    formData.append(`booking[upload_invoice]`, this.state.invoiceDoc[0]);
    formData.append(`booking[term]`, this.state.sellerTerm);

    this.sellerUploadInvoiceApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/seller_upload_invoice"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sellerFinalBl = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken,
      contentType: "multipart/form-data",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append(`booking[id]`, this.state.selectedBooking.id);
    formData.append(`booking[final_bl]`, this.state.finalBLDoc[0]);
    this.sellerFinalBlApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/seller_final_bl"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sellerCancelBooking = async () => {
    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      booking: {
        id: this.state.selectedBooking.id,
        booking_status: "rejected",
      },
    };
    this.sellerCancelBookingApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/bookings/booking_cancelled_by_seller"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage });
  };

  handleOpentrackingModal = (value: string, booking: any) => {
    this.setState({ selectedBooking: booking });
    switch (value) {
      case "Confirm Booking":
        this.setState({
          approvalModal: true
        })
        break;
      case "Authorize co code":
        this.TrackModel(booking);
        break;
      case "Issue Booking no":
        this.TrackModel(booking);
        break;
      case "Upload Draft B/L":
        this.TrackModel(booking);
        break;
      case "Upload Invoice":
        this.setState({
          paymentconfiguration: true,
        });
        break;
      case "Download Final b/l":
        this.setState({
          sucessOpenmodal: false,
        });
        break;
      case "Upload Final b/l":
        this.setState({
          finalBL: true,
        });
        break;
    }
  };

  handleApprovalModal = () => {
    this.setState({
      approvalModal: true,
      trackingModal: false,
    })
  }

  handleNominationmodal = () => {
    if (this.state.activeState == 0) {
      this.setState({
        nominationModal: true,
        trackingModal: false,
      });
    }
    if (this.state.activeState == 1) {
      this.setState({
        BookingrefrenceModal: true,
        trackingModal: false,
      });
    }
    if (this.state.activeState == 2) {
      this.setState({
        DraftBLstate: true,
        trackingModal: false,
      });
    }
  };

  handleClose = (Key: string) => {
    switch (Key) {
      case "approvalModal":
        this.setState({
          approvalModal: false,
        });
        break;
      case "nominationModal":
        this.setState({
          nominationModal: false,
        });
        break;
      case "BookingrefrenceModal":
        this.setState({
          BookingrefrenceModal: false,
        });
        break;
      case "submittingState":
        this.setState({
          submittingState: false,
        });
        break;
      case "trackingModal":
        this.setState({
          trackingModal: false,
          cancelBooking: false,
        });
        break;
      case "Nomination Successful!":
        this.setState({
          sucessOpenmodal: false,
        });
        break;
      case "Booking Reference No. Issued!":
        this.setState({
          BookingrefrenceSuccess: false,
        });
        break;
      case "Authorization Completed!":
        this.setState({
          authorizationModal: false,
        });
        break;
      case "Submission Successful!":
        this.setState({
          verifySucess: false,
          finalBLsuccess: false,
        });
        break;
      case "Invoice Sent!":
        this.setState({
          paymentSucess: false,
        });
        break;
      case "DraftBLstate":
        this.setState({
          DraftBLstate: false,
        });
        break;
      case "paymentconfiguration":
        this.setState({
          paymentconfiguration: false,
        });
        break;
      case "finalBL":
        this.setState({
          finalBL: false,
        });
        break;
    }
  };

  StepFile = () => {
    let active = this.state.activeState;
    return active;
  };

  renderButtonName = (booking: any) => {
    if (!booking.attributes.co_authorizer) {
      this.setState({ activeState: 0 });
    }
    if (booking.attributes.co_authorizer) {
      this.setState({ activeState: 1 });
    }
    if (booking.attributes.booking_reference_number) {
      this.setState({ activeState: 2 });
    }
    if (!booking.attributes.draft_bl_status&&booking.attributes.draft_bl_status!=null) {
      this.setState({ activeState: 2 });
     }
    
    if (booking.attributes.draft_bl_status) {
      this.setState({ activeState: 3 });
    }
    if (booking.attributes.payment_status) {
      this.setState({ activeState: 4 });
    }
    
    if (booking.attributes.final_bl_status) {
      this.setState({ activeState: 5 });
    }
  };

   
  CheckAtuDate(booking:any){

    let status = true;
     
    const date = moment(booking.attributes.load_time);

  const today = moment().startOf('day');


 const isGreaterThanToday = date.isAfter(today);


 if(isGreaterThanToday){

  status =false;
}


return status

  }

  paymentStatus(booking:any){

    return booking.attributes.payment_status ? false : true
  }


  DraftBLstatus=(booking:any)=>{
  return booking.attributes?.draft_file_status ? true:this.DraftBtn(booking)  
 }

 DraftBtn =(booking:any)=>{
   return booking.attributes.submit_si_status ? false :true
 }

 InvoiceStatus=(booking:any)=>{

  return booking?.attributes?.invoice_status ? true : this.InvoiceBtn(booking)
 }

  Co_Auth(booking:any){
    return booking.attributes.is_nominated ? false : true
  }

  InvoiceBtn=(booking:any)=>{
    return booking.attributes.draft_bl_status ? false :true
}

  TrackModel = (booking: any) => {
    this.renderButtonName(booking);
    this.setState({ trackingModal: true});
  };

  TrackModelViewUpdate=()=>{
    this.setState({ trackingModal: true});
  }

  handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({nominationCheckbox: event.target.checked });
  };

  handleChangeRowsPerPage = (event: any) => {
    let rpp = +event.target.value;
    this.setState({ rowsPerPage: rpp, page: 0 });
  };

  SubmitRefrenceNumber = () => { this.issueBookingReferenceNumber(); };

  handlePopupState = (e: any) => {
    let target = e.currentTarget;
    e.stopPropagation();
    this.setState({ anchorEl: target, popup: !this.state.popup });
  };

  handleNominationsucess = () => {
    if (this.state.nominationCheckbox) {
      this.setState({ sucessOpenmodal: true, nominationModal: false});
    }
  };

  handleTabChange = (event: any, newValue: any) => {
    this.setState({ currentTab: newValue });
  };

  // Upload draft B/L

  Authorizationsucess = () => {
    this.coAuthorize();
  };

  approvalConfirmation = () => {
    this.approveBooking();
  };

  askForCancellationReason = () => {
    this.setState({
      approvalModal: false,
      approvalReasonModal: true,
    })
  };

  approvalCancellation = () => {
    if(this.state.reasonText && this.state.reasonText !== ""){
      this.cancelBooking();
    }
  }

  closeApprovalConfirmedModal = () => {
    this.setState({
      approvalConfirmationModal: false, reasonText: "",
    })
  }

  closeApprovalCancelledModal = () => {
    this.setState({
      approvalCancelledModal: false
    });
  }

  closeApprovalReasonModal = () => {
    this.setState({
      approvalReasonModal: false,
      approvalModal: true,
    });
  }

  handleReasonFieldChange = (e : any)=>{
    this.setState({
      reasonText: e.target.value
    })
  }

  handleSubmitsucess = () => { this.sellerUploadInvoice(); };

  handleFinalBLsucess = () => { this.sellerFinalBl(); };

  handleVerifysucess = () => { this.uploadDraftBl(); };

  handleClickAway = (e: any,booking:any) => {
    
      this.setState({anchorEl: e.currentTarget})
      this.setState({ popup: !this.state.popup}); 
      this.setState({ selectedBooking: booking })
      this.renderButtonName(booking);
  };

  SwitchChnages = () => { this.onSellerListingSwitchRole() };

  OnSellerListingSwitch = (apiRequestCallId: string, responseJson: { message: string }) => {
    if (apiRequestCallId === this.onSellerListingSwitchRoleAPICallID) {
      if (!responseJson.message) {  this.setState({ sellerListingSnackbarOpen: true }) }
       else { setStorageData("UserRole", "buyer"); this.props.navigation.navigate("BuyersRequest")}
    }
  }

  onSellerListingSwitchRole = async () => {

    let authToken = await getStorageData("authToken");

    const header = {
      "Content-Type": "application/json", token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.onSellerListingSwitchRoleAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/switch_role`
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


   VerifyBL = async () => {

    let LocationToken = await getStorageData("authToken");
    const header = {
      token: LocationToken, "Content-Type": "application/json", };
    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      booking: { id: this.state.selectedBooking.id,  booking_status: "accepted"  },
    };

    this.verifyBLAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_listdemandpickflow/bookings/booking_cancelled_by_seller"
    );

    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),  JSON.stringify(header)
    );

    requestMessage.addData(  getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleRevisionsucess = () => {
    this.setState({revisionSucess: true, revisionrequest: false});
  };

  handleCancelbooking = () => {
      this.setState({cancelBooking: true})
  };

  Cancelbooking = () => { this.sellerCancelBooking() };

  handleNocancel = () => {
    this.setState({ cancelBooking: false});
  };

  DeletePdf = (docType: string) => {
    if (docType === "draftBLDoc") {
      this.setState({
        draftBLDoc: [],
        FileName: "",
      });
    }
    if (docType === "invoiceDoc") {
      this.setState({
        invoiceDoc: [],
        invoiceDocFileName: "",
      });
    }
    if (docType === "finalBLDoc") {
      this.setState({
        finalBLDoc: [],
        finalDocFileName: "",
      });
    }
  };

  UploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    docType: string
  ) => {
    const selectedFile = e.target.files && e.target.files[0];
    const { files } = e.target;
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) {
        toast.error(`you're cant upload file bigger than 10 Mb`);
      } else {this.UploadFileElse(e, docType); }
    }
  };
 setDocType= (docType: string, fileName: string) => {
  if (docType === "invoiceDoc") {
    this.setState({invoiceDocFileName: fileName });
  }
  if (docType === "draftBLDoc") {
    this.setState({FileName: fileName});
  }
  if (docType === "finalBLDoc") {
    this.setState({finalDocFileName: fileName});
  }
 
 }
 setBlobFile = (docType: string, data: File[],Urls: string) =>{
  if (docType === "invoiceDoc") {
    this.setState({invoiceDoc: data,invoiceBlob: Urls});
  }
  if (docType === "draftBLDoc") {
    this.setState({draftBLDoc: data, draftBLDocBlob: Urls});
  }
  if (docType === "finalBLDoc") {
    this.setState({ finalBLDoc: data, finalDocBlob: Urls});
  }
 }
  UploadFileElse = (
    e: React.ChangeEvent<HTMLInputElement>,
    docType: string
  ) => {
    const selectedFile = e.target.files && e.target.files[0];
    const { files } = e.target;
    const lastIndex = selectedFile?.name.lastIndexOf(".");
    let FileType = "";
    if (files) {
      if (lastIndex) {
        const AfterSeprator = selectedFile?.name.substring(lastIndex + 1); FileType = AfterSeprator as string;
      }
      if (["pdf"].includes(FileType.toLowerCase())) {
        const file: File = files[0];
        const fileName: string = file.name;
        const fileExtension: string | undefined = fileName.split(".").pop();
        let Urls = URL.createObjectURL(files[0]);
        this.setDocType(docType,fileName)
        if (fileExtension) {
          let data = [];
          data.push(file);
          this.setBlobFile(docType, data, Urls)
        }
      } else {
        toast.error(`You can't upload ${FileType} File Here`);
      }
    }
  };
  handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ DragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ DragActive: false });
    }
  };
  handleDropFile = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      if (files[0].size > 10 * 1024 * 1024) { toast.error(`you're cant upload file bigger than 10 Mb`);
      } else { this.DragElse(e); }
    }
  };

  DragElse = (e: React.DragEvent<HTMLElement>) => {
    const selectedFile = e.dataTransfer.files && e.dataTransfer.files[0];
    const { files } = e.dataTransfer;
    const lastIndexString = selectedFile?.name.lastIndexOf(".");
    let FileType = "";
    if (files) {
      if (lastIndexString) {
        const AfterSeprator = selectedFile?.name.substring(lastIndexString + 1);
        FileType = AfterSeprator;
      }
      if (["pdf"].includes(FileType.toLowerCase())) {
        const filePdf: File = files[0];
        const fileName: string = filePdf.name;
        const fileExtension: string | undefined = fileName.split(".").pop();
        let Urls = URL.createObjectURL(files[0]);
        this.setState({
          FileName: fileName,
        });
        if (fileExtension) {
          let data = [];
          data.push(filePdf);
          this.setState({
            draftBLDoc: data,
            draftBLDocBlob: Urls,
            DragActive: false,
          });
        }
      } else {
        toast.error(`You can't upload ${FileType} File Here`);
      }
    }
  };

  applyFilter = async () => {
    const token = await getStorageData("authToken");
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const reqUidData = this.state.filter.bookingID?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      }, []
    );   

    const polsData = this.state.filter.pol?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;  },
      []  );

    const podsData = this.state.filter.pod?.reduce(  (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );
    const typesData = this.state.filter.vessel?.reduce(
      (acc: string[], item: { label: string; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.label);
        return acc;
      },
      []
    );

    const typesDataId = this.state.filter.vessel?.reduce(
      (acc: number[], item: { label: string; id?: number; isChecked: boolean }) => {
        if (item.isChecked) acc.push(item.id || 0);
        return acc;
      },
      []
    );

    const principalOrganisation = this.state.activeSlotFilter?.map((elem:any)=> elem?.attributes?.name);

    const requestMessage = new Message(  getName(MessageEnum.RestAPIRequestMessage)  );
    this.applyFilterApiCallID = requestMessage.messageId;
    const queryParams = {
      booking_ids: reqUidData, pods: podsData, pols: polsData,
      etb_pol: this.state.StartDate,
      etb_pod: this.state.EndDate, 
      vessels: typesData,
      vessel_voyage_ids: typesDataId,
    };
    
    const formattedParams = Object.fromEntries(

      Object.entries(queryParams).map(([key, value]) => {
        return [
          key,
          value,
        ] }
      ) );
      const principalOrganizationParam = principalOrganisation.length > 0 ? `principal_organization_names=${JSON.stringify(principalOrganisation)}` : '';
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_listdemandpickflow/bookings/apply_booking_filters?booking_ids=${JSON.stringify(formattedParams.booking_ids)}
      &pols=${JSON.stringify(formattedParams.pols)}&pods=${JSON.stringify(formattedParams.pods)}&etb_pol=${queryParams.etb_pol}
      &eta_pod=${queryParams.etb_pod}&${principalOrganizationParam}&vessels=${JSON.stringify(formattedParams.vessels)}
      &vessel_ids=${JSON.stringify(formattedParams.vessel_voyage_ids)}`
    );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),"GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => { 
    const hasActiveFilter = this.state.activeSlotFilter.find((slot: any) => slot.attributes.code === principalOrg.attributes.code && slot.attributes.name === principalOrg.attributes.name)
    if (isChecked && !hasActiveFilter) {
      this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
      setTimeout(()=> { this.applyFilter();  },1000)
      return
    }
    this.setState(prevState => ({ activeSlotFilter: prevState.activeSlotFilter.filter((item: any) => item.attributes.code !== principalOrg.attributes.code && item.attributes.name !== principalOrg.attributes.name) }));
    setTimeout(()=> { this.applyFilter();},1000)
  }

  handleOfferExpanded = (val: string) => {
    if (val === "bookingID") { this.setState({ offerExpanded: this.state.filter.bookingID?.length > 0 }); }
    if (val === "pol") { this.setState({ offerExpandedPol: this.state.filter.pol?.length > 0 }); }
    if (val === "pod") { this.setState({ offerExpandedPod: this.state.filter.pod?.length > 0 });  }
    if (val === "vessel") { this.setState({ offerExpandedtype: this.state.filter.vessel?.length > 0 }); }
  };

  handleOfferChange = (
    event: any, values: Array<{ label: string; isChecked: boolean }>,
    arrName: string
  ) => {

    const { name, checked } = event.target;

    const updatedOptions = Array.isArray(values) ? values?.map(
      (item: { label: string; isChecked: boolean;id?:number }) => {
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    ) : values;

    if (arrName === "bookingID") {
      let temp = { ...this.state.filter, bookingID: updatedOptions };

      this.setState({ filter: temp }, ()=> { this.applyFilter(); });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };

      this.setState({ filter: temp }, ()=> {this.applyFilter();}); }

    if (arrName === "pod") {

      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, ()=> { this.applyFilter();});
    }
    if (arrName === "vessel") {
      let temp = { ...this.state.filter, vessel: updatedOptions };
      this.setState({ filter: temp }, ()=> { this.applyFilter();});
    }
  };

  handleOfferDelete = ( option: {  label: string; isChecked: boolean;}, values: Array<{ label: string; isChecked: boolean }>, arrName: string
  ) => {
    const updatedOptions = values?.map((item: any) => { return item.label === option.label ? { ...item, isChecked: false } : item;
    });
    if (arrName === "pod") {
      let temp = { ...this.state.filter, pod: updatedOptions };
      this.setState({ filter: temp }, ()=> { this.applyFilter();  });
    }
    if (arrName === "vessel") {
      let temp = { ...this.state.filter, vessel: updatedOptions };
      this.setState({ filter: temp }, ()=> { this.applyFilter();
      });
    }
    if (arrName === "bookingID") {
      let temp = { ...this.state.filter, bookingID: updatedOptions };
      this.setState({ filter: temp }, ()=> {
        this.applyFilter();
      });
    }
    if (arrName === "pol") {
      let temp = { ...this.state.filter, pol: updatedOptions };
      this.setState({ filter: temp }, ()=> {  this.applyFilter();
      });
    }
  };

  TogolFilter = () => {
    this.setState({
      open: !this.state.open
    })
  }

  SetDateFilterData = (range: any) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MMM-YYYY"), RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: moment(range.startDate).format(), EndDate: moment(range.endDate).format(),DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      filter: { ...this.state.filter, startDate: moment(range.startDate).format("YYYY-MM-DD"), endDate: moment(range.endDate).format("YYYY-MM-DD") }})
  }

  InvoiceClass=()=>{
    return (this.state.sellerTerm == null || this.state.sellerTerm?.length=== 0) ? "disbledBtn" : "nominationButton"
    }
  
    InvoiceInput=()=>{
      return (this.state.sellerTerm == null || this.state.sellerTerm?.length=== 0) ? true:false
    }

  FilterDateSelect = () => {
    const { StartDate, EndDate } = this.state
    if (StartDate === "") { toast.warning("Select start date")
    } else if (EndDate === "") { toast.warning("Select end date")
    } else { this.applyFilter() }
  }



  ClearFilterData = () => {
    this.setState({
      filter: { ...this.state.filter, startDate: "", endDate: "" }, DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),open: false, RenderStart: "", RenderEndDate: "", StartDate: "", EndDate: "",
    }, () => { this.applyFilter() })
  }

  DynamicDataSet = (e: { listing?: string, requst?: string, offers?: string, booking?: string }) => { this.setState({ DynamicCount: e }) }

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
