// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
import moment from 'moment';
import { DateRange } from "materialui-daterange-picker";
import { toast } from "react-toastify";

export const configJSON = require("./config");

interface Status {
  pending: number;
  rejected: number;
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;

  // Customizable Area End
}




// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  currentTab: number;
  anchorEl: any;
  buyerOffer:any;
  offerType:any;
  isBookButtonDisabled: any,
  page: number;
  rowsPerPage: number;
  popup: boolean;
  buyerPrinicpalOrgList:any;
  profilePop: boolean;
  rows: any;
  ModelData:any;
  switch: boolean;
  buyerExpanded:boolean
  show: boolean;
  showGuideVedios: boolean;
  RejectModal: boolean;
  SuccessModal: boolean;
  slotBooked:boolean;
  offerMsg:string;
  offerPendingData:string;
  offerRejectedData:string;
  buyerListings:any;
  buyerFilter: {
    listingID: Array<{ label: string; isChecked: boolean }>;
    pol: Array<{ label: string; isChecked: boolean }>;
    pod: Array<{ label: string; isChecked: boolean }>;
    type: Array<{ label: string; isChecked: boolean }>;
    startDate:string,
    endDate:string
  };
  buyerExpandedPol:boolean;
  buyerExpandedPod:boolean;
  buyerExpandedType:boolean;
  slotMessage:string;
  loading:boolean,
  reinitialisedForm:boolean;
  addBuyerListing:boolean;
  buyerListingAdded:boolean;
  buyerRequestSnackbarOpen: boolean,
  slotsData: {
    org: string;
    requestId: string;
    dateRange: string;
    pol1: string;
    pol2: string;
    carrier: string;
    pod1: string;
    pod2: string;
    type: string;
    codes: string;
    rate: string[];
    total: string;
    status: Status;
  }[];
  activeSlotFilter: any,
    isSlotFilterOpen: boolean,
    open: boolean,
    StartDate: string,
  EndDate: string,
  RenderStart: string,
  RenderEndDate: string,
  selectedOffer: any,
  DynamicCount: {
    listing?: string | number,
    requst?: string | number,
    offers?: string | number,
    booking?: string | number
  },
  DateRangeStartDate: Date | undefined,
  DateRangeEndDate: Date | undefined
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BuyersOfferController extends BlockComponent<
  Props,
  S,
  SS

>{

  getBuyerOfferApiCallID:string="";
  getBuyerOfferAcceptedApiCallID:string="";
  applyBuyerFilterApiCallID:string="";
  getBuyerListingDataApiCallID:string="";
  onRoleSwitchAPICallID:string="";
  getBuyerPrincipalOrganisationDataApiCallID:string="";

  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    (async () => {
      let buyerOfferLoginData = await getStorageData("UserRole");
      if(!buyerOfferLoginData){
        window.location.href = "/"
      } else if(buyerOfferLoginData == "seller"){
        window.location.href = "/SellerListing"
      }
    })()

    this.state = {
      StartDate: "",
      EndDate: "",
      RenderStart: "",
      RenderEndDate: "",
      open: false,
      activeSlotFilter: [],
    isSlotFilterOpen: false,
      currentTab: 1,
      anchorEl: null,
      SuccessModal: false,
      page: 0,
      slotMessage:'',
      popup: false,
      switch: true,
      rowsPerPage: 10,
      offerPendingData:'',
      offerRejectedData:'',
      showGuideVedios: false,
      profilePop: false,
      RejectModal: false,
      reinitialisedForm:false,
      buyerListingAdded:false,
      addBuyerListing: false,
      slotBooked:false,
      buyerRequestSnackbarOpen: false,
      offerMsg:'',
      offerType:'',
      isBookButtonDisabled: {},
      loading:true,
      slotsData: [],
      buyerListings:[],
      buyerPrinicpalOrgList:[],
      buyerOffer:[],
      ModelData:[],
      buyerFilter: {
        listingID: [],
        pol: [],
        pod: [],
        type: [],
        startDate:"",
        endDate:""
      },      
      buyerExpandedPol:false,
      buyerExpandedPod:false,
      buyerExpandedType:false,
      buyerExpanded:false,
      rows: [{
        org: "HMM",
        requestId: "30711",
        dateRange: "2 JUN - 2 SEP",
        pol1: "SGSIN",
        pol2: "TCN",
        carrier: "Arrow",
        pod1: "INMUN",
        pod2: "BCN",
        type: "EMPTY",
        codes: "MT,MH,RM",
        rate: ["20FT 100 $180/20FT CY/CY", "20FT 100 $180/20FT CY/CY"],
        total: "$36,000",
        status: {
          pending: 2,
          rejected: 1,
        },
      },
      ],
      show: false,
      selectedOffer: [],
      DynamicCount: {
        listing: "0",
        requst: "0",
        offers: "0",
        booking: "0"
      },
      DateRangeStartDate: new Date(),
      DateRangeEndDate: new Date()
    };
  }
 


  async componentDidMount() {
    let LocationToken = await getStorageData("authToken");
    super.componentDidMount();
    
  
    this.getBuyerOfferData(LocationToken)
    this.getBuyerListingData(LocationToken)
    this.getBuyerPrincipalOrganisation(LocationToken)
  }


  handleSnackbarClose = () => { this.setState({buyerRequestSnackbarOpen: false}) }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getBuyerOfferApiCallID:
        
      if(responseJson.length>0){
        this.setState({ buyerOffer: responseJson });
        this.onFilteredGetDataForListing(responseJson)
        this.offerResponse(responseJson)
         }
        break;
       
        case this.getBuyerOfferAcceptedApiCallID:
         
        this.offerData(responseJson)
        let LocationToken = await getStorageData("authToken");
        this.getBuyerListingData(LocationToken)
        
          case this.getBuyerListingDataApiCallID:
            if(responseJson?.length>0){
            this.setState({ buyerListings: responseJson });
            }

            break;
            case this.getBuyerPrincipalOrganisationDataApiCallID:
              this.setState({ buyerPrinicpalOrgList: responseJson?.data });
              break;

           case  this.applyBuyerFilterApiCallID:

            this.setState({buyerOffer:Array.isArray(responseJson) ?  responseJson : [],open:false});

    }

    this.onReceiveRoleData(apiRequestCallId, responseJson)
}
getBuyerPrincipalOrganisation = (token: string) => {
  const header = { "Content-Type": "application/json",
    token: token };
  const requestMessage = 
  new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.getBuyerPrincipalOrganisationDataApiCallID = 
  requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_profile/principal_organizations"
  );
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
  "GET");
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
DayMinus= (startdate:any,endDate:any)=>{
 const startMoment = moment(startdate);
  const endMoment = moment(endDate);
  const daysDifference = endMoment.diff(startMoment, 'days');
  const value = Math.max(daysDifference); 
  return Math.abs(value)
}


  
  handleClose = () => {
    this.setState({ 
      show: false,
       SuccessModal: false, 
       RejectModal: false, 
       anchorEl: null, 
       popup: false,
       slotBooked:false
       })
   
  }

  offerResponse= (responseJson:any) =>{
    this.setState({offerPendingData:responseJson?.[0]?.offer?.data?.[0]?.attributes?.pending_status_count});
        this.setState({offerRejectedData:responseJson?.[0]?.offer?.data?.[0]?.attributes?.rejected_status_count});
  }
  
  SwitchChnages = () => {
    this.onBuyerRequestRoleSwitch();
  }
  

  async offerData(responseJson:any){
    if(responseJson?.data&&responseJson?.data?.attributes){ let LocationToken = await getStorageData("authToken");
      this.getBuyerOfferData(LocationToken);this.setState({offerMsg:responseJson,buyerOffer:[],ModelData:[],anchorEl:null})
     if(responseJson.data.attributes.status=='accepted'){this.setState({SuccessModal:true})}
    if(responseJson.data.attributes.status=='rejected'){ this.setState({RejectModal:true}) }}
    if(responseJson?.message){this.setState({slotBooked:true,slotMessage:responseJson?.message})}
  }

   
  handlePopupState = (e: any, offer: any) => {

    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      popup: !this.state.popup,
      selectedOffer: offer,
      ModelData: offer
  });
  };


  handleChangeRowsPerPage = (event: any) => {
    let rpp = +event.target.value;
    this.setState({ rowsPerPage: rpp, page: 0 });
  };
  

  handleTabChange = (event: any, newValue: any) => {
    
    this.setState({ currentTab: newValue });
  };

  handleClickAway = (e: any) => {
   
    e.stopPropagation();
    if (this.state.anchorEl && !this.state.anchorEl.contains(e.target)) {
      this.setState({ popup: false });
  }
  };

  getBuyerOfferData = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyerOfferApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/make_an_offers/buyer_offer_list"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage });
  };

  getBuyerOfferAceeptReject = (offer: string,orderID:number) => {
       this.setState({offerType:offer, isBookButtonDisabled: {orderID,offer}})
      const token = localStorage.getItem('authToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    this.getBuyerOfferAcceptedApiCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/make_an_offers/accept_or_reject_offer"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    const data2 = {
      id: orderID,
      status: offer,
    };

    const data = {
      offer: data2,
    };

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

      try {
    runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState((prevState: any) => ({
          DynamicCount: {
            ...prevState.DynamicCount,
            requst: Number(prevState.DynamicCount.requst) - 1,
            offers: Number(prevState.DynamicCount.offers) - 1,
            booking: Number(prevState.DynamicCount.booking) + 1, 
          },
          isBookButtonDisabled: {},
        }));
     console.log(this.state.DynamicCount.booking,"books")
    } catch (error) {
      console.error("Error in API call:", error);
    }
  
    setTimeout(() => {
      this.setState({ isBookButtonDisabled: {} });
    }, 1000);
  };



  
  handleBuyerOfferChangeMethod = (
    event: any,
    values: Array<{ label: string; isChecked: boolean }>,
    
    arrName: string
  ) => {
    const { name, checked } = event.target;
    
    const updatedSelectOptionsData = values?.map(
      (item: { label: string; isChecked: boolean }) => {
    
        return item.label === name ? { ...item, isChecked: checked } : item;
      }
    );
    
    if (arrName === "pol") {
      let temp = { ...this.state.buyerFilter, pol: updatedSelectOptionsData };
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }

    if (arrName === "listingID") {
      let temp = { ...this.state.buyerFilter, listingID: updatedSelectOptionsData };
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }
    
    if (arrName === "type") {
      let temp = { ...this.state.buyerFilter, type: updatedSelectOptionsData };
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }

    if (arrName === "pod") {
      let temp = { ...this.state.buyerFilter, pod: updatedSelectOptionsData };
      this.setState({ buyerFilter: temp }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }
    
  };


  onReceiveRoleData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.onRoleSwitchAPICallID) {
      if(!responseJson.message) {
        this.setState({buyerRequestSnackbarOpen: true});
      } 
      else{
        setStorageData("UserRole", "seller")
        this.props.navigation.navigate("SellerListing");
      }}
    }

  handleBuyerOfferDeleteMethod = (
    option: any,
    valuesData: Array<{ label: string; isChecked: boolean }>, arrNameValue: string
  ) => {
    const updatedSelectOptionsAfterDelete = valuesData?.map((item: any) => {
    
      return item.label === option.label ? { ...item, isChecked: false } : item;
    });

    if (arrNameValue === "pol") {
      let tempData = { ...this.state.buyerFilter, pol: updatedSelectOptionsAfterDelete };
      this.setState({ buyerFilter: tempData }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }
    
    if (arrNameValue === "listingID") {
      let tempData = { ...this.state.buyerFilter, listingID: updatedSelectOptionsAfterDelete };
      this.setState({ buyerFilter: tempData }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }
    
    if (arrNameValue === "type") {
      let tempData = { ...this.state.buyerFilter, type: updatedSelectOptionsAfterDelete };
      this.setState({ buyerFilter: tempData }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }

    if (arrNameValue === "pod") {
      let tempData = { ...this.state.buyerFilter, pod: updatedSelectOptionsAfterDelete };
      this.setState({ buyerFilter: tempData }, ()=> {
        this.applyBuyerFilterMethod();
      });
    }
    
   
  };
  SetDateFilterData = (range: DateRange) => {
    this.setState({
      RenderStart: moment(range.startDate).format("DD-MMM-YYYY"), 
      RenderEndDate: moment(range.endDate).format("DD-MMM-YYYY"),
      StartDate: 
      moment(range.startDate).format(), DateRangeStartDate:range.startDate,DateRangeEndDate:range.endDate,
      EndDate: moment(range.endDate).format(),
      buyerFilter: 
      { ...this.state.buyerFilter, 
        startDate: moment(range.startDate).format("YYYY-MM-DD"), 
        endDate: moment(range.endDate).format("YYYY-MM-DD")
       },
    })
  }

  FilterDateSelect = () => {
    const { StartDate, EndDate } 
    = this.state
    if (StartDate === "") 
    {
      toast.warning("Select start date")
    } 
    else if (EndDate === "") 
    {
      toast.warning("Select end date")
    }
     else {
      this.applyBuyerFilterMethod()
    }
  }

  ClearFilterData = () => {
    this.setState({
      buyerFilter: { ...this.state.buyerFilter, startDate: "", endDate: "" },
      open: false,
      RenderStart: "", RenderEndDate: "",
      StartDate: "", EndDate: "",DateRangeStartDate:new Date(),
      DateRangeEndDate:new Date(),
    }, () => {
      this.applyBuyerFilterMethod()
    })
  }
  TogolFilter = () => {
    this.setState({
      open: !this.state.open,
    });
  }

  handleSlotFilter = (principalOrg: any, isChecked: boolean) => {
    const hasActiveFilter = this.state.activeSlotFilter.find((slot: any) => slot.attributes.code === principalOrg.attributes.code && slot.attributes.name === principalOrg.attributes.name)
    if (isChecked && !hasActiveFilter) {
      this.setState(prevState => ({ activeSlotFilter: [...prevState.activeSlotFilter, principalOrg] }))
      setTimeout(()=> {
        this.applyBuyerFilterMethod()
      },1000)
      return
    }
    this.setState(prevState => ({ activeSlotFilter: prevState.activeSlotFilter.filter((item: any) => item.attributes.code !== principalOrg.attributes.code && item.attributes.name !== principalOrg.attributes.name) }))
    setTimeout(()=> {
      this.applyBuyerFilterMethod()
    },1000)
  }

  onFilteredGetDataForListing = (responseJsonValue : any) => {
    let tempValue = {
      listingID: responseJsonValue?.map(
        (val: any) => val.offer.data.length > 0 && val.request.data.attributes.request_uid
      ),
    
      pol: responseJsonValue?.map((val: any) => val.offer.data.length > 0 && val.request.data.attributes.pol),
    
      pod: responseJsonValue?.map((val: any) => val.offer.data.length > 0 && val.request.data.attributes.pod),
    
      type: responseJsonValue?.map(
        (val: any) => val.offer.data.length > 0 && val?.container?.container_type 
      ),
      startDate: responseJsonValue?.map(
        (val: any) =>  val.request.data.attributes.container_loading_range_start
      ),
      endDate: responseJsonValue?.map(
        (val: any) =>  val.request.data.attributes.container_loading_range_end
      ),
    };
    const tempData = tempValue.listingID.filter((value:string|boolean)=>value !== false)
    const tempDataPol = tempValue.pol.filter((value:string|boolean)=>value !== false)
    const tempDataPod = tempValue.pod.filter((value:string|boolean)=>value !== false)
    const tempDataType = tempValue.type.filter((value:string|boolean)=>value !== false)
    let d1 = [...new Set(tempData)]
    
    let d2 = [...new Set(tempDataPol)]
    
    let d3 = [...new Set(tempDataPod)]
    
    let d4 = [...new Set(tempDataType)]
    let arrayData = {
      listingID: d1?.map((str: any) => ({
        label: str,
        isChecked: false,
      })),
    
      pol: d2?.map((strData: any) => ({ label: strData, isChecked: false })),
    
      pod: d3?.map((strData: any) => ({ label: strData, isChecked: false })),
    
      type: d4?.map((strData: any) => ({
    
        label: strData,
    
        isChecked: false,
      })),
      startDate: "",
      endDate: "",
    };
   
    this.setState({
    
      buyerFilter: arrayData,
    });
  }



  getBuyerListingData = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };


    const requestMessageData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBuyerListingDataApiCallID = requestMessageData.messageId;

    
    
    requestMessageData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_listdemandpickflow/pending_requests"
    );

    
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    
    requestMessageData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageData.id, requestMessageData);
};
  

handleBuyerOfferExpandedMethod = (val:string) => {
  
  if(val === "pol"){
    this.setState({ buyerExpandedPol: this.state.buyerFilter.pol?.length > 0 });
  }
  
  if(val === "listingID"){
    this.setState({ buyerExpanded: this.state.buyerFilter.listingID?.length > 0 });
  }
 
  if(val === "type"){
    this.setState({ buyerExpandedType: this.state.buyerFilter.type?.length > 0 });
  }

  if(val === "pod"){
    this.setState({ buyerExpandedPod: this.state.buyerFilter.pod?.length > 0 });
  }
 
};



applyBuyerFilterMethod = async () => {
  
  const tokenData = await getStorageData("authToken");
  
  const header = {
    "Content-Type": "application/json",
    token: tokenData,
  };

  

  const polsData = this.state.buyerFilter.pol
  ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
    if (item.isChecked) acc.push(item.label);
    return acc;
  }, []);

  
  const reqUidData = this.state.buyerFilter.listingID
  ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
    if (item.isChecked) acc.push(item.label);
    return acc;
  }, []);

  const typesData = this.state.buyerFilter.type
  ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
    if (item.isChecked) acc.push(item.label);
    return acc;
  }, []);



  const podsData = this.state.buyerFilter.pod
    ?.reduce((acc: string[], item: { label: string; isChecked: boolean }) => {
      if (item.isChecked) acc.push(item.label);
      return acc;
    }, []);

    const principalOrganisation = this.state.activeSlotFilter?.map((elem:any)=> elem?.attributes?.name)
  


  const requestMessageData = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );


  this.applyBuyerFilterApiCallID = requestMessageData.messageId;


  requestMessageData.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_listdemandpickflow/make_an_offers/apply_buyer_offer_filters?request_ids=${JSON.stringify(reqUidData)}&pols=${JSON.stringify(polsData)}
    &pods=${JSON.stringify(podsData)}&container_types=${JSON.stringify(typesData)}&${principalOrganisation.length > 0 ? `principal_organization_names=${JSON.stringify(principalOrganisation)}`: ``}
    &container_loading_range_end=${this.state.buyerFilter.endDate}
    &container_loading_range_start=${this.state.buyerFilter.startDate}` 
  );

  
  requestMessageData.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  
  requestMessageData.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );


  runEngine.sendMessage(requestMessageData.id, requestMessageData);
};


onBuyerRequestRoleSwitch = async() => {
  let authTokenData = await getStorageData("authToken");
  const header = {
    "Content-Type": "application/json",
    token: authTokenData,
  }; 

  const requestMessageVal = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.onRoleSwitchAPICallID = requestMessageVal.messageId;
  requestMessageVal.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/account_block/accounts/switch_role`
  );

  requestMessageVal.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessageVal.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  runEngine.sendMessage(requestMessageVal.id, requestMessageVal);
}
DynamicDataSet = (e: { listing?: string, requst?: string, offers?: string, booking?: string }) => { this.setState({ DynamicCount: e },
)}
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End