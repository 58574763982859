import React from "react";

// Customizable Area Start
import {
  Box,
  InputAdornment,
  IconButton,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import GenericCard from "../../../components/src/GenericCard.web";
import AppBackground from "../../../components/src/AppBackground.web";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ProjectText from "../../../components/src/ProjectText";
import { Formik } from 'formik';
import * as Yup from "yup";
import EmailAccountLoginController from "./EmailAccountLoginController.web";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import { ContainedButton } from "../../../components/src/GlobalButton";
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import { eyeoff, eyeopen, YellowIcon } from "./assets";

// Customizable Area End


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  GetInTouch = () => {
    const PasswordRegexTwoUpperCase = /^(?:[^A-Z]*[A-Z]){2,}[^A-Z]*$/;
    const PasswordRegexTwoLowerCase = /^(?:[^a-z]*[a-z]){2,}[^a-z]*$/;
    const PasswordRegexTwoSpecialCharacters = /^(?:[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*[!@#$%^&*()_+\-={}[\]:;<>,.?/~]){2,}[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*$/;
    const PasswordRegexTwoNumbers = /^(?:[^\d]*\d){2,}[^\d]*$/;
    return Yup.object().shape({
      email: Yup.string().email("Email address looks incorrect!").required("Email is required"),
      password: Yup.string().nullable().required('Password is required')
        .matches(PasswordRegexTwoUpperCase, 'Password is incorrect!')
        .matches(PasswordRegexTwoLowerCase, 'Password is incorrect!')
        .matches(PasswordRegexTwoSpecialCharacters, 'Password is incorrect!')
        .matches(PasswordRegexTwoNumbers, 'Password is incorrect!')
    })
  };
  // Customizable Area Start
  renderInput = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: null,
        }}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={this.GetInTouch}
        onSubmit={(values) => {
          this.doEmailLogIn(values)
        }}
      >
        {({ handleSubmit, setFieldValue, touched, errors, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                <InputLabel htmlFor="email">
                  <ProjectText Upercase variant="subtitle1" 
                  TextSize="14px" 
                  fontweight="900" 
                  textcolor="black">
                    EMAIL ADDRESS
                  </ProjectText>
                </InputLabel>
                <Box>
                  <LandingPageTextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    className="LoginInputfield"
                    name="email"
                    BoderRadius="8px"
                    inputProps={{
                      "data-test-id": "email"
                    }}
                    value={values.email}
                    FormError={errors.email !== undefined && touched.email !== undefined}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      this.SetFieldSVlaues(e, "email", setFieldValue)
                    }}
                  />
                  {errors.email !== undefined && touched.email !== undefined &&
                    <Box className="ErrorMessageContainer marginUp">
                      <ErrorIcon />
                      <ProjectText type="error" fontweight="500" TextSize="1rem">
                        {errors.email}
                      </ProjectText>
                    </Box>
                  }
                </Box>
              </Grid>
              <Grid item xs={12} md={8} 
              className="RemoveBottomPadding">
                <InputLabel htmlFor="password">
                  <ProjectText Upercase variant="subtitle1" 
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black">
                    PASSWORD
                  </ProjectText>
                </InputLabel>
                <LandingPageTextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  id="password"
                  BoderRadius="8px"
                  value={values.password ?? ""}
                  FormError={errors.password !== undefined && touched.password !== undefined}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    this.SetFieldSVlaues(e , "password", setFieldValue)
                  }}
                  className={this.state.enablePasswordField ? "" : "LoginInputfield"}
                  type={this.state.enablePasswordField ? 'text' : 'password'}
                  inputProps={{ "data-test-id": "password" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          data-test-id="passwordshow"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                        >
                          {this.state.enablePasswordField ? (
                            <div>
                              <img
                                src={eyeopen}
                                alt="off"
                                className="Icon"
                              />
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <img
                                src={eyeoff}
                                alt="on"
                                className="Icon"
                              />
                            </div>
                          )}

                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8} className="ForgotPasswordTextContainer">
                <Box className="textanderrormessage">
                  <Box>
                    {touched.password && errors.password !== undefined &&
                      <Box className="ErrorMessageContainer">
                        <ErrorIcon />
                        <ProjectText type="error" noWrap fontweight="500" TextSize="1rem">
                          {errors.password}
                        </ProjectText>
                      </Box>
                    }
                  </Box>
                  <Box
                    data-test-id="btnForgotPassword"
                    onClick={this.goToForgotPasswordBlog}
                  >
                    <ProjectText className="forgotpasswordtext" textcolor="#1653DD" align="right" TextSize=".875rem" fontweight="700">
                      FORGOT PASSWORD?
                    </ProjectText>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Box className="loginBtnContainer">
                  <ContainedButton fullWidth type="submit" data-test-id="SubmitBtn" >
                    Login
                  </ContainedButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    )
  }
  renderInputButton = () => {
    return (
      <Grid container justifyContent="center" >
        <Grid item xs={12}>
          <ProjectText className="LoginTitle"
           variant="h4"
            fontweight="700"
             space="both"
              TextSize="28px"
               align="center">
            {this.labelTitle}
          </ProjectText>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            {this.renderInput()}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  renderModal = () => {
    return (
      <Box>
        <Box className="CloseIcons" onClick={this.NavigateToLandingPage}>
          <CancelIcon />
        </Box>
        <Box className="ErrorIcon">
          <img src={YellowIcon} alt="YellowIcon" />
        </Box>
        <ProjectText variant="h5" fontweight="700" style={{ textAlign: "center" }}>
          Account Suspended!
        </ProjectText>
        <ProjectText style={{ textAlign: "center" }}>
          Account suspended. Contact PSA Admin for help.
        </ProjectText>
      </Box>
    )
  }
  OrganizationModal = () => {
    return (
      <Box>
        <Box className="CloseIcons" onClick={this.NavigateToLandingPage}>
          <CancelIcon />
        </Box>
        <Box className="ErrorIcon">
          <img src={YellowIcon} alt="yellow" />
        </Box>
        <ProjectText variant="h5" fontweight="700" style={{ textAlign: "center" }}>
          Organisation Suspended!
        </ProjectText>
        <ProjectText style={{ textAlign: "center" }}>
          Account suspended. Contact PSA Admin for help.
        </ProjectText>
      </Box>
    )
  }

  renderorgstate = () => {
    return this.state.organizationSuspended ? this.OrganizationModal() : this.renderInputButton()
  }
  ApiErrorData = () => {
    if (this.state.APIResponseError) {

      return (
        <Grid container justifyContent="center" className="ExtraPadding">
          <Grid item xs={12}>
            {this.state.accountSuspended ? this.renderModal() : this.renderorgstate()}
          </Grid>
        </Grid>
      )
    } else {
      return this.renderInputButton()
    }
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks

      <AppBackground>
        <Grid container justifyContent="center" className="GridContainerStyle" alignItems="center">
          <Grid item xs={10} sm={10} md={8} lg={6}>
            <GenericCard>
              <LoginPageStyle data-test-id="emailaccount">
                {this.ApiErrorData()}
              </LoginPageStyle>
            </GenericCard>
          </Grid>
        </Grid>
      </AppBackground>
      // Customizable Area End
    );
  }
}
const LoginPageStyle = styled("div")({
  "& .LoginInputfield": {
    height: '56px',
    "@media (max-width: 1200px)": {
      height: '56px !important'
    },
  },
  "& .LoginTitle": {
    paddingBottom: '25px',
    "@media (max-width: 1280px)": {
      fontSize: '25px'
    },
  },
  "& .errorColor": {
    color: "DB2525"
  },
  "& .ExtraPadding": {
    padding: "20px"
  },
  "& .RemoveBottomPadding": {
    paddingBottom: "5px"
  },
  "& .loginBtnContainer": {
    display: "flex",
    justifyContent: "center",
    padding: '8px 100px',
    "@media (max-width: 960px)": {
      padding: '8px 30px'
    },
  },
  "& .PasswordTextField": {
    "& input": {
      fontFamily: 'monospace',
      fontSize: "xx-large",
      height: "18px"
    }
  },

  "& .ForgotPasswordTextContainer": {
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .textanderrormessage": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
  },
  "& .forgotpasswordtext": {
    cursor: "pointer",
    paddingTop: '5px',
    "@media (max-width: 500px)": {
      fontSize: '12px'
    },
  },
  "& .marginUp": {
    marginTop: ".3125rem",
    marginBottom: ".3125rem"
  },
  "& .ErrorMessageContainer": {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: "red",
      marginRight: "5px"
    }
  },
  "& .ErrorIcon": {
    display: "flex",
    justifyContent: "center"
  },
  "& .Icon": {
    height: "1.4rem",
  },
  "& .CloseIcons": {
    position: "absolute",
    top: "10px",
    right: "10px"
  }
})
// Customizable Area End