import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Navbar from "../../../components/src/NavBar.web";
import {
    styled, 
    Box, 
    Tab, 
    Tabs, 
    FormControl,
    InputBase,
    InputLabel,
    withStyles,
    Dialog,
    Button, 
    Container, 
    Grid, 
    Paper,
    Snackbar, 
    SnackbarContent, 
    ClickAwayListener, 
    Collapse,
    FormControlLabel, 
    Checkbox, 
    Chip, 
    Popper, 
    Grow, 
    MenuList, 
    MenuItem, 
    MenuProps, 
    Tooltip, 
    TextField
} from "@material-ui/core";
import moment from "moment";
import Spinner from "../../../components/src/ProjectLoader";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import BuyersRequestController, {} from "./BuyersRequestController.web";
import 
{ 
  close, 
  resultNotFound, 
  ellipsis, 
  redQue, 
  add_black, 
  remove, 
  image_dropdown,  
  DateTimeIcon, 
  orangeSucessIcon
} from "./assets";
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField, 
  { 
    AutocompleteStyle, 
    OptionForSelect, 
    ProjectTextField 
  } from "../../../components/src/ProjectTextField";
import { 
  ContainedButton 
} from "../../../components/src/GlobalButton";
import { 
  Formik, 
  FormikErrors, 
  FormikTouched 
} from "formik";
import * as Yup from "yup";
import BuyerMatches from "./BuyerMatches.web";
import { 
  Delete
 } from "@material-ui/icons";
import BuyersOfferWeb from "./BuyersOffer.web";
import CancelIcon from '@material-ui/icons/Cancel';
import BuyerToggelComponentWeb from "../../../components/src/BuyerToggelComponent.web";
import { 
  DateRangePicker,
  DateRange
   } from "materialui-daterange-picker";
import { 
  AutocompleteRenderOptionState
} from "@material-ui/lab/Autocomplete";
import { 
  CustomBox,
  SectionWrapperLeft 
} from "./SellerListing.web";
import { 
  toast
 } from "react-toastify";
import { BorderedStyledTab} from "./SellerPendingRequest.web";




const StyledTabs = withStyles((theme) => ({
    root: {
    background: "#DADADA",borderTopRightRadius: "5px",
    borderTopLeftRadius: "5px",color: " #363636",
    fontSize: "20px",fontFamily: 'Asap',
    fontStyle: "normal",fontWeight: 500,
    lineHeight: "normal",
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
    root: {
      borderTopRightRadius: "5px",borderTopLeftRadius: "5px",
      overflow: "visible",fontWeight: 700,
      color: "#1E1E1E",fontSize: "14px",
      fontFamily: 'Asap',fontStyle: "normal",
      lineHeight: "normal",textTransform: "uppercase",
      width: "451.5px",height: "72px"
    },
    selected: {
      background: "#ffffff",
    },
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
  root: {
    fontSize: "18px", fontWeight: 700,
    color: "#363636",textTransform: "uppercase",
    fontFamily: 'Asap',
  }
}))(InputLabel);


const BootstrapInput = withStyles((theme) => ({
    root: {"label + &": {
      marginTop: theme.spacing(3),
    },
  },
    input: {
        borderRadius: 5,  position: "relative",
        backgroundColor: theme.palette.common.white,
        border: "1px solid #ced4da",fontFamily: 'Asap',
        fontSize: "16px", padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        color: "#363636",
        "&:focus": {
          borderColor: "##DCDCD",
           boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
        },
  },
}))(InputBase);


const StyledTableCell = withStyles((theme) => ({
    head: { 
      fontWeight: 600, 
      color: "#1E1E1E", 
      fontFamily: "Asap",
      fontSize: "14px",  
      lineHeight: "24px",
      fontStyle: "normal",
      background: "#E1E5EE",
      borderBottom: "2px solid #8A99BB"},
    body: { 
      fontSize: 14, 
      fontFamily: 'Asap'
    },
}))(TableCell);

const BuyerTableRow = withStyles((theme) => ({
    root: {
        borderRadius: "10px",
        color: "#363636",
        fontSize: "14px",
        fontFamily: 'Asap',
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        textTransform: "unset",
        background: "#fff"
    },
}))(TableRow);

const BuyerStyledTable = withStyles((theme) => ({
    root: {
      border: "none",
      borderCollapse: "separate",
      borderSpacing: "0 3px",
      rowGap: "10px",
      background: "transparent",
    },
}))(Table);

const StyledTableHeaderRow = withStyles((theme) => ({
    root: {},
}))(TableRow);

const StyledSwitch = styled("div")({
    position: 'relative',
    marginRight: "15px", 
    width: '75px',
    display: 'inline-block',
    height: '2.125rem',
    '& input': {
      width: 0, opacity: 0,
      height: 0,
    },
    '& .slider': {
      position: 'absolute', cursor: 'pointer',
      top: 0,
       backgroundColor: '#ccc',
      left: 0,
      right: 0,
      bottom: 0,transition: 'background-color 0.4s',
        "& .text": {
          position: "absolute",
          color: "#FFF",
          top: "5px",
          right: "9px",
          fontSize: "1rem",
          fontWeight: "bolder",
          fontFamily: 'Asap',
        },
        '&:before': {
          position: 'absolute',
          content: '""',
          height: '1.625rem', 
          width: '1.625rem',
          bottom: '.25rem',
          right: '0.56rem', 
          backgroundColor: '#FFFFFF',
          transition: 'transform 0.4s',
        },
    },
    '& input:checked + .slider': {
      backgroundColor: '#FF5C00'
    },
    '& input:focus + .slider': {
      boxShadow: '0 0 .0625rem #2196F3'
    },
    '& input:checked + .slider:before': {
      transform: 'translateX(-37px)'
    },
    '& .slider.round': {
      borderRadius: '2.125rem',
        '&:before': {
          borderRadius: '50%'
},
    },
});

const StyledBox = withStyles((theme) => ({
  root: {
    position: "relative", backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
     padding: "20px 110px",
    cursor: "pointer",
    fontSize: 16,
    borderRadius: 8,transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#000",
    },
  },
}))(Box);


const StyledChip = withStyles((theme) => ({
  root: {
    background: "#FF5C00", 
    fontFamily: "Asap, sans-serif",
    color: "white",
    fontSize: ".875rem", 
    flexDirection: "row-reverse",
    "& .MuiChip-deleteIcon": {
      color: "rgba(255, 255, 255, 1)",
    },
    "& .MuiChip-label": {
      paddingRight: "0rem"
    },
    "& .MuiChip-icon": {
      color: "rgba(255, 255, 255, 1)",margin: "0rem"
    }
  },
}))(Chip);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: "0rem .375rem .9375rem -0.1875rem rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.common.white,
    fontSize: "12px", color: "black",
    display: " inline-flex",justifyContent: "center",
    borderRadius: ".5rem",alignItems: "center",
    padding: ".5rem 1rem",fontFamily: "Asap, sans-serif",
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);


const columns = [
    {
      id: "org_", label: ["ORG "],minWidth: "auto",
    }, 
    {
      id: "request_id",minWidth: "auto",label: ["REQUEST ID"],
    }, 
    {
      id: "date_time",minWidth: "auto",label: ["DATE RANGE"],
    },
    {
      label: ["POL"], minWidth: "auto",id: "pol_terminal",
    },
    {
      minWidth: "auto", id: "pod_terminal", label: ["POD"],
    },
    {
      minWidth: "auto",label: ["STATUS"],id: "type_",
    },
    {
      label: ["TYPES"],id: "code_",minWidth: "auto",},{minWidth: "auto",id: "quantity",label: ["QUANTITY"],
    },
    {
      minWidth: "auto",id: "requestedRate",label: ["BUYER PRICE (USD)"],
    },
    {
      id: "total",label: ["TERMS"],minWidth: "auto",
    },
    {
      label: ["TOTAL (USD)"],minWidth: "auto",id: "quan",
    },
    {
      label: [""], minWidth: "auto", id: "dots",
    },
]

const projectTextFieldProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
}


const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
        <div 
          role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}aria-labelledby={`simple-tab-${index}`}{...other}>{value === index && children}
        </div>
    );
};

interface ContainerDetail {
  quantity?: number;
  container_size?: string;
  desired_rates?: number;
}

interface List {
  container_details: ContainerDetail[];
}


  class BuyersRequest extends BuyersRequestController {

    EditedBuyerListingSchema = () => {
      return Yup.object().shape({
        ...(this.state.buyerPrinicpalOrgList?.length !== 0 && {
          EDITEDBUYERPRINCIPALORGANISATION: Yup.string().required("Principal Organisation Required"),
        }),

        EDITEDBUYERPOD: Yup.string().required("POD Required"),
        EDITEDPODTERMINAL: Yup.string().required("POD Terminal Required"),
        EDITEDPOL: Yup.string().required("POL Required"),
        EDITEDPOLTERMINAL: Yup.string().required("POL Terminal Required"),
        EDITEDETBATPOL: Yup.string().required("Container Loading Range (From) Required"),
        EDITEDETAATPOD: Yup.string().required("Container Loading Range (To) Required"),

        EDITEDCONTAINERTYPE: Yup.string().required("Container Status Required"),
        EDITEDCONTAINERCODES: Yup.array().required("Container Types Required"),
        EDITEDCONTAINERSIZE: Yup.string().required("Container Size  Required"),

        EDITEDAVERAGETONSBOX: Yup.number().typeError('Must Be Number').required("Avg. Tons/Container Required").min(1, 'TONS/Container Must Be Greater Than Zero'),
        EDITEDTOTALQUANTITY: Yup.number().typeError('Must Be Number').required("Quantity Required").min(1, 'Quantity Must Be Greater Than Zero'),
        EDITEDRATE: Yup.number().typeError('Must Be Number').required("Buyer Price Required").min(1, 'Buyer Price Must Be Greater Than Zero'),
        EDITEDTERMS: Yup.string().required("Terms Required"),
        EDITEDTERMSTWO: Yup.string().required("Terms Required"),


        ...(this.state.isEditContainerClicked && {
          EDITEDCONTAINERSIZETWO: Yup.string().required("Container Size  Required"),
          EDITEDAVERAGETONSBOXTWO: Yup.number().typeError('Must Be Number').required("Avg. Tons/Container Required").min(1, 'TONS/Container Must Be Greater Than Zero'),
          EDITEDTOTALQUANTITYTWO: Yup.number().typeError('Must Be Number').required("Quantity Required").min(1, 'Quantity Must Be Greater Than Zero'),
          EDITEDRATETWO: Yup.number().typeError('Must Be Number').required("Buyer Price Required").min(1, 'Buyer Price Must Be Greater Than Zero'),
          EDITEDTERMSTHREE: Yup.string().required("Terms Required"),
          EDITEDTERMSFOUR: Yup.string().required("Terms Required"),
        }),

      });
  };

    
  shouldBuyerQuntitySeparator = (index: number, list: List) => {
    return (
      index !== list.container_details.length - 1 &&
      list.container_details.length > 1 &&
      list.container_details[1]?.quantity &&
      list.container_details[1]?.container_size &&
      list.container_details[0]?.quantity &&
      list.container_details[0]?.container_size
    ) ? ' | ' : ' ';
  };

  shouldBuyerRequestedRateSeparator = (index: number, list: List) => {
    return (
      index !== list.container_details.length - 1 &&
      list.container_details.length > 1 &&
      list.container_details[1]?.desired_rates &&
      list.container_details[1]?.container_size &&
      list.container_details[0]?.desired_rates &&
      list.container_details[0]?.container_size
    ) ? ' | ' : ' ';
  };
  


    buyerBeforeDeletListAlertModal = () => {
      return (

        <StyledAlertDialog
          fullWidth
          maxWidth="sm"
          open={this.state.listDeleted}
          onClose={this.handleDeleteCancelClick}
          data-test-id="DeleteModal"
          >
            <Box style={webStyle.listingSubmittedBox}>
              <div style={webStyle.textEnd}>
                <img data-test-id="DeleteClose" onClick={()=>this.handleDeleteCancelClick()} src={close} alt="close" />
              </div>
              <div style={{ ...webStyle.textCenter, ...webStyle.pTB50 }}>
                <div style={webStyle.mb20}>
                  <img src={redQue} alt="Red Q" />
                </div>
                <h1 style={webStyle.listingHeading}>Delete Request?</h1>
                <p style={webStyle.listingIdPara}>
                  Are you sure you want to delete your request (<span style={webStyle.deletReqId}>ID: {this.state.catchEditedData?.request?.data?.attributes?.request_uid}</span>)
                  ? This action can not be undone.
                </p>
                <div style={{ marginTop: "25px" }}>
                  <button onClick={()=> this.deleteBuyerListing(this.state.catchDeleteIdKey)} data-test-id="DeleteList" style={webStyle.deleteBtn}>Delete</button>
                  <button onClick={this.handleDeleteCancelClick} style={webStyle.cancelBtn} data-test-id="DeleteListCancle">Cancel</button>
                </div>
              </div>
            </Box>
          </StyledAlertDialog>
      )
    }
    

  
    BuyerErrorRender = (BuyerErrorMessage: string | undefined | string[] | FormikErrors<any> | FormikErrors<any>[], BuyerTextShow: boolean) => {
      return (
          BuyerErrorMessage !== undefined && BuyerTextShow &&
          <Box className="ErrorTextContainer">
            <ProjectText className={`TextError ${BuyerTextShow ? "" : "TextHidden"}`}>
              {typeof BuyerErrorMessage === "string" ? BuyerErrorMessage : ""}
            </ProjectText>
          </Box>
        );
    };

      

      // ADD FLOW START


      renderPrincipleOrgBlock = () => {
        if (!Array.isArray(this.state.buyerPrinicpalOrgList)) {
          return (
            <OptionForSelect
              value={this.state.buyerPrinicpalOrgList?.attributes?.name}
              key={this.state.buyerPrinicpalOrgList?.id}
              data-test-id="option1"
            >
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {this.state.buyerPrinicpalOrgList?.attributes?.name}
              </ProjectText>
            </OptionForSelect>
          );
        } else {
          return this.state.buyerPrinicpalOrgList?.map((val, index) => (
            <OptionForSelect
              value={val.attributes.name}
              key={val.id}
              data-test-id={`option${index + 1}`}
            >
              <ProjectText
                variant="h6"
                TextSize="14px"
                fontweight="500"
              >
                {val.attributes.name}
              </ProjectText>
            </OptionForSelect>
          ));
        }
      };

       // ADD FLOW END


       renderInitialListingTableCells = (list : any,
        buyerStyle : {
        textCenter: React.CSSProperties;
        tableContent: React.CSSProperties;
        highlightedTableContent: React.CSSProperties;
        tableLeftBorder: React.CSSProperties;
        org: React.CSSProperties;
      }) => {
        return (
          <>
          <TableCell style={{...buyerStyle.tableLeftBorder, borderLeftColor: list.request.data.attributes.color_name || '#B8D5F9'}}><span style={{...buyerStyle.org, backgroundColor: list.request.data.attributes.color_name || '#B8D5F9'}}>
                                            {list?.request?.data?.attributes?.principal_org_code
                                              ? list?.request?.data?.attributes?.principal_org_code
                                              : list?.request?.data?.attributes?.organization_code}
                                              </span></TableCell>
            <TableCell style={buyerStyle.textCenter}><span style={buyerStyle.tableContent}>{list?.request?.data?.attributes?.request_uid}</span></TableCell>
            <TableCell style={webStyle.whiteSpacePaddingCenter}>
              <span style={buyerStyle.tableContent}>
                {
                  `${moment(list?.request?.data?.attributes?.container_loading_range_start).format("DD-MMM-YYYY").toUpperCase()} - ${moment(list?.request?.data?.attributes?.container_loading_range_end).format("DD-MMM-YYYY").toUpperCase()}`
                }
              </span></TableCell>
            <TableCell style={buyerStyle.textCenter}><span style={buyerStyle.tableContent}>
            {list?.request?.data?.attributes?.pol}/
            <br />
            {list?.request?.data?.attributes?.pol_terminal}
            </span>
            </TableCell>
            <TableCell style={buyerStyle.textCenter}><span style={buyerStyle.tableContent}>
            {list?.request?.data?.attributes?.pod}/
            <br />
            {list?.request?.data?.attributes?.pod_terminal}
              </span></TableCell>
          </>
        )
       }

      

      renderContainerCells = (list : any,
        buyerStyle : {
        textCenter: React.CSSProperties;
        tableContent: React.CSSProperties;
        highlightedTableContent: React.CSSProperties;
      }) => {
        return (
          <>
            <TableCell style={{ padding: '0px'}}><div style={{...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, borderRadius: '4px 0px 0px 4px', paddingLeft: '4px', backgroundColor: list.request.data.attributes.color_name || '#B8D5F9'}}>{list?.container?.container_type}</div></TableCell>                          
              <TableCell style={{ padding: '0px'}}><div style={{...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent, backgroundColor: list.request.data.attributes.color_name || '#B8D5F9'}}>
                {
                  <div>
                    {list?.container?.codes?.map((code: string, index: number, array: string[]) => (
                      <span key={code}>{code}{index < array.length - 1 && ','}</span>
                    ))}
                  </div>
                }
                </div>
              </TableCell>
              <TableCell style={webStyle.whiteSpacePadding}>
                <div style={{...buyerStyle.tableContent, ...buyerStyle.highlightedTableContent,backgroundColor: list.request.data.attributes.color_name || '#B8D5F9'}}>
                  {list?.container_details?.map((container : { quantity?: number;
                    container_size?: string; }, index:number
                ) => (
                  <React.Fragment key={index}>
                  {(container.quantity ?? 0 ) > 0 && (
                      <>
                      <b>
                              {container.quantity}X{container.container_size} </b>
                          {this.shouldBuyerQuntitySeparator(index, list)}
                      </>
                  )}  </React.Fragment>
                  ))}
                </div>
              </TableCell>

              <TableCell style={webStyle.whiteSpacePadding}>
                <div style={{...buyerStyle.tableContent, 
                  ...buyerStyle.highlightedTableContent,backgroundColor: list.request.data.attributes.color_name || '#B8D5F9', borderRadius: '0px 4px 4px 0px'}}>
                  {list?.container_details?.map((
                    container : { desired_rates?: number;
                      container_size?: string;
                    }, index : number) => (
                      <React.Fragment key={index}>
                        {container?.desired_rates !== 0 &&
                        <>
                        <b>
                          {container?.desired_rates
                            ? `$${container.desired_rates.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/`
                            : ""}
                        </b>
                        {container?.container_size}
                        {this.shouldBuyerRequestedRateSeparator(index, list)}
                        </>
                          }
                      </React.Fragment>
                    ))}  </div>
              </TableCell>

              <TableCell style={webStyle.whiteSpacePaddingCenter}>
                {list?.container_details?.map((term: { terms: string;quantity:number }, index: number, array: { terms: string;quantity:number }[]) => (
                  <span style={{  ...buyerStyle.tableContent}} key={term?.terms}>
                    {term?.quantity !== 0 && term?.terms} {list?.container_details.length >1 && index==0 ? ' | ':''}
                  </span>
                ))}
                </TableCell>
                <TableCell style={{...buyerStyle.textCenter, ...buyerStyle.tableContent, color : '#1E1E1E'}}>
                {list?.container_details?.[1]?.desired_rates ? `$ ${(list?.container_details?.[0]?.desired_rates * list.container_details?.[0]?.quantity + list?.container_details?.[1]?.desired_rates * list.container_details?.[1]?.quantity).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` :  `$ ${(list?.container_details?.[0]?.desired_rates * list.container_details?.[0]?.quantity).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                </TableCell>
          </>
        )
      }

      PolAndPodAutoComplateOnChange = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, value: any, KeyName: string) => {
        if (KeyName === "EDITEDPOL" && value.attributes?.port_code) { setFieldValue("EDITEDPOLTERMINAL", "") }
        if (KeyName === "EDITEDBUYERPOD" && value.attributes?.port_code) {setFieldValue("EDITEDPODTERMINAL", "")}}

    renderPOLTextField = (errors: any, touched: any, setFieldValue: any, values: any) => {
      return( <AutocompleteStyle id="combo-box-demo"size="small"
          getOptionSelected={(option: unknown, value) => (option as {attributes: any; PortName: string }).attributes?.port_code === values.EDITEDPOL.split(",")[0]}
          options={this.state.buyerPolData.filter((item: { attributes: {port_code: string} }) => item.attributes?.port_code !== values.EDITEDBUYERPOD.split(",")[0])}
          getOptionLabel={(option: unknown) => (option as {attributes: any; PortName: string }).attributes?.port_code}
          onChange={(event: React.ChangeEvent<{}>, value) => {this.PolAndPodAutoComplateOnChange(setFieldValue, value, "EDITEDPOL")}}
          FormError={errors.EDITEDPOL !== undefined && touched.EDITEDPOL !== undefined && values.EDITEDPOL === ""} inputValue={values.EDITEDPOL.split(",")[0]}
          onInputChange={(event, newInputValue) => {if (event !== null) {setFieldValue("EDITEDPOL", newInputValue)}}} clearOnBlur={false} closeIcon={false} renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (<ProjectText TextSize="14px"variant="h6"fontweight="500">{(option as {attributes: any; PortName: string}).attributes?.port_code ?? ""}</ProjectText>
          )}/>)}

    renderPOLTerminalTextField = (errors: any, touched: any, setFieldValue: any, values: any) => {
      return( <AutocompleteStyle id="combo-box-demo" size="small"
          options={this.state.buyerPolData?.filter((item: any) => (values.EDITEDPOL === item.attributes.port_code)).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)}
          getOptionSelected={(option: unknown, value) => option as string === values.EDITEDPOLTERMINAL} getOptionLabel={(option: unknown) => option as string ?? ""}
          onChange={(event: React.ChangeEvent<{}>, value) => {this.PolAndPodAutoComplateOnChange(setFieldValue, value, "EDITEDPOLTERMINAL"); this.setState({newPOLTerminalValue: value})}} inputValue={values.EDITEDPOLTERMINAL}
          onInputChange={(event, newInputValue) => {if (event !== null ) {setFieldValue("EDITEDPOLTERMINAL", newInputValue)}}} clearOnBlur={false} FormError={errors.EDITEDPOLTERMINAL !== undefined && touched.EDITEDPOLTERMINAL !== undefined && values.EDITEDPOLTERMINAL === ""} closeIcon={false} renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (<ProjectText TextSize="14px" variant="h6" fontweight="500">{option as string ?? ""}</ProjectText>
          )}/>)}

    renderPODTextField = (errors: any, touched: any, setFieldValue: any, values: any) => {
      return( <AutocompleteStyle id="combo-box-demo" size="small"
          options={this.state.buyerPolData.filter((item: { attributes: any; PortName: string }) => item?.attributes?.port_code !== values.EDITEDPOL.split(",")[0])}
          getOptionLabel={(option: unknown) => (option as { attributes: any; PortName: string }).attributes?.port_code}
          onChange={(event: React.ChangeEvent<{}>, value) => {this.PolAndPodAutoComplateOnChange(setFieldValue, value, "EDITEDBUYERPOD")}}
          clearOnBlur={false}FormError={errors.EDITEDBUYERPOD !== undefined && touched.EDITEDBUYERPOD !== undefined && values.EDITEDBUYERPOD === ""} inputValue={values.EDITEDBUYERPOD.split(",")[0]} onInputChange={(event, newInputValue) => {if (event !== null) {setFieldValue("EDITEDBUYERPOD", newInputValue)}}}
          closeIcon={false} renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option: unknown, state: AutocompleteRenderOptionState) => (<ProjectText TextSize="14px" variant="h6" fontweight="500">{(option as {attributes: any; PortName: string}).attributes?.port_code ?? ""}</ProjectText>
              )}/>)}

    renderPODTerminalTextField = (errors: any, touched: any, setFieldValue: any, values: any) => {
      return( <AutocompleteStyle id="combo-box-demo" size="small"
          options={this.state.buyerPolData?.filter((item: any) => (values.EDITEDBUYERPOD === item.attributes.port_code)).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)}
          getOptionLabel={(option: unknown) => option as string ?? ""}
          onChange={(event: React.ChangeEvent<{}>, value) => {this.PolAndPodAutoComplateOnChange(setFieldValue, value, "EDITEDPODTERMINAL"); this.setState({newPODTerminalValue: value})}}
          clearOnBlur={false} inputValue={values.EDITEDPODTERMINAL} onInputChange={(event, newInputValue) => {if (event !== null) {setFieldValue("EDITEDPODTERMINAL", newInputValue)}}}
          FormError={errors.EDITEDPODTERMINAL !== undefined &&touched.EDITEDPODTERMINAL !== undefined && values.EDITEDPODTERMINAL === ""}
          closeIcon={false} renderInput={(params) => <TextField {...params} variant="outlined" />}
          renderOption={(option: unknown, state: AutocompleteRenderOptionState) => ( <ProjectText TextSize="14px" variant="h6" fontweight="500" >{option as string ?? ""}</ProjectText>
          )}/>)}



      // EDIT FLOW START

      renderEditedVasselBlock = ( 
        errors: FormikErrors <{
          EDITEDPOL: string,
          EDITEDPOLTERMINAL: string,
          EDITEDETBATPOL: string,
          EDITEDETAATPOD: string;
          EDITEDBUYERPOD: string;
          EDITEDPODTERMINAL: string;
        }>,
        touched: FormikTouched<{
          EDITEDPOL: string | boolean;
          EDITEDPOLTERMINAL: string | boolean;
          EDITEDETBATPOL: string | boolean;
          EDITEDETAATPOD: string | boolean;
          EDITEDBUYERPOD: string | boolean;
          EDITEDPODTERMINAL?: string | boolean;
        }> ,
        values: {
          EDITEDPOL: string;
          EDITEDPOLTERMINAL: string;
          EDITEDETBATPOL: string;
          EDITEDETAATPOD: string;
          EDITEDBUYERPOD: string;
          EDITEDPODTERMINAL: string;
        },
        setFieldValue: (
          field: string,
          value: string,
          shouldValidate?: boolean
        ) => void
      ) => {
        return (
          <section style={webStyle.vesselContainer}>
            <h3 style={webStyle.vesselHeading}>Vessel Information</h3>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDPOL">
                      <ProjectText
                        TextSize="14px"
                        variant="subtitle1"
                        Upercase
                        fontweight="900"
                        textcolor="black"
                      >
                        POL
                      </ProjectText>
                    </InputLabel>
                    {this.renderPOLTextField(errors, touched, setFieldValue, values)}
                    {this.BuyerErrorRender(errors.EDITEDPOL, errors.EDITEDPOL !== undefined && touched.EDITEDPOL !== undefined)}

                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDPOLTERMINAL">
                      <ProjectText
                        TextSize="14px"
                        Upercase
                        fontweight="900"
                        variant="subtitle1"
                        textcolor="black"
                      >
                        POL TERMINAL
                      </ProjectText>
                    </InputLabel>
                    {this.renderPOLTerminalTextField(errors, touched, setFieldValue, values)}
                    {this.BuyerErrorRender(
                      errors.EDITEDPOLTERMINAL,
                      errors.EDITEDPOLTERMINAL !== undefined &&
                      touched.EDITEDPOLTERMINAL !== undefined
                    )}
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDBUYERPOD">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        textcolor="black"
                        fontweight="900"
                      >
                        POD
                      </ProjectText>
                    </InputLabel>
                    {this.renderPODTextField(errors, touched, setFieldValue, values)}
                    {this.BuyerErrorRender(errors.EDITEDBUYERPOD,  errors.EDITEDBUYERPOD !== undefined && touched.EDITEDBUYERPOD !== undefined)}
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDPODTERMINAL">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        textcolor="black"
                        fontweight="900"
                      >
                        POD TERMINAL
                      </ProjectText>
                    </InputLabel>
                    {this.renderPODTerminalTextField(errors, touched, setFieldValue, values)}
                    {this.BuyerErrorRender(
                      errors.EDITEDPODTERMINAL,
                      errors.EDITEDPODTERMINAL !== undefined &&
                      touched.EDITEDPODTERMINAL !== undefined
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} style={{ marginBottom: "10px" }}>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDETBATPOL">
                  <ProjectText
                    variant="subtitle1"
                    Upercase
                    TextSize="14px"
                    fontweight="900"
                    textcolor="black"
                  >
                    CONTAINER LOADING RANGE (FROM)
                  </ProjectText>
                </InputLabel>
                <LandingPageTextField
                  BoderRadius="10px"
                  inputProps={{
                    "data-test-id": "EDITEDETBATPOL",
                    "min": moment.parseZone().format("YYYY-MM-DD"),
                    style: {height: '26px'}
                  }}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    this.EditedBuyerListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "EDITEDETBATPOL",
                      values
                    );
                    setFieldValue("EDITEDETAATPOD", "")
                  }}
                  variant="outlined"
                  size="small"
                  FormError={
                    errors.EDITEDETBATPOL !== undefined && touched.EDITEDETBATPOL !== undefined && values.EDITEDETBATPOL === ""
                  }
                  type="Date"
                  value={values.EDITEDETBATPOL}
                  id="EDITEDETBATPOL"
                  name="EDITEDETBATPOL"
                  fullWidth
                />
                {this.BuyerErrorRender(errors.EDITEDETBATPOL,  errors.EDITEDETBATPOL !== undefined && touched.EDITEDETBATPOL !== undefined && values.EDITEDETBATPOL === "")}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDETAATPOD">
                  <ProjectText
                    Upercase
                    TextSize="14px"
                    variant="subtitle1"
                    fontweight="900"
                    textcolor="black"
                  >
                    CONTAINER LOADING RANGE (TO)
                  </ProjectText>
                </InputLabel>
                <LandingPageTextField
                  BoderRadius="10px"
                  inputProps={{
                    "data-test-id": "EDITEDETAATPOD",
                    min: moment.parseZone(values.EDITEDETBATPOL).add(1, 'd').format("YYYY-MM-DD"),
                    style: {height: '26px'}
                  }}
                  variant="outlined"
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    this.setState({ buyerEtaDate: e.target.value });
                    this.EditedBuyerListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "EDITEDETAATPOD",
                      values
                    );
                  }}
                  FormError={
                    errors.EDITEDETAATPOD !== undefined && touched.EDITEDETAATPOD !== undefined && values.EDITEDETAATPOD === ""
                  }
                  size="small"
                  value={values.EDITEDETAATPOD}
                  type="Date"
                  id="EDITEDETAATPOD"
                  name="EDITEDETAATPOD"
                  fullWidth
                />
                 {this.BuyerErrorRender(errors.EDITEDETAATPOD,  errors.EDITEDETAATPOD !== undefined && touched.EDITEDETAATPOD !== undefined && values.EDITEDETAATPOD === "")}
              </Grid>
            </Grid>
          </section>
        );
      };

      renderEditConntainerSize = (values: any) => {return (<><ProjectText TextSize="1rem"textcolor="#ACACAC">{values.EDITEDCONTAINERSIZE ? '/' + values.EDITEDCONTAINERSIZE?.split(' ').join(''):''}</ProjectText></>)}
      
      renderEditedContainerFirstBlock = ( 
        errors: FormikErrors <{
          EDITEDCONTAINERTYPE: string,
          EDITEDCONTAINERCODES: string,
          EDITEDCONTAINERSIZE: string,
          EDITEDTOTALQUANTITY: string;
          EDITEDAVERAGETONSBOX: number;
          EDITEDRATE: string;
          EDITEDTERMS : string;
          EDITEDTERMSTWO : string;
        }>,
        touched: FormikTouched<{
          EDITEDCONTAINERTYPE: string | boolean;
          EDITEDCONTAINERCODES: string | boolean;
          EDITEDCONTAINERSIZE: string | boolean;
          EDITEDTOTALQUANTITY: string | boolean;
          EDITEDAVERAGETONSBOX: number | boolean;
          EDITEDRATE: string | boolean;
          EDITEDTERMS : string | boolean;
          EDITEDTERMSTWO : string | boolean;
        }> ,
        values: {
          EDITEDCONTAINERTYPE: string;
          EDITEDCONTAINERCODES: any[];
          EDITEDCONTAINERSIZE: string;
          EDITEDCONTAINERSIZETWO: string,
          EDITEDTOTALQUANTITY: string;
          EDITEDAVERAGETONSBOX?: number;
          EDITEDRATE: string;
          EDITEDTERMS: string;
          EDITEDTERMSTWO : string;
        },
        setFieldValue: (
          field: string,
          value: string | any[],
          shouldValidate?: boolean
        ) => void
      ) => {
        
        return (
          <>
            <Grid
              container
              style={{ marginBottom: "10px" }}
              spacing={2}
            >
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDCONTAINERTYPE">
                  <ProjectText
                    Upercase
                    variant="subtitle1"
                    fontweight="900"
                    textcolor="black"
                    TextSize="14px"
                  >
                    Container Status
                  </ProjectText>
                </InputLabel>
                <ProjectTextField
                style={{ height: '47px'}}
                MenuProps={projectTextFieldProps}
                  fullWidth
                  variant="outlined"
                  defaultValue="all"
                  FormError={
                    errors.EDITEDCONTAINERTYPE !== undefined &&
                    touched.EDITEDCONTAINERTYPE !== undefined
                  }
                  inputProps={{
                    "data-test-id": "EDITEDCONTAINERTYPE",
                  }}
                  value={values.EDITEDCONTAINERTYPE}
                  id="EDITEDCONTAINERTYPE"
                  name="EDITEDCONTAINERTYPE"
                  onChange={(
                    e: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    this.EditedBuyerListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "EDITEDCONTAINERTYPE",
                      values
                    );
                  }}
                >
                  {this.state.buyerContainerData?.map(
                    (dataEd: { buyerContainer_type: string }) => {
                      let keyCounter = 1
                      return (
                        <OptionForSelect
                          value={dataEd.buyerContainer_type}
                          key={`${dataEd.buyerContainer_type}_${keyCounter++}`}
                        >
                          <ProjectText
                            TextSize="14px"
                            variant="h6"
                            fontweight="500"
                          >
                            {dataEd.buyerContainer_type}
                          </ProjectText>
                        </OptionForSelect>
                      );
                    }
                  )}
                </ProjectTextField>
                {this.BuyerErrorRender(
                  errors.EDITEDCONTAINERTYPE,
                  errors.EDITEDCONTAINERTYPE !== undefined &&
                    touched.EDITEDCONTAINERTYPE !== undefined
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDCONTAINERSIZE"><ProjectText
                    Upercase
                    fontweight="900"
                    variant="subtitle1"
                    TextSize="14px"
                    textcolor="black"
                  >CONTAINER SIZE</ProjectText>
                </InputLabel>
                <ProjectTextField
                style={{ height: '47px'}}
                MenuProps={projectTextFieldProps}
                  variant="outlined"
                  fullWidth
                  defaultValue="all"
                  type="text"
                  inputProps={{
                    "data-test-id": "EDITEDCONTAINERSIZE",
                  }}
                  FormError={
                    errors.EDITEDCONTAINERSIZE !== undefined &&
                    touched.EDITEDCONTAINERSIZE !== undefined
                  }
                  value={values.EDITEDCONTAINERSIZE}
                  id="EDITEDCONTAINERSIZE"
                  name="EDITEDCONTAINERSIZE"
                  onChange={(
                    e: React.ChangeEvent<{ value: unknown }>
                  ) => {
                    this.EditedBuyerListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "EDITEDCONTAINERSIZE",
                      values
                    );
                    this.setState({
                      selectedBuyerContainerOneSize: e.target.value,
                    });
                  }}
                >
              {this.state.selectedContainer?.buyerBox_sizes
                      ?.filter((valueEd: {size : string}) => values.EDITEDCONTAINERSIZETWO ? valueEd?.size === values.EDITEDCONTAINERSIZE: true) 
                      .map((valueEdSize: {size: string}) => {
                        return (
                          <OptionForSelect value={valueEdSize?.size} key={valueEdSize?.size}>
                            <ProjectText variant="h6" TextSize="14px" fontweight="500">
                              {valueEdSize?.size}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      })}
                </ProjectTextField>
                {this.BuyerErrorRender(
                  errors.EDITEDCONTAINERSIZE,
                  typeof errors.EDITEDCONTAINERSIZE === "string"
                )}
              </Grid>
              <Grid
              container
              spacing={1}
              style={{ marginBottom: "10px" }}
            >
            </Grid>


            <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDTOTALQUANTITY">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        TextSize="14px"
                        fontweight="900"
                        textcolor="black"
                      >
                        QUANTITY
                      </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                      BoderRadius="10px"
                      inputProps={{
                        "data-test-id": "EDITEDTOTALQUANTITY",
                        style: { height: '26px'}
                      }}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDTOTALQUANTITY",
                          values
                        );
                      }}
                      size="small"
                      FormError={
                        errors.EDITEDTOTALQUANTITY !== undefined &&
                        touched.EDITEDTOTALQUANTITY !== undefined
                      }
                      value={values.EDITEDTOTALQUANTITY}
                      type="number"
                      id="EDITEDTOTALQUANTITY"
                      name="EDITEDTOTALQUANTITY"
                      fullWidth
                    />
                    {this.BuyerErrorRender(
                      errors.EDITEDTOTALQUANTITY,
                      errors.EDITEDTOTALQUANTITY !== undefined &&
                      touched.EDITEDTOTALQUANTITY !== undefined
                    )}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel htmlFor="EDITEDAVERAGETONSBOX">
                      <ProjectText
                        Upercase
                        fontweight="900"
                        variant="subtitle1"
                        textcolor="black"
                        TextSize="14px"
                      >
                        AVG. TONS/CONTAINER
                      </ProjectText>

                    </InputLabel>
                    <LandingPageTextField
                      BoderRadius="10px"
                      inputProps={{
                        "data-test-id": "EDITEDAVERAGETONSBOX",
                        style: { height: '26px'}
                      }}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDAVERAGETONSBOX",
                          values
                        );
                      }}
                      size="small"
                      FormError={
                        errors.EDITEDAVERAGETONSBOX !== undefined &&
                        touched.EDITEDAVERAGETONSBOX !== undefined
                      }
                      type="text"
                      value={values.EDITEDAVERAGETONSBOX}
                      id="EDITEDAVERAGETONSBOX"
                      name="EDITEDAVERAGETONSBOX"
                      fullWidth
                    />
                    {this.BuyerErrorRender(
                      errors.EDITEDAVERAGETONSBOX,
                      errors.EDITEDAVERAGETONSBOX !== undefined &&
                      touched.EDITEDAVERAGETONSBOX !== undefined
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDCONTAINERCODES">
                  <ProjectText
                    Upercase
                    TextSize="14px"
                    variant="subtitle1"
                    textcolor="black"
                    fontweight="900"
                  >
                    Container Types
                  </ProjectText>
                </InputLabel>
                <ProjectTextField
                MenuProps={projectTextFieldProps}
                  fullWidth
                  variant="outlined"
                  FormError={
                    errors.EDITEDCONTAINERCODES !== undefined &&
                    touched.EDITEDCONTAINERCODES !== undefined
                  }
                  value={values.EDITEDCONTAINERCODES}
                  multiple
                  style={{
                    height: '47px',
                    zIndex: 1,
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                  className="MultiSelect"
                  id="EDITEDCONTAINERCODES"
                  name="EDITEDCONTAINERCODES"
                  renderValue={() => {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', zIndex: 900 }}>
                        {Array.isArray(values.EDITEDCONTAINERCODES) && values.EDITEDCONTAINERCODES?.map((val: string) => {
                          return <Box style={{ display: "flex" }} key={val}>
                            <Chip variant="outlined" className="DeleteChip" key={val} label={val} />
                          </Box>
                        })}
                      </Box>
                    )
                  }}
                    inputProps={{ "data-test-id": "EDITEDCONTAINERCODES" }}
                    onChange={( e: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      this.EditedBuyerListingFormTextFieldChange(
                        e, setFieldValue,
                        "EDITEDCONTAINERCODES",   values
                      );
                    }}       >
                    {this.state.selectedContainer?.buyerCodes?.map(
                      (valEd: string) => {
                        return (
                          <OptionForSelect 
                          value={valEd?.trim()} key={valEd?.trim()} style={{ display: "flex", justifyContent: "space-between" }}>
                            <ProjectText fontweight="500"
                              variant="h6"
                              TextSize="14px"  >
                              {valEd?.trim()}
                            </ProjectText>
                            {values.EDITEDCONTAINERCODES.includes(valEd?.trim()) ? <Delete />  : null}
                          </OptionForSelect>
                        );
                      })}
                  </ProjectTextField>
                {this.BuyerErrorRender(
                  errors.EDITEDCONTAINERCODES,
                  errors.EDITEDCONTAINERCODES !== undefined &&
                    touched.EDITEDCONTAINERCODES !== undefined
                  
                )}
              </Grid>
            </Grid>
            <Grid
              spacing={4}
              container
              style={{ marginBottom: "10px" }}
            >
              <Grid item xs={12} lg={6}>
                <InputLabel htmlFor="EDITEDRATE">
                  <ProjectText
                    Upercase
                    TextSize="14px"
                    fontweight="900"
                    variant="subtitle1"
                    textcolor="black"
                  >
                    BUYER PRICE
                  </ProjectText>
                </InputLabel>
                <LandingPageTextField
                  inputProps={{
                    "data-test-id": "EDITEDRATE",
                    style: {height: '26px'}
                  }}
                  className="inputHeightMedias"
                  onChange={(
                    e: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => {
                    this.EditedBuyerListingFormTextFieldChange(
                      e,
                      setFieldValue,
                      "EDITEDRATE",
                      values
                    );
                  }}
                  FormError={
                    errors.EDITEDRATE !== undefined &&
                    touched.EDITEDRATE !== undefined
                  }
                  BoderRadius="10px"
                  variant="outlined"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                  size="small"
                  type="text"
                  value={values.EDITEDRATE}
                  id="EDITEDRATE"
                  name="EDITEDRATE"
                  InputProps={{
                    endAdornment: (
                      <Box style={{ backgroundColor: "white" }}>
                        {this.renderEditConntainerSize(values)}
                      </Box>
                    ),
                    startAdornment: (
                      this.renderUSDFieldAdornment()
                    ),
                  }}
                  fullWidth
                />
                {this.BuyerErrorRender(
                  errors.EDITEDRATE,
                  errors.EDITEDRATE !== undefined &&
                  touched.EDITEDRATE !== undefined
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box style={{ display: "flex" }}>
                  <InputLabel htmlFor="EDITEDTERMS">
                    <ProjectText
                      Upercase
                      textcolor="black"
                      variant="subtitle1"
                      fontweight="900"
                      TextSize="14px"
                    >
                      TERMS
                    </ProjectText>
                  </InputLabel>
                </Box>
                <Grid container spacing={2} justifyContent="center" className="SlashClassContainer">
                  <Grid item  lg={6}>
                    <LandingPageTextField
                      inputProps={{
                        "data-test-id": "EDITEDTERMS",
                        style: {height: '26px'}
                      }}
                      BoderRadius="10px"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDTERMS",
                          values
                        );
                      }}
                      variant="outlined"
                      FormError={
                        errors.EDITEDTERMS !== undefined &&
                        touched.EDITEDTERMS !== undefined
                      }
                      type="text"
                      size="small"
                      value={
                        values.EDITEDTERMS
                      }
                      name="EDITEDTERMS"
                      id="EDITEDTERMS"
                      fullWidth
                    />
                      {this.BuyerErrorRender(
                        errors.EDITEDTERMS,
                        errors.EDITEDTERMS !== undefined &&
                        touched.EDITEDTERMS !== undefined
                      )}
                </Grid>  <Box className="SlashClass"><Box><span>/</span></Box></Box><Grid item  lg={6}>
                    <LandingPageTextField
                      inputProps={{
                        "data-test-id": "EDITEDTERMSTWO",
                        style: {height: '26px'}
                      }}
                      BoderRadius="10px"
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDTERMSTWO",
                          values
                        );
                      }}
                      size="small"
                      value={
                        values.EDITEDTERMSTWO
                      }
                      FormError={
                        errors.EDITEDTERMSTWO !== undefined &&
                        touched.EDITEDTERMSTWO !== undefined
                      }
                      type="text"
                      id="EDITEDTERMSTWO"
                      name="EDITEDTERMSTWO"
                      fullWidth
                    />
                    {this.BuyerErrorRender(
                        errors.EDITEDTERMSTWO,
                        errors.EDITEDTERMSTWO !== undefined &&
                        touched.EDITEDTERMSTWO !== undefined
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      };

      renderEditedContainerSecondBlock = ( 
        errors: FormikErrors <{
          EDITEDCONTAINERSIZETWO: string,
          EDITEDTOTALQUANTITYTWO: string;
          EDITEDAVERAGETONSBOXTWO: string;
          EDITEDRATETWO : string;
          EDITEDTERMSTHREE : string;
          EDITEDTERMSFOUR : string;
        }>,
        touched: FormikTouched<{
          EDITEDCONTAINERSIZETWO: string | boolean;
          EDITEDTOTALQUANTITYTWO: string | boolean;
          EDITEDAVERAGETONSBOXTWO: string | boolean;
          EDITEDRATETWO : string | boolean;
          EDITEDTERMSTHREE : string | boolean;
          EDITEDTERMSFOUR : string | boolean;
        }> ,
        values: {
          EDITEDCONTAINERSIZETWO: string;
          EDITEDTOTALQUANTITYTWO: string;
          EDITEDAVERAGETONSBOXTWO : string;
          EDITEDRATETWO : string;
          EDITEDTERMSTHREE: string;
          EDITEDTERMSFOUR : string;
          EDITEDCONTAINERSIZE:string;
        },
        setFieldValue: (
          field: string,
          value: string,
          shouldValidate?: boolean
        ) => void
      ) => {
        console.log("data",values.EDITEDCONTAINERSIZETWO);
        
        return (
          <>
            <Grid
                spacing={2}
                container
                style={{ marginBottom: "10px", marginTop: '11px', paddingTop: '11px', borderTop: '1px solid rgba(205, 205, 205, 1)' }}
              >

              <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={1}>
                  <Grid item xs lg={6}>
                    <InputLabel htmlFor="EDITEDTOTALQUANTITYTWO">
                      <ProjectText
                        Upercase
                        fontweight="900"
                        TextSize="14px"
                        textcolor="black"
                        variant="subtitle1"
                      >
                        QUANTITY
                      </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                      inputProps={{
                        "data-test-id": "EDITEDTOTALQUANTITYTWO",
                        style: { height: '26px'}
                      }}
                      BoderRadius="10px"
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDTOTALQUANTITYTWO",
                          values
                        );
                      }}
                      size="small"
                      FormError={
                        errors.EDITEDTOTALQUANTITYTWO !== undefined &&
                        touched.EDITEDTOTALQUANTITYTWO !== undefined
                      }
                      value={values.EDITEDTOTALQUANTITYTWO}
                      id="EDITEDTOTALQUANTITYTWO"
                      name="EDITEDTOTALQUANTITYTWO"
                      fullWidth
                    />
                    {this.BuyerErrorRender(
                      errors.EDITEDTOTALQUANTITYTWO,
                      errors.EDITEDTOTALQUANTITYTWO !== undefined &&
                        touched.EDITEDTOTALQUANTITYTWO !== undefined
                    )}
                  </Grid>
                  <Grid item xs lg={6}>
                      <InputLabel htmlFor="EDITEDAVERAGETONSBOXTWO">
                        <ProjectText
                          Upercase
                          variant="subtitle1"
                          fontweight="900"
                          TextSize="14px"
                          textcolor="black"
                        >
                         AVG. TONS/CONTAINER
                        </ProjectText>
                      </InputLabel>
                    <LandingPageTextField
                      BoderRadius="10px"
                      variant="outlined"
                      inputProps={{
                        "data-test-id": "EDITEDAVERAGETONSBOXTWO",
                        style: { height: '26px'}
                      }}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.EditedBuyerListingFormTextFieldChange(
                          e,
                          setFieldValue,
                          "EDITEDAVERAGETONSBOXTWO",
                          values
                        );
                      }}
                      size="small"
                      FormError={
                        errors.EDITEDAVERAGETONSBOXTWO !== undefined &&
                        touched.EDITEDAVERAGETONSBOXTWO !== undefined
                      }
                      type="text"
                      value={values.EDITEDAVERAGETONSBOXTWO}
                      id="EDITEDAVERAGETONSBOXTWO"
                      name="EDITEDAVERAGETONSBOXTWO"
                      fullWidth
                    />
                    {this.BuyerErrorRender(
                      errors.EDITEDAVERAGETONSBOXTWO,
                      errors.EDITEDAVERAGETONSBOXTWO !== undefined &&
                      touched.EDITEDAVERAGETONSBOXTWO !== undefined
                    )}
                  </Grid>
                </Grid>
              </Grid>
                <Grid item xs={12} md={6} lg={6}>
                <div style={webStyle.removeBtnBox}>
                  <InputLabel htmlFor="EDITEDCONTAINERSIZETWO">
                    <ProjectText
                      Upercase
                      TextSize="14px"
                      variant="subtitle1"
                      fontweight="900"
                      textcolor="black"
                    >
                      Container Size
                    </ProjectText>
                  </InputLabel>
                  <Button variant="text"
                    data-test-id="editRemoveContainer"
                      onClick={() => this.handleEditedRemoveAction(setFieldValue)}
                      style={{
                        position: "absolute",
                        right: "8px"
                      }} startIcon={<img src={remove} alt="delte" />}>
                      <ProjectText variant="h6" fontweight="700" textcolor="#1653DD" TextSize=".875rem">
                        Remove
                      </ProjectText>
                    </Button>
                  </div>
                  <ProjectTextField
                  style={{ height: '47px'}}
                  MenuProps={projectTextFieldProps}
                    variant="outlined"
                    fullWidth
                    type="number"
                    defaultValue="all"
                    inputProps={{
                      "data-test-id": "EDITEDCONTAINERSIZETWO",
                    }}
                    FormError={
                      errors.EDITEDCONTAINERSIZETWO !== undefined &&
                      touched.EDITEDCONTAINERSIZETWO !== undefined && values.EDITEDCONTAINERSIZETWO === ""
                    }
                    value={values.EDITEDCONTAINERSIZETWO}
                    id="EDITEDCONTAINERSIZETWO"
                    name="EDITEDCONTAINERSIZETWO"
                    onChange={(
                      e: React.ChangeEvent<{ value: unknown }>
                    ) => {
                      this.EditedBuyerListingFormTextFieldChange(
                        e,
                        setFieldValue,
                        "EDITEDCONTAINERSIZETWO",
                        values
                      );
                    }}
                  >                  
                    {values?.EDITEDCONTAINERSIZETWO ? this.state.selectedContainer?.buyerBox_sizes
                      ?.filter((valueEd: {size : string}) => valueEd?.size === values.EDITEDCONTAINERSIZETWO)
                      .map((valueEdSize: {size: string}) => {
                        return (
                          <OptionForSelect value={valueEdSize?.size} key={valueEdSize?.size}>
                            <ProjectText variant="h6" TextSize="14px" fontweight="500">
                              {valueEdSize?.size}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      }) : (
                        this.state.selectedContainer?.buyerBox_sizes
                      ?.filter((valueEd: {size : string}) => valueEd?.size !== values.EDITEDCONTAINERSIZE)
                      .map((valueEdSize: {size: string}) => {
                        return (
                          <OptionForSelect value={valueEdSize?.size} key={valueEdSize?.size}>
                            <ProjectText variant="h6" TextSize="14px" fontweight="500">
                              {valueEdSize?.size}
                            </ProjectText>
                          </OptionForSelect>
                        );
                      })
                      )}
                  </ProjectTextField>
                  {this.BuyerErrorRender(
                    errors.EDITEDCONTAINERSIZETWO,
                    errors.EDITEDCONTAINERSIZETWO !== undefined &&
                      touched.EDITEDCONTAINERSIZETWO !== undefined && values.EDITEDCONTAINERSIZETWO === ""
                    
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "10px" }}
              >
                <Grid item xs lg={6}>
                  <InputLabel htmlFor="EDITEDRATETWO">
                    <ProjectText
                      Upercase
                      TextSize="14px"
                      variant="subtitle1"
                      fontweight="900"
                      textcolor="black"
                    >
                      BUYER PRICE
                    </ProjectText>
                  </InputLabel>
                  <LandingPageTextField
                    BoderRadius="10px"
                    inputProps={{
                      "data-test-id": "EDITEDRATETWO",
                      style:{height: '26px'}
                    }}
                    variant="outlined"
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      this.EditedBuyerListingFormTextFieldChange(
                        e,
                        setFieldValue,
                        "EDITEDRATETWO",
                        values
                      );
                    }}
                    FormError={
                      errors.EDITEDRATETWO !== undefined &&
                      touched.EDITEDRATETWO !== undefined
                    }
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                    type="text"
                    size="small"
                    value={values.EDITEDRATETWO}
                    name="EDITEDRATETWO"
                    id="EDITEDRATETWO"
                    InputProps={{
                      endAdornment: (
                        <Box style={{ backgroundColor: "white" }}>
                          <ProjectText
                            TextSize="1rem"
                            textcolor="#ACACAC"
                          >
                            {/* /{values.EDITEDCONTAINERSIZETWO?.split(' ').join('') || '40FT'} */}
                            /{this.editeSecondContainerSize(values)}
                          </ProjectText>
                        </Box>
                      ),
                      startAdornment: (
                        this.renderUSDFieldAdornment()
                      ),
                    }}
                    fullWidth
                  />
                  {this.BuyerErrorRender(
                    errors.EDITEDRATETWO,
                    errors.EDITEDRATETWO !== undefined &&
                    touched.EDITEDRATETWO !== undefined
                  )}

                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box style={{ display: "flex" }}>
                    <InputLabel htmlFor="EDITEDTERMSTHREE">
                      <ProjectText
                        Upercase
                        TextSize="14px"
                        variant="subtitle1"
                        fontweight="900"
                        textcolor="black"
                      >
                        TERMS
                      </ProjectText>
                    </InputLabel>
                  </Box>
                  <Grid container spacing={2} justifyContent="center" className="SlashClassContainer">
                    <Grid item xs lg={6}>
                      <LandingPageTextField
                        inputProps={{
                          "data-test-id": "EDITEDTERMSTHREE",
                          style: {height: '26px'}
                        }}
                        BoderRadius="10px"
                        variant="outlined"
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          this.EditedBuyerListingFormTextFieldChange(
                            e,
                            setFieldValue,
                            "EDITEDTERMSTHREE",
                            values
                          );
                        }}
                        FormError={
                          errors.EDITEDTERMSTHREE !== undefined &&
                          touched.EDITEDTERMSTHREE !== undefined
                        }
                        size="small"
                        type="text"
                        value={
                          values.EDITEDTERMSTHREE
                        }
                        id="EDITEDTERMSTHREE"
                        name="EDITEDTERMSTHREE"
                        fullWidth
                      />
                      {this.BuyerErrorRender(
                        errors.EDITEDTERMSTHREE,
                        errors.EDITEDTERMSTHREE !== undefined &&
                        touched.EDITEDTERMSTHREE !== undefined
                      )}</Grid>
                       <Box className="SlashClass"><Box><span>/</span></Box></Box><Grid item xs lg={6}>
                      <LandingPageTextField
                        BoderRadius="10px"
                        inputProps={{
                          "data-test-id": "EDITEDTERMSFOUR",
                          style: {height: '26px'}
                        }}
                        variant="outlined"
                        onChange={(
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          this.EditedBuyerListingFormTextFieldChange(
                            e,
                            setFieldValue,
                            "EDITEDTERMSFOUR",
                            values
                          );
                        }}
                        size="small"
                        FormError={
                          errors.EDITEDTERMSFOUR !== undefined &&
                          touched.EDITEDTERMSFOUR !== undefined
                        }
                        type="text"
                        value={
                          values.EDITEDTERMSFOUR
                        }
                        id="EDITEDTERMSFOUR"
                        name="EDITEDTERMSFOUR"
                        fullWidth
                      />
                      {this.BuyerErrorRender(
                        errors.EDITEDTERMSFOUR,
                        errors.EDITEDTERMSFOUR!== undefined &&
                        touched.EDITEDTERMSFOUR !== undefined
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          </>
        );
      };

      getNestedStateValueHelperFunc = (path: (string | number)[], defaultValue: any = ''): any => {
        return path.reduce((acc: any, val: string | number ) => acc?.[val] ?? defaultValue, this.state);
      };


      getInitialFormValuesForEdit =() =>{
        const basePath = ['catchEditedData'];
        // this.setState({newPOLTerminalValue: this.getNestedStateValueHelperFunc(['catchEditedData', 'request', 'data', 'attributes', 'pol_terminal']), newPODTerminalValue: this.getNestedStateValueHelperFunc(['catchEditedData', 'request', 'data', 'attributes', 'pod_terminal'])})
      
        return {
          EDITEDBUYERPRINCIPALORGANISATION: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'principal_organization_name']),
          EDITEDBUYERPOD: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'pod']),
          EDITEDPODTERMINAL: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'pod_terminal']),
          EDITEDPOL: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'pol']),
          EDITEDPOLTERMINAL: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'pol_terminal']),
          EDITEDETBATPOL: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'container_loading_range_start']),
          EDITEDETAATPOD: this.getNestedStateValueHelperFunc([...basePath, 'request', 'data', 'attributes', 'container_loading_range_end']),
          EDITEDCONTAINERTYPE: this.getNestedStateValueHelperFunc([...basePath, 'container', 'container_type']),
          EDITEDCONTAINERCODES: this.getNestedStateValueHelperFunc([...basePath, 'container', 'codes'], []),
          EDITEDCONTAINERSIZE: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'container_size'], ''),
          EDITEDTOTALQUANTITY: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'quantity'],''),
          EDITEDAVERAGETONSBOX: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'tons_per_teu_extra'], ''),
          EDITEDRATE: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'desired_rates'], ''),
          EDITEDTERMS: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'terms'], '').split('/')[0],
          EDITEDTERMSTWO: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 0, 'terms'], '').split('/')[1],
          EDITEDCONTAINERSIZETWO: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'container_size'], ''),
          EDITEDTOTALQUANTITYTWO: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'quantity'], ''),
          EDITEDAVERAGETONSBOXTWO: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'tons_per_teu_extra'], ''),
          EDITEDRATETWO: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'desired_rates'], ''),
          EDITEDTERMSTHREE: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'terms'], '').split('/')[0],
          EDITEDTERMSFOUR: this.getNestedStateValueHelperFunc([...basePath, 'container_details', 1, 'terms'], '').split('/')[1],
        }
      }


      buyerListUpdatedModal = () => {
        return (
          <Box style={webStyle.listingSubmittedBox} data-test-id="buyerListUpdated"><div style={webStyle.textEnd}><img src={close} alt="close" onClick={this.handleCloseAddEditSuccessModal} /></div>
            <div style={{ ...webStyle.textCenter, ...webStyle.pTB50 }}><div style={webStyle.mb20}><img style={webStyle.successImg} src={orangeSucessIcon} alt="success image" /></div>
            <h1 style={webStyle.listingHeading}>Request Updated!</h1>
            <p style={webStyle.listingIdPara}>Your request (ID: {this.state.buyerUpdatedListingID}) has been updated successfully and shared with the sellers.</p>
            <p style={webStyle.listingIdPara}>You will be notified when you receive offers.</p></div>
          </Box>
        )
      }

      onEditRequestFormSubmit = (values: any) => { const isPOLTerminalValid = this.state.buyerPolData?.filter((item: any) => (values.EDITEDPOL === item.attributes.port_code)).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)?.includes(values.EDITEDPOLTERMINAL)
      
      const isPODTerminalValid = this.state.buyerPolData?.filter((item: any) => (values.EDITEDBUYERPOD === item.attributes.port_code)).map((item: {attributes: {port_country: string[]}}) => item?.attributes?.port_country)?.flatMap((array: any) => array)?.includes(values.EDITEDPODTERMINAL)
      if(isPOLTerminalValid && isPODTerminalValid){ this.updateBuyerListing(values, this.state.catchEditedData?.request?.data?.id, this.state.catchEditedData?.container?.id,  this.state.catchEditedData?.container_details?.[0]?.id, this.state.catchEditedData?.container_details?.[1]?.id);}
      
      if(
          !isPOLTerminalValid
          ){
          toast.warning("Please select POL Terminal value")
      }
      
      if(!isPODTerminalValid){
          toast.warning("Please select POD Terminal value")
      }
      }



      EditedBuyerListingForm = () => {
        return (
          <StyledFormDialog open={this.state.triggerEditForm} maxWidth="md" fullWidth>
            {
              this.state.updateBuyerListing && (
              <StyleModel>
                  <Formik
                    
                    enableReinitialize={this.state.reinitialisedEditedForm}
                    validationSchema={() => this.EditedBuyerListingSchema()}
                    validateOnChange={true}
                    initialValues={this.getInitialFormValuesForEdit()}

                    onSubmit={(values) => {
                      this.onEditRequestFormSubmit(values);
                    }}
                    validateOnBlur={true}
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      handleSubmit,
                      isValid,
                      values,
                      
                    }) => {
                      return (
                        <form
                          autoComplete="off"
                          onSubmit={handleSubmit}
                          className="FormOrganisationDetails"
                        >
                          <Box style={webStyle.addListingModal}>
                            <Box
                              style={webStyle.close}
                              data-test-id="edited_close-btn"
                              onClick={this.handleEditedBuyerClose}
                            >
                              <img src={close} alt="" />
                            </Box>
                            <h1 style={webStyle.title}>Edit Request</h1>
                            <section style={webStyle.organisation}>
                              <InputLabel htmlFor="EDITEDBUYERPRINCIPALORGANISATION">
                                <ProjectText
                                  variant="subtitle1"
                                  Upercase
                                  TextSize="14px"
                                  fontweight="900"
                                  textcolor="black"
                                >
                                  PRINCIPAL ORGANISATION NAME
                                </ProjectText>
                              </InputLabel>
                              <ProjectTextField
                              style={{ height: '47px'}}
                              MenuProps={projectTextFieldProps}
                                variant="outlined"
                                defaultValue="all"
                                fullWidth
                                FormError={
                                errors.EDITEDBUYERPRINCIPALORGANISATION !== undefined &&
                                  touched.EDITEDBUYERPRINCIPALORGANISATION !== undefined
                                }
                                value={values.EDITEDBUYERPRINCIPALORGANISATION}
                                inputProps={{
                                  "data-test-id": "EDITEDBUYERPRINCIPALORGANISATION",
                                }}
                                name="EDITEDBUYERPRINCIPALORGANISATION"
                                id="EDITEDBUYERPRINCIPALORGANISATION"
                                onChange={(e) =>
                                  this.EditedBuyerListingFormTextFieldChange(
                                    e,
                                    setFieldValue,
                                    "EDITEDBUYERPRINCIPALORGANISATION",
                                    values
                                  )
                                }
                              >
                                {
                                  this.renderPrincipleOrgBlock()
                                }
                              </ProjectTextField>
                              {this.BuyerErrorRender(
                                errors.EDITEDBUYERPRINCIPALORGANISATION,
                                errors.EDITEDBUYERPRINCIPALORGANISATION !== undefined &&
                                touched.EDITEDBUYERPRINCIPALORGANISATION !== undefined
                              )}
                            </section>
      
                            {this.renderEditedVasselBlock(
                              errors,
                              touched,
                              values,
                              setFieldValue
                            )}

                            <section style={webStyle.vesselContainer}>
                              <h3 style={webStyle.vesselHeading}>
                                Container & Pricing Details
                              </h3>

                              {this.renderEditedContainerFirstBlock(
                              errors,
                              touched,
                              values,
                              setFieldValue
                            )}

                              {this.editContainerSizeButton(this.state.isEditContainerClicked, values, setFieldValue)}
                              
                              {/* Edited 2nd container part */}
                              
                            {

                             this.state.isEditContainerClicked ? this.renderEditedContainerSecondBlock(
                              errors,
                              touched,
                              values,
                              setFieldValue
                              ) : ""
                            }

                            </section>
      
                            <div style={webStyle.dFlexJCenter}>
                              <ContainedButton
                                type="submit"
                                data-test-id="EditedBuyerSubmitForm"
                                style={webStyle.btnTheme}
                              >
                                Submit
                              </ContainedButton>
                            </div>
                          </Box>
                        </form>
                      );
                    }}
                  </Formik>
              </StyleModel>
              )
            }

            {/* Edit buyer success popup */}

            {this.state.buyerListingAdded && this.buyerListUpdatedModal()}

          </StyledFormDialog>
        );
      };

      // EDIT FLOW END

      editContainerSizeButton = (isSecondContainerClicked: boolean,
        values: {
          EDITEDCONTAINERTYPE: string;
          EDITEDCONTAINERCODES: any[];
          EDITEDCONTAINERSIZE: string;
          EDITEDAVERAGETONSBOX: number;
          EDITEDRATE: string;
          EDITEDTERMS: string;
          EDITEDTERMSTWO: string;
          EDITEDCONTAINERSIZETWO: string;
          EDITEDAVERAGETONSBOXTWO: string;
          EDITEDRATETWO: string;
          EDITEDTERMSTHREE: string;
          EDITEDTERMSFOUR: string;
        },
        setFieldValue: (field: string, value: { EDITEDCONTAINERSIZE: string; EDITEDAVERAGETONSBOX: number; EDITEDRATE: string; EDITEDTERMS: string; EDITEDTERMSTWO: string }, shouldValidate?: boolean | undefined) => void
      ) => {
        
        let notdata = values.EDITEDCONTAINERSIZE && values.EDITEDAVERAGETONSBOX && values.EDITEDRATE && values.EDITEDTERMS && values.EDITEDTERMSTWO
        return !values.EDITEDCONTAINERSIZETWO && !isSecondContainerClicked && (
          <div style={webStyle.dFlexJCenter}>
            <ContainedButton onClick={() => this.handleEditContainerClick()} MarginFalse
              BorderColor={"black"}
              TextColor="black" bgcolor="transparent"
              HoverColor="transparent"
              style={{padding: '8px 16px'}}
              data-test-id="EDITCONTAINERSIZEBTN"
              disabled={!notdata}
              startIcon={<img src={add_black} className="BlackPlusIcon" alt="" style={webStyle.btnImgDiv} />}
              >
                ADD CONTAINER SIZE
            </ContainedButton>
          </div>
        )
      }

dataRangeFiter = ()=>{
  return(
    <Box style={{ position: "absolute",
    top: "70px", right: "0px"  }}>
    {this.state.open &&
      <DateContainer style={{ position: "relative" }}
       data-test-id="DateFilterMainDiv">
        <DatePickerStyle   open={this.state.open}
          toggle={this.TogolFilter}   wrapperClassName="DateMainClassName"
          className="DateMainClassName"
          onChange={(range: DateRange) => {this.SetDateFilterData(range)}}
          initialDateRange={{
            startDate: this.state.DateRangeStartDate || new Date(),
            endDate: this.state.DateRangeEndDate || new Date()
          }}
        />
        <Box className="ButtonContaienr">
          <Button style={{ marginRight: "10px" }}
           data-test-id="ClearFilter" variant="text" onClick={()=> {this.ClearFilterData()} }>
            <ProjectText variant="h6" 
            TextSize="16px" 
            textcolor="primary">
              clear
            </ProjectText>
          </Button>
          <Button variant="contained" 
          data-test-id="SelectFilter" 
          onClick={this.FilterDateSelect} color="primary" className="SelectButton">
            <ProjectText variant="h6" 
            TextSize="16px" 
            textcolor="white">
              Select
            </ProjectText>
          </Button>    </Box>
      </DateContainer>
    }
  </Box>
  )
}

renderRequestFilter = () => {
  return(
    <>
    <FormControl style={{ width: "100%", marginRight: "15px" }}>
            <Box style={{ marginTop: "24px" }}>
                  <StyledBox
                    onClick={() => this.handleBuyerOfferExpanded("listingID")}
                    data-test-id="buyerListingfilter"
                  >
                    <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpanded ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("listingID")} />
                  </DropdownArrowBox>
                  </StyledBox>
              <StyledInputLable shrink>REQUEST ID</StyledInputLable>
              {this.state.buyerExpanded&&<ClickAwayListener
              data-test-id="requestIdClickAway"
                onClickAway={() => this.setState({ buyerExpanded: false })}
              >
                
                  <Collapse
                    in={this.state.buyerExpanded}
                    unmountOnExit
                    timeout="auto"
                    disableStrictModeCompat={false}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      width: "100%",
                      display: "flex",
                      background: "#fff",
                      flexDirection: "column",
                      maxHeight: "178px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                     minHeight: "30px",
                    }}
                  >
                    {this.state.buyerFilter.listingID?.map(
                      (item: { label: string; isChecked: boolean }, index:number) => {
                        return (
                          <CustomFormControlLabel
                            key={item.label}
                            control={
                              <Checkbox
                              color="primary"
                              checked={item.isChecked}
                                data-test-id={"buyerListingcheck"+index}
                                onClick={(e) =>
                                  this.handleBuyerOfferChange(
                                    e,
                                    this.state.buyerFilter.listingID,
                                    "listingID"
                                  )
                                }
                                name={item.label}
                                style={{ paddingRight: "30px" }}
                              />
                            }
                            label={item.label}
                            labelPlacement="start"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "row-reverse",
                              fontFamily: "Asap",
                              fontSize: "14px",
                            }}
                          />
                        );
                      }
                    )}
                  </Collapse>
               
              </ClickAwayListener>}
              <Box
                style={{
                  padding: "2px",
                  width: "220px",
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  gap: "5px",
                  marginTop: "3px",
                }}
              >
                {this.state.buyerFilter.listingID.map(
                  (option: { label: string; isChecked: boolean }, index: number) => {
                    if (option.isChecked) {
                      return (
                        <li
                          key={option.label}
                          style={{ fontFamily: "Asap", fontSize: "14px" }}
                        >
                        <StyledChip
                          icon={<CancelIcon data-test-id={`buyerDeletChipListingIdChip${index}`} onClick={() => {
                            this.handleBuyerOfferDelete(
                              option,
                              this.state.buyerFilter.listingID,
                              "listingID"
                            )
                          }} />}
                          label={option.label}
                        />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
              </Box>
            </FormControl>
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
            <Box style={{ marginTop: "24px" }}>
                  <StyledBox data-test-id="polfilter" onClick={() => this.handleBuyerOfferExpanded("pol")}>
                  <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPol ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("pol")} />
                  </DropdownArrowBox>
                  </StyledBox>
              <StyledInputLable shrink>POL</StyledInputLable>{" "}
              {this.state.buyerExpandedPol&&<ClickAwayListener
               data-test-id="polClickAway"
                onClickAway={() => this.setState({ buyerExpandedPol: false })}
              >
                        
                  <Collapse
                    in={this.state.buyerExpandedPol}
                    timeout="auto"
                    unmountOnExit
                    disableStrictModeCompat={false}
                    style={{
                      position: "absolute",
                      background: "#fff",
                      zIndex: 1,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "178px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                     minHeight: "30px",
                    }}
                  >
                    {this.state.buyerFilter?.pol?.map(
                      (item: { label: string; isChecked: boolean }, index:number) => {
                        return (
                          <CustomFormControlLabel
                            key={item.label}
                            control={
                              <Checkbox
                              data-test-id={"buyerPolcheck"+index}
                                color="primary"
                                checked={item.isChecked}
                                onClick={(e) =>
                                  this.handleBuyerOfferChange(
                                    e,
                                    this.state.buyerFilter.pol,
                                    "pol"
                                  )
                                }
                                name={item.label}
                                style={{ paddingRight: "30px" }}
                              />
                            }
                            label={item.label}
                            style={{
                              fontFamily: "Asap",
                              display: "flex",
                              flexDirection: "row-reverse",
                              justifyContent: "space-between",
                              fontSize: "14px",
                            }}
                            labelPlacement="start"
                          />
                        );
                      }
                    )}
                  </Collapse>
                
              </ClickAwayListener>}
              <Box
                style={{
                  width: "220px",
                  marginTop: "3px",
                  display: "flex",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  gap: "5px",
                  listStyle: "none",
                  padding: "2px",
                }}
              >
                {this.state.buyerFilter.pol.map(
                  (option: { label: string; isChecked: boolean }, index) => {
                    if (option.isChecked) {
                      return (
                        <li
                        style={{ fontFamily: "Asap", fontSize: "14px" }}
                          key={option.label}
                        >
                        <StyledChip
                          icon={<CancelIcon data-test-id={`buyerDeletPolIdChip${index}`} onClick={() => {
                            this.handleBuyerOfferDelete(
                              option,
                                this.state.buyerFilter.pol,
                                "pol"
                            )
                          }} />}
                          label={option.label}
                        />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
              </Box>
            </FormControl>
    </>
  )
}

      renderFilterSection = () => {
        return (
          <CustomBox>
            {this.renderRequestFilter()}
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
            <Box style={{ marginTop: "24px" }}>
                  <StyledBox data-test-id="podfilter" onClick={() => this.handleBuyerOfferExpanded("pod")}>
                  <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedPod ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("pod")} />
                  </DropdownArrowBox>
                  </StyledBox>
              <StyledInputLable shrink>POD</StyledInputLable>{" "}
              {this.state.buyerExpandedPod&&<ClickAwayListener
               data-test-id="podClickAway"
                onClickAway={() => this.setState({ buyerExpandedPod: false })}
              >
               
                  <Collapse
                    in={this.state.buyerExpandedPod}
                    timeout="auto"
                    unmountOnExit
                    disableStrictModeCompat={false}
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      background: "#fff",
                      maxHeight: "178px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                     minHeight: "30px",
                    }}
                  >
                    {this.state.buyerFilter?.pod?.map(
                      (item: { label: string; isChecked: boolean },index:number) => {
                        return (
                          <CustomFormControlLabel
                            key={item.label}
                            control={
                              <Checkbox
                              data-test-id={"buyerPodCheck"+index}
                                color="primary"
                                checked={item.isChecked}
                                onClick={(e) =>
                                  this.handleBuyerOfferChange(
                                    e,
                                    this.state.buyerFilter.pod,
                                    "pod"
                                  )
                                }
                                name={item.label}
                                style={{ paddingRight: "30px" }}
                              />
                            }
                            label={item.label}
                            labelPlacement="start"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontFamily: "Asap",
                              fontSize: "14px",
                              flexDirection: "row-reverse",
                            }}
                          />
                        );
                      }
                    )}
                  </Collapse>
                
              </ClickAwayListener>}
              <Box
                style={{
                  padding: "2px",
                  display: "flex",
                  width: "220px",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  listStyle: "none",
                  gap: "5px",
                  marginTop: "3px",
                }}
              >
                {this.state.buyerFilter.pod.map(
                  (option: { label: string; isChecked: boolean }, index: number) => {
                    if (option.isChecked) {
                      return (
                        <li
                          key={option.label}
                          style={{ fontFamily: "Asap", fontSize: "14px" }}
                        >
                        <StyledChip
                          icon={<CancelIcon data-test-id={`buyerDeletPodIdChip${index}`} onClick={() => {
                            this.handleBuyerOfferDelete(
                              option,
                              this.state.buyerFilter.pod,
                              "pod"
                            )
                          }} 
                          
                          />}
                          label={option.label}
                        />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
              </Box>
            </FormControl>
            <FormControl style={{ width: "100%", marginRight: "15px" }}>
            <Box style={{ marginTop: "24px" }}>
                  <StyledBox data-test-id="typefilter" onClick={() => this.handleBuyerOfferExpanded("type")}>
                  <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.buyerExpandedType ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleBuyerOfferExpanded("type")} />
                  </DropdownArrowBox></StyledBox>           
              <StyledInputLable shrink>STATUS</StyledInputLable>{" "}
              {this.state.buyerExpandedType&&<ClickAwayListener
               data-test-id="typeClickAway"
                onClickAway={() => this.setState({ buyerExpandedType: false })}
              >
                  <Collapse
                    in={this.state.buyerExpandedType}
                    timeout="auto"
                    unmountOnExit
                    disableStrictModeCompat={false}
                    style={{
                      zIndex: 1,
                      position: "absolute",
                      background: "#fff",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "178px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                     minHeight: "30px",
                    }}
                  >
                    {this.state.buyerFilter?.type?.map(
                      (item: { label: string; isChecked: boolean }, index:number) => {
                        return (
                          <CustomFormControlLabel
                            key={item.label}
                            control={
                              <Checkbox
                              data-test-id={"buyerTypeCheck"+index}
                                color="primary"
                                checked={item.isChecked}
                                onClick={(e) =>
                                  this.handleBuyerOfferChange(
                                    e,
                                    this.state.buyerFilter.type,
                                    "type"
                                  )
                                }
                                name={item.label}
                                style={{ paddingRight: "30px" }}
                              />
                            }
                            labelPlacement="start"
                            label={item.label}
                            style={{
                              display: "flex",
                              fontSize: "14px",
                              flexDirection: "row-reverse",
                              fontFamily: "Asap",
                              justifyContent: "space-between",
                            }}
                          />
                        );
                      }
                    )}
                  </Collapse>
              </ClickAwayListener>}
              <Box
                style={{
                  display: "flex",
                  width: "220px",
                  justifyContent: "start",
                  marginTop: "3px",
                  gap: "5px",
                  flexWrap: "wrap",
                  padding: "2px",
                  listStyle: "none",
                }}
              >
                {this.state.buyerFilter.type.map(
                  (option: { label: string; isChecked: boolean }, index) => {
                    if (option.isChecked) {
                      return (
                        <li
                          key={option.label}
                          style={{ fontFamily: "Asap", fontSize: "14px" }}
                        >
                          <StyledChip
                            icon={<CancelIcon data-test-id={`buyerDeletTypeIdChip${index}`} onClick={() => {
                              this.handleBuyerOfferDelete(
                                option,
                                this.state.buyerFilter.type,
                                "type"
                              )
                            }} 
                            
                            />}
                            label={option.label}
                          />
                        </li>
                      );
                    }
                  }
                )}
              </Box>
              </Box>
            </FormControl>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <StyledInputLable
                htmlFor="ETB DATE RANGE"
                style={{ fontWeight: 700 }}
                shrink
              >
                LOADING DATE RANGE
              </StyledInputLable>
              <BootstrapInput
               defaultValue="" fullWidth
               id="ETB DATE RANGE"
               data-test-id="dateopne"   onClick={this.TogolFilter}
               value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : "" } />
               <CalendarImgBox><img src={DateTimeIcon} alt="" onClick={this.TogolFilter} /></CalendarImgBox>
          {this.dataRangeFiter()}
            </FormControl>
          </CustomBox>
        )
      }
      getPrincipalOrg(principalOrgList: any): string | JSX.Element{
        if (Array.isArray(principalOrgList)) 
        {
          return "ALL"
        } 
        else if (principalOrgList.type === "organization") 
        {
          return (
          <> 
          <span>{principalOrgList?.attributes?.name}
      </span> 
      <p  style={webStyle.boxtextp}>
          {principalOrgList?.attributes?.code}
           </p>
  </> 
  ) }
        return "ALL"
      }
       MapData = () => {
        // Extracting state variables
        let text = "";
        const activeSlotFilter = this.state.activeSlotFilter;
        let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
      
        // Building the text and backgroundColor strings
        activeSlotFilter.forEach((slot: any) => {
          text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `;
          if (slot.attributes?.color_name) 
          {
            backgroundColor += ` ${slot.attributes.color_name} `; 
          }}
        );        
        if (activeSlotFilter.length === 0) {
          return this.getPrincipalOrg(this.state.buyerPrinicpalOrgList);
        }
      
        // Truncating text if necessary
        const truncatedText = 
        text.length > 28 ? text.slice(0, 28) + " ..." 
        : text;
      
        return ( <div 
          className="DropDownDiv" 
          title={text?.split("/").join(" ")}
          >
            {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
              if (index % 2 === 0) {
                return (
                  <h3 
                  key={index}
                  className="DropDownDivh3"
                   > 
                   {txt?.split("/").join(" ")} 
                   </h3>
                )}
                 else {
                return (<p className="DropDownDivP" 
                key={index}
                    style={{padding: ".25rem .625rem",
                     borderRadius: ".25rem",
                      backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],
                    }
                  }>
                     {txt} </p>
                );
              } 
            }) }
               </div>   );
      }; 
      renderSlotForSwitchAndOrgSelectSection = () => {
         
        const MapDataElse = () => {
          return (
            <div className="DropDownDiv">
              <h3 
              style={{ fontSize: '1rem' }}>
                {this.state.activeSlotFilter?.attributes?.name}
                </h3>
              <p style={{ 
                padding: '.25rem .625rem', borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name }}>
                {this.state.activeSlotFilter?.attributes?.code}</p>
            </div>  )
        }    
        return (
            <Grid item 
            lg={6} md={6}>
              <SectionWrapperLeft 
              style={buyerStyle.leftBar}>
                <StyledSwitch>
                  <div className="switch" 
                  onClick={() => this.SwitchChnages()} 
                  data-test-id="switch-changes">
                    <input className="Inputswitch"   type="checkbox"
                      checked={this.state.switch}  />
                    <span className="slider round">
                      <ProjectText TextSize="1rem" 
                      fontweight="700" className="text">
                        {this.state.switch ? "BUY" : ""}  </ProjectText>
                    </span>   </div>
                </StyledSwitch>  
                <h1 style={buyerStyle.slotsFor}>
                  Slots for
                  </h1>
                  {!this.state.isSlotFilterOpen && ( 
                  <div 
                  style={{ position: 'relative', fontWeight: 500,
                  fontFamily: 'Asap',color: 'rgba(30, 30, 30, 1)', fontSize: '.875rem' }}
                  >
                    <div className="selectSlotInput" data-test-id="organizationFilter" style={buyerStyle.selectSlot}
                    onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                      >
                      <div style={{ fontWeight: 500,overflow: 'auto' , display: 'flex',alignItems:"center"}}>
                        {Array.isArray(this.state.activeSlotFilter) ?
                          this.MapData() : 
                          <MapDataElse/>  }  </div>
                      <img 
                      alt="dropdown"
                      style={{width: '10px', height: '10px', objectFit: 'contain'}}
                      src={image_dropdown}
                        />
                    </div>
                    </div>
                    )}
                {this.state.isSlotFilterOpen&&<ClickAwayListener onClickAway={() => this.setState({ isSlotFilterOpen: false })}
                >
                  <div 
                  style={{ position: 'relative', color: 'rgba(30, 30, 30, 1)', fontFamily: 'Asap', 
                  fontSize: '.875rem', fontWeight: 500 }}>
                    <div 
                    data-test-id="organizationFilter"
                    className="selectSlotInput" 
                    onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                      style={buyerStyle.selectSlot}
                      >
                      <div 
                      style={{ fontWeight: 500, display: 'flex', overflow: 'auto',alignItems:"center" }}
                      >
                        {Array.isArray(this.state.activeSlotFilter) ? this.MapData()
                        : 
                          <MapDataElse/> 
                          }
                      </div>
                      <img src={image_dropdown} 
                      alt="dropdown" 
                      style={{width: '10px', height: '10px', objectFit: 'contain'}}
                      />
                    </div>
                    {this.state.isSlotFilterOpen &&
                     ( <Box>
                    {Array.isArray(this.state.buyerPrinicpalOrgList) ? (
                    <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown"
                     style={buyerStyle.selectSlotDropdown}>
                     {this.state.buyerPrinicpalOrgList?.map((val: any) => {
                      return (
                        <div key={val.id}
                          style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, ...buyerStyle.selectSlotItem }}>
                          <h4 style={{ textTransform: 'uppercase' }}>{val.attributes.name}
                          </h4>
                          <Checkbox color="primary"
                            checked={this.state.activeSlotFilter.includes(val)}
                            data-test-id="organizationFilterDropdownCheck"
                            onChange={(e) => this.handleSlotFilter(val, e.target.checked)}
                          />
                        </div>
                     )})}
                       </div>)
                    : this.state.buyerPrinicpalOrgList.type !== "organization" && (
                      <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown"
                     style={buyerStyle.selectSlotDropdown}>
                    <div  
                      key={this.state.buyerPrinicpalOrgList.id}
                          style={{ borderLeft: `.25rem solid ${this.state.buyerPrinicpalOrgList.attributes.color_name}`, 
                          ...buyerStyle.selectSlotItem }}>
                          <h4 style={{ textTransform: 'uppercase' }}
                          > {this.state.buyerPrinicpalOrgList.attributes.name}
                          </h4>
                          <Checkbox color="primary" 
                          checked=
                          {this.state.activeSlotFilter.includes(this.state.buyerPrinicpalOrgList)}
                          onChange={(e) => this.handleSlotFilter(this.state.buyerPrinicpalOrgList, e.target.checked)}/>
                        </div>
                        </div>
                    )               
                    }
                    </Box>
                    )}
                  </div>
                </ClickAwayListener>}
              </SectionWrapperLeft>
            </Grid>
        )
      }


      

      renderUSDFieldAdornment = () => {
        return (
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                backgroundColor: "white",
                paddingTop: "10px",
                marginRight: "0.8rem",
              }}
            >
              <ProjectText
                TextSize="1rem"
                fontweight="700"
              >
                USD
              </ProjectText>
            </Box>
            <div style={{ background: "#CDCDCD", width: "1px", height: "44px", marginRight: "6px" }}></div>
          </Box>
        )
      }

      editeSecondContainerSize = (values: any) => {
        let size = ""
         this.state.selectedContainer?.buyerBox_sizes
            ?.filter((valueEd: { size: string }) => valueEd?.size !== values.EDITEDCONTAINERSIZE)
            .map((valueEdSize: { size: string }) => size = valueEdSize.size);
            return size?.split(' ').join('');
    }


      spinnLoader = () => {
        if (this.state.loading) {
          return <Spinner spinnerModal={this.state.loading} />
        }
      }


       ColumnStyle=(index:any,columns:any) =>{
        return index === columns.length - 1 ? '8px' : '0px'
       }


    render() {
        const { anchorElDropdown } = this.state;
        const open = Boolean(anchorElDropdown);


       

        return (
            <StyleContainer>
                <Navbar SellerPendingScreen={true} isBuyerScreen={true} AppBarTransparent = {false} TabDataCount={this.DynamicDataSet} />
                {
                  this.spinnLoader()
                }
            <section style={buyerStyle.root}>
                    <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={this.state.buyerRequestSnackbarOpen}
                        autoHideDuration={5000}
                        onClose={this.handleSnackbarClose}
                    >
                        <SnackbarContent
                        style={{ backgroundColor: '#fff', color: '#f00', fontWeight: 'bolder' }}
                        message="Can not switch user as organisation role is not both"
                        />
                    </Snackbar>
            
                <Container maxWidth="xl" data-test-id="BuyerListing">
                <Grid container alignItems="center" 
          style={buyerStyle.slotsSection}>
                            {this.renderSlotForSwitchAndOrgSelectSection()}
                            <BuyerToggelComponentWeb buyerRequestApiCall={()=>this.getBuyerListingData(this.state.buyerLoginData.token)} apiCall={true}/>
                            </Grid>

                    <StyledTabs
                        value={this.state.currentTab}
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: { display: "none" },
                        }}
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        data-test-id="handletabchange"
                    >
                        <StyledTab disableTouchRipple label={`REQUESTS (${this.state.DynamicCount.requst ? this.state.DynamicCount.requst : 0})`} />
                        <BorderedStyledTab disableTouchRipple label={`SELLER OFFERS (${this.state.DynamicCount.offers ? this.state.DynamicCount.offers : 0})`} data-test-id="BuyersOffer" onClick={() => this.goToBuyerRequestPage("BuyersOffer")} />
                        <StyledTab disableTouchRipple label={`BOOKINGS (${this.state.DynamicCount.booking ? this.state.DynamicCount.booking : 0})`} onClick={() => this.props.navigation.navigate("BuyerMatches")} />
                    </StyledTabs>
                    {
                      this.renderFilterSection()
                    }
                    <TabPanel value={this.state.currentTab} index={0} data-test-id="BuyerTable">
                            <TableContainer>
                                <BuyerStyledTable aria-label="sticky table">
                                    <TableHead>
                                        <StyledTableHeaderRow>
                                            {columns.map((column: any, index: number) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    style={{ padding: '25px 16px',minWidth: column.minWidth, borderBottomLeftRadius: index === 0 ? '8px' : '0px', borderBottomRightRadius: this.ColumnStyle(index,columns) }}
                                                >
                                                    <div style={buyerStyle.dFlex}>{column.label.map((elem: any, i: number) => <span key={i} style={{paddingLeft:column.id == "requestedRate" ? "16px":"0px"}}>{elem}</span>)}</div>
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableHeaderRow>
                                    </TableHead>
                                    <TableBody>
                                    {/* Buyer dynamic data */}
                                    {Array.isArray(this.state.buyerListings)&&this.state.buyerListings?.map((list: any) => {
                                        return (
                                        <BuyerTableRow
                                            key={
                                            list?.request?.data?.id
                                            }
                                        >
                                          {this.renderInitialListingTableCells(list, buyerStyle)}
                                              
                                            {
                                              this.renderContainerCells(list, buyerStyle)
                                            }
                                            <TableCell style={buyerStyle.textCenter}>
                                                
                                              {/* EDIT/DELET POPUP */}

                                              <Button
                                                  id="basic-button"
                                                  aria-controls={open ? 'basic-menu' : undefined}
                                                  aria-haspopup="true"
                                                  aria-expanded={open ? 'true' : undefined}
                                                  onClick={(e) => open ? this.handleCloseDropdown(): this.handleClick(e, list?.request?.data?.id, list)}
                                                  data-test-id="EditDeletePopup"
                                                >
                                                  <img src={ellipsis} alt="" /> 
                                              </Button>
                                              <Popper
                                                placement="left-start"
                                                anchorEl={anchorElDropdown}
                                                open={open}
                                                data-test-id="deletePopupClose"
                                                role={undefined}
                                                transition
                                                disablePortal
                                              >
                                                {({ TransitionProps }) => (
                                                  <Grow
                                                  {...TransitionProps}
                                                  style={{ transformOrigin: 'top right' }}
                                                  >
                                                    <Paper>
                                                      <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                                                        <MenuList autoFocusItem={open} id="basic-menu">
                                                          <MenuItem data-test-id="EditClick" onClick={()=> this.handleEditClick()} style={webStyle.editText}>EDIT</MenuItem>
                                                          <MenuItem data-test-id="DeleteClick" onClick={()=> this.handleDeleteListClick()} style={webStyle.deleteText}>DELETE</MenuItem>
                                                        </MenuList>
                                                      </ClickAwayListener>
                                                    </Paper>
                                                  </Grow>
                                                )}
                                              </Popper>
                                            </TableCell>

                                        </BuyerTableRow>
                                            );
                                        })}
                                        
                                    </TableBody>
                                </BuyerStyledTable>
                            </TableContainer>
                           {!Array.isArray(this.state.buyerListings) &&
                            <div style={{...buyerStyle.noDataShow, 
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}>
                            <img
                                src={resultNotFound}
                                alt="resultNotFound"
                                style={buyerStyle.noDataImgShow}
                            />
                            <p style={{...buyerStyle.noDtataContent, fontWeight: "bold"}}>No Results Found!</p>
                            <p style={buyerStyle.dataMessage}>Change or Remove Filters and Try Again.</p>
                            </div>
                             }

                    </TabPanel>
                    <TabPanel value={this.state.currentTab} index={1}>
                    <BuyersOfferWeb navigation={undefined} id={""}/>
                    </TabPanel>
                    <TabPanel value={this.state.currentTab} index={2}>
                      <BuyerMatches navigation={undefined} id={""}/>
                    </TabPanel>

                    {/* buyer list before delet popup */}
                    

                    {this.buyerBeforeDeletListAlertModal()}


      
                </Container>

                {this.EditedBuyerListingForm()}

                

            </section>
            </StyleContainer>
        );
    }
}


export default BuyersRequest;

const DatePickerStyle = styled(DateRangePicker)({

  "& .MuiInputLabel-animated": {
    transition: 
    "unset"
  },

  "& .DateMainClassName": {
    "& .MuiPaper-root": {
      paddingBottom: "60px !important", backgroundColor: "grey",
    }
  }
})

const DateContainer = styled(Box)({
  "& .ButtonContaienr": {
    position: "absolute", 
    display: "flex",
    justifyContent: "flex-end",
    bottom: "2px",
    right: "10px",zIndex: 9,
    padding: ".625rem .375rem"
  },
  "& .DateMainClassName": {
    "& .MuiInputBase-input":{
      fontFamily: "Asap, sans-serif",
    },
    "& .MuiPaper-root": {
      paddingBottom: 
      "20px",
        "& .MuiGrid-wrap-xs-nowrap":{
        "&.MuiGrid-root":{
          "& .materialui-daterange-picker-MuiList-root-59":{ display:"none"}
        }
      }
    },
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      fontFamily: 'Asap !important'
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57":{
      color: "red !important",
    }
  },
  "& ul":{ marginBottom: "20px"},
  "& li":{ fontFamily: "Asap, sans-serif"}
})
const StyledAlertDialog=styled(Dialog)({
  "& .MuiDialog-paperWidthSm":{
    borderRadius:"24px"
  }
})

const StyledFormDialog=styled(Dialog)({
  "& .MuiDialog-paperWidthMd":{
    borderRadius:"24px"
  }
})

const StyleModel = styled(Box)({
  "& .inputHeightMedias": {
    height: "initial !important"
},
  "& .ErrorTextContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    "& .TextHidden": {
        visibility: "hidden"
    },
    "& .TextError": {
        fontSize: "16px",fontFamily: "Asap, sans-serif",
        color: "red",
        marginBottom: "0px",
        transition: "width 1s ease-out ",
    },
    "& .ErrorIconContainer": {
        "& .MuiSvgIcon-root": {
            color: "red"
        },
        marginRight: "6px",
    },
},
"& .MultiSelect": {
    "& .MuiOutlinedInput-input": {
        padding: "5px 12px",
        height: "33px"
    }
},
"& .dial-code": {
    fontFamily: "Asap, sans-serif",
    fontWeight: "500",
    color: "black !important",
},
"& .country-name": {
    fontFamily: "Asap, sans-serif",
},
"& .PhoneInputPackgeClass": {
    height: "40px",
    fontFamily: "Asap, sans-serif",
    width: "100%",
    borderRadius: "10px",
    fontWeight: 500,
    fontSize: "15px",
    backgroundColor: "white",
    "@media (max-width: 1200px)": {
        height: "30px",
        fontSize: "12px",
    }
},
"& .no-entries-message": {
    color: "black !important",
    fontFamily: "Asap, sans-serif",
    fontWeight: "500",
},

"& .ErrorPhoneClass": {
    "& .selected-flag": {
        backgroundColor: "#FBE9E9"
    },
    "& .PhoneInputPackgeClass": {
        backgroundColor: "#FBE9E9"
    },
    "& .flag-dropdown": {
        backgroundColor: "#FBE9E9"
    },
},
"& .selected-flag": {
    backgroundColor: "white",
    borderRadius: "10px"
},
"& .flag-dropdown": {
    borderRadius: "10px 0px 0px 10px",
    borderRight: "none",
},
"& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
        color: "red",
        fontSize: "18px",
        position: "absolute",margin: "6px 14px",
    }
},
"& .CodesMultiSelect": {
    height: "56px"
},
"& .AutoComplateTextField": {
    color: "#3CA8D6",
},
"& .DeleteChip": {
    margin: "0 2px",
    backgroundColor: "#e3e6ed",
    color: "black",
    borde: "none",
    fontWeight: "700"
},

"& .MuiAutocomplete-inputRoot":{    
    fontFamily:'asap'
  },
"& .ViewlessContainer": {
    "& .MuiInputBase-input .Mui-disabled": {
        color: "black"
    }
},
"& .SellerMultipleSelect": {
    "& .SellerReactSelects__control": {
        borderRadius: "10px",
    }
},
"& .centerGrid": {justifyContent: "center"
},
"& .AddContainerFirstButton": {
    display: "flex", 
    justifyContent: "flex-end",
    whiteSpace: "nowrap",
    "& .Mui-disabled": {
        backgroundColor: "lightgrey",        
        color: "grey",
        "& .BlackPlusIcon": 
        {
            filter: "brightness(1)"
        },
    },
    "@media (max-width: 960px)": {
        display: "flex",
        justifyContent: "center"
    }
},
"& .SlashClassContainer": 
{
    position: "relative",
    "& .SlashClass": 
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        transform: "translate(40%, 100%)"
    },
},
"& .CenterAddContainer": 
{
    display: "flex", 
    justifyContent: "center !important",
    "& .Mui-disabled": {
        backgroundColor: "lightgrey", 
        color: "grey"
    },
    "@media (max-width: 960px)": {
        display: "flex",
        justifyContent: "center"
    }
},
"& .CurrencyBox": {
    marginRight: "5px"
},
"& .ViewMoreContainer": 
{
    position: "absolute",
    right: "20px",
    "@media (max-width: 600px)": {
        marginTop: "20px"
    }
},
"& .BlackPlusIcon": {
    filter: "brightness(0)"
},
"& .AddContainerSecondButton": {
    display: "flex",
    justifyContent: "flex-start",
    "& .Mui-disabled": {
        backgroundColor: "lightgrey",
        color: "grey"
    }
},
"& .AddContainerSecondButtonCenter": {
    display: "flex",
    justifyContent: "flex-start",
    "& .Mui-disabled": {
        backgroundColor: "lightgrey",
        color: "grey"
    },
    "@media (max-width: 960px)": {
        display: "flex",
        justifyContent: "center"
    }
},
"& .DivedSection": {
    "& .MuiDivider-root": {
        backgroundColor: "white",
        height: "5px"
    }
},
  });


const StyleContainer = styled(Box)({
  "& .DropDownDiv": {
    display: 'flex', 
    flexShrink: 0, 
    textTransform: 'uppercase', 
    gap: '.625rem', 
    alignItems: 'center', 
    justifyContent: 'center',
     paddingRight: '.625rem', 
     paddingLeft: '.625rem',
    "& .DropDownDivh3": {
      fontSize: '1rem',
      margin:"0px",
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "& .DropDownDivP": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "@media (max-width: 600px)": {
      paddingLeft: '0px',
    },
  },
    "& .noData": {
      display: "flex",
      fontFamily: "Asap",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: "6rem",
    },
    "& .noDataImg": {
      height: "5rem",
    },
    "& .head": {
      margin: "0.5rem",
      fontFamily: "Asap",
      fontSize: "2rem",
      fontWeight: "bold",
    },
    "& .msg": {
      margin: "0rem",
      fontFamily: "Asap",
      fontSize: "1rem",
    },
  });
const buyerStyle = {
    root: {
        background: "#eee",
        padding: "30px 0",
        // height: "100vh"
        minHeight : "100vh"
    },
    slotsSection: {
      marginBottom: "3.125rem",
    },
    leftBar: {
      display: "flex",
      alignItems: "center",
    },
    slotsFor: {
      margin: 0,
      fontFamily: `"Asap", "sans-serif"`,
      fontWeight: 800,
      fontSize: "24px",
      color: "#1E1E1E",
    },
    selectSlot: {
      width: "24.3125rem", 
      height: "3rem",
      background: "rgba(255, 255, 255, 1)",
      border: ".0625rem solid rgba(205, 205, 205, 1)",
      marginLeft: ".9375rem",  
      padding: '1rem .75rem',
      borderRadius: ".5rem",    
      display: 'flex',
      alignItems: 'center', 
      justifyContent: 'space-between',
      cursor: 'pointer'
    },
    selectSlotDropdown: 
    {
      position: 'absolute' as 'absolute',
      width: '25.125rem',background: 'white',  
      borderRadius: '.5rem',
      boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
      padding: '.25rem .375rem', display: 'flex',
      flexDirection: "column" as "column",
       gap: '.125rem',left: '.9375rem',
       marginTop: '.25rem',
       zIndex: 2   },
    selectSlotItem:
     {
      display: 'flex', 
      width: '100%',
      height: '2.25rem', 
      justifyContent: 'space-between',
      alignItems: 'center', 
      paddingLeft: '1rem', },
      
    btnDiv: {
      display: "flex",
      alignItems: "center",
    },
    deleteText: {
        fontFamily: "Asap",
        fontSize: "14px",
        color: "#DC2626",
        margin: 0,
        cursor: 'pointer',
    },
    editText: {
        fontFamily: "Asap",
        fontSize: "14px",
        color: "#1E1E1E",
        margin: "0 0 15px 0",
        cursor: 'pointer',
    },
    uncontrolledBox: {
        display: "inline-block", 
        padding: "15px",
        minWidth: "150px",
        textAlign: "end" as "end",
    },
    btnImgDiv: {
        marginRight: "8px",
         width: "10%"
    },
    textEnd: {
        justifyContent: "end",
        display: "flex",
    },
    org: {
        color: "#1E1E1E", 
        background: "#B8D5F9",
        fontSize: "15px",
        fontFamily: 'Asap',
        padding: "6px 12px",
        borderRadius: "4px",
        fontWeight: 500,
    },
    tableContent: {
        color: "#363636", 
        fontSize: "15px",
        fontFamily: 'Asap',
        fontWeight: 500,
    },
    highlightedTableContent: {
      padding: '8px 0px',
      textAlign: 'center' as 'center',
      width: '100%'
    },
    typeDiv: {
        display: "flex", 
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontSize: "15px",
        fontWeight: 500,
        background: "#B8D5F9",
        justifyContent: "space-between",
        padding: "6px 12px",
        border: "1px solid #6495D2", 
        borderRadius: "4px",
        marginBottom: "3px"
    },
    pipe: {
        position: "absolute" as "absolute",
        background: "#6495D2",
        height: "32px",  
        width: "1px",
        top: 0,
        left: "78px"
    },
    quantityDiv: {
        alignItems: "center",
        border: "1px solid #DADADA",
        borderRadius: "8px",
        display: "flex",
        marginRight: "15px",
        padding: "10px 20px",
    },
    available: {
        fontSize: "15px",  
        color: "#3C7C09",
        fontFamily: "Asap", 
        fontWeight: 500,
    },
    availableQuantity: {
        fontSize: "18px", color: "#3C7C09",
        fontFamily: "Asap",
        fontWeight: 500,
        margin: 0
    },
    total: {
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontWeight: 500,
        background: "#EAEAEA",
        padding: "5px 10px",
        fontSize: "15px",
        borderRadius: "4px"
    },
    bookedAmount: {
        fontSize: "15px",
        fontWeight: 500,
        textAlign: "center" as "center",
        fontFamily: "Asap",
        color: "#DB2525",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        marginTop: "10px"
    },
    mr15: {
        marginRight: "15px"
    },
    tableLeftBorder: {
        borderRadius: "6px",
        borderLeft: "3px solid #B8D5F9",
        textAlign: "center" as "center"
    },
    tableRightBorder: {
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        textAlign: "center" as "center",
        borderRight: "3px solid #ffffff",
    },
    dFlex: {
        listStyle: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    textCenter: {
        textAlign: "center" as "center"
    },
    MainDiv: {
        height: "192px",
        marginLeft: "33%",
        width: "304px",
        display: "inline-flex",
        flexDirection: "column" as const,
        alignItems: "center",
        marginTop: "6%"
    },
    InnerDiv: {
        width: "180%",
        height: "100%",
        marginLeft: "40%"
    },
    headerImagess: {
        width: "100px",
        height: "100px",
        marginLeft: "30%"
    },
    headingNotFound: {
        color: "#1E1E1E",
        fontFamily: "Asap",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
    },
    headingChanges: {
        color: "#363636",
        fontFamily: "Asap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        width: "284px",
        height: "18px",
        marginTop: "auto"
    },
    tableYellowBorder: {
        borderRadius: "6px",
        borderLeft: "3px solid #D0B655",
        textAlign: "center" as "center"
    },
    orgYellow: {
        fontSize: "15px",
        padding: "6px 12px",
        color: "#1E1E1E",
        fontWeight: 500,
        fontFamily: "Asap",
        background: "#FFECA5",
        borderRadius: "4px"
    },
    typeDivYellow: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#FFECA5",
        border: "1px solid #FFECA5",
        fontSize: "15px",
        fontFamily: "Asap",
        fontWeight: 500,
        borderRadius: "4px",
        padding: "6px 12px",
        marginBottom: "3px"
    },
    tableGreenBorder: {
        borderLeft: "3px solid #7DBD43",
        textAlign: "center" as "center",
        borderRadius: "6px",
    },
    orgGreen: {
        background: "#CFF0B0",
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px",
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivGreen: {
        justifyContent: "space-between",
        display: "flex",
        background: "#CFF0B0",
        border: "1px solid #7DBD43",
        fontSize: "15px",
        color: "#1E1E1E",
        fontFamily: "Asap",
        padding: "6px 12px",
        borderRadius: "4px",
        fontWeight: 500,
        marginBottom: "3px"
    },
    tableDarkBlueBorder: {
        borderRadius: "6px",
        textAlign: "center" as "center",
        borderLeft: "3px solid #6AA2C6",
    },
    orgDarkBlue: {
        background: "#C2E7FF",
        fontWeight: 500,
        fontFamily: "Asap",
        fontSize: "15px",
        padding: "6px 12px",
        borderRadius: "4px",
        color: "#1E1E1E",
    },
    typeDivDarkBlue: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        fontSize: "15px",
        fontWeight: 500,
        borderRadius: "4px",
        fontFamily: "Asap",
        background: "#C2E7FF",
        padding: "6px 12px",
        border: "1px solid #6AA2C6",
        marginBottom: "3px"
    },
    tablePinkBorder: {
        textAlign: "center" as "center",
        borderLeft: "3px solid #FF8276",
        borderRadius: "6px",
    },
    orgPink: {
        fontWeight: 500,
        background: "#F5B7B1",
        fontFamily: "Asap",
        fontSize: "15px",
        color: "#1E1E1E",
        padding: "6px 12px",
        borderRadius: "4px"
    },
    typeDivPink: {
        background: "#F5B7B1",
        display: "flex",
        color: "#1E1E1E",
        justifyContent: "space-between",
        fontSize: "15px",
        fontFamily: "Asap",
        padding: "6px 12px",
        fontWeight: 500,
        borderRadius: "4px",
        border: "1px solid #FF8276",
        marginBottom: "3px"
    },
    tablePurpleBorder: {
        textAlign: "center" as "center",
        borderRadius: "6px",
        borderLeft: "3px solid #D87DFF",
    },
    orgPurple: {
        background: "#D7BDE2",
        fontFamily: "Asap",
        color: "#1E1E1E",
        padding: "6px 12px",
        fontWeight: 500,
        fontSize: "15px",
        borderRadius: "4px"
    },
    typeDivPurple: {
        display: "flex",
        justifyContent: "space-between",
        color: "#1E1E1E",
        background: "#D7BDE2",
        fontSize: "15px",
        padding: "6px 12px",
        fontFamily: "Asap",
        border: "1px solid #D87DFF",
        borderRadius: "4px",
        fontWeight: 500,
        marginBottom: "3px"
    },
    tableMustardBorder: {
        textAlign: "center" as "center",
        borderLeft: "3px solid #FFAA62",
        borderRadius: "6px",
    },
    orgMustard: {
        fontSize: "15px",
        fontWeight: 500,
        padding: "6px 12px",
        color: "#1E1E1E",
        background: "#F5CBA7",
        fontFamily: "Asap",
        borderRadius: "4px"
    },
    typeDivMustard: {
        display: "flex",
        justifyContent: "space-between",
        background: "#F5CBA7",
        fontSize: "15px",
        fontWeight: 500,
        border: "1px solid #FFAA62",
        padding: "6px 12px",
        color: "#1E1E1E",
        fontFamily: "Asap",
        borderRadius: "4px",
        marginBottom: "3px"
    },
    ellipsis: {
        cursor: "pointer"
    },
  
    addListingModal: {
        padding: "20px 10px",
        borderRadius: "24px",
    },
    p_10_b_30: {
        padding: "10px 30px"
    },
    close: {
        cursor: "pointer",
        textAlign: "end" as "end",
    },
    title: {
        fontSize: "28px",
        margin: "0 0 15px 0",
        fontFamily: "Asap, sans-serif",
        color: "#1E1E1E",
        textAlign: "center" as "center"
    },
    organisation: {
        margin: "0 20px 20px 20px"
    },
    principalLabel: {

        fontWeight: 600,
        fontSize: "14px",
        color: "#363636",
        fontFamily: "Asap, sans-serif",
    },
    principalLabelAverageBox: {
        color: "#363636",
        fontWeight: 600,
        fontFamily: "Asap, sans-serif",
        fontSize: "14px",
        display: "flex",
        justifyContent: "space-between"
    },
    Removes: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "#DB2525",
        fontFamily: "Asap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
    },
    icons: {
      width: "5%",
      height: "2%",
      marginLeft: "52%"
  },
  vesselContainer: {
      background: "#E3E6ED",
      padding: "25px 10px",
      borderRadius: "18px",
      marginBottom: "15px"
  },
  vesselHeading: {
      color: "#1E1E1E",
      fontSize: "18px",
      fontFamily: "asap, sans-serif",
      textAlign: "center" as "center",
      margin: "0 0 25px 0"
  },
  inputText: {
      border: "1px solid #CBD5E1",
      height: "44px",
      width: "100%",
      borderRadius: "5px",
      padding: "0 8px"
  },


  bBottom: {
      margin: "0 2px 20px 2px",
      border: "1px solid #CBD5E1",
  },
  rateBox: {
      alignItems: "center",
      border: "1px solid #CBD5E1",
      display: "flex",
      background: "#fff",
      borderRadius: "8px",
  },
  borderRight: {
      height: "41px",
      borderRight: "1px solid #CDCDCD",
  },
 
  rateDiv: {
      fontSize: "14px",
      color: "#1E1E1E",
      fontFamily: "Asap, sans-serif"
  },
  usd: {
      margin: "0 10px",
  },
  inputRateText: {
      height: "44px",border: "none",
      borderTopLeftRadius: "5px",width: "40px",
      padding: "0 8px",borderBottomLeftRadius: "5px",
  },
  slash: {
      position: "relative" as "relative",
      color: "gray",left: "12px"
  },
  ml5: {
      width: "4%",
      marginRight: '85%',
  },
  dFlexJCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
  },
  submitBtn: {
      display: "flex",alignItems: "center",
      justifyContent: "center",fontSize: "16px",
      background: "#AA4649",border: "none",
      borderRadius: "32px",padding: "9px 18px",
      color: "#FFFFFF",fontWeight: 600,
      height: "45px",width: "256px",
      textTransform: "uppercase" as "uppercase",
      cursor: "pointer",fontFamily: 'Asap',
  },
  BuyerSubmittedBox: {
      padding: "10px 20px"
  },
  mb20: {
      marginBottom: "20px"
  },
  BuyerHeading: {
      color: "#1E1E1E",fontSize: "28px",
      fontFamily: "Asap, sans-serif",fontWeight: 700
  },
  successBtn: {
      width: "10%"
  },

  pTB50: {
      padding: "50px 0"
  },
  noDataShow : {
      fontFamily: "Asap",paddingTop: "6rem",
  },
  noDataImgShow : {
      height: "5rem",
  },
  noDtataContent : {
      fontFamily: "Asap",fontSize: "2rem",
      margin: "0.5rem",
  },
  dataMessage: {
      fontFamily: "Asap",margin: "0rem",
      fontSize: "1rem",
  },
}

const CalendarImgBox = styled(Box)({
  root: {position: "relative"
  },
  "& img": {
    cursor: "pointer",
    height: "24px",
     width: "24px",
    position: "absolute",
    top: "32px", 
    zIndex: 1,right: "12px"
  }
});
const DropdownArrowBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "10px", width: "10px", 
    position: "absolute",top: "15px", 
    zIndex: 1, right: "20px",
    cursor: "pointer"

  }
});

const CustomFormControlLabel = styled(FormControlLabel)({
  "& span": {
    fontFamily: "Asap"
  }
})

const webStyle = {
  boxtextp:{
    fontSize: "13px",width: "fit-content",
    marginLeft:"4px",height: "fit-content",
    fontWeight: 900,fontFamily: "Asap",
    fontStyle: "normal",borderRadius:"4px",
    padding: "4px 16px 4px",whiteSpace: "nowrap" as "nowrap",
    background: "rgb(184, 213, 249)",
  },
btnImgDiv: {
  marginRight: "8px",
},

  addListingModal: {
    padding: "20px 10px",
  },
  title: {
    fontSize: "28px",
    color: "#1E1E1E",
    fontFamily: "Asap, sans-serif",
    margin: "0 0 15px 0",
    textAlign: "center" as "center",
  },
  close: {
    textAlign: "end" as "end",
    cursor: "pointer",
  },
  organisation: {
    margin: "0 20px 20px 20px",
  },
  whiteSpacePadding: {
    padding: "0px",
    whiteSpace : "nowrap" as "nowrap"
  },
  whiteSpacePaddingCenter: {
    whiteSpace : "nowrap" as "nowrap",
    textAlign: "center" as "center"
  },
  principalLabel: {
    color: "#363636",
    fontSize: "14px",
    fontFamily: "Asap, sans-serif",
    fontWeight: 600,
  },
  vesselHeading: {
    color: "#1E1E1E",fontSize: "18px",
    fontFamily: "asap, sans-serif",textAlign: "center" as "center",
    margin: "0 0 25px 0",
  },
  vesselContainer: {
    background: "#E3E6ED", padding: "25px 10px",
    borderRadius: "16px",marginBottom: "8px",
  },
 
  inputText: {
    border: "1px solid #CBD5E1",height: "44px",
    width: "100%", borderRadius: "5px",
    padding: "0 8px",
  },
  validityBox: {
    display: "flex",alignItems: "center",
    border: "1px solid #CBD5E1",borderRadius: "8px",
  },
  beforeETB: {
    color: "#797979",fontSize: "14px",
    fontFamily: "Asap, sans-serif",
  },
  
  etbDiv: {
    marginLeft: "10px",
  },
  
  expiryDate: {
    color: "#1E1E1E",fontSize: "14px",
    fontFamily: "Asap, sans-serif",
  },
  
  bBottom: {
    border: "1px solid #CBD5E1",margin: "0 2px 20px 2px",
  },
  validtyInputText: {
    border: "none",height: "44px",
    width: "40px",borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",padding: "0 8px",
  },
  rateBox: {
    display: "flex", alignItems: "center",
    border: "1px solid #CBD5E1",borderRadius: "8px",
    background: "#fff",
  },
  expiryDateDiv: {
    display: "flex", alignItems: "center",
    border: "1px solid #CBD5E1",borderRadius: "8px",
    height: "40px",paddingLeft: "10px",
  },
  borderRight: {
    borderRight: "1px solid #CDCDCD", height: "41px",
  },
 
  rateDiv: {
    color: "#1E1E1E",
    fontSize: "14px",
    fontFamily: "Asap, sans-serif",
  },

  mb20: {
    marginBottom: "20px",
  },
  usd: {
    margin: "0 10px",
  },
  
  slash: {
    color: "gray",position: "relative" as "relative",
    left: "12px",
  },
  inputRateText: {
    border: "none",
    height: "44px",
    width: "40px", 
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
     padding: "0 8px",
  },
  ml5: {
    marginLeft: "5px",
  },
  dFlexJCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  
  listingSubmittedBox: {
    padding: "10px 20px",
  },

  btnTheme: {
    display: "flex", alignItems: "center",
    justifyContent: "center",fontSize: "16px",
    background: "#FF5C00",border: "none",
    height: "45px",width: "256px",
    borderRadius: "32px",padding: "9px 18px",
    color: "#FFFFFF",fontWeight: 600,
    cursor: "pointer",marginTop : "10px",
    textTransform: "uppercase" as "uppercase",
  },
  
 

  monthlyPricing: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      background: "transparent",
      border: "1px solid #1E1E1E",
      borderRadius: "32px",
      color: "#1E1E1E",
      marginRight: "30px",
      fontWeight: 600,
      height: "48px", 
      width: "256px",
      textTransform: "uppercase" as "uppercase",
      cursor: "pointer",
      fontFamily:"Asap"
    },
    listingIdPara: {
      fontSize: "16px",
      fontFamily: "Asap, sans-serif",
      color: "#363636", 
      margin: "0 0 5px 0",
    },

    textEnd: {
      display: "flex",
      justifyContent: "end",
    },
    textCenter: {
      textAlign: "center" as "center",
    },
    
    pTB50: {
      padding: "50px 0",
    },
    listingHeading: {
      fontSize: "28px",
      fontFamily: "Asap, sans-serif",
      color: "#1E1E1E",
      fontWeight: 700,
    },
    deletReqId: {
      color: "#1E1E1E",
      fontWeight: 700,
    },
    successImg : {
      width: '100px',
      height: '100px',
    },
    deleteText:{
      color:"#1E1E1E",
      fontFamily:"Asap",
      fontSize:"14px",
      margin:0,
      cursor: "pointer",
    },
    editText:{
      color:"#1E1E1E",
      fontSize:"14px",
      fontFamily:"Asap",
      margin: "0 0 15px 0",
      cursor: "pointer",
    },
    uncontrolledBox:{
      display: "inline-block", 
      padding: "15px",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      minWidth: "100px",
      textAlign: "end" as "end",
      borderRadius:"8px"
    },

    // Delete modal css

cancelBtn: {
  fontSize: "16px",
  color: "#000000",
  border: "1px solid #000000",
  borderRadius: "32px",
  background: "#FFFFFF",padding: "9px 18px",
  fontWeight: 600,height: "45px",
  cursor: "pointer",width: "212px",
  textTransform: "uppercase" as "uppercase",
},
deleteBtn: {
  color: "#FFFFFF",
  fontSize: "16px",
  background: "#eb0008",
  border: "none",
  padding: "9px 18px",
  borderRadius: "32px",
  cursor: "pointer",
  fontWeight: 600,
  height: "45px",
  width: "212px",
  marginRight: "15px",
  textTransform: "uppercase" as "uppercase",
},
// Delete modal css


removeBtnBox : {
  display: "flex",
  justifyContent: "space-between", 
  alignItems: "center",
  position: 'relative' as 'relative'
}
};

