// Customizable Area Start
import React from "react";
import Button from "@material-ui/core/Button";
import { Formik, ErrorMessage } from "formik";
import AppBackground from "../../../components/src/AppBackground.web";
import GenericCard from "../../../components/src/GenericCard.web";
import OrganizationUserSignUpController from "./OrganizationUserSignUpController.web";
import { Box, InputLabel, Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { withRouter } from "react-router-dom";
import Spinner from "../../../components/src/ProjectLoader";
import { withStyles, Theme } from "@material-ui/core/styles";
import { successImg, image_eyeoff, image_eyeon } from "./assets";
import * as Yup from "yup";
import ProjectText from "../../../components/src/ProjectText";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import ErrorIcon from '@material-ui/icons/Error';
import { ContainedButton } from "../../../components/src/GlobalButton";

const validationSchema = () => {
  const PasswordRegexTwoUpperCase = /^(?:[^A-Z]*[A-Z]){2,}[^A-Z]*$/;
  const PasswordRegexTwoLowerCase = /^(?:[^a-z]*[a-z]){2,}[^a-z]*$/;
  const PasswordRegexTwoSpecialCharacters = /^(?:[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*[!@#$%^&*()_+\-={}[\]:;<>,.?/~]){2,}[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*$/;
  const PasswordRegexTwoNumbers = /^(?:[^\d]*\d){2,}[^\d]*$/;
  return Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Email address looks incorrect!")
      .required("Email is required"),
    designation: Yup.string().required("Designation is required"),
    department: Yup.string().required("Department is required"),
    phone: Yup.string()
      .matches(/^\+(?:\d ?){6,20}\d$/, "Invalid contact number")
      .required("Contact number is required"),
    password: Yup.string()
      .required('Password is required')
      .matches(PasswordRegexTwoUpperCase, 'Password must contain at least 2 uppercase letters')
      .matches(PasswordRegexTwoLowerCase, 'Password must contain at least 2 lowercase letters')
      .matches(PasswordRegexTwoSpecialCharacters, 'Password must contain exactly two special characters')
      .matches(PasswordRegexTwoNumbers, 'Password must contain exactly two numbers')
  });
}

class OrganizationUserSignUp extends OrganizationUserSignUpController {
  ErrorRender = (ErrorMessage: string | undefined, TextShow: boolean) => {
    return (
      <Box className="ErrorTextContainer">
        <Box className={`ErrorIconContainer ${TextShow ? "" : "TextHidden"}`}>
          <ErrorIcon />
        </Box>
        <ProjectText className={`TextError ${TextShow ? "" : "TextHidden"}`}>
          {typeof ErrorMessage === "string" ? ErrorMessage : ""}
        </ProjectText>
      </Box>
    )
  }

  PasswordTextFieldRender = (errors: { password?: string }, touched: { password?: string | boolean }, values: { password: string }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    return (
      <Grid item xs={12} md={6} >
        <Box className="TooltipContainer">
          <InputLabel htmlFor="password">
            <ProjectText className="inputLabelTitle" Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
              Password
            </ProjectText>
          </InputLabel>
          <LightTooltip
            title="Password must be 8 characters and contains at least 2 letters, numbers, and special characters. (e.g. P@s$W0rd1)"
            placement="right"
            className="tooltip"
          >
            <InfoOutlinedIcon className="infoIcon" />
          </LightTooltip>
        </Box>
        <LandingPageTextField BoderRadius="10px" inputProps={{
          "data-test-id": "password",
        }}
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            this.TextfieldChnage(e, setFieldValue)
          }}
          FormError={errors.password !== undefined && touched.password !== undefined}
           autoComplete="new-password" 
           spellCheck={false}
          type={this.state.showPassword ? 'text' : "password"}
          value={values.password }
          id="password"
          name="password"
          className={this.state.showPassword ? "" : "PasswordTextField"}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton onClick={this.handleClickShowPassword} data-test-id="showPassword" aria-label="toggle password visibility">
                <Box>
                  <img src={this.state.showPassword ? image_eyeon:image_eyeoff} alt={this.state.showPassword ? "on":"off" } className="SmallIcon"
                   />
                </Box>
              </IconButton>
            </InputAdornment>,
          }}
          fullWidth
        />
        {this.ErrorRender(errors.password, errors.password !== undefined && touched.password !== undefined)}
      </Grid>
    )
  }
  render() {
    return (
      // Required for all blocks
      <UserPageStyle>
        <AppBackground>
          {this.state.loading && <Spinner spinnerModal={this.state.loading} />}
          {!this.state.showPopup && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="GridContainerStyle"
            >
              <Grid md={6} className="GridContainerPadding" >
                <GenericCard>
                  <Grid container data-test-id="OrganizationUserSignUp">
                    <Grid item xs={12}>
                      <Grid xs={12} item>
                        <ProjectText className="OrganizationUserSignUpTitle" align="center">
                          Create your SlotXpert Account with
                        </ProjectText>
                        <ProjectText className="OrganizationUserSignUpSecTitle" align="center">
                          {this.state.orgName}
                        </ProjectText>
                      </Grid>
                    </Grid>
                    <Box>
                      <Formik
                        initialValues={{
                          name: this.state.FullName || "",
                          email: this.state.EmailId || "",
                          designation: "",
                          department: "",
                          phone: "",
                          password:"",
                        }}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={(values) => {                       
                          this.UserSignUp(values);
                        }}
                        validateOnChange={true}
                        validateOnBlur={true}
                      >
                        {({ errors, touched, setFieldValue, handleSubmit, values }) => {
                          return (
                            <form onSubmit={handleSubmit} data-test-id="OrganizationUserSignUpForm">
                              <Box>
                                <Grid container spacing={2}
                                 justifyContent="center"
                                  alignItems="center">
                                  <Grid item xs={12}  md={6}>
                                    <Box className="TooltipContainer">
                                      <InputLabel htmlFor="name">
                                        <ProjectText className="inputLabelTitle" 
                                        Upercase variant="subtitle1"
                                         TextSize="14px"
                                          fontweight="900"
                                           textcolor="black">
                                          FULL NAME
                                        </ProjectText>
                                      </InputLabel>
                                    </Box>
                                    <LandingPageTextField BoderRadius="10px"
                                     inputProps={{
                                      "data-test-id": "name",
                                    }}
                                      variant="outlined"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        this.TextfieldChnage(e, setFieldValue)
                                      }}
                                      FormError={errors.name !== undefined && touched.name !== undefined}
                                      type='text'
                                      value={values.name}
                                      id="name"
                                      name="name"
                                      fullWidth
                                    />
                                    {this.ErrorRender(errors.name, errors.name !== undefined && touched.name !== undefined)}
                                  </Grid>
                                  <Grid item xs={12}  md={6}>
                                    <Box className="TooltipContainer">
                                      <InputLabel htmlFor="email">
                                        <ProjectText className="inputLabelTitle" Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                          EMAIL ADDRESS
                                        </ProjectText>
                                      </InputLabel>
                                    </Box>
                                    <LandingPageTextField BoderRadius="10px" inputProps={{
                                      "data-test-id": "email",
                                    }}
                                      variant="outlined"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        this.TextfieldChnage(e, setFieldValue)
                                      }}
                                      FormError={errors.email !== undefined && touched.email !== undefined}
                                      type='text'
                                      value={values.email}
                                      id="email"
                                      name="email"
                                      fullWidth
                                    />
                                    {this.ErrorRender(errors.email, errors.email !== undefined && touched.email !== undefined)}
                                  </Grid>
                                  <Grid item xs={12} md={6} >
                                    <Box className="TooltipContainer">
                                      <InputLabel htmlFor="designation">
                                        <ProjectText className="inputLabelTitle" Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                          designation
                                        </ProjectText>
                                      </InputLabel>
                                    </Box>
                                    <LandingPageTextField BoderRadius="10px" inputProps={{
                                      "data-test-id": "designation",
                                    }}
                                      variant="outlined"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        this.TextfieldChnage(e, setFieldValue)
                                      }}
                                      FormError={errors.designation !== undefined && touched.designation !== undefined}
                                      type='text'
                                      value={values.designation}
                                      id="designation"
                                      name="designation"
                                      fullWidth
                                    />
                                    {this.ErrorRender(errors.designation, errors.designation !== undefined && touched.designation !== undefined)}
                                  </Grid>
                                  <Grid item xs={12} md={6} >
                                    <Box className="TooltipContainer">
                                      <InputLabel htmlFor="department">
                                        <ProjectText className="inputLabelTitle" Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                          department
                                        </ProjectText>
                                      </InputLabel>
                                    </Box>
                                    <LandingPageTextField BoderRadius="10px" inputProps={{
                                      "data-test-id": "department",
                                    }}
                                      variant="outlined"
                                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        this.TextfieldChnage(e, setFieldValue)
                                      }}
                                      FormError={errors.department !== undefined && touched.department !== undefined}
                                      type='text'
                                      value={values.department}
                                      id="department"
                                      name="department"
                                      fullWidth
                                    />
                                    {this.ErrorRender(errors.department, errors.department !== undefined && touched.department !== undefined)}
                                  </Grid>
                                  <Grid item xs={12} md={6} >
                                    <Box className="TooltipContainer">
                                      <InputLabel htmlFor="phone">
                                        <ProjectText className="inputLabelTitle" Upercase variant="subtitle1" TextSize="14px" fontweight="900" textcolor="black">
                                          CONTACT NUMBER
                                        </ProjectText>
                                      </InputLabel>
                                    </Box>
                                    <Box className={`${errors.phone !== undefined && touched.phone ? "ErrorPhoneClass" : false}`}>
                                      <PhoneInput
                                        country={"sg"}
                                        placeholder={""}
                                        value={values.phone}
                                        onChange={(number: string, data: { dialCode: string }) => {
                                          this.PhoneInputNumber(data.dialCode, number, setFieldValue)
                                        }}
                                        searchPlaceholder="Search for countries"
                                        inputProps={{
                                          required: true,
                                          id: "phone",
                                          name: "phone",
                                          "data-test-id": "PhoneNumber"
                                        }}
                                        enableSearch={true}
                                        countryCodeEditable={false}
                                        jumpCursorToEnd={true}
                                        inputClass={`PhoneInputPackgeClass`}
                                      />
                                    </Box>
                                    {this.ErrorRender(errors.phone, errors.phone !== undefined && touched.phone !== undefined)}
                                  </Grid>
                                  {this.PasswordTextFieldRender(errors, touched, values, setFieldValue)}
                                  <Grid item xs={12} md={6} className="buttonContainer">
                                    <ContainedButton
                                      fullWidth
                                      type="submit"
                                      variant="contained"
                                      data-test-id="submitButton"
                                    >
                                      CREATE ACCOUNT
                                    </ContainedButton>
                                  </Grid>
                                </Grid>
                              </Box>
                            </form>
                          )
                        }}
                      </Formik>
                    </Box>
                  </Grid>
                </GenericCard>
              </Grid>
            </Grid>
          )}
          {this.state.showPopup && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="GridContainerStyle"
            >
              <Grid item xs={5}>
                <GenericCard>
                  <Grid container spacing={1} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Box className="successmodal">
                        <img
                          src={successImg}
                          alt="checkimg"
                          className="successImg"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <ProjectText variant="h6"
                       align="center"
                        TextSize="1.75rem"
                         fontweight="700">Account Created!</ProjectText>
                    </Grid>
                    <Grid item xs={12} >
                      <ProjectText variant="h6"
                       align="center"
                        TextSize="1rem"
                         fontweight="500">Let's get started!</ProjectText>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="successmodal">
                        <ContainedButton fullWidth onClick={() => this.props.navigation.navigate("EmailAccountLogin")}>LOGIN</ContainedButton>
                      </Box>
                    </Grid>
                  </Grid>
                </GenericCard>
              </Grid>
            </Grid>
          )}
        </AppBackground>
      </UserPageStyle>
    );
  }
}
export default withRouter(OrganizationUserSignUp);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.common.white,
    color: "black",
    fontSize: 14,
    display: " inline-flex",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    fontFamily: "Asap, sans-serif",
    padding: "8px 16px",
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const styles = {
  container: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid gray",
  },
  errorContainer: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid red",
  },
  button: {
    border: "none",
    borderRadius: "9px",
  },
  input: {
    height: "56px",
    width: "100%",
    border: "none",
    borderRadius: "9px",
  },
};
const InputContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});
const CustmizedInputLabel = styled(InputLabel)({
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0em",
  textAlign: "left",
  display: "contents",
  color: "black !important",
  textTransform: "uppercase",
  fontFamily: "Asap, sans-serif",
  fontWeight: 600,
});
const CustmizedPassword = styled(Input)({
  width: "100%",
  height: "56px",
  padding: "10px 22px 10px 12px",
  borderRadius: "8px",
  border: "1px solid gray",
  outline: "none",
});
const UserPageStyle = styled("div")({
  "& .OrganizationUserSignUpTitle": {
    fontSize: '28px',
    fontWeight: 700,
    marginBottom: '5px',
    "@media (max-width: 1280px)": {
      fontSize: '25px'
    },
    "@media (max-width: 1080px)": {
      fontSize: '20px'
    },
    "@media (max-width: 600px)": {
      fontSize: '17px'
    },
  },
  "& .OrganizationUserSignUpSecTitle": {
    fontSize: '20px',
    fontWeight: 700,
    marginBottom: '12px',
    "@media (max-width: 1280px)": {
      fontSize: '18px'
    },
    "@media (max-width: 1080px)": {
      fontSize: '16px'
    },
    "@media (max-width: 600px)": {
      fontSize: '14px'
    },
  },
  "& .MuiOutlinedInput-input": {
    "@media (max-width: 1200px)": {
      padding: '9px 14px'
    },
  },
  "& .ErrorTextContainer": {
    display: "flex",
    marginTop: "5px",
    alignItems: "center",
    "& .TextHidden": {
      visibility: "hidden"
    },
    "& .ErrorIconContainer": {
      marginRight: "6px",
      "& .MuiSvgIcon-root": {
        color: "red"
      }
    },
    "& .TextError": {
      color: "red",
      fontSize: "16px",
      marginBottom: "0px",
      transition: "width 1s ease-out ",
      fontFamily: "Asap, sans-serif",
    },
  },
  "& .TooltipContainer": {
    display: "flex",
    alignItems: "center",
    overflowX: "hidden",
    width: "100%",
    marginBottom: "5px",
    "& .inputLabelTitle": {
      "@media (max-width: 600px)": {
        fontSize: '13px'
      },
    },
    "& .MuiInputLabel-root": {
      marginRight: "4px"
    },
    "& .tooltip": {
      fontSize: "1.5rem"
    }
  },
  "& .GridContainerPadding":{
    padding:"0 24px",
    overflowX: 'hidden',
    "@media (max-width: 960px)": {
      maxHeight: '700px',
    },
  },
  "& .SmallIcon": {
    height: "1.375rem"
  },
  "& .PasswordTextField": {
    "& input": {
      fontFamily: 'monospace',
      fontSize: "xx-large",
      height: "18px"
    }
  },
  // phone number class start
  "& .PhoneInputPackgeClass": {
    fontFamily: "Asap, sans-serif",
    width: "100%",
    height: "56px",
    borderRadius: "10px",
    fontSize: "15px",
    fontWeight: 500,
    backgroundColor: "white",
  },
  "& .dial-code": {
    color: "black !important",
    fontFamily: "Asap, sans-serif",
    fontWeight: "500"
  },
  "& .no-entries-message": {
    color: "black !important",
    fontFamily: "Asap, sans-serif",
    fontWeight: "500"
  },
  "& .country-name": {
    fontFamily: "Asap, sans-serif",
  },
  "& .ErrorPhoneClass": {
    "& .PhoneInputPackgeClass": {
      backgroundColor: "#FBE9E9"
    },
    "& .flag-dropdown": {
      backgroundColor: "#FBE9E9"
    },
    "& .selected-flag": {
      backgroundColor: "#FBE9E9"
    }
  },
  "& .flag-dropdown": {
    borderRadius: "10px 0px 0px 10px",
    borderRight: "none",
  },
  "& .selected-flag": {
    borderRadius: "10px",
    backgroundColor: "white",
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px",
      margin: "6px 14px",
      color: "red",
      position: "absolute"
    }
  },
  // Phone Number class end
  "& .title": {
    fontFamily: "Asap, sans-serif",
    textAlign: "center",
    fontWeight: "bolder",
  },
  "& .error": {
    color: "red",
    fontSize: "0.9rem",
    marginTop: "0.2rem",
    fontFamily: "Asap, sans-serif",
  },
  "& .buttonContainer": {
    justifyContent: "center",
    display: "flex",
    alignItems: "cenetr",
    whiteSpace: 'nowrap'
  },
  "& .customizedButton": {
    backgroundColor: "#1653DD",
    padding: "0.8rem 3rem 0.8rem 3rem",
    borderRadius: "45px",
    color: "white",
    fontFamily: "Asap, sans-serif",
    fontSize: "1rem",
    fontWeight: 600,
    letterSpacing: "3px",
  },

  "& .phone": {
    padding: "10px 22px 10px 12px",
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    paddingLeft: "35px",
    fontWeight: 700,
  },
  "& .Custmizedinputs": {
    width: "100% !important",
    height: "56px",
    padding: "10px 0px 10px 0px",
    borderRadius: "8px",
    backgroundcolor: "red",
    borderRadious: "8px",
  },
  "& .textbox": {
    width: "100%",
    height: "56px",
    padding: "10px 22px 10px 12px",
    borderRadius: "8px",
    border: "1px solid gray",
    fontSize: "0.9rem",
  },
  "& .errorInput": {
    border: "1px solid red !important",
  },
  "& .infoIcon": {
    marginLeft: "0.3rem",
  },
  "& .react-tel-input .flag-dropdown.open .selected-flag": {
    background: "#ffffff00",
    borderRadius: "3px 0 0 0",
  },
  "& .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus": {
    backgroundColor: "#fff0",
  },
  "& .successmodal": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .successImg": {
    height: "5rem",
    width: "5rem",
  },
  "& .heading": {
    fontSize: "1.5rem",
    margin: "0",
    fontFamily: "Asap, sans-serif",
    fontWeight: 800,
  },
  "& .p": {
    margin: "0.5rem",
    fontFamily: "Asap, sans-serif",
    marginBottom: "1rem",
  },
  "& .Icon": {
    height: "1.4rem",
  },
  "& .MuiInputBase-input": {
    fontSize: "0.9rem",
    fontFamily: "Asap, sans-serif",
    color: "black",
  },
  "& .tooltipContainer": {
    display: "flex",
    alignItems: "center",
  },
});
// Customizable Area End
