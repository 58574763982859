Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "listdemandpickflow";
exports.labelBodyText = "listdemandpickflow Body";
exports.btnExampleTitle = "CLICK ME";
exports.textone="MSK";
exports.texttwo="ID:B38790";
exports.textthree="11 oct(wed)";
exports.textfour="SGSIN";
exports.textfour1="WAN HOI/SGSIN";
exports.textfive="TCN";
exports.textsix="100x";
exports.textseven="20FT";
exports.texteight="View Details"
exports.textnine="20Ft";
exports.bookingDetails="Booking Details";
exports.slotxpert="slotxpert #";
exports.vessel="vessel/voyage";
exports.number="R50405352"
exports.pol="POL";
exports.pod="POD";
exports.sellerbooking="SELLER BOOKING #";
exports.pending="PENDING"
exports.containers="CONTAINERS";
exports.value="4 X 20FT EMPTY";
exports.rates="Rates";
exports.time="12:00PM";
exports.date="2 SEP 2023";
exports.eta="ETA";
exports.etb="ETB";
exports.cancel="CANCEL";
exports.no="No";
exports.cancelButton="CANCEL BOOKING"
exports.cancelHeading="Cancel Booking"
exports.cancelText="Are you sure you want to cancel your booking #38750."
exports.nominationheading="Nomination pending..."
exports.nominationurl="https://shorturl/?uefbDVD"
exports.nominationcheck="To nominate containers go through given link."
exports.nominationbutton="Confirm"
exports.reject="Reject"
exports.verifyheading="Verify Draft B/L"
exports.verifyurl="Draft b/l"
exports.verifytext="agwdbsdssegv.PDF"
exports.verifybutton="Submit"
exports.reasonheading="Request Revision"
exports.reasonnotify="We will notified the seller! please enter the reason!"
exports.reasonnotifytwo="Why are you requesting revision?"
exports.reasontext="REASON FOR REVISION"
exports.paymentheading="Payment Confirmation"
exports.paymnetnotify="UPLOAD DOCUMENT"
exports.paymenttext="Browse or drag & drop file here (PDF, DOCX)"
exports.paymenttextdoc="Browse or drag & drop file here (Only PDF)"
exports.submitheading="Submit Shipping Instructions"
exports.polterminal="TEUs BOOKED"
exports.podterminal="POD TERMINAL"
exports.shipperdetails="Shipper Details"
exports.vesselinformation="Shipment Details"
exports.viewless="VIEW LESS"
exports.shippercompany="Shipper Company Name"
exports.shippercharge="Shipper Person-In-Charge"
exports.shipperaddress="Shipper Address"
exports.streetaddressone="Street Address"
exports.streetaddresstwo="Street Address Line 2"
exports.consigneeaddresstwo="Street Address Line 2"
exports.city="City"
exports.state="State/Province "
exports.postal="Postal/Zip Code"
exports.country="Country"
exports.shipperemail="Shipper Email address"
exports.shippercontact="Shipper Contact Number"
exports.consigneedetails=" Consignee Details"
exports.consigneename="Consignee Name"
exports.consigneecharge="Consignee Person-In-Charge"
exports.consigneeaddress=" Consignee Address"
exports.consigneeemail="Consignee Email address"
exports.taxText="TAX ID (Optional)"

exports.consigneecontact="Consignee Contact Number"
exports.empty="empty"
exports.containerdetails="Container Details"
exports.containernumber="Container Number"
exports.containertype="Container Type "
exports.containerstatus="Container Status "
exports.containersize="Container Size"
exports.containeraverage="Container Codes"
exports.containeraveragetypes="Container Types"
exports.containerseal="Seal Number"
exports.containercode="HS Code"
exports.containerpackaging="Packing Type"
exports.packagecount="Package Count"
exports.packagegwt="GWT (kgs)"
exports.packagedescription="Cargo Description"
exports.addcontainertype="Add Container"
exports.viewmore="View More"
exports.remove="Delete"
exports.ViewDetails="View Details"
exports.polText="POL";
exports.podText="POD";
exports.recentText="RECENTLY UPLOADED"
exports.InboundPercentageAPIEndpoint="bx_block_datamigration2/inbound_utilisation_percentage"
exports.OutboundPercentageAPIEndpoint="bx_block_datamigration2/outbound_utilisation_teu_percentage"
exports.OutboundWeightPercentageAPIEndpoint="bx_block_datamigration2/outbound_utilisation_weight_percentage"
exports.ServiceoverviewTableAPIEndpoint="bx_block_datamigration2/services_overview"
exports.ListofdirectionsAPIEndpoint="bx_block_datamigration2/list_of_directions"
exports.InboundservicesAPIEndpoint="bx_block_datamigration2/services_inbound_percentage?direction="
exports.OutboundservicesAPIEndpoint="bx_block_datamigration2/services_outound_percentage?direction="
exports.FedderlistAPIEndpoint="bx_block_datamigration2/dedicated_feeders"
exports.FeddergraphAPIEndpoint="bx_block_datamigration2/dedicated_feeder_graph?feeder="
exports.UpdatedtimeAPIEndpoint="bx_block_datamigration2/updated_on_vessel"
exports.MonthlyutilizationAPIEndpoint="bx_block_datamigration2/utilization_by_month_and_year?"
exports.TraderouteAPIEndpoint="bx_block_datamigration2/trade_route_list"
exports.ServiceAPIEndpoint="bx_block_datamigration2/list_of_services"
exports.PortlocationAPIEndpoint="bx_block_datamigration2/previous_port_location"
exports.DestinationportAPIEndpoint="bx_block_datamigration2/destination_port_location"
exports.TraderoutetooltipAPIEndpoint="bx_block_content_management/trade_routes"
exports.DownloadfileAPIEndpoint="bx_block_datamigration2/generate_csv"

// Customizable Area End