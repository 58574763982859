import React from "react";
// Customizable Area Start
import BuyerUserProfileController, {
  Props
} from "./BuyerUserProfileController.web";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
  FormControl,
  Grid,
  Divider,
  Button,
  InputAdornment,
  InputLabel,
  Typography,Tooltip,
} from "@material-ui/core";
import { withStyles, styled,Theme } from "@material-ui/core/styles";
import Navbar from "../../../components/src/NavBar.web";
import PhoneInput from "react-phone-input-2";
import LandingPageTextField from "../../../components/src/ProjectTextField";
import ProjectModel from "../../../components/src/ProjectModel";
import {
  image_eyeoff,
  image_eyeon
} from "./assets";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { Formik } from "formik";
import * as Yup from "yup";
import ProjectText from "../../../components/src/ProjectText";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// Customizable Area End

export default class BuyerUserProfile extends BuyerUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GetInTouch = () => {
    return Yup.object().shape({
      FullName: Yup.string().required("Full Name Required"),
      designation: Yup.string().required("Designation Required"),
      department: Yup.string().required("Department Required"),
      contactNumber: Yup.string()
        .required("Contact Number Required")
        .test({
          name: 'contactNumber',
          message: 'Contact Number Required',
          test: () => {
              return this.state.mainPhoneNumber?.trim() !== ""
          },
      })
        .min(10, "The Contact Number you have entered is invalid")
        .max(15, "The Contact Number you have entered is invalid")
        .nullable()
    });
  };
  getErrorMessage = (touched: any, errors: any) => {
    return ( touched &&
      errors && (
        <PhoneInputStyleGh>
          <Typography
            style={{ marginTop: "2px", fontSize: "17px", color: "red", fontFamily: "Asap, sans-serif" }}
          >
            {errors}
          </Typography>
        </PhoneInputStyleGh>
      )
    );
  };
  renderEditInfoModal = () => {
    return (
      <ProjectModel
        IconButtonBgColor="black"
        BtnColor="white"
        maxWidth="md"
        OpenModel={this.state.isEditFnameModalOpen}
        onClose={this.toggleModal}
        DialogContentPadding={false}
        BorderRadius="24px"
        CloseIconSize="16px"
        OutsideCloseFalse={false}
      >
        <Box style={{ padding: "20px" }}>
          <Formik
            validationSchema={this.GetInTouch}
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{
              FullName: this.state.FullName || "",
              designation: this.state.designation || "",
              department: this.state.department || "",
              contactNumber: this.state.contactNumber || ""
            }}
            onSubmit={values => {
              this.UserUpdateProfile()
            }}
          >
            {({ errors, values, touched, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} 
              autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ProjectText
                      fontweight="900"
                      TextSize="28px"
                      align="center"
                      textcolor="#1E1E1E"
                      LineHeight="normal"
                    >
                      Edit Information
                    </ProjectText>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="UserName">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        fontweight="700"
                        TextSize="14px"
                        textcolor="#1E1E1E"
                        LineHeight="normal"
                        space="both"
                      >
                        FULL NAME
                      </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                      BoderRadius="8px"
                      inputProps={{
                        "data-test-id": "UserName"
                      }}
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.setFullName(e.target.value);
                        setFieldValue("FullName", e.target.value)
                      }}
                      type="text"
                      id="FullName"
                      name="FullName"
                      value={values.FullName}
                      fullWidth
                      FormError = {touched.FullName !== undefined && errors.FullName !== undefined}
                    />
                    {this.getErrorMessage(touched.FullName, errors.FullName)}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="DESIGNATION">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        fontweight="700"
                        TextSize="14px"
                        textcolor="#1E1E1E"
                        LineHeight="normal"
                        space="both"
                      >
                        DESIGNATION
                      </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                      inputProps={{
                        "data-test-id": "DESIGNATION"
                      }}
                      BoderRadius="8px"
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.setdesignation(e.target.value);
                        setFieldValue("designation", e.target.value)
                      }}
                      type="text"
                      id="designation"
                      name="designation"
                      value={values.designation}
                      fullWidth
                      FormError = {touched.designation !== undefined && errors.designation !== undefined}
                    />
                    {this.getErrorMessage(touched.designation, errors.designation)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="DEPARTMENT">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        fontweight="700"
                        TextSize="14px"
                        textcolor="#1E1E1E"
                        LineHeight="normal"
                        space="both"
                      >
                        DEPARTMENT
                      </ProjectText>
                    </InputLabel>
                    <LandingPageTextField
                      inputProps={{
                        "data-test-id": "DEPARTMENT"
                      }}
                      BoderRadius="8px"
                      variant="outlined"
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      ) => {
                        this.setdepartment(e.target.value);
                        setFieldValue("department", e.target.value)
                      }}
                      type="text"
                      id="department"
                      name="department"
                      value={values.department}
                      fullWidth
                      FormError = {touched.department !== undefined && errors.department !== undefined}
                    />
                    {this.getErrorMessage(touched.department, errors.department)}
                  </Grid>


                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="CONTACT NUMBER">
                      <ProjectText
                        Upercase
                        variant="subtitle1"
                        fontweight="700"
                        TextSize="14px"
                        textcolor="#1E1E1E"
                        LineHeight="normal"
                        space="both"
                      >
                        CONTACT NUMBER
                      </ProjectText>
                    </InputLabel>
                    <PhoneInputStyle>
                      <PhoneInput
                        country={"in"}
                        placeholder={""}
                        inputProps={{
                          required: true,
                          id: "contactNumber",
                          name: "contactNumber",
                          "data-test-id": "PhoneNumberModel"
                        }}
                        enableSearch={true}
                        disableSearchIcon={true}
                        jumpCursorToEnd={true}
                        countryCodeEditable={false}
                        inputClass={"PhoneInputPackgeClass"}
                        value={values.contactNumber}
                        onChange={(e, i: any) => { setFieldValue("contactNumber", `+${e}`); this.handleChange(e, i.dialCode) }}
                      />
                    </PhoneInputStyle>
                    {this.getErrorMessage(touched.contactNumber, errors.contactNumber)}
                  </Grid>
                  <Grid
                    container
                    style={{ justifyContent: "center", padding: "16px" }}
                  >
                    <Grid item xs={6} md={3}>
                      <SaveButton >
                        <Button className={`saveBtn ${this.state.isBuyerRole ? "buyerSaveBtn" : "sellerSaveBtn"}`}
                          type="submit"
                          data-test-id="updateUserProfile"
                          variant="contained"
                        >
                          <ProjectText textcolor="white">SAVE DETAILS</ProjectText>
                        </Button>
                      </SaveButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </ProjectModel>
    );
  };
  renderEditPasswordModal = () => {
    return (
      <div>
        <ProjectModel
          OpenModel={this.state.isEditPasswordModalOpen}
          onClose={this.editPasswordModal}
          maxWidth="md"
          fullWidth
          IconButtonBgColor="black"
          BtnColor="white"
          BorderRadius="24px"
          DialogContentPadding={true}
          CloseBtnPostionAbsolute={true}
          CloseIconSize="16px"
          OutsideCloseFalse={false}
        >
          <Box style={{ padding: "20px" }}>
            <Formik
              data-test-id="formik"
              initialValues={{
                oldAuth: this.state.oldAuth,
                newAuth: this.state.newAuth,
                confirmNewPassword: this.state.confirmPassword,
              }}
              validationSchema={() => {
                const PasswordRegexTwoUpperCase = /^(?:[^A-Z]*[A-Z]){2,}[^A-Z]*$/;
                const PasswordRegexTwoLowerCase = /^(?:[^a-z]*[a-z]){2,}[^a-z]*$/;
                const PasswordRegexTwoSpecialCharacters = /^(?:[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*[!@#$%^&*()_+\-={}[\]:;<>,.?/~]){2,}[^\s!@#$%^&*()_+\-={}[\]:;<>,.?/~]*$/;
                const PasswordRegexTwoNumbers = /^(?:[^\d]*\d){2,}[^\d]*$/;
                const PasswordMaxLength = 11;
                return Yup.object().shape({
                  oldAuth: Yup.string().required("Old Password Required")
                    .matches(
                      /^[^\s]+$/,
                      'Spaces are not allowed in the password'
                    ),
                  newAuth: Yup.string()
                    .test(
                      'newAuth',
                      'New Password Required',
                      function (value) {
                        const oldAuth = this.resolve(Yup.ref('oldAuth'));
                        return !!oldAuth || !!value;
                      }
                    )
                    .when('oldAuth', {
                      is: (val) => !!val,
                      then: Yup.string()
                        .required("New Password Required")
                        .matches(PasswordRegexTwoUpperCase, 'Password must contain at least 2 uppercase letters')
                        .matches(PasswordRegexTwoLowerCase, 'Password must contain at least 2 lowercase letters')
                        .matches(PasswordRegexTwoSpecialCharacters, 'Password must contain exactly two special characters')
                        .matches(PasswordRegexTwoNumbers, 'Password must contain exactly two numbers')
                        .matches(
                          /^[^\s]+$/,
                          'Spaces are not allowed in the password'
                        )
                        .notOneOf([Yup.ref('oldAuth'), "null"], 'New password and old password must be different')
                        .max(PasswordMaxLength, `Password must be ${PasswordMaxLength} characters or less`)
                    }),
                  confirmNewPassword: Yup.string().required("Confirm New Password Required")
                    .oneOf([Yup.ref('newAuth'), null], 'New password and confirm password must match')
                    .matches(
                      /^[^\s]+$/,
                      'Spaces are not allowed in the password'
                    ),
                });
              }}
              onSubmit={(values) => {
                this.updateUserPassword();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <ProjectText
                        variant="h5"
                        fontweight="900"
                        TextSize="28px"
                        align="center"
                        textcolor="#1E1E1E"
                      >
                        Edit Password
                      </ProjectText>
                    </Grid>
                    <Grid item xs={12} >
                      <Box justifyContent="center" alignItems="center" display="grid">
                        <InputLabel htmlFor="OLD PASSWORD">
                          <ProjectText Upercase variant="subtitle1" fontweight="900">
                            OLD PASSWORD
                          </ProjectText>
                        </InputLabel>
                        <BuyerUserProfilePageStyle>
                          <LandingPageTextField
                            className={!this.state.enableOLdPasswordField ? "passwordInput" : "passwordInput"}
                            variant="outlined"
                            BoderRadius="8px"
                            id="Password"
                            name="oldAuth"
                            type={
                              !this.state.enableOLdPasswordField ? "text" : "password"
                            }
                            FormError = {touched.oldAuth !== undefined && errors.oldAuth !== undefined}
                            inputProps={{ "data-test-id": "password" }}
                            value={this.state.oldAuth}
                            onChange={e => { this.setOldPassword(e.target.value); setFieldValue("oldAuth", e.target.value) }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    data-test-id="showOldPassword"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowOldPassword}
                                    edge="end">
                                    {this.state.enableOLdPasswordField ? (
                                      <BuyerUserProfilePageStyle>
                                        <img src={image_eyeoff}
                                          alt="off"
                                          className="SmallIcon"
                                        />
                                      </BuyerUserProfilePageStyle>
                                    ) : (
                                      <BuyerUserProfilePageStyle>
                                        <img src={image_eyeon}
                                          alt="off"
                                          className="SmallIcon"
                                        />
                                      </BuyerUserProfilePageStyle>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.getErrorMessage(touched.oldAuth, errors.oldAuth)}
                        </BuyerUserProfilePageStyle>
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Box justifyContent="center" alignItems="center" display="grid">
                        <Box  display="flex">
                        <InputLabel htmlFor="NEW PASSWORD" >
                          <ProjectText Upercase variant="subtitle1" fontweight="900" marginRight="5px">
                            NEW PASSWORD
                          </ProjectText>
                        </InputLabel>
                        <LightTooltip
            title="Password must be 8 characters and contains at least 2 letters, numbers, and special characters. (e.g., P@s$W0rd1)"
            placement="right"
            className="tooltip"
          >
            <InfoOutlinedIcon className="infoIcon" fontSize="small" style={{marginTop:"2px"}} />
          </LightTooltip>
          </Box>
                        <BuyerUserProfilePageStyle>
                          <LandingPageTextField
                            className={!this.state.enableOLdPasswordField ? "passwordInput" : "passwordInput"}
                            variant="outlined"
                            id="Password"
                            name="newAuth"
                            BoderRadius="8px"
                            type={
                              !this.state.enableNewPasswordField ? "text" : "password"
                            }
                            FormError = {touched.newAuth !== undefined && errors.newAuth !== undefined}
                            inputProps={{
                              maxLength: 16,
                              "data-test-id": "txtInputPassword"
                            }}
                            value={this.state.newAuth}
                            onChange={e => { this.setNewPassword(e.target.value); setFieldValue("newAuth", e.target.value) }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    data-test-id="showNewPassword"
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowNewPassword}
                                    edge="end" >
                                    {this.state.enableNewPasswordField ? (
                                      <BuyerUserProfilePageStyle>
                                        <img src={image_eyeoff}
                                          alt="off" className="SmallIcon" />
                                      </BuyerUserProfilePageStyle>
                                    ) : (
                                      <BuyerUserProfilePageStyle>
                                        <img
                                          src={image_eyeon} alt="off"
                                          className="SmallIcon"
                                        /> </BuyerUserProfilePageStyle>
                                    )} </IconButton>    </InputAdornment>
                              )
                            }} />
                          {this.getErrorMessage(touched.newAuth, errors.newAuth)}
                        </BuyerUserProfilePageStyle>
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Box justifyContent="center" alignItems="center" display="grid">
                        <Box display="flex">
                        <InputLabel htmlFor="CONFIRM PASSWORD">
                          <ProjectText Upercase variant="subtitle1" fontweight="900" marginRight="5px">
                            CONFIRM NEW PASSWORD
                          </ProjectText>
                        </InputLabel>
                        <LightTooltip
            title="Password must be 8 characters and contains at least 2 letters, numbers, and special characters. (e.g., P@s$W0rd1)"
            placement="right"
            className="tooltip"
          >
            <InfoOutlinedIcon className="infoIcon" fontSize="small" style={{marginTop:"2px"}} />
          </LightTooltip>
          </Box>
                        <BuyerUserProfilePageStyle>
                          <LandingPageTextField
                            className={!this.state.enableOLdPasswordField ? "passwordInput" : "passwordInput"}
                            variant="outlined"
                            id="Password"
                            name="confirmNewPassword"
                            BoderRadius="8px"
                            type={
                              !this.state.enableConfirmField ? "text" : "password"
                            }
                            FormError = {touched.confirmNewPassword !== undefined && errors.confirmNewPassword !== undefined}
                            inputProps={{
                              maxLength: 12,
                              "data-test-id": "txtConfirmPassword"
                            }}
                            value={this.state.confirmPassword}
                            onChange={e => { this.setConfirmPassword(e.target.value); setFieldValue("confirmNewPassword", e.target.value) }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowConfirmPassword}
                                    edge="end"
                                    data-test-id="showconfirmPassword"
                                  >
                                    {this.state.enableConfirmField ? (
                                      <BuyerUserProfilePageStyle>
                                        <img
                                          src={image_eyeoff}
                                          alt="off"
                                          className="SmallIcon"
                                        />
                                      </BuyerUserProfilePageStyle>
                                    ) : (
                                      <BuyerUserProfilePageStyle>
                                        <img
                                          src={image_eyeon}
                                          alt="off"
                                          className="SmallIcon"
                                        />
                                      </BuyerUserProfilePageStyle>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                          {this.getErrorMessage(touched.confirmNewPassword, errors.confirmNewPassword)}
                        </BuyerUserProfilePageStyle>
                      </Box>
                    </Grid>
                    <Grid
                      container
                      style={{ justifyContent: "center", padding: "16px" }}
                    >
                      <Grid item xs={6} md={3}>
                        <SaveButton>
                          <Button
                            variant="contained"
                            type="submit"
                            data-test-id={"btnupdateUserPassword"}
                            className={`saveBtn ${this.state.isBuyerRole ? "buyerSaveBtn" : "sellerSaveBtn"}`}
                          >
                            <ProjectText style={{ color: "white" }}>
                              SAVE DETAILS
                            </ProjectText>
                          </Button>
                        </SaveButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>)}
            </Formik>
          </Box>
        </ProjectModel>
      </div >
    );
  };

  accountInfoNotificationSetting = () => {
    const IOSSwitch = withStyles(theme => (
      {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        transition: 'left 500ms ease-in-out',
        margin: theme.spacing(1),
        "& .MuiSwitch-track": {
          backgroundColor: "#CDCDCD"
        },
        "& .MuiSwitch-switchBase":{
          transition: 'left 500ms ease-in-out',
        }
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"])

      },
      thumb: {
        width: 24,
        height: 24
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(16px)",
          color: theme.palette.common.white,
          "& + $track": {
            backgroundColor: this.state.isBuyerRole ? "rgba(255, 92, 0, 0.69)" : "#1653DD",
            opacity: 1,
            border: "none"
          }
        },
        "&$focusVisible $thumb": {
          color: "#52d869",
          border: "6px solid #fff"
        }
      },
      checked: {},
      focusVisible: {}
    }))(Switch);
    return (
      <Grid container className="mainDiv">
        <Grid className="mainDiv">
          <Grid item xs={12} className="actInfo">
            <ProjectText variant="h5" className="AccountInfo">
              Account Information
            </ProjectText>
          </Grid>
        </Grid>
        <Grid className="mainDiv">
          <Grid item xs={11} lg={8} className="basicContainer">   
            <ProjectText variant="h5" className="BasicInfo">
              Basic Info
            </ProjectText>
            <Grid xs={12}>
              <Grid container justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}>
                <Grid container item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    ORGANISATION
                  </ProjectText>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="getData" fontweight="400">
                    {this.state.organization}
                  </ProjectText>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    EMAIL ADDRESS
                  </ProjectText>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="getData" fontweight="400">
                    {this.state.email}
                  </ProjectText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid xs={12}>
              <Grid
                container
                justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}
                className="pt20"
              >
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    FULL NAME
                  </ProjectText>
                </Grid>
                <Grid item xs={4}>
                  <ProjectText className="getData" fontweight="400">
                    {this.state.FullName}
                  </ProjectText>
                </Grid>
                <Grid container item xs={2} lg={4} justifyContent="flex-end">
                  <Box
                    className="pr23"
                    display="flex"
                    justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}
                    onClick={this.toggleModal}
                  >
                    <BorderColorIcon className={
                      this.state.isBuyerRole
                        ? "BuyerEditIcon"
                        : "sellerEditIcon"
                    }
                      data-test-id="editIcon"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    DESIGNATION
                  </ProjectText>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="getData" fontweight="400">
                    {this.state.designation}
                  </ProjectText>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    DEPARTMENT
                  </ProjectText>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="getData" fontweight="400">
                    {this.state.department}
                  </ProjectText>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    CONTACT NUMBER
                  </ProjectText>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <ProjectText className="getData" fontweight="400" >
                    {this.state.contactNumber}
                  </ProjectText>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid xs={12}>
              <Grid
                container
                justifyContent="flex-start" alignItems="baseline" style={{ paddingBottom: "10px" }}
              >
                <Grid item xs={6} lg={4}>
                  <ProjectText className="AccountData" fontweight="900">
                    PASSWORD
                  </ProjectText>
                </Grid>
                <Grid item xs={4}>
                  <ProjectText className="Passwordcontent">
                    {`Last change on ${this.state.passwordChangeDate}`}
                  </ProjectText>
                </Grid>
                <Grid container item xs={2} lg={4} justifyContent="flex-end">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="editIcon"
                  >
                    <BorderColorIcon className={
                      this.state.isBuyerRole
                        ? "BuyerEditIcon"
                        : "sellerEditIcon"
                    }
                      onClick={this.editPasswordModal}
                      data-test-id="editPasswordIcon" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="mainDiv">
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid className="mainDiv">
          <Grid item xs={11} lg={8} className="mainFirst" spacing={8}> 
            <ProjectText variant="h5" className="notificationContent">
              Notification Settings
            </ProjectText>
            {["EMAIL NOTIFICATIONS"].map((item, _i) => {
              return (  <>
                  <ProjectText className="emailContent">{item}</ProjectText>
                  <ProjectText className="content">
                    Manage email updates on the following when you are offline, using the toggles below.
                  </ProjectText>
                  <Grid container justifyContent="space-between">
                    {
                      this.state.isBuyerRole ? 
                    [
                      {
                        name: "REQUEST UPDATES",
                        status: this.state.requestEmailStatus,
                        key: "request_email_status"
                      },
                      {
                        name: "OFFER UPDATES",
                        status: this.state.offerEmailStatus,
                        key: "offer_email_status"
                      },
                      { name: "BOOKING UPDATES", status: this.state.bookingEmailStatus, key: "booking_email_status" },
                    ].map((item, index) => {
                      return ( <Grid
                          key={index}
                          item
                          xs={6}
                          sm={6}
                         md={4}
                          lg={2}
                          xl={2} 
                        >
                          <Box>
                            <FormControl component="fieldset">
                              <FormGroup>
                                <FormControlLabel
                                  style={{ marginLeft: "1.75rem" }}
                                  checked={item.status}
                                  control={
                                    <IOSSwitch
                                      data-test-id={`updateEmailStatus-${item.key}`}
                                      onClick={() =>
                                        this.updateEmailStatus(
                                          item.key,
                                          !item.status
                                        )
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </FormControl>
                            <ProjectText className="notificationText">
                              {item.name}
                            </ProjectText>
                            
                          </Box>
                        </Grid>
                      );
                    })
                    :
                    [
                      {
                        name: "LISTING UPDATES",
                        status: this.state.sellerEmailStatus,
                        key: "seller_email_status"
                      },
                      {
                        name: "OFFER UPDATES",
                        status: this.state.offerEmailStatus,
                        key: "offer_email_status"
                      },
                      { name: "BOOKING UPDATES", status: this.state.bookingEmailStatus, key: "booking_email_status" },
                      { name: "DISTRIBUTION LIST", status: this.state.distributionEmailStatus, key: "distribution_email_status" }
                    ].map((item, index) => {
                      return (
                        <Grid
                          key={index}
                          item
                          xs={6}
                          sm={6}
                         md={4}
                          lg={2}
                          xl={2} 
                        >
                          <Box>
                            <FormControl component="fieldset">
                              <FormGroup>
                                <FormControlLabel
                                  style={{ marginLeft: "1.75rem" }}
                                  checked={item.status}
                                  control={
                                    <IOSSwitch
                                      data-test-id={`updateEmailStatus-${item.key}`}
                                      onClick={() =>
                                        this.updateEmailStatus(
                                          item.key,
                                          !item.status
                                        )
                                      }
                                    />
                                  }
                                  label=""
                                />
                              </FormGroup>
                            </FormControl>
                            <ProjectText className="notificationText">
                              {item.name}
                            </ProjectText>
                            
                          </Box>
                        </Grid>
                      );
                    })
                  }
                  </Grid>
                </>
              );
            })}
            <Divider style={{ marginTop: "30px" }} />
            <ProjectText className="emailContent">
              IN-APP NOTIFICATIONS
            </ProjectText>
            <ProjectText className="content">
              Manage in-app notifications on the following when you are online, using the toggles below.
            </ProjectText>
            <Grid container justifyContent="space-between">
              {
                this.state.isBuyerRole ?
              [
                {
                  name: "REQUEST UPDATES",
                  status: this.state.requestAppStatus,
                  key: "request_app_status"
                },
                {
                  name: "OFFER UPDATES",
                  status: this.state.offerAppStatus,
                  key: "offer_app_status"
                },
                { name: "BOOKING UPDATES", status: this.state.bookingAppStatus, key: "booking_app_status" },
                // { name: "DISTRIBUTION LIST", status: this.state.distributionAppStatus, key: "distribution_app_status" }
              ].map((item, index) => {
                return (
                  <Grid key={index} item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Box>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            style={{ marginLeft: "1.75rem" }}
                            checked={item.status}
                            control={
                              <IOSSwitch
                                data-test-id={`updateAppStatus-${item.key}`}
                                onClick={() =>
                                  this.updateAppStatus(
                                    item.key,
                                    !item.status
                                  )
                                }
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </FormControl>
                      <ProjectText className="notificationText">
                        {item.name}
                      </ProjectText>
                   
                    </Box>
                  </Grid>
                );
              })
              :
              [
                {
                  name: "LISTING UPDATES",
                  status: this.state.sellerAppStatus,
                  key: "listing_app_status"
                },
                {
                  name: "OFFER UPDATES",
                  status: this.state.offerAppStatus,
                  key: "offer_app_status"
                },
                { name: "BOOKING UPDATES", status: this.state.bookingAppStatus, key: "booking_app_status" },
                { name: "DISTRIBUTION LIST", status: this.state.distributionAppStatus, key: "distribution_app_status" }
              ].map((item, index) => {
                return (  <Grid key={index} item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <Box>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            style={{ marginLeft: "1.75rem" }}
                            checked={item.status}
                            control={
                              <IOSSwitch
                                data-test-id={`updateAppStatus-${item.key}`}
                                onClick={() =>
                                  this.updateAppStatus(
                                    item.key,
                                    !item.status
                                  )
                                }
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </FormControl>
                      <ProjectText className="notificationText">
                        {item.name}
                      </ProjectText>
                      
                    </Box>
                  </Grid>
                );
              })
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box maxWidth="sm" data-test-id="UserProfile">
        <Navbar SellerPendingScreen={true} AppBarTransparent={false} isBuyerScreen={this.state.isBuyerRole ? true : false} />
        <UserProfileStyle>
          {this.accountInfoNotificationSetting()}
          {this.state.isEditFnameModalOpen && this.renderEditInfoModal()}
          {this.state.isEditPasswordModalOpen && this.renderEditPasswordModal()}
        </UserProfileStyle>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.25)",
    backgroundColor: theme.palette.common.white,
    color: "black",
    fontSize: 14,
    display: " inline-flex",
    justifyContent: "center",
    borderRadius: "8px",
    alignItems: "center",
    fontFamily: "Asap, sans-serif",
    padding: "8px 16px",
  },
  arrow: {
    color: "white"
  }
}))(Tooltip);

const PhoneInputStyle = styled(Box)({
  "& .country-list": {
    top: "0px !important",
    height: "155px !important"
  },
  "& .react-tel-input": {
    fontFamily: "Asap"
  },
  "& .react-tel-input .country-list": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .react-tel-input .country-list .dial-code": {
    fontFamily: "Asap",
    color: '#1b1b1b',
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",
    height: "56px",
    borderRadius: "5px",
    fontSize: "16px",
    boder: "none !important",
    backgroundColor: "white",
  },
  "& .flag-dropdown": {
    boder: "none !important"
  },
  "& .PhoneInput": {
    position: "relative",
    "& .PhoneError": {
      fontSize: "18px",
      color: "red",
      margin: "6px 14px",
      position: "absolute"
    },


  }
});
const PhoneInputStyleGh = styled(Box)({
  display: "flex",
  "@media (max-width: 1200px)": {
    marginTop: '12px',
  }
});
const SaveButton = styled(Box)({
  "& .saveBtn": {
    width: "100%",
    borderRadius: "45px",
    padding: "13px",
    color: "#ffff"
  },
  "& .buyerSaveBtn": {
    backgroundColor: "rgba(255, 92, 0, 0.69)"
  },
  "& .sellerSaveBtn": {
    backgroundColor: "#1653DD"
  }
});
const BuyerUserProfilePageStyle = styled(Box)({
  "& .SmallIcon": {
    height: "1.375rem"
  },
  "& .passwordInput": {
    minWidth: "400px !important",
    "@media (max-width: 1200px)": {
      minWidth: "315px !important",
      marginBottom: "20px"
    },
  }
});
const UserProfileStyle = styled(Box)({
  marginTop: "90px",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#f3eeee",
  padding: "20px",
  "& .mainDiv": {
    background: "#FFF", width: "100%",
    justifyContent: "center",display: "flex",
    borderRadius: "5px",gap: "20px",
    marginBottom: "30px"
  },
  "& .mainFirst": {
    padding: "30px",background: "#CDCDCD40",
    borderRadius: " 16px"
  },
  "& .NotifitextAlign": {
    textAlign: "center"
  },
  "& .notificationText": {
    color: " #363636",fontfamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 900,lineHight: "normal",
    textTransform: "uppercase"
  },
  "& .notificationContent": {
    color: "#1E1E1E",fontFamily: "Asap",
    fontSize: "20px",fontStyle: "normal",
    fontWeight: 900,lineheight: "normal",
    textAlign: "center",padding: "10px"
  },
  "& .emailContent": {
    color: "#363636",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 900,linHeight: "normal",
    textAlign: "center",padding: "10px"
  },
  "& .appnotiFication": {
    color: "#363636",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 700,lineHeight: "normal",
    textTransform: "uppercase",textAlign: "center",
    padding: "30px"
  },
  "& .actInfo": {
    textAlign: "center",marginTop: "20px"
  },
  "& .AccountInfo": {
    color: "#1E1E1E",fontFamily: "Asap",
    fontSize: "28px",fontStyle: "normal",
    fontWeight: 700,lineHeight: "normal",
    padding: "10px"
  },
  "& .AccountData": {
    paddingTop: "5px",paddingLeft: "23px",
    paddingBottom: "15px",color: "#363636",
    fontFamily: "Asap",fontSize: "14px",
    fontStyle: "normal",lineHeight: "normal",
    textTransform: "uppercase",
    "@media (max-width: 500px)": {
      fontSize: "13px",
      paddingBottom: "0px",
    }
  },
  "& .ProjectText": {
    color: "#1E1E1E",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: "400",lineHeight: "normal"
  },
  
  "& .content": {
    textAlign: "center",fontWeight: 400,
    fontSize: 14,color: "#797979",
    padding: "10px"
  },
  "& .BasicInfo": {
    color: " #1E1E1E",fontFamily: "Asap",
    fontSize: "20px",fontStyle: "normal",
    fontWeight: "700",lineHeight: "normal",
    textAlign: "center",padding: "25px"
  },
  "& .basicContainer": {
    background: "#CDCDCD40",
    borderRadius: "16px",
    border: "1px solid #CDCDCD",
    gap: "16px"
  },
  "& .Passwordcontent": {
    color: " #797979",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 400,lineHeight: "normal"
  },
  "& .getData": {
    color: "#1E1E1E",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    lineHeight: "normal",overflow: "visible",
    overflowWrap: "anywhere",
    "@media (max-width: 500px)": {
      fontSize: "13px",
    }
  },
  "& .buyerEditIcon": {
    color: "rgba(255, 92, 0, 0.69)",fontSize: "17px",
    width: "16px",height: "16px"
  },
  "& .sellerEditIcon": {
    color: "#1653DD",fontSize: "17px",
    width: "16px",height: "16px"
  },
  "& .editIcon": {
    padding: "25px",cursor: "pointer",
  },
  "& .pr23": {
    cursor: "pointer",paddingRight: "23px"
  },
  "& .pt20": {
    paddingTop: "20px"
  }
});

// Customizable Area End 